/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type CompositeProduct = Beer & Cider & Coffee & Kombucha & UnverifiedProduct & Product
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum AbbrevWeekDay {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed'
}

/** Basic account request for MMB and PintLabs */
export type AccountRequest = {
  __typename?: 'AccountRequest';
  /** Catch-all for capturing evolving registration data that doesn't require dedicated props. Typically used for marketing feedback. */
  additionalData?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Date string, in UTC, when  */
  approvedDate?: Maybe<Scalars['String']>;
  /** Existing brand they would like to claim or gain access to. */
  claimedBrand?: Maybe<Scalars['ID']>;
  /** Email and phone */
  contact: Contact;
  /** Will always be lambda */
  createdBy?: Maybe<Scalars['String']>;
  /** Date string, in UTC */
  createdDate: Scalars['String'];
  /** Last Name */
  familyName: Scalars['String'];
  /** First Name */
  givenName: Scalars['String'];
  id: Scalars['ID'];
  /** Email address of user */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** Address of new brand */
  newBrandAddress?: Maybe<Address>;
  /** Title of a brand not currently in our database. */
  newBrandTitle?: Maybe<Scalars['String']>;
  /** Notes logged in PintLabs by admins to keep track of reasons why requests were denied or other relevant info. */
  notes?: Maybe<Scalars['String']>;
  /** Permissions requested based on job/responsibilities */
  requestedRole?: Maybe<AccountRequestRole>;
  /** Approval status of the request. Defaults to Pending and changed by admins in PintLabs */
  status?: Maybe<AccountRequestStatus>;
  /** Date string, in UTC */
  updatedDate: Scalars['String'];
  /** If approved, the ID of the user that was created from this account request */
  userId?: Maybe<Scalars['ID']>;
};

export type AccountRequestConnection = {
  __typename?: 'AccountRequestConnection';
  items?: Maybe<Array<Maybe<AccountRequest>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
};

/** Status of the account request. */
export enum AccountRequestRole {
  AdminOwner = 'Admin___Owner',
  Location = 'Location',
  Marketing = 'Marketing',
  Product = 'Product'
}

/** Status of the account request. */
export enum AccountRequestStatus {
  Approved = 'Approved',
  Archived = 'Archived',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export enum Acidity {
  High = 'High',
  Light = 'Light',
  Low = 'Low',
  Medium = 'Medium'
}

/** Object representing a complete mailing address or physical location. */
export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  /** Country code, e.g. `US` */
  country?: Maybe<Scalars['String']>;
  /** Auto-generated by AWS based on address information */
  geo?: Maybe<GeoCoords>;
  postalCode?: Maybe<Scalars['String']>;
  /** Full US state or region name */
  state?: Maybe<Scalars['String']>;
  /** Primary street address. Required for generating GeoCoords */
  street?: Maybe<Scalars['String']>;
  /** unit/apt/suite is optional */
  street2?: Maybe<Scalars['String']>;
};

export enum Alcohol {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export enum AlcoholBase {
  MaltSugar = 'Malt___Sugar',
  Spirit = 'Spirit',
  Wine = 'Wine'
}

export enum Allergen {
  Barley = 'Barley',
  Dairy = 'Dairy',
  Eggs = 'Eggs',
  Grapes = 'Grapes',
  Histamines = 'Histamines',
  Lactose = 'Lactose',
  Nuts = 'Nuts',
  Rye = 'Rye',
  Seafood = 'Seafood',
  Soy = 'Soy',
  Sulphites = 'Sulphites'
}

export enum Appearance {
  Clear = 'Clear',
  Hazy = 'Hazy',
  Opaque = 'Opaque'
}

export enum AppleVariety {
  AshmeadSKernel = 'Ashmead___s_Kernel',
  Baldwin = 'Baldwin',
  Bedan = 'Bedan',
  BinetRouge = 'Binet_Rouge',
  Bramtot = 'Bramtot',
  BrownSnout = 'Brown_Snout',
  BrownSApple = 'Brown___s_Apple',
  Campfield = 'Campfield',
  ChiselJersey = 'Chisel_Jersey',
  Cortland = 'Cortland',
  Dabinett = 'Dabinett',
  EllisBitter = 'Ellis_Bitter',
  EsopusSpitzenburg = 'Esopus_Spitzenburg',
  FrequinRouge = 'Frequin_Rouge',
  Fuji = 'Fuji',
  Gala = 'Gala',
  Geneva = 'Geneva',
  GenevaTremlett = 'Geneva_Tremlett',
  GoldenDelicious = 'Golden_Delicious',
  GoldenRusset = 'Golden_Russet',
  Goldrush = 'Goldrush',
  Graniwinkle = 'Graniwinkle',
  GrannySmith = 'Granny_Smith',
  Harrison = 'Harrison',
  HarryMasterSJersey = 'Harry_Master___s_Jersey',
  HeweSVirginaCrab = 'Hewe___s_Virgina_Crab',
  Honeycrisp = 'Honeycrisp',
  Idared = 'Idared',
  Jonagold = 'Jonagold',
  Kermerrien = 'Kermerrien',
  KingstonBlack = 'Kingston_Black',
  McIntosh = 'McIntosh',
  MedailleDOr = 'Medaille_d___or',
  Michelin = 'Michelin',
  NewtownPippen = 'Newtown_Pippen',
  NorthernSpy = 'Northern_Spy',
  PorterSPerfection = 'Porter___s_Perfection',
  PumpkinSweet = 'Pumpkin_Sweet',
  RedDelicious = 'Red_Delicious',
  Redfield = 'Redfield',
  SmithSCider = 'Smith___s_Cider',
  SomersetRedstreak = 'Somerset_Redstreak',
  SweetBough = 'Sweet_Bough',
  Taliaferro = 'Taliaferro',
  TolmanSweet = 'Tolman_Sweet',
  Wickson = 'Wickson',
  YarlingtonMill = 'Yarlington_Mill'
}

/** Field resolved reduced price for a specific discount and pour size. */
export type AppliedDiscount = {
  __typename?: 'AppliedDiscount';
  /** Discount Preset ID. */
  discountId: Scalars['ID'];
  /** Pour size this discount applies to. */
  pourSize: PourSize;
  /** Calculated discount price from the serving `salePrice` first, then `price` second. */
  reducedPrice?: Maybe<Scalars['Float']>;
};

export enum ApproximateDistance {
  ShortBikeRide = 'Short_Bike_Ride',
  ShortCarRide = 'Short_Car_Ride',
  ShortWalk = 'Short_Walk'
}

/** Asset base Interface to be implemented by S3Asset and Cloudinary Asset Types */
export type Asset = {
  /** Serves as image title and `alt` content when served in front-end. */
  alt?: Maybe<Scalars['String']>;
  /** (Resolved) Brand to which this asset is attached. i.e. asset is used as Logo */
  brand?: Maybe<Brand>;
  /** File size, in bytes, of the unmodified asset */
  bytes?: Maybe<Scalars['Int']>;
  /** Additional text to describe the asset. Only displays in certain placements on front-end. */
  caption?: Maybe<Scalars['String']>;
  /** Primary organizational category. If left empty the asset will be categorized according to the `type` field. */
  category?: Maybe<AssetCategory>;
  /** Email address of user. Auto generated. */
  createdBy: Scalars['String'];
  /** ISO Date string in UTC. Auto generated. */
  createdDate: Scalars['String'];
  /** Height, in pixels, of the full-size asset */
  height?: Maybe<Scalars['Int']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Email address of user. Auto generated. */
  lastUpdatedBy: Scalars['String'];
  /** (Resolved) Locations to which this asset is attached */
  locations?: Maybe<Array<Maybe<Location>>>;
  /** S3 bucket path for Legacy 3S Assets. If populated indicates that this asset is hosted on S3. */
  path?: Maybe<Scalars['String']>;
  /** (Resolved) Products to which this asset is attached */
  products?: Maybe<Array<Maybe<CompositeProduct>>>;
  /** indicates whether the asset can be used/seen by other users */
  shared?: Maybe<Scalars['Boolean']>;
  /** Organizational tags created by users. Supported in MediaLibrary queries */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** MIME type of the asset. e.g. `image/jpg` */
  type?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto generated. */
  updatedDate: Scalars['String'];
  /** Width, in pixels, of the full-size asset */
  width?: Maybe<Scalars['Int']>;
};

export enum AssetCategory {
  Label = 'Label',
  Logo = 'Logo',
  Photo = 'Photo',
  Video = 'Video'
}

export enum Availability {
  Archived = 'Archived',
  Limited = 'Limited',
  Seasonal = 'Seasonal',
  YearRound = 'Year_Round'
}

export type BmSubscriptionAssociation = {
  __typename?: 'BMSubscriptionAssociation';
  bmSubId: Scalars['String'];
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  lastUpdatedBy: Scalars['String'];
  role: BrewMenuRole;
  updatedDate: Scalars['String'];
};

export enum BaseSpirit {
  Apertif = 'Apertif',
  Bourbon = 'Bourbon',
  Brandy = 'Brandy',
  Champagne = 'Champagne',
  Gin = 'Gin',
  Liqueur = 'Liqueur',
  Rum = 'Rum',
  Scotch = 'Scotch',
  Tequila = 'Tequila',
  Vodka = 'Vodka',
  Whiskey = 'Whiskey',
  Wine = 'Wine'
}

export enum BatteryReplacementPlan {
  '1Year' = '_1_Year',
  '6Month' = '_6_Month',
  '90Days' = '_90_Days'
}

/** Batery status as human readable labels based on voltage */
export enum BatteryStatus {
  /** Voltage is <= 3.1 */
  Empty = 'Empty',
  /** Voltage is >= 3.4 */
  Full = 'Full',
  /** Voltage is between 3.2 - 3.3 */
  Low = 'Low'
}

export enum BeanRegion {
  Africa = 'Africa',
  Asia = 'Asia',
  CentralAmerica = 'Central_America',
  SouthAmerica = 'South_America'
}

export enum BeanType {
  Arabica = 'Arabica',
  Excelsa = 'Excelsa',
  Liberica = 'Liberica',
  Robusta = 'Robusta'
}

export type Beer = Product & WildCardTitleSearch & {
  __typename?: Scalars['String'];
  abv?: Maybe<Scalars['Float']>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  brand?: Maybe<Brand>;
  carbonation?: Maybe<Carbonation>;
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  guid?: Maybe<Scalars['String']>;
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  ibu?: Maybe<Scalars['Int']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  isNewProduct?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  originalGravity?: Maybe<Scalars['Float']>;
  perceivedBitterness?: Maybe<Scalars['Int']>;
  perceivedProductBitterness?: Maybe<PerceivedBitterness>;
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  releaseDate?: Maybe<Scalars['String']>;
  servingTemperature?: Maybe<ServingTemperature>;
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  slug?: Maybe<Scalars['String']>;
  srm?: Maybe<Scalars['Int']>;
  /** The beer style information */
  style?: Maybe<Style>;
  styleAlias?: Maybe<Scalars['String']>;
  tastingExperience?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

/** How often a `PaymentMethod` will be billed. */
export enum BillingCycle {
  /** Once a year. */
  Annual = 'Annual',
  /** Once a month. */
  Monthly = 'Monthly'
}

/** Billing policy information for an organization. */
export type BillingPolicy = {
  __typename?: 'BillingPolicy';
  /** Billing address. */
  address?: Maybe<Address>;
  /** How often a `PaymentMethod` will be billed. */
  billingCycle?: Maybe<BillingCycle>;
  /** Unique ID associated with the payment processing platform for this policy. */
  billingId?: Maybe<Scalars['String']>;
  /** Contact information. */
  contact?: Maybe<Contact>;
  /** Last date a payment was recieved. ISO Date string in UTC. */
  lastPaymentDate?: Maybe<Scalars['String']>;
  /** Nest date a payment is due. ISO Date string in UTC. */
  nextPaymentDate?: Maybe<Scalars['String']>;
  /** Method of payment currently selected for this policy. */
  paymentMethod?: Maybe<PaymentMethod>;
};

/**
 * The `Board` type internally stores a special field called `items` in DynamoDB for determining the
 * favorites and routes that are associated with it. This field contains a list of object with the
 * following: `itemType`, `itemId`, and `parentId`. The `itemType` directly maps to the `BoardItemType`
 * on the GraphQL schema. The `itemId` is the ID of the item that is associated with the board. The
 * `parentId` is the ID of the parent item that is associated with the item. The `parentId` is not
 * required for some types like `Brand` and `Route`, but is required for `Product` and `Location`.
 */
export type Board = {
  __typename?: 'Board';
  /** Will always be lambda */
  createdBy: Scalars['String'];
  /** Date string, in UTC */
  createdDate: Scalars['String'];
  /** Description of the board. */
  description?: Maybe<Scalars['String']>;
  /** Favorited Brands, Products and Locations */
  favorites?: Maybe<Favorites>;
  /** ID of the board. */
  id: Scalars['ID'];
  /** Is the Board private or publicly accessible by other users via. sharing. */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** Email address of user */
  lastUpdatedBy: Scalars['String'];
  /** Routes that are attached to this board */
  routes?: Maybe<Array<Maybe<BreweryRoute>>>;
  /** Title of the board. */
  title: Scalars['String'];
  /** Date string, in UTC */
  updatedDate: Scalars['String'];
};

/** A BoardItem can be one of the following. This helps us resolve and manage Board Items. */
export enum BoardItemType {
  Brand = 'Brand',
  Location = 'Location',
  Product = 'Product',
  Route = 'Route'
}

export enum Body {
  Full = 'Full',
  Light = 'Light',
  Medium = 'Medium',
  Moderate = 'Moderate',
  Thin = 'Thin'
}

/**
 * Brand
 *
 * Contains specific infromation about a Brands. Each Brand can have unique products,
 * and locations associated with it.
 *
 * PK: brand
 * SK: id
 */
export type Brand = WildCardTitleSearch & {
  __typename?: 'Brand';
  /** Address information for a Brand. */
  address?: Maybe<Address>;
  /** (Resolved) Assets tied to this specific Brand. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** Brewers association ID. */
  baId?: Maybe<Scalars['String']>;
  /** Contact information for a Brand. */
  contact?: Maybe<Contact>;
  /** Email address of the user who created the keg. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** Date string, in UTC, when the keg was created. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the brand. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Overrides the title on consumer-facing apps. */
  displayTitle?: Maybe<Scalars['String']>;
  /** (Resolved) Featured product of a Brand. Storing product Id in DDB. */
  featuredBrew?: Maybe<CompositeProduct>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content */
  guid?: Maybe<Scalars['String']>;
  /** Brand ID. PK. Auto-generated */
  id: Scalars['ID'];
  /** Email address of the user who last updated the keg. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** (Resolved) Locations associated with this Brand. */
  locations?: Maybe<Array<Maybe<Location>>>;
  /** All Media assets for this brand. */
  mediaLibrary?: Maybe<Array<Maybe<Asset>>>;
  /** A Brands Organization. Resolved Field */
  organization?: Maybe<Organization>;
  /** (Resolved) Products associated with this Brand. */
  products?: Maybe<Array<Maybe<CompositeProduct>>>;
  /** Slug of the Brand. Auto-Generated in createBrand using the title. */
  slug?: Maybe<Scalars['String']>;
  /** Social media platforms associated with the Brand. */
  socialMedia?: Maybe<Array<Maybe<SocialMedia>>>;
  /** Additional information about a Brand. */
  subtitle?: Maybe<Scalars['String']>;
  /** Title of the brand. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits of a Brand - contains status, brandStatus, brandTypes, brewTypes, isIndepentdent, isContract. */
  traits?: Maybe<BrandTraits>;
  /** Date string, in UTC, when the keg was last updated. Auto-generated. */
  updatedDate: Scalars['String'];
  /** Website URL associated with the Brand. */
  website?: Maybe<Scalars['String']>;
  /** Year that the Brand was established. */
  yearEstablished?: Maybe<Scalars['String']>;
};

export type BrandAssociation = {
  __typename?: 'BrandAssociation';
  brandId: Scalars['String'];
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  lastUpdatedBy: Scalars['String'];
  role: MarketMyBreweryRole;
  updatedDate: Scalars['String'];
};

export type BrandConnection = {
  __typename?: 'BrandConnection';
  items?: Maybe<Array<Maybe<Brand>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export type BrandMessage = {
  __typename?: 'BrandMessage';
  brand: Brand;
  message: Scalars['String'];
};

/** Connection object returned by `superAdminUpdateBrandProductAvailability` endpoint. */
export type BrandProductAvailabilityConnection = {
  __typename?: 'BrandProductAvailabilityConnection';
  /** Products that were updated for the Brand. */
  items?: Maybe<Array<Maybe<CompositeProduct>>>;
  /** Total number of products updated for the Brand. */
  returnedCount?: Maybe<Scalars['Int']>;
};

export enum BrandStatus {
  Active = 'Active',
  Archived = 'Archived',
  InReview = 'In_Review',
  Pending = 'Pending'
}

export type BrandThroughput = {
  __typename?: 'BrandThroughput';
  /** Brandthroughput returns a list of results generated from the Athena query. */
  brandThroughput?: Maybe<Array<Maybe<BrandThroughputDetail>>>;
};

/** BrandThroughput returned result from the Athena query */
export type BrandThroughputDetail = {
  __typename?: 'BrandThroughputDetail';
  /** The returned throughput of the brand. */
  brandThroughput?: Maybe<Scalars['Float']>;
  /** The returned title of the brand. */
  brandTitle?: Maybe<Scalars['String']>;
  /** The returned Performance Platform Subscription name. */
  ppSubTitle?: Maybe<Scalars['String']>;
  /** The returned timestamp of the throughput. */
  timestamp?: Maybe<Scalars['String']>;
};

export type BrandTraits = {
  __typename?: 'BrandTraits';
  brandStatus?: Maybe<BrandStatus>;
  brandTypes?: Maybe<BrandType>;
  brewTypes?: Maybe<Array<Maybe<BrewTypes>>>;
  isContract?: Maybe<Scalars['Boolean']>;
  isIndependent?: Maybe<Scalars['Boolean']>;
  /** DEPRECATED since 1.19 use 'brandStatus' instead EOL 1.21 */
  status?: Maybe<BrandStatus>;
};

export enum BrandType {
  Craft = 'Craft',
  Mass = 'Mass',
  NonBrewery = 'Non_Brewery'
}

/** All the data needed to display a brew menu on a TV, desktop, mobile device or in print.  */
export type BrewMenu = {
  __typename?: 'BrewMenu';
  /** If used provides a date range for which the menu is active to avoid showing inactive menus and help with scheduling. `[startDate,endDate]` Expects null or 2 dates. Date strings, in UTC of when the menu is active. Start and end dates, respectively. */
  activeDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Email address of user. Auto-generated. */
  createdBy: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** Menu content to display. */
  displays?: Maybe<Array<Maybe<BrewMenuDisplay>>>;
  /** UUID. Represents the SK. */
  id: Scalars['ID'];
  /** Email address of user. Auto-generated. */
  lastUpdatedBy: Scalars['String'];
  /** BDB location to which the menu is connected, if any. Crucial for populating information about the location such as hours, address, logos, etc. */
  locationId?: Maybe<Scalars['ID']>;
  /** DEPRECATED, EOL v1.29, use `sectionIds`. Ordered list of composite IDs for `MenuItems` and `MenuSections` on this `BrewMenu`. */
  menuItemAndSectionCompositeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** DEPRECATED, EOL v1.28, use `menuItemOrSectionIds`. (Resolved) Items to be listed on a BrewMenu. */
  menuItems?: Maybe<Array<Maybe<MenuItem>>>;
  /** DEPRECATED, EOL v1.29, use `sections`. (Resolved) Ordered list of `MenuItems` and `MenuSections` on this `BrewMenu`. */
  menuItemsAndSections?: Maybe<Array<Maybe<MenuItemOrSection>>>;
  /** PP Subscription to which the menu is connected, if any. Crucial for populating information about the location such as hours, address, logos, etc. */
  ppSubId?: Maybe<Scalars['ID']>;
  /** Ordered list of `MenuSection` IDs. */
  sectionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** (Resolved) Ordered list of `MenuSections` on this `BrewMenu`. */
  sections?: Maybe<Array<Maybe<MenuSection>>>;
  /** See BrewMenuStatus enum for status options. */
  status: BrewMenuStatus;
  /** Name of the menu. */
  title: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

/** Menu category options for organizing products in signage/display and filtering on personal devices */
export enum BrewMenuCategory {
  Beverages = 'Beverages',
  Featured = 'Featured',
  Food = 'Food',
  Kids = 'Kids',
  Merch = 'Merch',
  New = 'New',
  NonAlcoholic = 'NonAlcoholic',
  Other = 'Other',
  Pets = 'Pets',
  Seasonal = 'Seasonal',
  ToGo = 'ToGo'
}

export type BrewMenuConnection = {
  __typename?: 'BrewMenuConnection';
  /** Results found from query */
  items?: Maybe<Array<Maybe<BrewMenu>>>;
  /** Pagination token used for obtaining the next page of results */
  nextToken?: Maybe<Scalars['String']>;
  /** Total number of results found from query */
  returnedCount?: Maybe<Scalars['Int']>;
};

/**
 * Custom Product `menuItemType` for Menu Item. Provide a `productType` and the fields that
 * correspond to that type. Do NOT mismatch fields.
 */
export type BrewMenuCustomProduct = {
  __typename?: 'BrewMenuCustomProduct';
  /** Map field containing the product if it is a specific brew type. */
  beverage?: Maybe<UBrewMenuCustomBeverage>;
  /** Email address of user. Auto-generated. */
  createdBy: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** Description of the BrewMenuCustomProduct. */
  description?: Maybe<Scalars['String']>;
  /** UUID. SK of BrewMenuCustomProduct. */
  id: Scalars['ID'];
  /** Email address of user. Auto-generated. */
  lastUpdatedBy: Scalars['String'];
  /** See enum CustomProductType for available options. */
  productType: CustomProductType;
  /** List of available serving sizes and prices for this product. */
  servings?: Maybe<Array<Maybe<ServingSize>>>;
  /** Additional information about a BrewMenuCustomProduct. */
  subtitle?: Maybe<Scalars['String']>;
  /** Name of the BrewMenuCustomProduct. */
  title: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

/** Query connection object that contains results that can be paginated. */
export type BrewMenuCustomProductConnection = {
  __typename?: 'BrewMenuCustomProductConnection';
  /** Brew Menu Custom Products. */
  items?: Maybe<Array<Maybe<BrewMenuCustomProduct>>>;
  /** Pagination token used for obtaining the next page of results. */
  nextToken: Scalars['String'];
  /** Items found during the query. */
  returnedCount?: Maybe<Scalars['Int']>;
};

/** Displays provide context to the content of a menu. They are like TapSensors in that a display represents a peice of hardware with indivudual settings and ready to accept data e.g. a Menu */
export type BrewMenuDisplay = {
  __typename?: 'BrewMenuDisplay';
  /** Frontend managed Cloudinary Asset ID used as background watermark. If null, no associated image. */
  backgroundWatermarkAssetId?: Maybe<Scalars['String']>;
  /** Frontend managed Cloudinary Asset ID used as this displays Logo. If null, no associated logo. */
  cloudinaryAssetId?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** Overrides the title on consumer-facing apps. */
  displayTitle?: Maybe<Scalars['String']>;
  /** The type of the display. Used to control which settings are exposed in the admin and which templates are used to render the menu on the front-end. */
  displayType: DisplayType;
  /** UUID. Auto-generated. */
  id: Scalars['ID'];
  /** Email address of user. Auto-generated. */
  lastUpdatedBy: Scalars['String'];
  /** (Resolved) The Brew Menu content. Stores the Brew Menu ID in DDB. */
  menu?: Maybe<BrewMenu>;
  /** DEPRECATED */
  promotionalImageAssetIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** List of Frontend managed Cloudinary Asset IDs and filenames used as promotional images on the display. If null or empty, no associated images. */
  promotionalImageAssets?: Maybe<Array<Maybe<CloudinaryAssetIdAndName>>>;
  /** See type BrewMenuDisplaySettings for more information. */
  settings?: Maybe<BrewMenuDisplaySettings>;
  /** URL slug. Auto-generated. */
  slug?: Maybe<Scalars['String']>;
  /** See enum BrewMenuStatus for available options. */
  status: BrewMenuStatus;
  /** Title of the display. Used to generate the slug and for admin purposes. */
  title: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

export type BrewMenuDisplayConnection = {
  __typename?: 'BrewMenuDisplayConnection';
  /** Results found from query */
  items?: Maybe<Array<Maybe<BrewMenuDisplay>>>;
  /** Pagination token used for obtaining the next page of results */
  nextToken?: Maybe<Scalars['String']>;
  /** Total number of results found from query */
  returnedCount?: Maybe<Scalars['Int']>;
};

/** Configurations that control the display and styles of the Brew Menu. */
export type BrewMenuDisplaySettings = {
  __typename?: 'BrewMenuDisplaySettings';
  /** Does this menu paginate. Defaults to `true`. */
  allowPagination: Scalars['Boolean'];
  /** Background color for displays. Defaults to `#FFFFFF`. */
  backgroundColor: Scalars['String'];
  /** Color themes for menu. */
  colorScheme: ColorScheme;
  /** Number of menu columns. */
  columns: DisplayColumns;
  /** Fallback product image type. */
  fallbackImage: ProductImage;
  /** Font for Brew Menu Display. */
  font: DisplayFont;
  /** Brew Menu Display font size. Defaults to `M`. */
  fontSize?: Maybe<FontSize>;
  /** Type of media to used on Brew Menu Display. */
  mediaType: MediaType;
  /** Number of menu items per screen. Defaults to 6. */
  numberOfItemsPerScreen?: Maybe<Scalars['Int']>;
  /** Menu orientation. */
  orientation: DisplayOrientation;
  /** Primary font color for displays. Defaults to `#CBF17A`. */
  primaryFontColor: Scalars['String'];
  /** Primary product image type. */
  productImage: ProductImage;
  /** Time in seconds between display rotations. */
  rotationInterval: DisplayRotationInterval;
  /** Enforced screen mode for customer facing menus. */
  screenMode: ScreenMode;
  /** Secondary font color for displays. Defaults to `#FFFFFF`. */
  secondaryFontColor: Scalars['String'];
  /** Background color of sections. Defaults to `#000000`. */
  sectionBackgroundColor: Scalars['String'];
  /** Color of sections. Defaults to `#FFFFFF`. */
  sectionFontColor: Scalars['String'];
  /** Font size for the section title. Defaults to 24px. */
  sectionTitleFontSize?: Maybe<Scalars['String']>;
  /** Determines if ABV should be displayed. Defaults to `true`. */
  showABV: Scalars['Boolean'];
  /** Display the brewery. Defaults to `true`. */
  showBrewery: Scalars['Boolean'];
  /** Determines if the description should be displayed. Defaults to `true`. */
  showDescription?: Maybe<Scalars['Boolean']>;
  /** Determines if IBU should be displayed. Defaults to `true`. */
  showIBU: Scalars['Boolean'];
  /** Display the breweries city and state. Defaults to `true`. */
  showLocation?: Maybe<Scalars['Boolean']>;
  /** If the logo will be shown on all menu pages or just the first. Defaults to `true`. */
  showLogoOnAllPages?: Maybe<Scalars['Boolean']>;
  /** Determines if On Deck Kegs should be displayed. Defaults to `true`. */
  showOnDeck: Scalars['Boolean'];
  /** Display pricing information. Defaults to `true`. */
  showPricing: Scalars['Boolean'];
  /** Determines if Primary Flavor Notes should be displayed. Defaults to `true`. */
  showPrimaryFlavorNotes: Scalars['Boolean'];
  /** Display the product name. Defaults to `true`. */
  showProductName: Scalars['Boolean'];
  /** Display a sales price, or discounted price. */
  showReducedPrice?: Maybe<Scalars['Boolean']>;
  /** Display the SRM value. Defaults to `true`. */
  showSRM: Scalars['Boolean'];
  /** Show menu sectioning. Defaults to `true`. */
  showSection: Scalars['Boolean'];
  /** Determines if Serving Types should be displayed. Defaults to `true`. */
  showServingType?: Maybe<Scalars['Boolean']>;
  /** Display size. Defaults to `true`. */
  showSize: Scalars['Boolean'];
  /** Deterimines if Product Style should be displayed. Defaults to `true`. */
  showStyle: Scalars['Boolean'];
  /** Determines if the Tap Number should be displayed. Defaults to `true`. */
  showTapNumber?: Maybe<Scalars['Boolean']>;
  /** Determines if Unknown Kegs should be displayed. Defaults to `true`. */
  showUnknownKegs: Scalars['Boolean'];
  /** Tertiary font color for displays. Defaults to `#B5B5B5`. */
  tertiaryFontColor: Scalars['String'];
};

/** Supported item types for `BrewMenu`. */
export enum BrewMenuItemType {
  /** `MenuItem` associated with a single `Product`, `TapSensor`, or `CustomProduct`. */
  MenuItem = 'MenuItem',
  /** Named `MenuSection` with an ordered list of `MenuItems` associated with it. */
  MenuSection = 'MenuSection'
}

/**
 * Connection objedct used for returning results from a query
 * for updateBrewMenuItemVisbility.  MenuItemVisbility is a
 * subset of MenuItem due to the nature of limited results returned
 * from a batch update in DynamoDB.
 */
export type BrewMenuItemVisbilityConnection = {
  __typename?: 'BrewMenuItemVisbilityConnection';
  /** MenuItemVisility results found from query. */
  items?: Maybe<Array<Maybe<MenuItemVisibility>>>;
  /** Total number of results found from query */
  returnedCount?: Maybe<Scalars['Int']>;
};

/** Availability options for a Brew Menu Product. */
export type BrewMenuProductAvailability = {
  __typename?: 'BrewMenuProductAvailability';
  /** (Override) Main switch: if this is false the rest of the data is ignored. */
  available: Scalars['Boolean'];
  bottled: Scalars['Boolean'];
  canned: Scalars['Boolean'];
  cask: Scalars['Boolean'];
  crowler: Scalars['Boolean'];
  growler: Scalars['Boolean'];
  nitro: Scalars['Boolean'];
  onTap: Scalars['Boolean'];
  /** Redundant to canned and bottled, but provides a more generic option */
  packaged: Scalars['Boolean'];
};

export enum BrewMenuRole {
  Owner = 'owner',
  Staff = 'staff'
}

/** Current status of the Brew Menu. */
export enum BrewMenuStatus {
  Active = 'Active',
  Archived = 'Archived',
  Draft = 'Draft',
  Scheduled = 'Scheduled'
}

/** A subscription to BrewMenu a.k.a. a BMSub. It can be attached to a PP Subscription, Brand or operate independently. It is the primary way to control access to BrewMenu features on both the admin and consumer-facing sides. */
export type BrewMenuSubscription = {
  __typename?: 'BrewMenuSubscription';
  /** ID of the Brand to which this BMSub is attached. */
  brandId?: Maybe<Scalars['ID']>;
  /** Email address of user. Auto-generated. */
  createdBy: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** This is a resolved field. List of BrewMenuDisplays. */
  displays?: Maybe<Array<Maybe<BrewMenuDisplay>>>;
  /** ID of HubSpot company record related to this PP Subscription. */
  hubSpotId?: Maybe<Scalars['String']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Disables access to BrewMenu features without altering the tier or subscription information. Intended for SuperAdmin use only. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy: Scalars['String'];
  /** IDs of a Brand locations to which this BMSub is attached. */
  locationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Asset used as logo for this BrewMenuSubscription */
  logo?: Maybe<OrganizationAsset>;
  /** This is a resolved field. List of BrewMenus. */
  menus?: Maybe<Array<Maybe<BrewMenu>>>;
  /** A Brew Menu Subscriptions Organization */
  organization?: Maybe<Organization>;
  /** ID of the PPSub to which this BMSub is attached. */
  ppSubId?: Maybe<Scalars['ID']>;
  settings?: Maybe<BrewMenuSubscriptionSettings>;
  /** Unique URL-safe identifier. Serves as the base path for subscription content. e.g. `/[BrewMenuSubscription.slug]/display/1` */
  slug?: Maybe<Scalars['String']>;
  /** Subscription tier; controls access to features. */
  subscriptionTier?: Maybe<BrewMenuSubscriptionTier>;
  /** Subscription display title; only necessary if not attached to a brand or PPSub. */
  title?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

export type BrewMenuSubscriptionConnection = {
  __typename?: 'BrewMenuSubscriptionConnection';
  /** Results found from query */
  items?: Maybe<Array<Maybe<BrewMenuSubscription>>>;
  /** Pagination token used for obtaining the next page of results */
  nextToken?: Maybe<Scalars['String']>;
  /** Total number of results found from query for the current page */
  returnedCount?: Maybe<Scalars['Int']>;
  /** Total number of results found from query */
  totalResults?: Maybe<Scalars['Int']>;
};

export type BrewMenuSubscriptionSettings = {
  __typename?: 'BrewMenuSubscriptionSettings';
  /** Address of BrewMenuSubscription, if not attached to PPSub or Brand location */
  address?: Maybe<Address>;
  /** Contact information for BrewMenuSubscription, if not attached to PPSub or Brand location */
  contact?: Maybe<Contact>;
};

/** Controls which features users have access to within `BrewMenu`. Mirrored from Stripe subscription status. */
export enum BrewMenuSubscriptionTier {
  /** Documentation for this enum is provided via https://docs.stripe.com/api/subscriptions/object#subscription_object-status */
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export enum BrewOption {
  Beer = 'Beer',
  Cider = 'Cider',
  Cocktails = 'Cocktails',
  Coffee = 'Coffee',
  GlutenFree = 'Gluten_Free',
  Kombucha = 'Kombucha',
  NonAlcoholic = 'Non__Alcoholic',
  Seltzer = 'Seltzer',
  Wine = 'Wine'
}

export enum BrewTypes {
  Beer = 'Beer',
  Cider = 'Cider',
  Cocktail = 'Cocktail',
  Coffee = 'Coffee',
  Kombucha = 'Kombucha',
  Mead = 'Mead',
  NonAlcoholic = 'Non__Alcoholic',
  Seltzer = 'Seltzer',
  Unverified = 'Unverified',
  Wine = 'Wine'
}

export type BreweryRoute = {
  __typename?: 'BreweryRoute';
  /** List of board ids that this route is included in. */
  boardAssociations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** coordinates for each stop on the route */
  coordinates?: Maybe<Array<Maybe<BreweryRouteGeoCoord>>>;
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  /** description of the route, if it has one */
  description?: Maybe<Scalars['String']>;
  /** how far is this route trip? */
  distance?: Maybe<Scalars['Float']>;
  /** how long would this trip take? */
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Boolean to indicate if a BreweryRoute should be returned. */
  isActive: Scalars['Boolean'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** Notes logged by users to comment on saved routes. */
  notes?: Maybe<Scalars['String']>;
  /** routeId provided by MapBox */
  routeId: Scalars['String'];
  /** title of the route */
  title: Scalars['String'];
  updatedDate: Scalars['String'];
};

export type BreweryRouteGeoCoord = {
  __typename?: 'BreweryRouteGeoCoord';
  /**
   * Full route stop address generated from Mapbox. This is parsed based
   * off the response payload from Mapbox and provides more flexibility in
   * the future if we want to only display a few fields off the address
   * such as street, city, state, etc. The address field names in MapBox
   * do not map 1:1 with our address fields, so they must be transformed
   * to match our structure.
   */
  address?: Maybe<Address>;
  /** Route stop lat */
  lat: Scalars['Float'];
  /**
   * Optional location associated with a route stop. This allows pulling
   * the location's brand asset to produce an image for the route stop.
   *
   * There are some caveats to note with this field. 1, there is no validation
   * on the location id passed. 2, there is no way currently for the backend
   * to retroactively update routes if a location has been deleted. Neither of
   * the above should affect the functionality of this endpoint, as a location not
   * found will simply be returned as null and handled as a route stop without an
   * asset would be.
   */
  location?: Maybe<Location>;
  /** Route stop lon */
  lon: Scalars['Float'];
  /**
   * The place name is returned from mapbox as an already stringified
   * version of the address. This reduces flexibility in how the address
   * is used since you must display the entire payload returned from
   * mapbox, but is simple to store for future use.
   */
  placeName?: Maybe<Scalars['String']>;
  /** Route stop title */
  title?: Maybe<Scalars['String']>;
};

export enum Carbonation {
  Effervescent = 'Effervescent',
  Sparkling = 'Sparkling',
  Still = 'Still'
}

export type Category = {
  __typename?: 'Category';
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Cider = Product & WildCardTitleSearch & {
  __typename?: Scalars['String'];
  /** Alcohol of the cider as a percentage with up to 1 decimal. */
  abv?: Maybe<Scalars['Float']>;
  /** Acidity of a cider. */
  acidity?: Maybe<Acidity>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Enum variety of apples. */
  appleVariety?: Maybe<Array<Maybe<AppleVariety>>>;
  /** Product label. Supports multiple assets, but front-end expects only 1. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** Enum for the cider Body. */
  body?: Maybe<Body>;
  /** PK of a Brand. */
  brand?: Maybe<Brand>;
  /** brixCount is the cider's measured sweetness. */
  brixCount?: Maybe<Scalars['Float']>;
  /** Enum for the cider Carbonation. */
  carbonation?: Maybe<Carbonation>;
  /** Enum for the cider color. */
  ciderColor?: Maybe<CiderColor>;
  ciderFamily?: Maybe<CiderFamily>;
  /** Color of the liquid epressed as Tailwind color e.g. `flower-fruity-apple` or `srm-19`. */
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the brand. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Enum of foods to pair with a cider Product. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  /** Enum origin of fruit. */
  fruitOrigin?: Maybe<Array<Maybe<FruitOrigin>>>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: Maybe<Scalars['String']>;
  /** Side profile flavors of a cider. */
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Ingredients of the product. */
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  /** Boolean for if the cider is new. */
  isNewProduct?: Maybe<Scalars['Boolean']>;
  /** Boolean if product is verified */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** List of locations where this cider is served either in draft or packaged form. */
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. Use `locationRelationships`. */
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Enum variety of pears. */
  pearVariety?: Maybe<Array<Maybe<PearVariety>>>;
  /** Enum for the cider sweetness. */
  perceivedSweetness?: Maybe<PerceivedSweetness>;
  /** Main profile flavors of a cider. */
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** Date the product was first made available to the public. Expects a valid date string such as `11/27/1984` though the front-end may only render the year in most cases. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Enum of serving temperatures for a cider Product. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** List of suggested glassware to use when drinking this product. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of the cider. Auto-Generated in createProduct using the title. */
  slug?: Maybe<Scalars['String']>;
  /** The cider style information */
  style?: Maybe<Style>;
  /** Enum for the cider Tannin. */
  tannin?: Maybe<Tannin>;
  /** Description of the cider experience from pour to sip. Limited by the front-end to 300 characters. */
  tastingExperience?: Maybe<Scalars['String']>;
  /** Title of the cider Product. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits of a cider Product. Contains availability, nutrition, allergens, and otherTraits. */
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export enum CiderColor {
  Amber = 'Amber',
  Copper = 'Copper',
  Garnet = 'Garnet',
  Gold = 'Gold',
  Pink = 'Pink',
  Purple = 'Purple',
  Ruby = 'Ruby',
  Straw = 'Straw',
  Yellow = 'Yellow'
}

export enum CiderFamily {
  AppleCider = 'Apple_Cider',
  BarrelAgeCider = 'Barrel_Age_Cider',
  BotanicalCider = 'Botanical_Cider',
  FruitCider = 'Fruit_Cider',
  HarvestDrivenCider = 'Harvest_Driven_Cider',
  OtherSpecialtyCider = 'Other_Specialty_Cider',
  Perry = 'Perry',
  SpecialtyCider = 'Specialty_Cider'
}

/**
 * CloudinaryAsset
 *
 * An asset that exists in Cloudinary. Can be referenced via
 * the cloudinaryId field.
 */
export type CloudinaryAsset = Asset & {
  __typename?: 'CloudinaryAsset';
  /** Alt text of the image, required for accesibility */
  alt?: Maybe<Scalars['String']>;
  /** Brand to which this asset belongs */
  brand?: Maybe<Brand>;
  /** File size, in bytes, of the unmodified asset */
  bytes?: Maybe<Scalars['Int']>;
  caption?: Maybe<Scalars['String']>;
  /** Primary organizational category. If left empty the asset will be categorized according to the `type` field. */
  category?: Maybe<AssetCategory>;
  /** ID used to query assets on Cloudinary CDN. On Cloudinary API responses this value maps to `public_id` */
  cloudinaryId?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  /** Height, in pixels, of the full-size asset */
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lastUpdatedBy: Scalars['String'];
  /** Locations to which this asset is attached */
  locations?: Maybe<Array<Maybe<Location>>>;
  /** Legacy asset path within a S3 bucket. If this field is populated the fron-ent will attempt to load the asset from S3 instead of Cloudinary */
  path?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<CompositeProduct>>>;
  /** Allow all other user roles to view and use this asset */
  shared?: Maybe<Scalars['Boolean']>;
  /** Ad-hoc organizational tags created by the user. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** MIME type of the asset */
  type?: Maybe<Scalars['String']>;
  updatedDate: Scalars['String'];
  /** Width, in pixels, of the full-size asset */
  width?: Maybe<Scalars['Int']>;
};

export type CloudinaryAssetIdAndName = {
  __typename?: 'CloudinaryAssetIdAndName';
  cloudinaryAssetFileName?: Maybe<Scalars['String']>;
  cloudinaryAssetId?: Maybe<Scalars['String']>;
};

export type Cocktail = Product & WildCardTitleSearch & {
  __typename?: 'Cocktail';
  /** Alcohol of this cocktail as a percentage with up to 1 decimal. */
  abv?: Maybe<Scalars['Float']>;
  /** Enum has the Alcohol level for the wine. */
  alcohol?: Maybe<Alcohol>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Product label. Supports multiple assets, but front-end expects only 1. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** Enum for the base spirit used for cocktail. */
  baseSpirit?: Maybe<Array<Maybe<BaseSpirit>>>;
  /** Enum for the cocktails Body. */
  body?: Maybe<Body>;
  /** PK of a Brand. */
  brand?: Maybe<Brand>;
  /** Enum for the products Carbonation. */
  carbonation?: Maybe<Carbonation>;
  /** Enum for the cocktails color. */
  cocktailColor?: Maybe<CocktailColor>;
  /** Color of the liquid epressed as Tailwind color e.g. `flower-fruity-apple` or `srm-19`. */
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the brand. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Enum of foods to pair with a cocktail Product. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  /** Enum for the garnishs used for cocktail. */
  garnish?: Maybe<Array<Maybe<Garnish>>>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: Maybe<Scalars['String']>;
  /** Side profile flavors of a cocktail. */
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Ingredients of the product. */
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  /** Boolean if the cocktail is new */
  isNewProduct?: Maybe<Scalars['Boolean']>;
  /** Boolean if product is verified. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** List of locations where this cocktail is served either in draft or packaged form. */
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. Use `locationRelationships` */
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Enum for the mixers used for cocktail. */
  mixer?: Maybe<Array<Maybe<Mixer>>>;
  /** Enum for the cocktails perceived bitterness. */
  perceivedProductBitterness?: Maybe<PerceivedBitterness>;
  /** Enum for the cocktails perceived saltiness. */
  perceivedSaltiness?: Maybe<PerceivedSaltiness>;
  /** Enum for the cocktails perceived sourness. */
  perceivedSourness?: Maybe<PerceivedSourness>;
  /** Enum for the cocktails perceived spiciness. */
  perceivedSpiciness?: Maybe<PerceivedSpiciness>;
  /** Enum for the cocktails perceived sweetness. */
  perceivedSweetness?: Maybe<PerceivedSweetness>;
  /** Main profile flavors of a cocktail. */
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** The product type. */
  productType?: Maybe<Scalars['String']>;
  /** Date the product was first made available to the public. Expects a valid date string such as `11/27/1984` though the front-end may only render the year in most cases. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Enum of serving temperatures for a cocktail Product. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** List of suggested glassware to use when drinking this product. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of the cocktail. Auto-Generated in createProduct using the title. */
  slug?: Maybe<Scalars['String']>;
  /** The cocktail style information */
  style?: Maybe<Style>;
  /** Description of the cocktail experience from pour to sip. Limited by the front-end to 300 characters. */
  tastingExperience?: Maybe<Scalars['String']>;
  /** Title of the cocktail Product. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits of a cocktail Product. Contains availability, nutrition, allergens, and otherTraits. */
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export enum CocktailColor {
  Blue = 'Blue',
  Brown = 'Brown',
  Clear = 'Clear',
  Color = 'Color',
  Green = 'Green',
  Orange = 'Orange',
  Purple = 'Purple',
  Red = 'Red',
  Yellow = 'Yellow'
}

export type Coffee = Product & WildCardTitleSearch & {
  __typename?: Scalars['String'];
  /** Alcohol of this coffee as a percentage with up to 1 decimal. */
  abv?: Maybe<Scalars['Float']>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Product label. Supports multiple assets, but front-end expects only 1. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** Enum for where the bean is from. */
  beanRegion?: Maybe<BeanRegion>;
  /** Enum bean type used for the coffee. */
  beanType?: Maybe<Array<Maybe<BeanType>>>;
  /** PK of a Brand. */
  brand?: Maybe<Brand>;
  /** Enum for the products Carbonation. */
  carbonation?: Maybe<Carbonation>;
  /** Enum for how the coffee is prepared. */
  coffeePreparation?: Maybe<Preparation>;
  /** Color of the liquid epressed as Tailwind color e.g. `flower-fruity-apple` or `srm-19`. */
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the brand. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Enum of foods to pair with a coffee Product. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: Maybe<Scalars['String']>;
  /** Side profile flavors of a coffee. */
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Ingredients of the product. */
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  /** Boolean if the coffee is new. */
  isNewProduct?: Maybe<Scalars['Boolean']>;
  /** Boolean if product is verified. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** List of locations where this coffee is served either in draft or packaged form. */
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. Use `locationRelationships`. */
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Enum for the coffee's perceived bitterness. */
  perceivedProductBitterness?: Maybe<PerceivedBitterness>;
  /** Enum for the coffee's perceived sweetness. */
  perceivedSweetness?: Maybe<PerceivedSweetness>;
  /** Main profile flavors of a coffee. */
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** Enum for how the coffee process. */
  process?: Maybe<Process>;
  /** The product type. */
  productType?: Maybe<Scalars['String']>;
  /** Date the product was first made available to the public. Expects a valid date string such as `11/27/1984` though the front-end may only render the year in most cases. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Enum for how the coffee's roast profile. */
  roastProfile?: Maybe<RoastProfile>;
  /** Enum of serving temperatures for a coffee Product. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** List of suggested glassware to use when drinking this product. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of the coffee. Auto-Generated in createProduct using the title. */
  slug?: Maybe<Scalars['String']>;
  /** The coffee style information */
  style?: Maybe<Style>;
  /** Description of the coffee experience from pour to sip. Limited by the front-end to 300 characters. */
  tastingExperience?: Maybe<Scalars['String']>;
  /** Title of the coffee Product. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits of a coffee Product. Contains availability, nutrition, allergens, and otherTraits. */
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export enum ColorIntensity {
  Deep = 'Deep',
  Medium = 'Medium',
  Pale = 'Pale'
}

/** Color themes for menu. May be overridden by ScreenMode.Dark */
export enum ColorScheme {
  Cool = 'Cool',
  /** Dark background */
  Dark = 'Dark',
  /** (Default) Light background with dark text. */
  Light = 'Light',
  Medium = 'Medium',
  Warm = 'Warm'
}

/** Connection Status options used by devices */
export enum ConnectionStatus {
  Offline = 'Offline',
  Online = 'Online'
}

/** Contact information for a user. */
export type Contact = {
  __typename?: 'Contact';
  /** Email address of user. */
  email?: Maybe<Scalars['String']>;
  /** Phone number of a user. */
  phone?: Maybe<Scalars['String']>;
};

export enum ContactPreference {
  Email = 'Email',
  Phone = 'Phone',
  Sms = 'Sms'
}

export enum Coupler {
  A = 'A',
  D = 'D',
  G = 'G',
  M = 'M',
  S = 'S',
  U = 'U'
}

/** Coupler types required for connecting to the draft system. */
export enum CouplerType {
  /** German Slider */
  A = 'A',
  /** American Sankey */
  D = 'D',
  /** Grundy */
  G = 'G',
  /** German Slider */
  M = 'M',
  /** European Sankey */
  S = 'S',
  /** U System */
  U = 'U'
}

/** Type of the custom product being added. */
export enum CustomProductType {
  /** Beer brew type used by beverage field. */
  Beer = 'Beer',
  /** Cider brew type used by beverage field. */
  Cider = 'Cider',
  /** Cocktail brew type used by beverage field. */
  Cocktail = 'Cocktail',
  /** Coffee brew type used by beverage field. */
  Coffee = 'Coffee',
  /** All food products. */
  Food = 'Food',
  /** Kombucha brew type used by beverage field. */
  Kombucha = 'Kombucha',
  /** Mead brew type used by beverage field. */
  Mead = 'Mead',
  /** General merchandise. */
  Merch = 'Merch',
  /** Non-alcoholic brew type used by beverage field. */
  NonAlcoholic = 'Non__Alcoholic',
  /** Generic custom product type when no other option makes sense. */
  Other = 'Other',
  /** Packaged products. */
  PackagedOnlyProduct = 'Packaged_Only_Product',
  /** Products for pets. */
  Pets = 'Pets',
  /** Seltzer brew type used by beverage field. */
  Seltzer = 'Seltzer',
  /** Any soft drink. */
  SoftDrink = 'Soft_Drink',
  /** Unverified brew type used by beverage field. */
  Unverified = 'Unverified',
  /** Wine brew type used by beverage field. */
  Wine = 'Wine'
}

/** Object for storing the days and hours something is available. Ex. Discounts. */
export type DateAvailability = {
  __typename?: 'DateAvailability';
  /** Boolean determining if the menu item is available on any date */
  anyDate?: Maybe<Scalars['Boolean']>;
  /** Boolean determining if the menu item is available all days and hours */
  days?: Maybe<Scalars['Boolean']>;
  /** End date of menu item availability */
  endDate?: Maybe<Scalars['String']>;
  /** Days and hours of the week the menu item is available */
  hoursAvailable?: Maybe<Array<Maybe<Hours>>>;
  /** Start date of menu item availability */
  startDate?: Maybe<Scalars['String']>;
};

export type DateRange = {
  __typename?: 'DateRange';
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

/** Generic response for multi-item delete endpoints. */
export type DeleteItemsResponse = {
  __typename?: 'DeleteItemsResponse';
  /** List of deleted item IDs. */
  deletedIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Count of deleted IDs. */
  returnedCount?: Maybe<Scalars['Int']>;
};

/**
 * Connection object for returning a list of MenuItem IDs delted.
 * DEPRECATED, EOL v1.28, new Connection replaces this for sections and menu items deletes.
 */
export type DeleteMenuItemConnection = {
  __typename?: 'DeleteMenuItemConnection';
  /** MenuItemVisility results found from query. */
  items?: Maybe<Array<Maybe<DeletedMenuItem>>>;
  /** Total number of results */
  returnedCount?: Maybe<Scalars['Int']>;
};

/** DEPRECATED, EOL v1.29. Delete connection object that contains the deleted `MenuItems` or `MenuSections` IDs. */
export type DeleteMenuItemsOrSectionsConnection = {
  __typename?: 'DeleteMenuItemsOrSectionsConnection';
  /** IDs of the deleted items. */
  items?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Items found during the query. */
  returnedCount?: Maybe<Scalars['Int']>;
};

/** Return type for `superAdminDeleteUser` endpoint. */
export type DeleteUserResponse = {
  __typename?: 'DeleteUserResponse';
  /** Was the user record deleted from Clerk. */
  clerkUserDeleted?: Maybe<Scalars['Boolean']>;
  /** Does the user record exist in Clerk. */
  clerkUserExists?: Maybe<Scalars['Boolean']>;
  /** Was the user record deleted from Cognito. */
  cognitoUserDeleted?: Maybe<Scalars['Boolean']>;
  /** Does the user record exist in Cognito. */
  cognitoUserExists?: Maybe<Scalars['Boolean']>;
  /** Was the user record deleted from DynamoDB. */
  ddbUserDeleted?: Maybe<Scalars['Boolean']>;
  /** Does the user record exist in DynamoDB. */
  ddbUserExists?: Maybe<Scalars['Boolean']>;
  /** Is the user an Organization Owner. */
  isUserOrgOwner?: Maybe<Scalars['Boolean']>;
  /** Error and warning messages if any. */
  messages: Array<Maybe<Scalars['String']>>;
};

/** Object for returning a single deleted MenuItem. */
export type DeletedMenuItem = {
  __typename?: 'DeletedMenuItem';
  /** UUID. SK for MenuItem. */
  id: Scalars['ID'];
};

export type DepletionEstimates = {
  __typename?: 'DepletionEstimates';
  date?: Maybe<Scalars['String']>;
  fluidOz?: Maybe<Scalars['Float']>;
};

/**
 * DepletionPaceDetails
 *
 * DepletionPaceDetails provides the fields that will be returned from
 * the datalake `get_keg_depletion_pace_week` query.
 */
export type DepletionPaceDetails = {
  __typename?: 'DepletionPaceDetails';
  /** Date string */
  depletionDate?: Maybe<Scalars['String']>;
  /** Depletion pace returns 'On Pace', 'Fast', or 'Slow' which is based on the depletion rate of a keg.  */
  depletionPace?: Maybe<Scalars['String']>;
};

export type DepletionStats = {
  __typename?: 'DepletionStats';
  /** Future daily fluid oz estimates. */
  depletionEstimates?: Maybe<Array<Maybe<DepletionEstimates>>>;
  /** Pace of depletion as a subjective integer. Higher the number, the faster the keg is depleting.  */
  depletionPace?: Maybe<Scalars['Float']>;
  /** Rate of depletion, in ounces per day. */
  depletionRate?: Maybe<Scalars['Float']>;
  /** Date string, in UTC, when the keg is projected to kick. */
  projectedKickDate?: Maybe<Scalars['String']>;
};

/** Options for aggregated device health.  Set by back-end based on connection history over the last ~week */
export enum DeviceHealth {
  Fair = 'Fair',
  Good = 'Good',
  Poor = 'Poor',
  Unknown = 'Unknown'
}

/** Status of a sensor or gateway; indicates whether it is currently in use, temporarily disabled or archived (replaced) */
export enum DeviceStatus {
  Active = 'Active',
  Archived = 'Archived',
  Inactive = 'Inactive'
}

export enum DigiConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Unknown = 'unknown'
}

/** Object attached to pricing preset to indicate a menu item discount. */
export type DiscountPreset = {
  __typename?: 'DiscountPreset';
  /** User that created the DiscountPreset */
  createdBy: Scalars['String'];
  /** Date DiscountPreset was created */
  createdDate: Scalars['String'];
  /** Dates, hours and days of the week the discount is available */
  dateTimeActive?: Maybe<DateAvailability>;
  /** Fixed amount of the DiscountPreset */
  fixedAmount?: Maybe<Scalars['Float']>;
  /** ID of the DiscountPreset */
  id: Scalars['ID'];
  /** User that last updated the DiscountPreset */
  lastUpdatedBy: Scalars['String'];
  /** Percent discount */
  percent?: Maybe<Scalars['Float']>;
  /** Pour sizes that this discount will apply to if added to a Menu Item. */
  pourSizes?: Maybe<Array<Maybe<PourSize>>>;
  /** See enum CustomProductType for available options. */
  productType?: Maybe<CustomProductType>;
  /** Title of the DiscountPreset */
  title: Scalars['String'];
  /** Type of discount to be applied. */
  type?: Maybe<DiscountPresetType>;
  /** Date string, in UTC */
  updatedDate: Scalars['String'];
};

/** Connection object used to return a list of DiscountPreset objects. */
export type DiscountPresetConnection = {
  __typename?: 'DiscountPresetConnection';
  /** Discount objects found during query */
  items?: Maybe<Array<Maybe<DiscountPreset>>>;
  /** Next token used for pagination */
  nextToken?: Maybe<Scalars['String']>;
  /** Number of objects returned */
  returnedCount?: Maybe<Scalars['Int']>;
};

/** Type of Discount Preset. */
export enum DiscountPresetType {
  /** Applies a fixed price. */
  FixedAmount = 'Fixed_Amount',
  /** Reduces the price by a fixed amount. */
  FixedAmountOff = 'Fixed_Amount_Off',
  /** Reduces the price by a percentage of the full price. */
  PercentOff = 'Percent_Off'
}

/** How many columns the Brew Menu Display should have. */
export enum DisplayColumns {
  Four = 'Four',
  One = 'One',
  Three = 'Three',
  /** (Default) */
  Two = 'Two'
}

/** Font for Brew Menu Display */
export enum DisplayFont {
  Futura = 'Futura',
  Lora = 'Lora',
  Merriweather = 'Merriweather',
  Montserrat = 'Montserrat',
  OpenSans = 'OpenSans',
  Raleway = 'Raleway',
  /** (Default) */
  Roboto = 'Roboto',
  SourceSansPro = 'SourceSansPro'
}

/** Orientation of the display screen. Intended for TV displays only. */
export enum DisplayOrientation {
  /** (Default) */
  Landscape = 'Landscape',
  Portrait = 'Portrait'
}

/** Time in seconds between display rotations. */
export enum DisplayRotationInterval {
  N_5 = 'N_5',
  /** (Default) */
  N_10 = 'N_10',
  N_15 = 'N_15',
  N_30 = 'N_30',
  N_45 = 'N_45',
  N_60 = 'N_60',
  N_120 = 'N_120',
  N_240 = 'N_240'
}

/** Type of BrewMenuDisplay. */
export enum DisplayType {
  Print = 'Print',
  Tv = 'TV',
  Website = 'Website'
}

export enum EducationalActivity {
  BeerSchool = 'Beer_School',
  BookClub = 'Book_Club',
  CandleMaking = 'Candle_Making',
  CookingLessons = 'Cooking_Lessons',
  PaintArt = 'Paint_Art',
  TownHallMeeting = 'Town_Hall_Meeting',
  Woodworking = 'Woodworking'
}

export enum EntertainmentActivity {
  ArtGallery = 'Art_Gallery',
  BartendingShow = 'Bartending_Show',
  CarClub = 'Car_Club',
  ComedyNight = 'Comedy_Night',
  DragBrunch = 'Drag_Brunch',
  DragShow = 'Drag_Show',
  FashionShow = 'Fashion_Show',
  Karaoke = 'Karaoke',
  LiveDj = 'Live_Dj',
  LiveMusic = 'Live_Music',
  MagicNight = 'Magic_Night',
  MusicVideoBingo = 'Music_Video_Bingo',
  OpenMicNight = 'Open_Mic_Night',
  Poetry = 'Poetry',
  RadioNight = 'Radio_Night',
  SilentDisco = 'Silent_Disco'
}

export enum FacilityType {
  BrewPub = 'Brew_Pub',
  CraftBarOnly = 'Craft_Bar_Only',
  Office = 'Office',
  PerformancePlatformCustomer = 'Performance_Platform_Customer',
  RestaurantOnly = 'Restaurant_Only',
  RestaurantAndBrewhouse = 'Restaurant_and_Brewhouse',
  TapTastingRoom = 'Tap_Tasting_Room'
}

export type FavoriteBrand = {
  __typename?: 'FavoriteBrand';
  /** List of board ids that this favorite is included in. */
  boardAssociations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Auto-generated Id. PK of a Brand. */
  brandId: Scalars['String'];
  /** Boolean to toggle mark item as favorited. Not currently in use. */
  isActive: Scalars['Boolean'];
  /** Notes about favorite logged by user. */
  notes?: Maybe<Scalars['String']>;
};

export type FavoriteLocation = {
  __typename?: 'FavoriteLocation';
  /** List of board ids that this favorite is included in. */
  boardAssociations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Auto-generated Id. PK of a Location. */
  brandId: Scalars['String'];
  /** Boolean to toggle mark item as favorited. Not currently in use. */
  isActive: Scalars['Boolean'];
  /** Auto-generated Id. SK of a Location */
  locationId: Scalars['String'];
  /** Notes about favorite logged by user. */
  notes?: Maybe<Scalars['String']>;
};

export type FavoriteProduct = {
  __typename?: 'FavoriteProduct';
  /** List of board ids that this favorite is included in. */
  boardAssociations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Auto-generated Id. PK of a Product. */
  brandId: Scalars['String'];
  /** Boolean to toggle mark item as favorited. Not currently in use. */
  isActive: Scalars['Boolean'];
  /** Notes about favorite logged by user. */
  notes?: Maybe<Scalars['String']>;
  /** Auto-generated Id. SK of a Product. */
  productId: Scalars['String'];
};

export enum FavoriteType {
  Brand = 'Brand',
  Location = 'Location',
  Product = 'Product'
}

export type Favorites = {
  __typename?: 'Favorites';
  /** Deprecated as of 1.12.0. */
  brands?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** List of brands saved by User. */
  favoriteBrands?: Maybe<Array<Maybe<FavoriteBrand>>>;
  /** List of locations saved by User. */
  favoriteLocations?: Maybe<Array<Maybe<FavoriteLocation>>>;
  /** List of products saved by User. */
  favoriteProducts?: Maybe<Array<Maybe<FavoriteProduct>>>;
  /** Deprecated as of 1.12.0. */
  locations?: Maybe<Array<Maybe<LocationIds>>>;
  /** Deprecated as of 1.12.0. */
  products?: Maybe<Array<Maybe<ProductIds>>>;
};

export enum Finish {
  Long = 'Long',
  Medium = 'Medium',
  Short = 'Short'
}

export enum FlavorIntensity {
  High = 'High',
  Light = 'Light',
  Medium = 'Medium'
}

export enum FlavorNote {
  Acai = 'Acai',
  Acidic = 'Acidic',
  Agave = 'Agave',
  Alder = 'Alder',
  Allspice = 'Allspice',
  Almond = 'Almond',
  Anise = 'Anise',
  Apple = 'Apple',
  AppleJuice = 'Apple_Juice',
  AppleSkin = 'Apple_Skin',
  Apricot = 'Apricot',
  Asparagus = 'Asparagus',
  Bacon = 'Bacon',
  BakedFruits = 'Baked_Fruits',
  Banana = 'Banana',
  Barley = 'Barley',
  BarleySugar = 'Barley_Sugar',
  Barnyard = 'Barnyard',
  Basil = 'Basil',
  Beech = 'Beech',
  Bergamot = 'Bergamot',
  Biscuit = 'Biscuit',
  Bitter = 'Bitter',
  BittersweetChocolate = 'Bittersweet_Chocolate',
  BlackCherry = 'Black_Cherry',
  BlackCurrant = 'Black_Currant',
  BlackFruit = 'Black_Fruit',
  BlackPepper = 'Black_Pepper',
  BlackTea = 'Black_Tea',
  BlackTruffle = 'Black_Truffle',
  Blackberries = 'Blackberries',
  BloodOrange = 'Blood_Orange',
  Blossom = 'Blossom',
  Blueberries = 'Blueberries',
  Bonfire = 'Bonfire',
  Botanic = 'Botanic',
  Bourbon = 'Bourbon',
  Boysenberry = 'Boysenberry',
  Brandy = 'Brandy',
  Bread = 'Bread',
  BreakfastCereal = 'Breakfast_Cereal',
  BrownBread = 'Brown_Bread',
  BrownSugar = 'Brown_Sugar',
  Brownie = 'Brownie',
  Bubblegum = 'Bubblegum',
  Burnt = 'Burnt',
  BurntSugar = 'Burnt_Sugar',
  Butter = 'Butter',
  Butterscotch = 'Butterscotch',
  Cabbage = 'Cabbage',
  CakeBatter = 'Cake_Batter',
  Candy = 'Candy',
  CandySugar = 'Candy_Sugar',
  Cannabis = 'Cannabis',
  Cantalope = 'Cantalope',
  Cantaloupe = 'Cantaloupe',
  Caramel = 'Caramel',
  CaramelizedSugar = 'Caramelized_Sugar',
  Caraway = 'Caraway',
  Cardamom = 'Cardamom',
  Catty = 'Catty',
  Cayenne = 'Cayenne',
  Cedar = 'Cedar',
  Celery = 'Celery',
  Cereal = 'Cereal',
  Chamomile = 'Chamomile',
  ChampagneLike = 'Champagne_Like',
  Charred = 'Charred',
  CharredWood = 'Charred_Wood',
  Cheese = 'Cheese',
  Cherry = 'Cherry',
  Chili = 'Chili',
  Chocolate = 'Chocolate',
  ChocolateMalt = 'Chocolate_Malt',
  Cidery = 'Cidery',
  Cinnamon = 'Cinnamon',
  Citrus = 'Citrus',
  CitrusJuice = 'Citrus_Juice',
  CitrusZest = 'Citrus_Zest',
  Clementine = 'Clementine',
  Clove = 'Clove',
  Cocoa = 'Cocoa',
  Coconut = 'Coconut',
  Coffee = 'Coffee',
  Cola = 'Cola',
  ConcordGrape = 'Concord_Grape',
  CookedApple = 'Cooked_Apple',
  CookedPear = 'Cooked_Pear',
  Coriander = 'Coriander',
  Corn = 'Corn',
  CornChip = 'Corn_Chip',
  CornMalt = 'Corn_Malt',
  CottonCandy = 'Cotton_Candy',
  Crackers = 'Crackers',
  Cranberry = 'Cranberry',
  Cream = 'Cream',
  Cucumber = 'Cucumber',
  Dank = 'Dank',
  DarkChocolate = 'Dark_Chocolate',
  DarkFruit = 'Dark_Fruit',
  DarkSugars = 'Dark_Sugars',
  Dates = 'Dates',
  Dill = 'Dill',
  Dirt = 'Dirt',
  Doughy = 'Doughy',
  Dragonfruit = 'Dragonfruit',
  DriedApple = 'Dried_Apple',
  DriedFruit = 'Dried_Fruit',
  DriedHerbs = 'Dried_Herbs',
  DriedPear = 'Dried_Pear',
  Dry = 'Dry',
  Earthy = 'Earthy',
  Effervescent = 'Effervescent',
  Elderberries = 'Elderberries',
  Elderflower = 'Elderflower',
  Espresso = 'Espresso',
  Eucalyptus = 'Eucalyptus',
  Farmhouse = 'Farmhouse',
  Fennel = 'Fennel',
  Figs = 'Figs',
  Floral = 'Floral',
  ForestFloor = 'Forest_Floor',
  FrenchToast = 'French_Toast',
  FreshApple = 'Fresh_Apple',
  FreshPear = 'Fresh_Pear',
  Frosting = 'Frosting',
  FruitAndSpice = 'Fruit_and_Spice',
  Fruity = 'Fruity',
  Funky = 'Funky',
  Fusel = 'Fusel',
  Garlic = 'Garlic',
  Geranium = 'Geranium',
  Ginger = 'Ginger',
  Gooseberry = 'Gooseberry',
  GrahamCracker = 'Graham_Cracker',
  Grainy = 'Grainy',
  Granola = 'Granola',
  Grapefruit = 'Grapefruit',
  Grapes = 'Grapes',
  Grass = 'Grass',
  GreenApple = 'Green_Apple',
  GreenLeaves = 'Green_Leaves',
  GreenOnion = 'Green_Onion',
  GreenTea = 'Green_Tea',
  Guava = 'Guava',
  Habanero = 'Habanero',
  Hay = 'Hay',
  Hazelnut = 'Hazelnut',
  Herbal = 'Herbal',
  Hibiscus = 'Hibiscus',
  Hickory = 'Hickory',
  Honey = 'Honey',
  Honeycomb = 'Honeycomb',
  Honeydew = 'Honeydew',
  Honeysuckle = 'Honeysuckle',
  Hops = 'Hops',
  Horsehair = 'Horsehair',
  IslayWhiskey = 'Islay_Whiskey',
  Jammy = 'Jammy',
  Jasmine = 'Jasmine',
  Juicy = 'Juicy',
  Juniper = 'Juniper',
  Kiwi = 'Kiwi',
  Kumquat = 'Kumquat',
  Latte = 'Latte',
  Lavender = 'Lavender',
  Leafy = 'Leafy',
  Leather = 'Leather',
  Lees = 'Lees',
  Lemon = 'Lemon',
  Lemongrass = 'Lemongrass',
  Licorice = 'Licorice',
  LightlyToasted = 'Lightly_Toasted',
  Lilac = 'Lilac',
  Lily = 'Lily',
  Lime = 'Lime',
  Loganberries = 'Loganberries',
  Lychee = 'Lychee',
  Maize = 'Maize',
  Malt = 'Malt',
  Mandarin = 'Mandarin',
  Mango = 'Mango',
  MapleSyrup = 'Maple_Syrup',
  Marshmallow = 'Marshmallow',
  Melon = 'Melon',
  Menthol = 'Menthol',
  Mesquite = 'Mesquite',
  Metallic = 'Metallic',
  MeyerLemon = 'Meyer_Lemon',
  Milk = 'Milk',
  MilkSugar = 'Milk_Sugar',
  Mineral = 'Mineral',
  Mint = 'Mint',
  Molasses = 'Molasses',
  Moss = 'Moss',
  Muffin = 'Muffin',
  MuscatGrape = 'Muscat_Grape',
  Mushroom = 'Mushroom',
  Nectarine = 'Nectarine',
  Nougat = 'Nougat',
  Nutmeg = 'Nutmeg',
  Nuts = 'Nuts',
  Oak = 'Oak',
  Oats = 'Oats',
  Oil = 'Oil',
  Olive = 'Olive',
  Onion = 'Onion',
  Orange = 'Orange',
  OrangeJuice = 'Orange_Juice',
  OrangeMarmalade = 'Orange_Marmalade',
  Papaya = 'Papaya',
  Parsley = 'Parsley',
  PassionFruit = 'Passion_Fruit',
  PastryDough = 'Pastry_Dough',
  Peach = 'Peach',
  Peanut = 'Peanut',
  PeanutButter = 'Peanut_Butter',
  Pear = 'Pear',
  PearDrop = 'Pear_Drop',
  PearJuice = 'Pear_Juice',
  PearSkin = 'Pear_Skin',
  Peat = 'Peat',
  Pecans = 'Pecans',
  Pepper = 'Pepper',
  Peppermint = 'Peppermint',
  Perfume = 'Perfume',
  Phenolic = 'Phenolic',
  Pickles = 'Pickles',
  Pie = 'Pie',
  PieCrust = 'Pie_Crust',
  PinaColada = 'Pina_Colada',
  Pine = 'Pine',
  Pineapple = 'Pineapple',
  Pistachio = 'Pistachio',
  Plum = 'Plum',
  Pluot = 'Pluot',
  PoblanoPeppers = 'Poblano_Peppers',
  Pomegranate = 'Pomegranate',
  Potatoes = 'Potatoes',
  Potpourri = 'Potpourri',
  PricklyPear = 'Prickly_Pear',
  Prosecco = 'Prosecco',
  Prunes = 'Prunes',
  Pumpkin = 'Pumpkin',
  Quince = 'Quince',
  Raisins = 'Raisins',
  Raspberries = 'Raspberries',
  RawTreeNuts = 'Raw_Tree_Nuts',
  RedApple = 'Red_Apple',
  RedBerries = 'Red_Berries',
  RedCherry = 'Red_Cherry',
  RedCurrant = 'Red_Currant',
  RedWine = 'Red_Wine',
  RefinedSugar = 'Refined_Sugar',
  Resin = 'Resin',
  Rhubarb = 'Rhubarb',
  Rice = 'Rice',
  Rich = 'Rich',
  Roast = 'Roast',
  Rose = 'Rose',
  Rosemary = 'Rosemary',
  Rum = 'Rum',
  Rye = 'Rye',
  Sage = 'Sage',
  Sake = 'Sake',
  Salt = 'Salt',
  Sandalwood = 'Sandalwood',
  Sherry = 'Sherry',
  Smoke = 'Smoke',
  SmokedBacon = 'Smoked_Bacon',
  SmokedMeat = 'Smoked_Meat',
  Soil = 'Soil',
  Sour = 'Sour',
  SoySauce = 'Soy_Sauce',
  Spice = 'Spice',
  Spicy = 'Spicy',
  Spruce = 'Spruce',
  Squash = 'Squash',
  Starfruit = 'Starfruit',
  StoneFruit = 'Stone_Fruit',
  Straw = 'Straw',
  Strawberries = 'Strawberries',
  Sugars = 'Sugars',
  Sulphur = 'Sulphur',
  Sweet = 'Sweet',
  SweetChocolate = 'Sweet_Chocolate',
  SweetCorn = 'Sweet_Corn',
  Tamarind = 'Tamarind',
  Tangerine = 'Tangerine',
  Tart = 'Tart',
  Tea = 'Tea',
  Thyme = 'Thyme',
  Toast = 'Toast',
  ToastedNuts = 'Toasted_Nuts',
  Tobacco = 'Tobacco',
  Toffee = 'Toffee',
  Tortilla = 'Tortilla',
  Treacle = 'Treacle',
  Tropical = 'Tropical',
  TropicalFruits = 'Tropical_Fruits',
  Truffle = 'Truffle',
  Vanilla = 'Vanilla',
  Vinegar = 'Vinegar',
  Violets = 'Violets',
  Walnut = 'Walnut',
  WaterCracker = 'Water_Cracker',
  Watermelon = 'Watermelon',
  Waxy = 'Waxy',
  Wheat = 'Wheat',
  Whiskey = 'Whiskey',
  WhiteChocolate = 'White_Chocolate',
  WhiteFlowers = 'White_Flowers',
  WhiteGrape = 'White_Grape',
  WhitePepper = 'White_Pepper',
  WhiteWine = 'White_Wine',
  Wood = 'Wood',
  Yeasts = 'Yeasts',
  Yogurt = 'Yogurt'
}

/** Brew Menu Display font sizes. */
export enum FontSize {
  L = 'L',
  M = 'M',
  S = 'S'
}

export enum FoodOption {
  Any = 'Any',
  Brunch = 'Brunch',
  CarryIn = 'Carry_In',
  CarryOut = 'Carry_Out',
  FarmToTable = 'Farm_to_Table',
  FoodTruck = 'Food_Truck',
  FreeRange = 'Free_Range',
  FullServiceMenu = 'Full_Service_Menu',
  GlutenFree = 'Gluten_Free',
  LateNightMenu = 'Late_Night_Menu',
  LimitedMenu = 'Limited_Menu',
  OrderIn = 'Order__in',
  Organic = 'Organic',
  SnacksAndApps = 'Snacks_and_Apps',
  Vegan = 'Vegan',
  Vegetarian = 'Vegetarian'
}

export type FoodPairing = {
  __typename?: 'FoodPairing';
  category?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum FruitOrigin {
  Europe = 'Europe',
  France = 'France',
  Spain = 'Spain',
  UnitedStates = 'United_States'
}

export enum GameActivity {
  ArcadeGames = 'Arcade_games',
  BeerPong = 'Beer_Pong',
  Bingo = 'Bingo',
  BoardGames = 'Board_Games',
  CardGames = 'Card_Games',
  DartLeague = 'Dart_League',
  FantasyLeague = 'Fantasy_League',
  GameNight = 'Game_Night',
  Mafia = 'Mafia',
  Poker = 'Poker',
  PoolBilliards = 'Pool_Billiards',
  Singo = 'Singo',
  Trivia = 'Trivia',
  YardGames = 'Yard_Games'
}

export enum Garnish {
  Cherry = 'Cherry',
  Lemon = 'Lemon',
  Mint = 'Mint',
  Olive = 'Olive',
  Onion = 'Onion',
  Orange = 'Orange'
}

/** ### Gateways represent the hardware link between [TapSensors](#definition-TapSensor) and on-premise internet connection */
export type Gateway = {
  __typename?: 'Gateway';
  /** ZigBee Channel can default to 25. CS Rep should know when switching channel is reccomended based on traffic. */
  channel?: Maybe<Scalars['Int']>;
  /** Connection status obtained from Digi Remote Manager API */
  connectionStatus?: Maybe<DigiConnectionStatus>;
  /** Email address of user that created the gateway */
  createdBy?: Maybe<Scalars['String']>;
  /** Date string, in UTC */
  createdDate: Scalars['String'];
  /** Digi ID used to correlate Digi devices returned from Digi Remote Manager API with BrewLogix Gateways in the DigiRemoteManager resolver */
  digiDeviceId?: Maybe<Scalars['String']>;
  /** Firmware version obtained from Digi Remote Manager API */
  firmware?: Maybe<Scalars['String']>;
  /** Firmware status obtained from Digi Remote Manager API */
  firmwareStatus?: Maybe<Scalars['String']>;
  /** Organizationally Unique Identifier (OUI) followed by a manufacturer assigned 6 digit (24 bit) unique number (MAC). OUI values will be 00 or 01. For all gateways we use the last 6 digits of the MAC address as the basis of the Gateway ID. */
  gatewayDeviceId: Scalars['String'];
  /** Overview of device health measured by connection status history obtained from the Digi API. This is populated based on [business rules](https://brewlogix.atlassian.net/browse/PLB-77) directly via Digi's API. As of 4/17/23 it is Normal_Weight_Reading fully supported. */
  health?: Maybe<DeviceHealth>;
  /** PP subscription ID. This ID is a pk and cannot be changed. Once created it the value must remain the same. */
  id: Scalars['ID'];
  /** Date string, in UTC of when the gateway was installed at a location */
  installedDate?: Maybe<Scalars['String']>;
  /** IP of the Gateway */
  ip?: Maybe<Scalars['String']>;
  /** DateTime of last successful Gateway connection obtained from Digi Remote Manager API */
  lastConnect?: Maybe<Scalars['String']>;
  /** Date string, in UTC of when the gateway last sent a PUT request. This is used to calculate online/offline status */
  lastContactDate?: Maybe<Scalars['String']>;
  /** DateTime of last Gateway disconnection obtained from Digi Remote Manager API */
  lastDisconnect?: Maybe<Scalars['String']>;
  /** Email address or lambda that last modified the gateway */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** Gateway device model and version number. */
  model?: Maybe<Scalars['String']>;
  /** Seconds. Default to 60. Enables a delay during startup to wait for subsytem to establish connection with netowrk. Legacy. */
  pairingDelay?: Maybe<Scalars['Int']>;
  /** ZigBee PANID Lower Byte */
  panId?: Maybe<Scalars['String']>;
  performancePlatformSubscription?: Maybe<PerformancePlatformSubscription>;
  /** Date string, in UTC, representing the end-of-life support for the gateway. */
  serviceWindow?: Maybe<Scalars['String']>;
  /** Milliseconds.This is the time between time between wake-ups fo readings. Will be properly overridden by config file. Default to 294. */
  sleepTime?: Maybe<Scalars['Int']>;
  status?: Maybe<DeviceStatus>;
  /** Tap sensors attached to this gateway */
  tapSensors?: Maybe<Array<Maybe<TapSensor>>>;
  /** Optional. Use to associate a friendly name to this gateway, or if a PP Sub has more than one gateway. If left blank front-end will use the ID. */
  title?: Maybe<Scalars['String']>;
  /** Status of the gateway; indicates whether it is currently in use, temporarily disabled or archived (replaced) */
  type?: Maybe<GatewayType>;
  /** Date string, in UTC */
  updatedDate: Scalars['String'];
  /** Seconds. This is the minimum time between gateway check-ins, `heartbeat`. Default to 3600. */
  upgradeTimeout?: Maybe<Scalars['Int']>;
};

export type GatewayConnection = {
  __typename?: 'GatewayConnection';
  items?: Maybe<Array<Maybe<Gateway>>>;
  nextToken?: Maybe<Scalars['String']>;
  returnedCount?: Maybe<Scalars['Int']>;
};

/**
 * How the gateway connects to the on-premise internet.
 *
 * `Wifi` DEPRECATED in favor of `WiFi as of version 1.2.1. Will be removed by 1.4.0
 */
export enum GatewayType {
  Ethernet = 'Ethernet',
  Unknown = 'Unknown',
  WiFi = 'WiFi',
  Wifi = 'Wifi'
}

/** Lattitude and longitude pairs for mapping a physical location */
export type GeoCoords = {
  __typename?: 'GeoCoords';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export enum GrapeOrigin {
  Argentina = 'Argentina',
  Australia = 'Australia',
  Austria = 'Austria',
  California = 'California',
  Chile = 'Chile',
  Croatia = 'Croatia',
  France = 'France',
  Germany = 'Germany',
  Greece = 'Greece',
  Indiana = 'Indiana',
  Israel = 'Israel',
  Italy = 'Italy',
  Lebanon = 'Lebanon',
  Michigan = 'Michigan',
  NewZealand = 'New_Zealand',
  Oregon = 'Oregon',
  Portugal = 'Portugal',
  Romania = 'Romania',
  SouthAfrica = 'South_Africa',
  Spain = 'Spain',
  Texas = 'Texas',
  Uruguay = 'Uruguay',
  Washington = 'Washington'
}

/**
 * The `GuestBoards` are references to another `Board` that are saved by a `User`. These referenced
 * `Boards` cannot be updated, they can only be removed.
 */
export type GuestBoard = {
  __typename?: 'GuestBoard';
  /** Board resolved from private fields in database. */
  board?: Maybe<Board>;
  /** Guest Board ID. */
  id: Scalars['ID'];
};

export enum HoneyVariety {
  Acacia = 'Acacia',
  Alfalfa = 'Alfalfa',
  Blueberry = 'Blueberry',
  Buckwheat = 'Buckwheat',
  Clover = 'Clover',
  DesertBlossom = 'Desert_Blossom',
  Eucalyptus = 'Eucalyptus',
  Meadowfoam = 'Meadowfoam',
  Mesquite = 'Mesquite',
  OrangeBlossom = 'Orange_Blossom',
  Tupelo = 'Tupelo',
  Wildflower = 'Wildflower'
}

export enum Hops {
  Admiral = 'Admiral',
  AfricanQueen = 'African_Queen',
  AgedDebitteredHopsLambic = 'Aged_Debittered_Hops__Lambic',
  Ahtanum = 'Ahtanum',
  Alchemy = 'Alchemy',
  Amarillo = 'Amarillo',
  AmarilloGold = 'Amarillo_Gold',
  Apollo = 'Apollo',
  Aquila = 'Aquila',
  Aramis = 'Aramis',
  ArgentineCascade = 'Argentine_Cascade',
  Athanum = 'Athanum',
  Aurora = 'Aurora',
  AuschaSaaz = 'Auscha__Saaz',
  AustralianDrRudi = 'Australian_Dr_Rudi',
  Azacca = 'Azacca',
  Azzeca = 'Azzeca',
  Bru_1 = 'BRU__1',
  Banner = 'Banner',
  BarbeRouge = 'Barbe_Rouge',
  Belma = 'Belma',
  Boadicea = 'Boadicea',
  Bobek = 'Bobek',
  Bor = 'Bor',
  Bramling = 'Bramling',
  BramlingCross = 'Bramling_Cross',
  Bravo = 'Bravo',
  BrewerSGold = 'Brewer___s_Gold',
  BrewerSGoldAmerican = 'Brewer___s_Gold__American',
  Bullion = 'Bullion',
  C40 = 'C40',
  Ctz = 'CTZ',
  CaliforniaIvanhoe = 'California_Ivanhoe',
  Callista = 'Callista',
  Calypso = 'Calypso',
  CaramelMalt = 'Caramel_Malt',
  Cascade = 'Cascade',
  Cashmere = 'Cashmere',
  Celeia = 'Celeia',
  Centennial = 'Centennial',
  Challenger = 'Challenger',
  Chinook = 'Chinook',
  Citiva = 'Citiva',
  Citra = 'Citra',
  Cluster = 'Cluster',
  Cobb = 'Cobb',
  Columbia = 'Columbia',
  Columbus = 'Columbus',
  ColumbusTomahawk = 'Columbus__Tomahawk',
  Comet = 'Comet',
  Cryo = 'Cryo',
  Crystal = 'Crystal',
  Delta = 'Delta',
  Denali = 'Denali',
  EastKentGolding = 'East_Kent_Golding',
  Ekuanot = 'Ekuanot',
  ElDorado = 'El_Dorado',
  Ella = 'Ella',
  Enigma = 'Enigma',
  Equinox = 'Equinox',
  Eroica = 'Eroica',
  Eureka = 'Eureka',
  Experimental_05256 = 'Experimental_05256',
  Experimental_06277 = 'Experimental_06277',
  Experimental_946 = 'Experimental_946',
  FalconerSFlight = 'Falconer___s_Flight',
  FirstGold = 'First_Gold',
  Fox = 'Fox',
  FrenchStrisserspalt = 'French_Strisserspalt',
  FrenchTriskel = 'French_Triskel',
  FuggleAmerican = 'Fuggle__American',
  FuggleEnglish = 'Fuggle__English',
  Fuggles = 'Fuggles',
  Galaxy = 'Galaxy',
  Galena = 'Galena',
  GermanMagnum = 'German_Magnum',
  GermanMandarinaBavaria = 'German_Mandarina_Bavaria',
  GermanOpal = 'German_Opal',
  GermanPerle = 'German_Perle',
  GermanPolaris = 'German_Polaris',
  GermanSelect = 'German_Select',
  GermanTradition = 'German_Tradition',
  Glacier = 'Glacier',
  GoldingAmerican = 'Golding__American',
  GreenBullet = 'Green_Bullet',
  Grungeist = 'Grungeist',
  HallertauBlanc = 'Hallertau_Blanc',
  HallertauHallertauerMittelfruher = 'Hallertau_Hallertauer_Mittelfruher',
  HallertauHallertauerTradition = 'Hallertau_Hallertauer_Tradition',
  HallertauNorthernBrewer = 'Hallertau_Northern_Brewer',
  HallertauerHerkules = 'Hallertauer_Herkules',
  HallertauerHersbrucker = 'Hallertauer_Hersbrucker',
  HallertauerMagnum = 'Hallertauer_Magnum',
  HallertauerPerle = 'Hallertauer_Perle',
  HallertauerSelect = 'Hallertauer_Select',
  HallertauerAmerican = 'Hallertauer__American',
  Heirloom = 'Heirloom',
  Helga = 'Helga',
  Herald = 'Herald',
  HopExtract = 'Hop_Extract',
  HopShot = 'Hop_Shot',
  Hops = 'Hops',
  Horizon = 'Horizon',
  HuellMelon = 'Huell_Melon',
  HullerBitterer = 'Huller_Bitterer',
  Idaho_7 = 'Idaho_7',
  Incognito = 'Incognito',
  Jarrylo = 'Jarrylo',
  Junga = 'Junga',
  KentGoldings = 'Kent_Goldings',
  Kohatu = 'Kohatu',
  LemonDrop = 'Lemon_Drop',
  LexyGreenC4 = 'Lexy_Green_C4',
  Liberty = 'Liberty',
  Loral = 'Loral',
  Lotus = 'Lotus',
  LublinLubelski = 'Lublin__Lubelski',
  LupoMax = 'LupoMax',
  Magnum = 'Magnum',
  MarcoPolo = 'Marco_Polo',
  Marynka = 'Marynka',
  Medusa = 'Medusa',
  Meridian = 'Meridian',
  Millenium = 'Millenium',
  Mistral = 'Mistral',
  Mosaic = 'Mosaic',
  Motueka = 'Motueka',
  MountHood = 'Mount_Hood',
  Moutere = 'Moutere',
  MtRainier = 'Mt_Rainier',
  Nelson = 'Nelson',
  NelsonSauvin = 'Nelson_Sauvin',
  NewZealandHallertauer = 'New_Zealand_Hallertauer',
  NewZealandMotueka = 'New_Zealand_Motueka',
  NewZealandSauvin = 'New_Zealand_Sauvin',
  Newport = 'Newport',
  No_06300 = 'No_06300',
  Noble = 'Noble',
  Northdown = 'Northdown',
  NorthernBrewerAmerican = 'Northern_Brewer__American',
  Nugget = 'Nugget',
  Olympic = 'Olympic',
  Omega = 'Omega',
  Orbit = 'Orbit',
  Orion = 'Orion',
  OttoSupreme = 'Otto_Supreme',
  Pike = 'PIKE',
  PacificGem = 'Pacific_Gem',
  PacificJade = 'Pacific_Jade',
  Pacifica = 'Pacifica',
  Palisades = 'Palisades',
  Pekko = 'Pekko',
  PerleAmerican = 'Perle__American',
  PetitBlanc = 'Petit_Blanc',
  Phoenix = 'Phoenix',
  Pilgrim = 'Pilgrim',
  Pioneer = 'Pioneer',
  Polaris = 'Polaris',
  Premiant = 'Premiant',
  PremiantSaaz = 'Premiant__Saaz',
  PrideOfKent = 'Pride_of_Kent',
  PrideOfRingwood = 'Pride_of_Ringwood',
  Progress = 'Progress',
  Rakau = 'Rakau',
  Record = 'Record',
  Revolution = 'Revolution',
  RiwakaNz = 'Riwaka_NZ',
  SaazAmerican = 'Saaz__American',
  SaazCzech = 'Saaz__Czech',
  Sabro = 'Sabro',
  Samba = 'Samba',
  Santiam = 'Santiam',
  SaphirGermanOrganic = 'Saphir__German_Organic',
  Satus = 'Satus',
  Simcoe = 'Simcoe',
  SladekSaaz = 'Sladek__Saaz',
  Sonnet = 'Sonnet',
  SorachiAce = 'Sorachi_Ace',
  SouthernCross = 'Southern_Cross',
  SouthernPassion = 'Southern_Passion',
  SouthernPromise = 'Southern_Promise',
  SouthernStar = 'Southern_Star',
  Sovereign = 'Sovereign',
  Spalt = 'Spalt',
  SpaltSelect = 'Spalt_Select',
  SpaltSpalter = 'Spalt_Spalter',
  Sterling = 'Sterling',
  Sticklebract = 'Sticklebract',
  Strata = 'Strata',
  Strisselspalt = 'Strisselspalt',
  StyrianAurora = 'Styrian_Aurora',
  StyrianBobeks = 'Styrian_Bobeks',
  StyrianGoldings = 'Styrian_Goldings',
  StyrianWolf = 'Styrian_Wolf',
  Sultana = 'Sultana',
  Summit = 'Summit',
  Sun = 'Sun',
  SuperAlpha = 'Super_Alpha',
  SuperGalena = 'Super_Galena',
  SuperStyrians = 'Super_Styrians',
  Sybilla = 'Sybilla',
  Taihete = 'Taihete',
  Talisman = 'Talisman',
  Talus = 'Talus',
  Tango = 'Tango',
  Target = 'Target',
  TettnangTettnanger = 'Tettnang_Tettnanger',
  TettnangerAmerican = 'Tettnanger__American',
  Tomahawk = 'Tomahawk',
  Topaz = 'Topaz',
  Tradition = 'Tradition',
  Trident = 'Trident',
  Triumph = 'Triumph',
  TrschitzSaaz = 'Trschitz__Saaz',
  Ultra = 'Ultra',
  Vanguard = 'Vanguard',
  VicSecret = 'Vic_Secret',
  WaiIti = 'Wai__iti',
  Waimea = 'Waimea',
  Wakatu = 'Wakatu',
  Warrior = 'Warrior',
  WhitbreadGoldingsVariety = 'Whitbread_Goldings_Variety',
  Willamette = 'Willamette',
  WyeTarget = 'Wye_Target',
  YakimaCluster = 'Yakima_Cluster',
  YakimaGoldings = 'Yakima_Goldings',
  YakimaMagnum = 'Yakima_Magnum',
  YakimaWillamette = 'Yakima_Willamette',
  Yeoman = 'Yeoman',
  Zappa = 'Zappa',
  Zenith = 'Zenith',
  Zeus = 'Zeus',
  Zythos = 'Zythos'
}

export type Hours = {
  __typename?: 'Hours';
  day?: Maybe<AbbrevWeekDay>;
  hours?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  isOpen?: Maybe<Scalars['Boolean']>;
};

export type Ingredient = {
  __typename?: 'Ingredient';
  title: Scalars['String'];
  type: IngredientType;
};

export enum IngredientType {
  Hops = 'Hops',
  KeyIngredients = 'KeyIngredients',
  Malts = 'Malts',
  Other = 'Other',
  Yeasts = 'Yeasts'
}

export type InvalidReading = {
  __typename?: 'InvalidReading';
  deviceId?: Maybe<Scalars['String']>;
  gatewayDeviceId?: Maybe<Scalars['String']>;
  kegId?: Maybe<Scalars['String']>;
  ppSubId?: Maybe<Scalars['String']>;
  reading?: Maybe<Scalars['Int']>;
  tapSensorId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Int']>;
};

/** Basic inventory records for PintLabs */
export type InventoryRecord = {
  __typename?: 'InventoryRecord';
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** Order notes, history and other information about the record */
  description?: Maybe<Scalars['String']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Estimated time the incoming devices will arrive. */
  incomingEta?: Maybe<Scalars['String']>;
  inventoryType?: Maybe<InventoryType>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  /** Number of devices on order */
  quantityIncoming?: Maybe<Scalars['Int']>;
  /** Number of devices available in the warehouse */
  quantityOnHand?: Maybe<Scalars['Int']>;
  /** Status of inventory used to provide actionable feedback and send alerts. Set automatically by back-end on create/update */
  status?: Maybe<InventoryStatus>;
  /** Quantity level used to determine reorder status */
  statusThreshold?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

export type InventoryRecordConnection = {
  __typename?: 'InventoryRecordConnection';
  items?: Maybe<Array<Maybe<InventoryRecord>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
};

export type InventoryStats = {
  __typename?: 'InventoryStats';
  assignedInventoryCount?: Maybe<Scalars['Int']>;
  assignedInventoryValue?: Maybe<Scalars['Float']>;
  tappedInventoryCount?: Maybe<Scalars['Int']>;
  tappedInventoryValue?: Maybe<Scalars['Float']>;
  totalInventoryCount?: Maybe<Scalars['Int']>;
  totalInventoryValue?: Maybe<Scalars['Float']>;
};

/** Actionable labels for inventory levels. Currently set manually, but will be set automatically by back-end on mutations. */
export enum InventoryStatus {
  /** Possible supply issues - reorder immediately. Used if `quantityOnHand` at or below 50% of `statusThreshold` */
  Critical = 'Critical',
  /** Reorder suggested. Used when `quantityOnHand` <= `statusThreshold`  */
  Low = 'Low',
  /** No action necessary. `quantityOnHand` > `statusThreshold` */
  Safe = 'Safe'
}

/** Type of goods or device the inventory record represents */
export enum InventoryType {
  Accesory = 'Accesory',
  Battery = 'Battery',
  Cable = 'Cable',
  Gateway = 'Gateway',
  Misc = 'Misc',
  TapSensor = 'TapSensor'
}

export enum JobTitle {
  BackOfHouseStaff = 'Back_of_House_Staff',
  BarManager = 'Bar_Manager',
  Barback = 'Barback',
  Bartender = 'Bartender',
  BeverageManager = 'Beverage_Manager',
  DraftManager = 'Draft_Manager',
  FrontOfHouseStaff = 'Front_of_House_Staff',
  GeneralManager = 'General_Manager',
  Owner = 'Owner',
  Server = 'Server'
}

/** A keg contains a product within a physical vessel. This is one of the primary object with which the PerformancePlatform operations system interacts. */
export type Keg = {
  __typename?: 'Keg';
  /** Date string, in UTC, when the keg was kicked. */
  actualKickDate?: Maybe<Scalars['String']>;
  assignedPositionIndex?: Maybe<Scalars['Int']>;
  /** Field resolves to a TapSensor. DDB field will contain the TapSensor id, tapNumber, title, and updatedDate. */
  assignedTapSensor?: Maybe<TapSensor>;
  /** Date string, in UTC, when the keg was packaged. Helpful for determining freshness. */
  bornOnDate?: Maybe<Scalars['String']>;
  /** Cooler in which the keg is stored. */
  cooler?: Maybe<Scalars['String']>;
  /** Cost of the keg. */
  cost?: Maybe<Scalars['Float']>;
  /** DEPRECATED. Coupler type required for connecting to the draft system. Expects `A`, `D`, `G`, `M`, `S`, or `U`. */
  coupler?: Maybe<Scalars['String']>;
  /** Coupler type required for connecting to the draft system. */
  couplerType?: Maybe<CouplerType>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** Current volume of the keg, in ounces. */
  currentFluidOz?: Maybe<Scalars['Float']>;
  /** DEPRECATED in favor of `servings[n].isDefault`. Sets the default serving size for this keg. */
  defaultServingSize?: Maybe<Scalars['Int']>;
  /** A snapshot of the product that was in the keg when it was kicked. */
  depletedProduct?: Maybe<CompositeProduct>;
  /** DEPRECATED in favor of `depletionStats`. Rate at which the keg is depeting. */
  depletionPace?: Maybe<Scalars['Int']>;
  /** DEPRECATED in favor of `depletionStats`. Rate at which the keg is depeting, in oz per day. */
  depletionRate?: Maybe<Scalars['Float']>;
  /** Depletion and kick information for the keg. */
  depletionStats?: Maybe<DepletionStats>;
  /** Date string, in UTC, when the keg will expire. */
  expirationDate?: Maybe<Scalars['String']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Initial volume of the keg when tapped, in ounces. */
  initialFluidOz?: Maybe<Scalars['Float']>;
  /** Indicates the `cost` value is an estimate. */
  isEstimate?: Maybe<Scalars['Boolean']>;
  isPartial?: Maybe<Scalars['Boolean']>;
  isTemporary?: Maybe<Scalars['Boolean']>;
  /** Current status of the keg. */
  kegStatus?: Maybe<KegStatus>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** (Resolved) PricingPreset used for describing a set of pricing and serving sizes for this keg. The `pricingPresetId` is stored but unlisted. */
  pricingPreset?: Maybe<PricingPreset>;
  /** Product object associated with the keg. */
  product?: Maybe<CompositeProduct>;
  /** DEPRECATED in favor of `depletionStats`. Date string, in UTC, when the keg is projected to kick */
  projectedKickDate?: Maybe<Scalars['String']>;
  /** Date string, in UTC, when the keg was received. */
  receivedDate?: Maybe<Scalars['String']>;
  /** DEPRECATED in favor of `servings`. Serving sizes, in ounces. */
  servingSizes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Servings sizes and price for this keg. Used for BrewMenu. */
  servings?: Maybe<Array<Maybe<ServingSize>>>;
  /** Date string, in UTC, when the keg was last tapped. */
  tappedDate?: Maybe<Scalars['String']>;
  /** Number of unexpected weight increases for the keg */
  unexpectedWeightIncreases?: Maybe<Scalars['Int']>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** Keg vessel. Provides size, shape, and material information. */
  vessel?: Maybe<Vessel>;
};

export type KegConnection = {
  __typename?: 'KegConnection';
  items?: Maybe<Array<Maybe<Keg>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
  /** Used in OpenSearch queries only. */
  totalResults?: Maybe<Scalars['Int']>;
};

export type KegDepletionEstimate = {
  __typename?: 'KegDepletionEstimate';
  date?: Maybe<Scalars['String']>;
  fluidOz?: Maybe<Scalars['Float']>;
};

/**
 * KegDepletionPace
 *
 * Returns the date and depletionPace (str) for a given keg.
 */
export type KegDepletionPace = {
  __typename?: 'KegDepletionPace';
  /** depletionPaceDetails returns the last 7 day of depletion data for a keg */
  depletionPaceDetails?: Maybe<Array<Maybe<DepletionPaceDetails>>>;
};

export type KegDepletionStats = {
  __typename?: 'KegDepletionStats';
  dailyEstimates?: Maybe<Array<Maybe<KegDepletionEstimate>>>;
  projectedKickDate?: Maybe<Scalars['String']>;
};

/** This entire type is a stub for graphql type generation */
export type KegHistory = {
  __typename?: 'KegHistory';
  stub?: Maybe<Scalars['String']>;
};

/**
 * A keg contains a product within a physical vessel. This is one of the primary object with which the PerformancePlatform operations system interacts.
 * This is the public version of a keg, with some private information redacted.
 */
export type KegPublic = {
  __typename?: 'KegPublic';
  /** Date string, in UTC, when the keg was kicked. */
  actualKickDate?: Maybe<Scalars['String']>;
  assignedPositionIndex?: Maybe<Scalars['Int']>;
  /** Field resolves to a TapSensor. DDB field will contain the TapSensor id, tapNumber, title, and updatedDate. */
  assignedTapSensor?: Maybe<TapSensor>;
  /** Date string, in UTC, when the keg was packaged. Helpful for determining freshness. */
  bornOnDate?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** Current volume of the keg, in ounces. */
  currentFluidOz?: Maybe<Scalars['Float']>;
  /** DEPRECATED in favor of `servings[n].isDefault`. Sets the default serving size for this keg. */
  defaultServingSize?: Maybe<Scalars['Int']>;
  /** DEPRECATED in favor of `depletionStats`. Rate at which the keg is depeting. */
  depletionPace?: Maybe<Scalars['Int']>;
  /** DEPRECATED in favor of `depletionStats`. Rate at which the keg is depeting, in oz per day. */
  depletionRate?: Maybe<Scalars['Float']>;
  /** Depletion and kick information for the keg. */
  depletionStats?: Maybe<DepletionStats>;
  /** Date string, in UTC, when the keg will expire. */
  expirationDate?: Maybe<Scalars['String']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Initial volume of the keg when tapped, in ounces. */
  initialFluidOz?: Maybe<Scalars['Float']>;
  /** Indicates the `cost` value is an estimate. */
  isEstimate?: Maybe<Scalars['Boolean']>;
  /** Represents if this keg has any recorded depletions. Used to track kegs that are not on tap, but also not depleted, assuming the keg may go on tap again. */
  isPartial?: Maybe<Scalars['Boolean']>;
  /** If this keg went on tap due to an automated keg change event, it is considered 'Temporary' (or 'Unknown' in the UI). */
  isTemporary?: Maybe<Scalars['Boolean']>;
  /** Current status of the keg. */
  kegStatus?: Maybe<KegStatus>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** (Resolved) PricingPreset used for describing a set of pricing and serving sizes for this keg. The `pricingPresetId` is stored but unlisted. */
  pricingPreset?: Maybe<PricingPreset>;
  /** Product object associated with the keg. */
  product?: Maybe<CompositeProduct>;
  /** DEPRECATED in favor of `depletionStats`. Date string, in UTC, when the keg is projected to kick */
  projectedKickDate?: Maybe<Scalars['String']>;
  /** Date string, in UTC, when the keg was received. */
  receivedDate?: Maybe<Scalars['String']>;
  /** DEPRECATED in favor of `servings`. Serving sizes, in ounces. */
  servingSizes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Servings sizes and price for this keg. Used for BrewMenu. */
  servings?: Maybe<Array<Maybe<ServingSize>>>;
  /** Date string, in UTC, when the keg was last tapped. */
  tappedDate?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** Keg vessel. Provides size, shape, and material information. */
  vessel?: Maybe<Vessel>;
};

/** Status of a keg as it progresses through the inventory system. */
export enum KegStatus {
  /** Keg is On Deck. */
  Assigned = 'Assigned',
  /** Keg is empty. */
  Depleted = 'Depleted',
  /** Keg is currently connected to a tap. */
  Tapped = 'Tapped',
  /** Keg is in inventory. Sometimes referred to as 'on hand.' */
  Unassigned = 'Unassigned'
}

/**
 * KegTappedEvent is the return type for the `addKegTappedEvent` mutation. This will
 * be used to track missed KegTappedEvents which will be applied to the datalake for better
 * data integrity.
 */
export type KegTappedEvent = {
  __typename?: 'KegTappedEvent';
  /** ID of the Device. */
  deviceId: Scalars['String'];
  /** ID of the Keg. */
  kegId: Scalars['String'];
  /** ID of the Performance Platform Subscription. */
  ppSubId: Scalars['String'];
  /** ID of the Tap Sensor. */
  tapId: Scalars['String'];
  /** Timestamp for the given reading. */
  timestamp: Scalars['Int'];
};

export type KegThroughput = {
  __typename?: 'KegThroughput';
  keg?: Maybe<Keg>;
  throughputDetails?: Maybe<Array<Maybe<ThroughputDetails>>>;
  totalThroughput?: Maybe<Scalars['Float']>;
};

export type Kombucha = Product & WildCardTitleSearch & {
  __typename?: Scalars['String'];
  abv?: Maybe<Scalars['Float']>;
  acidity?: Maybe<Acidity>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  appearance?: Maybe<Appearance>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  body?: Maybe<Body>;
  brand?: Maybe<Brand>;
  brixCount?: Maybe<Scalars['Float']>;
  carbonation?: Maybe<Carbonation>;
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  guid?: Maybe<Scalars['String']>;
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  isNewProduct?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  kombuchaFamily?: Maybe<KombuchaFamily>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  perceivedSweetness?: Maybe<PerceivedSweetness>;
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  probiotics?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['String']>;
  servingTemperature?: Maybe<ServingTemperature>;
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  slug?: Maybe<Scalars['String']>;
  sourness?: Maybe<Sourness>;
  /** The kombucha style information */
  style?: Maybe<Style>;
  tastingExperience?: Maybe<Scalars['String']>;
  teaCharacter?: Maybe<TeaCharacter>;
  title: Scalars['String'];
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export enum KombuchaFamily {
  FloralHerb = 'Floral__Herb',
  Fruited = 'Fruited',
  GingerSpice = 'Ginger_Spice',
  HardKombucha = 'Hard_Kombucha',
  Hops = 'Hops',
  Jun = 'Jun',
  Original = 'Original'
}

export type Link = {
  __typename?: 'Link';
  label: Scalars['String'];
  target?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

/**
 * **Brand Locations**
 *
 * Locations owned by a brand. Managed in MMB and the primary content for BreweryDB map features.
 */
export type Location = WildCardTitleSearch & {
  __typename?: 'Location';
  address?: Maybe<Address>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  brand?: Maybe<Brand>;
  completionPercentage?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  hours?: Maybe<Array<Maybe<Hours>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Maybe<Link>>>;
  locationHighlights?: Maybe<Array<Maybe<LocationHighlight>>>;
  /** List available products at this location */
  productRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. use `productRelationships` */
  productsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** URL slug. Auto-generated. */
  slug?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  timezone?: Maybe<TimeZone>;
  title: Scalars['String'];
  traits?: Maybe<LocationTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  yearClosed?: Maybe<Scalars['String']>;
  yearOpened?: Maybe<Scalars['String']>;
};

export type LocationConnection = {
  __typename?: 'LocationConnection';
  items?: Maybe<Array<Maybe<Location>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

/** Aggregate of location features */
export type LocationHighlight = {
  __typename?: 'LocationHighlight';
  /** DEPRECATE */
  customHeadline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** DEPRECATE */
  headline?: Maybe<LocationHighlightHeadline>;
};

export enum LocationHighlightHeadline {
  BrewHighlights = 'Brew_Highlights',
  CustomHeadline = 'Custom_Headline',
  LocationHighlights = 'Location_Highlights',
  OurBrews = 'Our_Brews',
  OurCulture = 'Our_Culture',
  OurVibe = 'Our_Vibe',
  ProductionHighlights = 'Production_Highlights',
  TapHighlights = 'Tap_Highlights',
  TourHighlights = 'Tour_Highlights'
}

export type LocationIds = {
  __typename?: 'LocationIds';
  brandId: Scalars['String'];
  locationId: Scalars['String'];
};

export type LocationProductRelationship = {
  __typename?: 'LocationProductRelationship';
  available?: Maybe<Scalars['Boolean']>;
  canned?: Maybe<Scalars['Boolean']>;
  growler?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  onTap?: Maybe<Scalars['Boolean']>;
  product?: Maybe<CompositeProduct>;
};

export type LocationProductRelationshipConnection = {
  __typename?: 'LocationProductRelationshipConnection';
  items?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
};

export enum LocationStatus {
  Closed = 'Closed',
  ComingSoon = 'Coming_Soon',
  OpenForBusiness = 'Open_for_Business',
  TemporarilyClosed = 'Temporarily_Closed'
}

export type LocationTraits = {
  __typename?: 'LocationTraits';
  brewOptions?: Maybe<Array<Maybe<BrewOption>>>;
  draftTaps?: Maybe<Scalars['Int']>;
  educationalActivity?: Maybe<Array<Maybe<EducationalActivity>>>;
  entertainmentActivity?: Maybe<Array<Maybe<EntertainmentActivity>>>;
  foodOptions?: Maybe<Array<Maybe<FoodOption>>>;
  gameActivity?: Maybe<Array<Maybe<GameActivity>>>;
  isOpenToPublic?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isProductionFacility?: Maybe<Scalars['Boolean']>;
  miscActivity?: Maybe<Array<Maybe<MiscActivity>>>;
  nearbyAttractions?: Maybe<Array<Maybe<NearbyAttraction>>>;
  parkingOptions?: Maybe<Array<Maybe<ParkingOptions>>>;
  patronOptions?: Maybe<Array<Maybe<PatronOption>>>;
  physicalActivity?: Maybe<Array<Maybe<PhysicalActivity>>>;
  seatingOptions?: Maybe<Array<Maybe<SeatingOption>>>;
  socialMedia?: Maybe<Array<Maybe<SocialMedia>>>;
  status?: Maybe<LocationStatus>;
  tourInfo?: Maybe<Array<Maybe<Tour>>>;
  type?: Maybe<FacilityType>;
  website?: Maybe<Scalars['String']>;
};

/** Location types used by Performance Platform locations/subscriptions */
export enum LocationType {
  Bar = 'Bar',
  BeerHall = 'Beer_Hall',
  BrewPub = 'Brew_Pub',
  Cafe = 'Cafe',
  Casino = 'Casino',
  Distillery = 'Distillery',
  HotelLodge = 'Hotel___Lodge',
  MusicVenue = 'Music_Venue',
  Restaurant = 'Restaurant',
  RestaurantAndBrewhouse = 'Restaurant_and_Brewhouse',
  SportsVenue = 'Sports_Venue',
  TapTastingRoom = 'Tap_Tasting_Room'
}

export enum Malt {
  AbbeyMalt = 'Abbey_Malt',
  AcidulatedMalt = 'Acidulated_Malt',
  AmberMalt = 'Amber_Malt',
  AromaticMalt = 'Aromatic_Malt',
  AsheburneMildMalt = 'Asheburne_Mild_Malt',
  BambergSmokedMalt = 'Bamberg_Smoked_Malt',
  BarleyBlack = 'Barley__Black',
  BarleyFlaked = 'Barley__Flaked',
  BarleyHulls = 'Barley__Hulls',
  BarleyLightlyRoasted = 'Barley__Lightly_Roasted',
  BarleyMalted = 'Barley__Malted',
  BarleyRaw = 'Barley__Raw',
  BarleyRoasted = 'Barley__Roasted',
  BarleyRoastedDeHusked = 'Barley__Roasted__De__husked',
  BarleyTorrefied = 'Barley__Torrefied',
  BeechwoodSmoked = 'Beechwood_Smoked',
  BelgianPale = 'Belgian_Pale',
  BelgianPilsner = 'Belgian_Pilsner',
  BiscuitMalt = 'Biscuit_Malt',
  BlackMalt = 'Black_Malt',
  BlackMaltDebittered = 'Black_Malt__Debittered',
  BlackMaltOrganic = 'Black_Malt__Organic',
  BlackPatent = 'Black_Patent',
  BlackRoast = 'Black_Roast',
  BlackTreacle = 'Black_Treacle',
  BlackprinzMalt = 'Blackprinz_Malt',
  BlueAgaveNectar = 'Blue_Agave_Nectar',
  BlueCorn = 'Blue_Corn',
  Bonlander = 'Bonlander',
  Briess_2RowChocolateMalt = 'Briess_2__row_Chocolate_Malt',
  BriessBlackprinzMalt = 'Briess_Blackprinz_Malt',
  BritishPaleMalt = 'British_Pale_Malt',
  BrownMalt = 'Brown_Malt',
  BrownSugar = 'Brown_Sugar',
  Brumalt = 'Brumalt',
  BuckwheatRoasted = 'Buckwheat__Roasted',
  C_15 = 'C__15',
  Canada_2RowSilo = 'Canada_2__Row_Silo',
  CaneSugar = 'Cane_Sugar',
  CaraAmber = 'CaraAmber',
  CaraAroma = 'CaraAroma',
  CaraBelge = 'CaraBelge',
  CaraBohemian = 'CaraBohemian',
  CaraBrown = 'CaraBrown',
  CaraFoam = 'CaraFoam',
  CaraHell = 'CaraHell',
  CaraMunich = 'CaraMunich',
  CaraMunich_20L = 'CaraMunich_20L',
  CaraMunich_40L = 'CaraMunich_40L',
  CaraMunich_60L = 'CaraMunich_60L',
  CaraMunich_80L = 'CaraMunich_80L',
  CaraMunich_120L = 'CaraMunich_120L',
  CaraMunichI = 'CaraMunich_I',
  CaraMunichIi = 'CaraMunich_II',
  CaraMunichIii = 'CaraMunich_III',
  CaraPilsDextrinMalt = 'CaraPils__Dextrin_Malt',
  CaraPilsDextrinMaltOrganic = 'CaraPils__Dextrin_Malt__Organic',
  CaraRed = 'CaraRed',
  CaraRye = 'CaraRye',
  CaraStan = 'CaraStan',
  CaraVienneMalt = 'CaraVienne_Malt',
  CaraWheat = 'CaraWheat',
  CaraMalt = 'Cara_Malt',
  CarafaI = 'Carafa_I',
  CarafaIi = 'Carafa_II',
  CarafaIii = 'Carafa_III',
  CarafaSpecial = 'Carafa_Special',
  CaramelCrystalMalt = 'Caramel__Crystal_Malt',
  CaramelCrystalMalt_5L = 'Caramel__Crystal_Malt_5L',
  CaramelCrystalMalt_8L = 'Caramel__Crystal_Malt_8L',
  CaramelCrystalMalt_10L = 'Caramel__Crystal_Malt_10L',
  CaramelCrystalMalt_15L = 'Caramel__Crystal_Malt_15L',
  CaramelCrystalMalt_20L = 'Caramel__Crystal_Malt_20L',
  CaramelCrystalMalt_25L = 'Caramel__Crystal_Malt_25L',
  CaramelCrystalMalt_30L = 'Caramel__Crystal_Malt_30L',
  CaramelCrystalMalt_40L = 'Caramel__Crystal_Malt_40L',
  CaramelCrystalMalt_45L = 'Caramel__Crystal_Malt_45L',
  CaramelCrystalMalt_50L = 'Caramel__Crystal_Malt_50L',
  CaramelCrystalMalt_55L = 'Caramel__Crystal_Malt_55L',
  CaramelCrystalMalt_60L = 'Caramel__Crystal_Malt_60L',
  CaramelCrystalMalt_65L = 'Caramel__Crystal_Malt_65L',
  CaramelCrystalMalt_70L = 'Caramel__Crystal_Malt_70L',
  CaramelCrystalMalt_75L = 'Caramel__Crystal_Malt_75L',
  CaramelCrystalMalt_80L = 'Caramel__Crystal_Malt_80L',
  CaramelCrystalMalt_85L = 'Caramel__Crystal_Malt_85L',
  CaramelCrystalMalt_90L = 'Caramel__Crystal_Malt_90L',
  CaramelCrystalMalt_95L = 'Caramel__Crystal_Malt_95L',
  CaramelCrystalMalt_100L = 'Caramel__Crystal_Malt_100L',
  CaramelCrystalMalt_110L = 'Caramel__Crystal_Malt_110L',
  CaramelCrystalMalt_120L = 'Caramel__Crystal_Malt_120L',
  CaramelCrystalMalt_135L = 'Caramel__Crystal_Malt_135L',
  CaramelCrystalMalt_145L = 'Caramel__Crystal_Malt_145L',
  CaramelCrystalMalt_150L = 'Caramel__Crystal_Malt_150L',
  CaramelCrystalMalt_200L = 'Caramel__Crystal_Malt_200L',
  CaramelCrystalMalt_300L = 'Caramel__Crystal_Malt_300L',
  CaramelCrystalMaltDark = 'Caramel__Crystal_Malt__Dark',
  CaramelCrystalMaltExtraDark = 'Caramel__Crystal_Malt__Extra_Dark',
  CaramelCrystalMaltGold = 'Caramel__Crystal_Malt__Gold',
  CaramelCrystalMaltHeritage = 'Caramel__Crystal_Malt__Heritage',
  CaramelCrystalMaltLight = 'Caramel__Crystal_Malt__Light',
  CaramelCrystalMaltMedium = 'Caramel__Crystal_Malt__Medium',
  CaramelCrystalMaltOrganic = 'Caramel__Crystal_Malt__Organic',
  CarolinaRyeMalt = 'Carolina_Rye_Malt',
  Cereal = 'Cereal',
  CherrySmoked = 'Cherry_Smoked',
  CherrywoodSmokeMalt = 'Cherrywood_Smoke_Malt',
  Chestnuts = 'Chestnuts',
  ChitMalt = 'Chit_Malt',
  ChocolateMalt = 'Chocolate_Malt',
  ChocolateMaltOrganic = 'Chocolate_Malt__Organic',
  ChocolateRyeMalt = 'Chocolate_Rye_Malt',
  ChocolateWheatMalt = 'Chocolate_Wheat_Malt',
  CoffeeMalt = 'Coffee_Malt',
  CoffeeMaltLight = 'Coffee_Malt__Light',
  Corn = 'Corn',
  CornGrits = 'Corn_Grits',
  CornSugarDextrose = 'Corn_Sugar__Dextrose',
  CornField = 'Corn__Field',
  CornFlaked = 'Corn__Flaked',
  Cornmeal = 'Cornmeal',
  Crisp_77 = 'Crisp_77',
  Crisp_120 = 'Crisp_120',
  Crystal_77 = 'Crystal_77',
  DarkChocolate = 'Dark_Chocolate',
  DememeraSugar = 'Dememera_Sugar',
  DextrinMalt = 'Dextrin_Malt',
  DextroseSyrup = 'Dextrose_Syrup',
  ExtraSpecialMalt = 'Extra_Special_Malt',
  FawcettCrystalRye = 'Fawcett_Crystal_Rye',
  FawcettRye = 'Fawcett_Rye',
  Fructose = 'Fructose',
  GermanCologne = 'German_Cologne',
  GladfieldPale = 'Gladfield_Pale',
  GlenEagleMarisOtter = 'Glen_Eagle_Maris_Otter',
  Glucose = 'Glucose',
  GoldenPromise = 'Golden_Promise',
  Harrington_2RowBaseMalt = 'Harrington_2__Row_Base_Malt',
  HazelnutRoasted = 'Hazelnut__Roasted',
  HighFructoseCornSyrup = 'High_Fructose_Corn_Syrup',
  Honey = 'Honey',
  HoneyMalt = 'Honey_Malt',
  HughBairdPaleAleMalt = 'Hugh_Baird_Pale_Ale_Malt',
  KilnAmber = 'Kiln_Amber',
  Lactose = 'Lactose',
  LagerMalt = 'Lager_Malt',
  MaltExtract = 'Malt_Extract',
  MaltedRye = 'Malted_Rye',
  MaltoFrancoBelgePilsMalt = 'Malto_Franco__Belge_Pils_Malt',
  Maltodextrin = 'Maltodextrin',
  Maltoferm = 'Maltoferm',
  Maltose = 'Maltose',
  MapleSyrup = 'Maple_Syrup',
  MarisOtter = 'Maris_Otter',
  MelanoidinMalt = 'Melanoidin_Malt',
  Metcalfe = 'Metcalfe',
  MidnightWheat = 'Midnight_Wheat',
  MildMalt = 'Mild_Malt',
  Millet = 'Millet',
  MunichMalt = 'Munich_Malt',
  MunichMalt_5L = 'Munich_Malt_5L',
  MunichMalt_10L = 'Munich_Malt_10L',
  MunichMalt_20L = 'Munich_Malt_20L',
  MunichMalt_40L = 'Munich_Malt_40L',
  MunichMaltDark = 'Munich_Malt__Dark',
  MunichMaltLight = 'Munich_Malt__Light',
  MunichMaltOrganic = 'Munich_Malt__Organic',
  MunichMaltSmoked = 'Munich_Malt__Smoked',
  MunichMaltTypeI = 'Munich_Malt__Type_I',
  MunichMaltTypeIi = 'Munich_Malt__Type_II',
  MunichWheat = 'Munich_Wheat',
  OatsFlaked = 'Oats__Flaked',
  OatsGoldenNaked = 'Oats__Golden_Naked',
  OatsMalted = 'Oats__Malted',
  OatsRolled = 'Oats__Rolled',
  OatsSteelCutPinheadOats = 'Oats__Steel_Cut__Pinhead_Oats',
  OatsToasted = 'Oats__Toasted',
  PaleChocolateMalt = 'Pale_Chocolate_Malt',
  PaleMalt = 'Pale_Malt',
  PaleMaltGoldenPromise = 'Pale_Malt__Golden_Promise',
  PaleMaltHalcyon = 'Pale_Malt__Halcyon',
  PaleMaltOptic = 'Pale_Malt__Optic',
  PaleMaltOrganic = 'Pale_Malt__Organic',
  PaleWheat = 'Pale_Wheat',
  Palev = 'Palev',
  PalmSugar = 'Palm_Sugar',
  PearlMalt = 'Pearl_Malt',
  PeatedMalt = 'Peated_Malt',
  PeatedMaltSmoked = 'Peated_Malt__Smoked',
  Piloncillo = 'Piloncillo',
  Pilsner_2Rs = 'Pilsner_2RS',
  PilsnerMalt = 'Pilsner_Malt',
  PilsnerMaltOrganic = 'Pilsner_Malt__Organic',
  Rahr_2RowMalt = 'Rahr_2__Row_Malt',
  RahrSpecialPale = 'Rahr_Special_Pale',
  Rauchmalz = 'Rauchmalz',
  RedWheatMalt = 'Red_Wheat_Malt',
  Rice = 'Rice',
  RiceFlaked = 'Rice__Flaked',
  RiceHulls = 'Rice__Hulls',
  RiceRed = 'Rice__Red',
  RiceWhite = 'Rice__White',
  RoastMalt = 'Roast_Malt',
  RyeMalt = 'Rye_Malt',
  RyeFlaked = 'Rye__Flaked',
  SamuelAdamsTwoRowPaleMaltBlend = 'Samuel_Adams_two__row_pale_malt_blend',
  ScottishMalt = 'Scottish_Malt',
  SixRowPaleMalt = 'Six__Row_Pale_Malt',
  SmokedMalt = 'Smoked_Malt',
  SorghumRoasted = 'Sorghum__Roasted',
  SpecialBMalt = 'Special_B_Malt',
  SpecialRoast = 'Special_Roast',
  SpecialWMalt = 'Special_W_Malt',
  SpeltMalt = 'Spelt_Malt',
  SugarCreekRye = 'Sugar_Creek_Rye',
  SugarAlbion = 'Sugar__Albion',
  SugarDark = 'Sugar__Dark',
  SuperiorPilsen = 'Superior_Pilsen',
  ToastedMalt = 'Toasted_Malt',
  TorrefiedWheat = 'Torrefied_Wheat',
  Treacle = 'Treacle',
  TwoRowBarleyMalt = 'Two__Row_Barley_Malt',
  TwoRowPaleMalt = 'Two__Row_Pale_Malt',
  TwoRowPaleMaltOrganic = 'Two__Row_Pale_Malt__Organic',
  TwoRowPaleMaltSmoked = 'Two__Row_Pale_Malt__Smoked',
  TwoRowPaleMaltToasted = 'Two__Row_Pale_Malt__Toasted',
  TwoRowPilsnerMalt = 'Two__Row_Pilsner_Malt',
  TwoRowPilsnerMaltBelgian = 'Two__Row_Pilsner_Malt__Belgian',
  TwoRowPilsnerMaltGermany = 'Two__Row_Pilsner_Malt__Germany',
  TwoRowPilsnerMaltUk = 'Two__Row_Pilsner_Malt__UK',
  VictoryMalt = 'Victory_Malt',
  ViennaMalt = 'Vienna_Malt',
  ViennaRed = 'Vienna_Red',
  WeyermannRye = 'Weyermann_Rye',
  WheatMalt = 'Wheat_Malt',
  WheatMaltBelgian = 'Wheat_Malt__Belgian',
  WheatMaltDark = 'Wheat_Malt__Dark',
  WheatMaltGerman = 'Wheat_Malt__German',
  WheatMaltLight = 'Wheat_Malt__Light',
  WheatMaltOrganic = 'Wheat_Malt__Organic',
  WheatMaltRed = 'Wheat_Malt__Red',
  WheatMaltSmoked = 'Wheat_Malt__Smoked',
  WheatMaltWhite = 'Wheat_Malt__White',
  WheatFlaked = 'Wheat__Flaked',
  WheatRaw = 'Wheat__Raw',
  WheatRed = 'Wheat__Red',
  WheatRoasted = 'Wheat__Roasted',
  WheatToasted = 'Wheat__Toasted',
  WheatTorrified = 'Wheat__Torrified',
  WhiteWheat = 'White_Wheat',
  WyermannVienna = 'Wyermann_Vienna'
}

export enum MarketMyBreweryRole {
  AdminChampion = 'admin_champion'
}

export enum Material {
  Pet = 'PET',
  StainlessSteel = 'Stainless_Steel'
}

export type Mead = Product & WildCardTitleSearch & {
  __typename?: 'Mead';
  /** Alcohol of this mead as a percentage with up to 1 decimal. */
  abv?: Maybe<Scalars['Float']>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Product label. Supports multiple assets, but front-end expects only 1. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** Enum for the mead's Body. */
  body?: Maybe<Body>;
  /** PK of a Brand. */
  brand?: Maybe<Brand>;
  /** Enum for the products Carbonation. */
  carbonation?: Maybe<Carbonation>;
  /** Color of the liquid epressed as Tailwind color e.g. `flower-fruity-apple` or `srm-19`. */
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the brand. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Enum of foods to pair with a mead Product. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: Maybe<Scalars['String']>;
  /** Side profile flavors of a mead. */
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** Enum for the mead's honey variety. */
  honeyVariety?: Maybe<HoneyVariety>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Ingredients of the product. */
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  /** Boolean if the mead is new */
  isNewProduct?: Maybe<Scalars['Boolean']>;
  /** Boolean if product is verified. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** List of locations where this mead is served either in draft or packaged form. */
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. Use `locationRelationships` */
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Enum for the mead's color. */
  meadColor?: Maybe<MeadColor>;
  /** Enum for the mead's perceived sweetness. */
  perceivedSweetness?: Maybe<PerceivedSweetness>;
  /** Main profile flavors of a mead. */
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** The product type. */
  productType?: Maybe<Scalars['String']>;
  /** Date the product was first made available to the public. Expects a valid date string such as `11/27/1984` though the front-end may only render the year in most cases. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Enum of serving temperatures for a mead Product. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** List of suggested glassware to use when drinking this product. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of the mead. Auto-Generated in createProduct using the title. */
  slug?: Maybe<Scalars['String']>;
  /** The mead style information */
  style?: Maybe<Style>;
  /** Description of the mead experience from pour to sip. Limited by the front-end to 300 characters. */
  tastingExperience?: Maybe<Scalars['String']>;
  /** Title of the mead Product. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits of a mead Product. Contains availability, nutrition, allergens, and otherTraits. */
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export enum MeadColor {
  Amber = 'Amber',
  Black = 'Black',
  Brown = 'Brown',
  Copper = 'Copper',
  Garnet = 'Garnet',
  Gold = 'Gold',
  Pink = 'Pink',
  Purple = 'Purple',
  Ruby = 'Ruby',
  Straw = 'Straw',
  Yellow = 'Yellow'
}

export type MediaLibrary = {
  __typename?: 'MediaLibrary';
  items?: Maybe<Array<Maybe<Asset>>>;
  nextToken?: Maybe<Scalars['String']>;
  returnedCount?: Maybe<Scalars['Int']>;
};

/** Type of media used on the Brew Menu Display. */
export enum MediaType {
  /** Associated glassware icon. */
  Glassware = 'Glassware',
  /** Keg depletion levels imagery. */
  KegLevels = 'KegLevels',
  /** (Default) Associated product logo. */
  ProductLogos = 'ProductLogos'
}

/**
 * Menu Item contains the data to descrbe and display an item on a Brew Menu.
 * - `itemId`: ID of Product, CustomProduct, or TapSensor
 * - `parentId`: ID of Brand, BMSub (same as bmSubId), or PPSub
 * - `menuItemType`: Product, CustomProduct, or TapSensor
 */
export type MenuItem = {
  __typename?: 'MenuItem';
  /** (Resolved) All discounted prices for servings that apply on this Menu Item. */
  appliedDiscounts?: Maybe<Array<Maybe<AppliedDiscount>>>;
  /** Assets for this BrewMenu item */
  assets?: Maybe<Array<Maybe<OrganizationAsset>>>;
  /** Form factors in which the product is offered. */
  availability: BrewMenuProductAvailability;
  /** Menu category for organizing products in signage/display and filtering on personal devices. See enum BrewMenuCategory for options. */
  category?: Maybe<BrewMenuCategory>;
  /** Email address of user. Auto-generated. */
  createdBy: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** (Resolved) Products that are not in a keg. May be one-off canned beer/kombucha/cider, food or merchandise. */
  customProduct?: Maybe<BrewMenuCustomProduct>;
  /** Discount Preset IDs used by the field resolver. */
  discountPresetIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** (Resolved) Discount Presets associated with this Menu Item. */
  discountPresets?: Maybe<Array<Maybe<DiscountPreset>>>;
  /** Go-to serving vessel for the menu item. May be overridden if `BrewMenuProductAvailability` does not include `OnTap`  */
  glassware?: Maybe<ServingVessel>;
  /** UUID. SK for MenuItem. */
  id: Scalars['ID'];
  /** Determines visibility of a MenuItem on its parent Menu. */
  isVisible: Scalars['Boolean'];
  /** Typically the ID portion of the SK of the item associated with this `MenuItem`. */
  itemId?: Maybe<Scalars['ID']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy: Scalars['String'];
  /** The type of the item associated with this `MenuItem`. One of Product, CustomProduct, or TapSensor. */
  menuItemType: MenuItemType;
  /** Typically the ID portion of the PK of the item associated with this `MenuItem`. */
  parentId?: Maybe<Scalars['ID']>;
  /** (Resolved) Product from BreweryDB. */
  product?: Maybe<CompositeProduct>;
  /** List of available serving sizes. */
  servings?: Maybe<Array<Maybe<ServingSize>>>;
  /** List of `MenuItemTags` assoicated with this `MenuItem`. */
  tags?: Maybe<Array<Maybe<MenuItemTag>>>;
  /** (Resolved) TapSensor the product is attached to. */
  tapSensor?: Maybe<TapSensor>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

export type MenuItemConnection = {
  __typename?: 'MenuItemConnection';
  /** Results found from query */
  items?: Maybe<Array<Maybe<MenuItem>>>;
  /** Pagination token used for obtaining the next page of results */
  nextToken?: Maybe<Scalars['String']>;
  /** Total number of results found from query */
  returnedCount?: Maybe<Scalars['Int']>;
};

/** Union of `MenuItem` and `MenuSection` used by `BrewMenu` to store and display either. */
export type MenuItemOrSection = MenuItem | MenuSection;

/**
 * Labeled tags that can be added to Brew Menu Items that are used to call out specials, new items, and
 * other deals. These tags are displayed on the Brew Menu Item in the app.
 */
export type MenuItemTag = {
  __typename?: 'MenuItemTag';
  /** Color of the tag. */
  color: Scalars['String'];
  /** Text label for the tag. */
  label: Scalars['String'];
};

/** Enum to specify type of Menu Item. */
export enum MenuItemType {
  CustomProduct = 'CustomProduct',
  Product = 'Product',
  TapSensor = 'TapSensor'
}

/**
 * MenuItemVisbility is a subset of MenuItem due to the nature of
 * limited results returned from a batch update in DynamoDB.
 */
export type MenuItemVisibility = {
  __typename?: 'MenuItemVisibility';
  /** Id of the MenuItem. */
  id?: Maybe<Scalars['ID']>;
  /** Determines visibility of a MenuItem on its parent Menu. */
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** Brew Menu Section stores Menu Items into a named group. */
export type MenuSection = {
  __typename?: 'MenuSection';
  /** Email address of user. Auto-generated. */
  createdBy: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** List of `BrewMenuDisplay` IDs that should hide this section. Hiding is done by the FE. */
  hiddenOnDisplays?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Menu Section ID. */
  id: Scalars['ID'];
  /** Email address of user. Auto-generated. */
  lastUpdatedBy: Scalars['String'];
  /** Ordered list of `MenuItem` IDs for this section. */
  menuItemIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** (Resolved) Ordered list of `MenuItems` for this section. */
  menuItems?: Maybe<Array<Maybe<MenuItem>>>;
  /** Title of the section. */
  title: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

/** Query connection object that contains results that can be paginated. */
export type MenuSectionsConnection = {
  __typename?: 'MenuSectionsConnection';
  /** Brew Menu Sections. */
  items?: Maybe<Array<Maybe<MenuSection>>>;
  /** Pagination token used for obtaining the next page of results. */
  nextToken: Scalars['String'];
  /** Items found during the query. */
  returnedCount?: Maybe<Scalars['Int']>;
};

export type Message = {
  __typename?: 'Message';
  barTitle?: Maybe<Scalars['String']>;
  code?: Maybe<MessageCode>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  gatewayDeviceId?: Maybe<Scalars['String']>;
  gatewayId?: Maybe<Scalars['ID']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isAutoClearable?: Maybe<Scalars['Boolean']>;
  kegId?: Maybe<Scalars['String']>;
  lastConnection?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  performancePlatformSubscription?: Maybe<PerformancePlatformSubscription>;
  ppSubTitle?: Maybe<Scalars['String']>;
  sensorDeviceId?: Maybe<Scalars['String']>;
  source?: Maybe<MessageSource>;
  tapNumber?: Maybe<Scalars['Int']>;
  tapSensorId?: Maybe<Scalars['String']>;
  tapSensorsInfo?: Maybe<Array<Maybe<TapSensorMessageInfo>>>;
  timeRead?: Maybe<Scalars['String']>;
  timeResolved?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  type?: Maybe<MessageType>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

export type MessageCenter = {
  __typename?: 'MessageCenter';
  messageCount?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Maybe<Message>>>;
  nextToken?: Maybe<Scalars['String']>;
  /** Field that exists on a Message specifically for observable filtering. No value will be set here in the database */
  ppSubId?: Maybe<Scalars['ID']>;
};

export enum MessageCode {
  BatteryNormal = 'Battery_Normal',
  GatewayIsOffline = 'Gateway_Is_Offline',
  KegAssigned = 'Keg_Assigned',
  KegOnSensor = 'Keg_On_Sensor',
  LowBattery = 'Low_Battery',
  NoAssignedKegs = 'No_Assigned_Kegs',
  NoKegOnSensor = 'No_Keg_On_Sensor',
  NoProductOnTemporaryKegOnSensorTap = 'No_Product_On_Temporary_Keg_On_Sensor_Tap',
  NoSensorReading = 'No_Sensor_Reading',
  NormalWeightReading = 'Normal_Weight_Reading',
  PartialKegRemoval = 'Partial_Keg_Removal',
  ProductNormalOnTemporaryKeg = 'Product_Normal_On_Temporary_Keg',
  SensorIsOffline = 'Sensor_Is_Offline',
  UnexpectedWeightIncrease = 'Unexpected_Weight_Increase',
  UnknownKegOnSensorTap = 'Unknown_Keg_On_Sensor_Tap',
  UnresponsiveGateway = 'Unresponsive_Gateway'
}

export type MessageConnection = {
  __typename?: 'MessageConnection';
  items?: Maybe<Array<Maybe<Message>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
};

export enum MessageSource {
  Gateway = 'GATEWAY',
  Sensor = 'SENSOR'
}

export enum MessageType {
  Critical = 'Critical',
  Info = 'Info',
  Warning = 'Warning'
}

export enum Method {
  Blended = 'Blended',
  Built = 'Built',
  Draft = 'Draft',
  Muddled = 'Muddled',
  Shaken = 'Shaken',
  Stirred = 'Stirred'
}

export enum MiscActivity {
  CharityNight = 'Charity_Night',
  ChiliCookOff = 'Chili_Cook__Off',
  CigarNight = 'Cigar_Night',
  EatingContest = 'Eating_Contest',
  FamilyNight = 'Family_Night',
  FarmersMarket = 'Farmers_Market',
  FishFry = 'Fish_Fry',
  FoodTruck = 'Food_Truck',
  MilitaryNight = 'Military_Night',
  MugClub = 'Mug_Club',
  PotluckNight = 'Potluck_night',
  TapTakeover = 'Tap_Takeover',
  ThemeNight = 'Theme_Night',
  VendorEvent = 'Vendor_Event',
  ViewingParty = 'Viewing_Party'
}

export enum Mixer {
  Juice = 'Juice',
  Soda = 'Soda',
  Syrup = 'Syrup'
}

export type Mutation = {
  __typename?: 'Mutation';
  addAssignedKeg?: Maybe<TapSensor>;
  /** Add Favorite as own item in Dynamo. */
  addFavorite?: Maybe<User>;
  /** Add items to a Board. */
  addItemToBoard?: Maybe<Board>;
  addKegTappedEvent?: Maybe<KegTappedEvent>;
  /** DEPRECATED, EOL v1.29. Adds a `MenuItem` or `MenuSection` to a `BrewMenu`. */
  addMenuItemOrSectionToBrewMenu?: Maybe<BrewMenu>;
  /** DEPRECATED, EOL v1.28,use `addMenuItemOrSectionToBrewMenu`. */
  addMenuItemToBrewMenu?: Maybe<BrewMenu>;
  addMessage?: Maybe<MessageCenter>;
  /** DEPRECATED as of 1.17.0. Use addFavorite and updateFavorite. */
  addUpdateFavorite?: Maybe<User>;
  archiveBrand?: Maybe<Brand>;
  archiveMessage?: Maybe<MessageCenter>;
  closeLocation?: Maybe<Location>;
  createAccountRequest?: Maybe<AccountRequest>;
  createAsset?: Maybe<Asset>;
  /** Create a Board. Create only takes a title. */
  createBoard?: Maybe<Board>;
  createBrand?: Maybe<Brand>;
  createBrewMenu?: Maybe<BrewMenu>;
  /** Create a Brew Menu Custom Product entry in DDB. */
  createBrewMenuCustomProduct?: Maybe<BrewMenuCustomProduct>;
  createBrewMenuDisplay?: Maybe<BrewMenuDisplay>;
  /**
   * Create a BrewMenuSubscription for an Organization. Only the Organization Owner can call this Mutation.
   * The submission must also have a valid stripe checkout ID.
   */
  createBrewMenuSubscription?: Maybe<BrewMenuSubscription>;
  createBreweryRoute?: Maybe<BreweryRoute>;
  /** Creates a brew menu discount preset associated with the given brew menu subscription. */
  createDiscountPreset?: Maybe<DiscountPreset>;
  createKeg?: Maybe<Keg>;
  createKegs?: Maybe<Array<Maybe<Keg>>>;
  createLocation?: Maybe<Location>;
  createLocationProductRelationship?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Create a `MenuItem` entry in DynamoDB. */
  createMenuItem?: Maybe<MenuItem>;
  /** Create a `MenuSection` entry in DynamoDB. */
  createMenuSection?: Maybe<MenuSection>;
  createOrganizationAsset?: Maybe<OrganizationAsset>;
  /**
   * Endpoint that creates an Organization for current user. This endpoint only works if the user does not belong to an Organization.
   * The user who calls this will be the owner of created Organization, and will automatically have the correct role associated
   * to perform Owner actions. If the user already belongs to an Organization, an Exception will be raised and an error returned.
   */
  createOrganizationForSelf?: Maybe<Organization>;
  /**
   * Creates the `OrganizationGroup` by creating the primary and subscription records.
   * Intended to be used by `Organization` owners or Super Admins.
   */
  createOrganizationGroup?: Maybe<OrganizationGroup>;
  createOrganizationPricingPreset?: Maybe<PricingPreset>;
  createPerformancePlatformSubscriptionRequest?: Maybe<PerformancePlatformSubscriptionRequest>;
  /** @DEPRECATED - Use `createOrganizationPricingPreset` */
  createPricingPreset?: Maybe<PricingPreset>;
  /**
   * Mutation to create a BrewLogix `Product`, all types, including Unverified Products. Each product type has a
   * both a shared unique input as well as a type-specific input.
   */
  createProduct?: Maybe<CompositeProduct>;
  /**
   * Creates the report record for tracking the status of the report in the DB and returns the
   * report tracking record with a timed upload link for uploading the raw PoS report files.
   */
  createReport?: Maybe<TrackedVarianceReport>;
  createStyle?: Maybe<Style>;
  createVessel?: Maybe<Vessel>;
  deleteAsset?: Maybe<Asset>;
  deleteAuthenticatedUser?: Maybe<User>;
  /** Delete a board. A user can only delete one of their own boards. */
  deleteBoard?: Maybe<Board>;
  /** DEPRECATED since 1.19.1. See superAdminDeleteBrand */
  deleteBrand?: Maybe<Brand>;
  deleteBrewMenu?: Maybe<BrewMenu>;
  /** Delete a Brew Menu Custom Product entry from DDB. Deletes the Custom Product and it's Menu Items, along with removing it from Brew Menus. */
  deleteBrewMenuCustomProduct?: Maybe<BrewMenuCustomProduct>;
  deleteBrewMenuDisplay?: Maybe<BrewMenuDisplay>;
  /** DEPRECATED, EOL v1.28, use `deleteMenuItemsOrSections`. */
  deleteBrewMenuItems?: Maybe<DeleteMenuItemConnection>;
  deleteBreweryRoute?: Maybe<BreweryRoute>;
  /** Deletes a brew menu discount preset associated with the given brew menu subscription. */
  deleteDiscountPreset?: Maybe<DiscountPreset>;
  /** Will delete the Favorite dynamo record entirely. */
  deleteFavorite?: Maybe<User>;
  deleteKeg?: Maybe<Keg>;
  deleteLocation?: Maybe<Location>;
  deleteLocationProductRelationship?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED, EOL v1.28, use `deleteMenuItemsOrSections`. */
  deleteMenuItem?: Maybe<MenuItem>;
  /**
   * Deletes one or more `MenuItems` belonging to a `BrewMenuSubscription` by IDs.
   * This also removes the deleted `MenuItems` from any `MenuSections` they are attached too.
   * This endpoint returns a `DeleteItemsResponse` that contains a list of the deleted item IDs.
   */
  deleteMenuItems?: Maybe<DeleteItemsResponse>;
  /** DEPRECATED, EOL v1.29. Deletes `MenuItems` and `MenuSections` by the provided IDs. */
  deleteMenuItemsOrSections?: Maybe<DeleteMenuItemsOrSectionsConnection>;
  /**
   * Deletes one or more `MenuSections` belonging to a `BrewMenuSubscription` by IDs.
   * This also removes the deleted `MenuSections` from any `BrewMenus` they are attached too.
   * This endpoint returns a `DeleteItemsResponse` that contains a list of the deleted item IDs.
   */
  deleteMenuSections?: Maybe<DeleteItemsResponse>;
  deleteOrganizationAsset?: Maybe<OrganizationAsset>;
  /**
   * Deletes the `OrganizationGroup` by removing it's primary and subscription records.
   * Intended to be used by `Organization` owners or Super Admins.
   */
  deleteOrganizationGroup?: Maybe<OrganizationGroup>;
  deleteOrganizationPricingPreset?: Maybe<PricingPreset>;
  deletePPSub?: Maybe<PerformancePlatformSubscription>;
  /** @DEPRECATED - Use `deleteOrganizationPricingPreset` */
  deletePricingPreset?: Maybe<PricingPreset>;
  deleteProduct?: Maybe<CompositeProduct>;
  deleteStyle?: Maybe<Style>;
  deleteVessel?: Maybe<Vessel>;
  promoteAssignedKegs?: Maybe<TapSensor>;
  rearrangeTapSensorAssignedKegs?: Maybe<TapSensor>;
  refreshTapSensor?: Maybe<TapSensor>;
  removeAndReplaceTappedKeg?: Maybe<TapSensor>;
  removeAssignedKeg?: Maybe<TapSensor>;
  /** Remove a `GuestBoard` reference to another User's Board. */
  removeGuestBoard?: Maybe<GuestBoard>;
  /** Remove items from a Board. */
  removeItemFromBoard?: Maybe<Board>;
  /** DEPRECATED, EOL v1.28, use `addMenuItemOrSectionToBrewMenu`. */
  removeMenuItemFromBrewMenu?: Maybe<BrewMenu>;
  /** DEPRECATED, EOL v1.29. Removes a `MenuItem` or `MenuSection` from a `BrewMenu`. */
  removeMenuItemOrSectionFromBrewMenu?: Maybe<BrewMenu>;
  removeTappedKeg?: Maybe<TapSensor>;
  /** Save a `GuestBoard` reference to another User's `Board`. */
  saveGuestBoard?: Maybe<GuestBoard>;
  setSensorReadingToInvalid?: Maybe<InvalidReading>;
  setTappedKeg?: Maybe<TapSensor>;
  /** Add a Tenant(Application) to an Organization */
  superAdminAddTenantToOrganization?: Maybe<Organization>;
  superAdminAddUserBMSubAssociation?: Maybe<BmSubscriptionAssociation>;
  superAdminAddUserBrandAssociation?: Maybe<BrandAssociation>;
  superAdminAddUserPPSubAssociation?: Maybe<PpSubscriptionAssociation>;
  superAdminCreateBrewMenuSubscription?: Maybe<BrewMenuSubscription>;
  superAdminCreateGateway?: Maybe<Gateway>;
  superAdminCreateInventoryRecord?: Maybe<InventoryRecord>;
  /** Create an Organization, superadmin only. */
  superAdminCreateOrganization?: Maybe<Organization>;
  superAdminCreatePPSub?: Maybe<PerformancePlatformSubscription>;
  superAdminCreateTapSensor?: Maybe<TapSensor>;
  /**
   * Create a Cognito User + DDB Reference. Only used for creating Cognito users. Clerk
   * users should be created by directly calling Clerk libs and adding a db reference using
   * the createUserReference on the public api.
   */
  superAdminCreateUser?: Maybe<User>;
  /** Superadmin-only deletes */
  superAdminDeleteAccountRequest?: Maybe<AccountRequest>;
  superAdminDeleteBrand?: Maybe<BrandMessage>;
  superAdminDeleteBrewMenuSubscription?: Maybe<BrewMenuSubscription>;
  superAdminDeleteGateway?: Maybe<Gateway>;
  superAdminDeleteInventoryRecord?: Maybe<InventoryRecord>;
  superAdminDeleteMessage?: Maybe<Message>;
  /** Delete an Organization, superadmin only. */
  superAdminDeleteOrganization?: Maybe<Organization>;
  superAdminDeleteTapSensor?: Maybe<TapSensor>;
  superAdminDeleteUnverifiedProduct?: Maybe<CompositeProduct>;
  superAdminDeleteUser?: Maybe<DeleteUserResponse>;
  superAdminPromoteAssignedKegs?: Maybe<TapSensor>;
  /** Remove a Tenant(Application) from an Organization */
  superAdminRemoveTenantFromOrganization?: Maybe<Organization>;
  superAdminRemoveUserBMSubAssociation?: Maybe<BmSubscriptionAssociation>;
  superAdminRemoveUserBrandAssociation?: Maybe<BrandAssociation>;
  /** Remove a users role in an organization, unless the user is an Owner */
  superAdminRemoveUserOrganization?: Maybe<User>;
  superAdminRemoveUserPPSubAssociation?: Maybe<PpSubscriptionAssociation>;
  /** Set a users role in an Organization */
  superAdminSetUserOrganization?: Maybe<User>;
  /**
   * Superadmin-only updates.
   * If account approval was to be handled on BE the resolver function would watch for
   * `AccountRequest.status` !=== Approved to `AccountRequest.status` !=== Approved and run additional lambdas.
   * Otherwise Front-end will handle running superAdminCreateUser through the approval flow.
   */
  superAdminUpdateAccountRequest?: Maybe<AccountRequest>;
  superAdminUpdateBrewMenuSubscription?: Maybe<BrewMenuSubscription>;
  superAdminUpdateGateway?: Maybe<Gateway>;
  superAdminUpdateInventoryRecord?: Maybe<InventoryRecord>;
  /** Update an Organization, superadmin only. */
  superAdminUpdateOrganization?: Maybe<Organization>;
  superAdminUpdatePPSub?: Maybe<PerformancePlatformSubscription>;
  superAdminUpdateProductAvailabilityByBrandId?: Maybe<BrandProductAvailabilityConnection>;
  superAdminUpdateTapSensor?: Maybe<TapSensor>;
  /** Update an end user. Can be used for Clerk or Cognito Users */
  superAdminUpdateUser?: Maybe<User>;
  /**
   * Updates the report record status to `PendingCSUpload` and returns the report tracking record
   * with a timed upload link for uploading the pre-processed PoS report file.
   */
  superAdminUploadCSReport?: Maybe<TrackedVarianceReport>;
  /**
   * Syncs Brew Menu items with Performance Platform TapSensors ensuring there is a 1-to-1 match of a Menu Item for each
   * TapSensor. This process will also copy the `servings` from the `TapSensor.tappedKeg.servings` to any newly created
   * Menu Item.
   * NOTE - Deleted TapSensors will have their Menu Items removed when you call this endpoint.
   */
  syncMenuItemsWithTapSensors?: Maybe<BrewMenuSubscription>;
  updateAsset?: Maybe<Asset>;
  /** Update the title of a Board. */
  updateBoard?: Maybe<Board>;
  updateBrand?: Maybe<Brand>;
  updateBrewMenu?: Maybe<BrewMenu>;
  /** Update an existing Brew Menu Custom Product entry in DDB. */
  updateBrewMenuCustomProduct?: Maybe<BrewMenuCustomProduct>;
  updateBrewMenuDisplay?: Maybe<BrewMenuDisplay>;
  /** DEPRECATED, EOL v1.28, use `updateBrewMenu`. Updates the Brew Menu `menuItems` with the input `menuItems` while maintaining item ordering. */
  updateBrewMenuItems?: Maybe<BrewMenu>;
  updateBrewMenuItemsVisibility?: Maybe<BrewMenuItemVisbilityConnection>;
  updateBrewMenuSubscription?: Maybe<BrewMenuSubscription>;
  updateBreweryRoute?: Maybe<BreweryRoute>;
  /** Updates a brew menu discount preset associated with the given brew menu subscription. */
  updateDiscountPreset?: Maybe<DiscountPreset>;
  /** Update notes and isActive on a Favorite. */
  updateFavorite?: Maybe<User>;
  updateKeg?: Maybe<Keg>;
  updateLocation?: Maybe<Location>;
  updateLocationProductRelationship?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Update a `MenuItem` entry in DynamoDB. */
  updateMenuItem?: Maybe<MenuItem>;
  /** Update a `MenuSection` entry in DynamoDB. */
  updateMenuSection?: Maybe<MenuSection>;
  updateMessage?: Maybe<MessageCenter>;
  /** Owner endpoint for updating Orgs (NOT super admin). */
  updateOrganization?: Maybe<Organization>;
  updateOrganizationAsset?: Maybe<OrganizationAsset>;
  /**
   * Update the `OrganizationGroup` primary and subscription records.
   * Intended to be used by `Organization` owners or Super Admins.
   */
  updateOrganizationGroup?: Maybe<OrganizationGroup>;
  updateOrganizationPricingPreset?: Maybe<PricingPreset>;
  updatePPSub?: Maybe<PerformancePlatformSubscription>;
  /** @DEPRECATED - Use `updateOrganizationPricingPreset` */
  updatePricingPreset?: Maybe<PricingPreset>;
  updateProduct?: Maybe<CompositeProduct>;
  updateStyle?: Maybe<Style>;
  updateUser?: Maybe<User>;
  updateVessel?: Maybe<Vessel>;
};


export type MutationAddAssignedKegArgs = {
  input: AddAssignedKeg;
};


export type MutationAddFavoriteArgs = {
  input: InputFavorite;
};


export type MutationAddItemToBoardArgs = {
  input: InputBoardItem;
};


export type MutationAddKegTappedEventArgs = {
  input: InputKegTappedEvent;
};


export type MutationAddMenuItemOrSectionToBrewMenuArgs = {
  input: InputBrewMenuItemOrSection;
};


export type MutationAddMenuItemToBrewMenuArgs = {
  input: InputMenuItem;
};


export type MutationAddMessageArgs = {
  input: AddMessage;
};


export type MutationAddUpdateFavoriteArgs = {
  input: InputFavorite;
};


export type MutationArchiveBrandArgs = {
  input: ArchiveBrand;
};


export type MutationArchiveMessageArgs = {
  input: ArchiveMessage;
};


export type MutationCloseLocationArgs = {
  input: CloseLocation;
};


export type MutationCreateAccountRequestArgs = {
  input: InputCreateAccountRequest;
};


export type MutationCreateAssetArgs = {
  input: InputCreateAsset;
};


export type MutationCreateBoardArgs = {
  input: InputCreateBoard;
};


export type MutationCreateBrandArgs = {
  input: CreateBrand;
};


export type MutationCreateBrewMenuArgs = {
  input: InputCreateBrewMenu;
};


export type MutationCreateBrewMenuCustomProductArgs = {
  input: InputCreateCustomProduct;
};


export type MutationCreateBrewMenuDisplayArgs = {
  input: InputCreateBrewMenuDisplay;
};


export type MutationCreateBrewMenuSubscriptionArgs = {
  input: InputCreateBrewMenuSubscription;
};


export type MutationCreateBreweryRouteArgs = {
  input: InputCreateBreweryRoute;
};


export type MutationCreateDiscountPresetArgs = {
  input: InputCreateDiscountPreset;
};


export type MutationCreateKegArgs = {
  input: CreateKeg;
};


export type MutationCreateKegsArgs = {
  input: CreateKegs;
};


export type MutationCreateLocationArgs = {
  input: CreateLocation;
};


export type MutationCreateLocationProductRelationshipArgs = {
  input: InputLocationProductRelationship;
};


export type MutationCreateMenuItemArgs = {
  input: InputCreateMenuItem;
};


export type MutationCreateMenuSectionArgs = {
  input: InputCreateMenuSection;
};


export type MutationCreateOrganizationAssetArgs = {
  input: InputCreateOrganizationAsset;
};


export type MutationCreateOrganizationForSelfArgs = {
  input: InputCreateOrganizationForSelf;
};


export type MutationCreateOrganizationGroupArgs = {
  input: InputCreateOrganizationGroup;
};


export type MutationCreateOrganizationPricingPresetArgs = {
  input?: InputMaybe<InputCreateOrganizationPricingPreset>;
};


export type MutationCreatePerformancePlatformSubscriptionRequestArgs = {
  input: InputCreatePerformancePlatformSubscriptionRequest;
};


export type MutationCreatePricingPresetArgs = {
  input?: InputMaybe<InputCreatePricingPreset>;
};


export type MutationCreateProductArgs = {
  input: CreateProduct;
};


export type MutationCreateReportArgs = {
  input: InputCreateReport;
};


export type MutationCreateStyleArgs = {
  input: CreateStyle;
};


export type MutationCreateVesselArgs = {
  input: CreateVessel;
};


export type MutationDeleteAssetArgs = {
  input: InputAssetIds;
};


export type MutationDeleteAuthenticatedUserArgs = {
  input?: InputMaybe<InputDeleteAuthenticatedUser>;
};


export type MutationDeleteBoardArgs = {
  input: InputDeleteBoard;
};


export type MutationDeleteBrandArgs = {
  input: DeleteBrand;
};


export type MutationDeleteBrewMenuArgs = {
  input: InputDeleteBrewMenu;
};


export type MutationDeleteBrewMenuCustomProductArgs = {
  input: InputDeleteCustomProduct;
};


export type MutationDeleteBrewMenuDisplayArgs = {
  input: InputDeleteBrewMenuDisplay;
};


export type MutationDeleteBrewMenuItemsArgs = {
  input: InputDeleteMenuItems;
};


export type MutationDeleteBreweryRouteArgs = {
  input: InputDeleteBreweryRoute;
};


export type MutationDeleteDiscountPresetArgs = {
  input: InputDeleteDiscountPreset;
};


export type MutationDeleteFavoriteArgs = {
  input: InputDeleteFavorite;
};


export type MutationDeleteKegArgs = {
  input: DeleteKeg;
};


export type MutationDeleteLocationArgs = {
  input: DeleteLocation;
};


export type MutationDeleteLocationProductRelationshipArgs = {
  input: InputLocationProductRelationship;
};


export type MutationDeleteMenuItemArgs = {
  input: InputDeleteMenuItem;
};


export type MutationDeleteMenuItemsArgs = {
  input: InputDeleteBmMenuItems;
};


export type MutationDeleteMenuItemsOrSectionsArgs = {
  input: InputDeleteMenuItemsOrSections;
};


export type MutationDeleteMenuSectionsArgs = {
  input: InputDeleteBmMenuSections;
};


export type MutationDeleteOrganizationAssetArgs = {
  input: InputOrganizationAssetById;
};


export type MutationDeleteOrganizationGroupArgs = {
  input: InputDeleteOrganizationGroup;
};


export type MutationDeleteOrganizationPricingPresetArgs = {
  input?: InputMaybe<InputDeleteOrganizationPricingPreset>;
};


export type MutationDeletePpSubArgs = {
  input: DeletePpSub;
};


export type MutationDeletePricingPresetArgs = {
  input?: InputMaybe<InputDeletePricingPreset>;
};


export type MutationDeleteProductArgs = {
  input: DeleteProduct;
};


export type MutationDeleteStyleArgs = {
  input: DeleteStyle;
};


export type MutationDeleteVesselArgs = {
  input: DeleteVessel;
};


export type MutationPromoteAssignedKegsArgs = {
  input: InputPromoteAssignedKegs;
};


export type MutationRearrangeTapSensorAssignedKegsArgs = {
  input: RearrangeTapSensorAssignedKegs;
};


export type MutationRefreshTapSensorArgs = {
  input: InputRefreshTapSensor;
};


export type MutationRemoveAndReplaceTappedKegArgs = {
  input: RemoveAndReplaceTappedKeg;
};


export type MutationRemoveAssignedKegArgs = {
  input: RemoveAssignedKeg;
};


export type MutationRemoveGuestBoardArgs = {
  input: InputRemoveGuestBoard;
};


export type MutationRemoveItemFromBoardArgs = {
  input: InputBoardItem;
};


export type MutationRemoveMenuItemFromBrewMenuArgs = {
  input: InputMenuItem;
};


export type MutationRemoveMenuItemOrSectionFromBrewMenuArgs = {
  input: InputBrewMenuItemOrSection;
};


export type MutationRemoveTappedKegArgs = {
  input: RemoveTappedKeg;
};


export type MutationSaveGuestBoardArgs = {
  input: InputSaveGuestBoard;
};


export type MutationSetSensorReadingToInvalidArgs = {
  input: InputInvalidSensorReading;
};


export type MutationSetTappedKegArgs = {
  input: SetTappedKeg;
};


export type MutationSuperAdminAddTenantToOrganizationArgs = {
  input: InputSuperAdminAddRemoveTenantToOrganization;
};


export type MutationSuperAdminAddUserBmSubAssociationArgs = {
  input: InputAddOrRemoveBmSubscriptionAssociation;
};


export type MutationSuperAdminAddUserBrandAssociationArgs = {
  input?: InputMaybe<InputAddOrRemoveBrandAssociation>;
};


export type MutationSuperAdminAddUserPpSubAssociationArgs = {
  input?: InputMaybe<InputAddOrRemovePpSubscriptionAssociation>;
};


export type MutationSuperAdminCreateBrewMenuSubscriptionArgs = {
  input: InputSuperAdminCreateBrewMenuSubscription;
};


export type MutationSuperAdminCreateGatewayArgs = {
  input: InputSuperAdminCreateGateway;
};


export type MutationSuperAdminCreateInventoryRecordArgs = {
  input: InputCreateInventoryRecord;
};


export type MutationSuperAdminCreateOrganizationArgs = {
  input: InputSuperAdminCreateOrganization;
};


export type MutationSuperAdminCreatePpSubArgs = {
  input: InputSuperAdminCreatePpSub;
};


export type MutationSuperAdminCreateTapSensorArgs = {
  input: InputSuperAdminCreateTapSensor;
};


export type MutationSuperAdminCreateUserArgs = {
  input: InputSuperAdminCreateUser;
};


export type MutationSuperAdminDeleteAccountRequestArgs = {
  input: InputSuperAdminDeleteAccountRequest;
};


export type MutationSuperAdminDeleteBrandArgs = {
  input: InputSuperAdminDeleteBrand;
};


export type MutationSuperAdminDeleteBrewMenuSubscriptionArgs = {
  input: InputSuperAdminDeleteBrewMenuSubscription;
};


export type MutationSuperAdminDeleteGatewayArgs = {
  input: DeleteGateway;
};


export type MutationSuperAdminDeleteInventoryRecordArgs = {
  input: InputDeleteInventoryRecord;
};


export type MutationSuperAdminDeleteMessageArgs = {
  input: InputSuperAdminDeletePpSubItem;
};


export type MutationSuperAdminDeleteOrganizationArgs = {
  input: InputSuperAdminDeleteOrganization;
};


export type MutationSuperAdminDeleteTapSensorArgs = {
  input: InputSuperAdminDeleteTapSensor;
};


export type MutationSuperAdminDeleteUnverifiedProductArgs = {
  input: InputSuperAdminDeleteUnverifiedProduct;
};


export type MutationSuperAdminDeleteUserArgs = {
  input: InputSuperAdminUser;
};


export type MutationSuperAdminPromoteAssignedKegsArgs = {
  input: InputSuperAdminPromoteAssignedKegs;
};


export type MutationSuperAdminRemoveTenantFromOrganizationArgs = {
  input: InputSuperAdminAddRemoveTenantToOrganization;
};


export type MutationSuperAdminRemoveUserBmSubAssociationArgs = {
  input: InputAddOrRemoveBmSubscriptionAssociation;
};


export type MutationSuperAdminRemoveUserBrandAssociationArgs = {
  input?: InputMaybe<InputAddOrRemoveBrandAssociation>;
};


export type MutationSuperAdminRemoveUserOrganizationArgs = {
  input: InputSuperAdminRemoveUserOrganization;
};


export type MutationSuperAdminRemoveUserPpSubAssociationArgs = {
  input?: InputMaybe<InputAddOrRemovePpSubscriptionAssociation>;
};


export type MutationSuperAdminSetUserOrganizationArgs = {
  input: InputSuperAdminSetUserOrganization;
};


export type MutationSuperAdminUpdateAccountRequestArgs = {
  input: InputSuperAdminUpdateAccountRequest;
};


export type MutationSuperAdminUpdateBrewMenuSubscriptionArgs = {
  input: InputSuperAdminUpdateBrewMenuSubscription;
};


export type MutationSuperAdminUpdateGatewayArgs = {
  input: InputSuperAdminUpdateGateway;
};


export type MutationSuperAdminUpdateInventoryRecordArgs = {
  input: InputUpdateInventoryRecord;
};


export type MutationSuperAdminUpdateOrganizationArgs = {
  input: InputSuperAdminUpdateOrganization;
};


export type MutationSuperAdminUpdatePpSubArgs = {
  input: InputSuperAdminUpdatePpSub;
};


export type MutationSuperAdminUpdateProductAvailabilityByBrandIdArgs = {
  input: InputBrandProductAvailability;
};


export type MutationSuperAdminUpdateTapSensorArgs = {
  input: InputSuperAdminUpdateTapSensor;
};


export type MutationSuperAdminUpdateUserArgs = {
  input: InputSuperAdminUpdateUser;
};


export type MutationSuperAdminUploadCsReportArgs = {
  input: InputSuperAdminUploadCsReport;
};


export type MutationSyncMenuItemsWithTapSensorsArgs = {
  input: InputByBmSubId;
};


export type MutationUpdateAssetArgs = {
  input: InputUpdateAsset;
};


export type MutationUpdateBoardArgs = {
  input: InputUpdateBoard;
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrand;
};


export type MutationUpdateBrewMenuArgs = {
  input: InputUpdateBrewMenu;
};


export type MutationUpdateBrewMenuCustomProductArgs = {
  input: InputUpdateCustomProduct;
};


export type MutationUpdateBrewMenuDisplayArgs = {
  input: InputUpdateBrewMenuDisplay;
};


export type MutationUpdateBrewMenuItemsArgs = {
  input: InputUpdateBrewMenuItems;
};


export type MutationUpdateBrewMenuItemsVisibilityArgs = {
  input: InputUpdateBrewMenuItemsVisibility;
};


export type MutationUpdateBrewMenuSubscriptionArgs = {
  input: InputUpdateBrewMenuSubscription;
};


export type MutationUpdateBreweryRouteArgs = {
  input: InputUpdateBreweryRoute;
};


export type MutationUpdateDiscountPresetArgs = {
  input: InputUpdateDiscountPreset;
};


export type MutationUpdateFavoriteArgs = {
  input: InputFavorite;
};


export type MutationUpdateKegArgs = {
  input: UpdateKeg;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocation;
};


export type MutationUpdateLocationProductRelationshipArgs = {
  input: InputLocationProductRelationship;
};


export type MutationUpdateMenuItemArgs = {
  input: InputUpdateMenuItem;
};


export type MutationUpdateMenuSectionArgs = {
  input: InputUpdateMenuSection;
};


export type MutationUpdateMessageArgs = {
  input: UpdateMessage;
};


export type MutationUpdateOrganizationArgs = {
  input: InputUpdateOrganization;
};


export type MutationUpdateOrganizationAssetArgs = {
  input: InputUpdateOrganizationAsset;
};


export type MutationUpdateOrganizationGroupArgs = {
  input: InputUpdateOrganizationGroup;
};


export type MutationUpdateOrganizationPricingPresetArgs = {
  input?: InputMaybe<InputUpdateOrganizationPricingPreset>;
};


export type MutationUpdatePpSubArgs = {
  input: UpdatePpSub;
};


export type MutationUpdatePricingPresetArgs = {
  input?: InputMaybe<InputUpdatePricingPreset>;
};


export type MutationUpdateProductArgs = {
  input: UpdateProduct;
};


export type MutationUpdateStyleArgs = {
  input: UpdateStyle;
};


export type MutationUpdateUserArgs = {
  input: UpdateUser;
};


export type MutationUpdateVesselArgs = {
  input: UpdateVessel;
};

export type NearbyAttraction = {
  __typename?: 'NearbyAttraction';
  approximateDistance: Array<Maybe<ApproximateDistance>>;
  title: Scalars['String'];
};

export type Nutrition = {
  __typename?: 'Nutrition';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  cholesterol?: Maybe<Scalars['Float']>;
  dietaryFiber?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  saturatedFat?: Maybe<Scalars['Float']>;
  sodium?: Maybe<Scalars['Float']>;
  sugars?: Maybe<Scalars['Float']>;
  transFat?: Maybe<Scalars['Float']>;
};

export type Observables = {
  __typename?: 'Observables';
  messageCenterEvents?: Maybe<MessageCenter>;
  messageCenterUpdates?: Maybe<MessageCenter>;
  tapSensorEvents?: Maybe<TapSensor>;
  tapSensorUpdates?: Maybe<TapSensor>;
};


export type ObservablesMessageCenterEventsArgs = {
  input: InputMessageCenterEvents;
};


export type ObservablesMessageCenterUpdatesArgs = {
  id: Scalars['ID'];
};


export type ObservablesTapSensorEventsArgs = {
  input: InputTapSensorEvents;
};


export type ObservablesTapSensorUpdatesArgs = {
  id: Scalars['ID'];
};

export enum OpenSearchType {
  Brand = 'Brand',
  Location = 'Location',
  Product = 'Product'
}

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Organization = {
  __typename?: 'Organization';
  /** Address of the org. e.g. the main office */
  address?: Maybe<Address>;
  /** Billing information. */
  billing?: Maybe<BillingPolicy>;
  /** Brands attached to this Org. Resolved field. */
  brands?: Maybe<Array<Maybe<Brand>>>;
  /** BrewMenu subscriptions attached to this Org. Resolved field. */
  brewMenuSubscriptions?: Maybe<Array<Maybe<BrewMenuSubscription>>>;
  /** Frontend managed Cloudinary Asset ID used as this organizations Logo. If null, no associated logo. Use this field until the media library is in place */
  cloudinaryAssetId?: Maybe<Scalars['String']>;
  /** General contact info for the org. e.g. customer support or main office */
  contact?: Maybe<Contact>;
  /** User created by */
  createdBy: Scalars['String'];
  /** Date string, in UTC */
  createdDate: Scalars['String'];
  /** Unique ID of the org within a 3rd party auth provider, e.g. Clerk. */
  externalId?: Maybe<Scalars['String']>;
  /** Company record in Hubspot for maintaining paper trail in CRM for sales/marketing */
  hubspotId?: Maybe<Scalars['String']>;
  /** Unique ID of the org. Primary key in DDB. */
  id: Scalars['ID'];
  /** User updated by */
  lastUpdatedBy: Scalars['String'];
  /** Assets used for branding of the Org. Resolved field. */
  logo?: Maybe<OrganizationAsset>;
  /** Primary owner and point of contact for the organization. Only user with destructive permissions and can manage org users and billing. */
  owner?: Maybe<User>;
  /** PP Subs attached to this Org. Resolved field. */
  ppSubscriptions?: Maybe<Array<Maybe<PerformancePlatformSubscription>>>;
  /** Unique, Auto-Generated, URL-safe identifier. */
  slug?: Maybe<Scalars['String']>;
  /** Superadmin-only field for archiving/activating/deactivating an Org. */
  status?: Maybe<OrganizationStatus>;
  /** Name of the Organization */
  title?: Maybe<Scalars['String']>;
  /** Date string, in UTC */
  updatedDate: Scalars['String'];
  /** All other users part of this organization. Can be invited and managed by owner. Resolved field. */
  users?: Maybe<Array<Maybe<User>>>;
};

export type OrganizationAsset = {
  __typename?: 'OrganizationAsset';
  /** Resolved BrewMenuSubscriptions this asset is attached to as a logo */
  bmSubLogoAssignments?: Maybe<Array<Maybe<BrewMenuSubscription>>>;
  /** Cloudinary ID */
  cloudinaryId: Scalars['String'];
  /** Date string, in UTC */
  createdBy: Scalars['String'];
  /** User created by */
  createdDate: Scalars['String'];
  /** autogenerated internal guid ID */
  id: Scalars['ID'];
  /** Date string, in UTC */
  lastUpdatedBy: Scalars['String'];
  /** Resolved MenuItems this asset is attached to as a list of assets */
  menuItemAssetAssignments?: Maybe<Array<Maybe<MenuItem>>>;
  /**
   * Resolved Organization this asset is attached to as a logo. This is technically a list
   * to keep the underlying implementation consistent, but there should only ever be 1 Organization here.
   */
  organizationLogoAssignments?: Maybe<Array<Maybe<Organization>>>;
  /** Resolved PerformancePlatformSubscriptions this asset is attached to as a logo */
  ppSubLogoAssignments?: Maybe<Array<Maybe<PerformancePlatformSubscription>>>;
  /** Do we need this field right now? */
  shared?: Maybe<Scalars['Boolean']>;
  /** Tags added/edited by users for this asset */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** User updated by */
  updatedDate: Scalars['String'];
};

export type OrganizationAssociation = {
  __typename?: 'OrganizationAssociation';
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  lastUpdatedBy: Scalars['String'];
  organizationId: Scalars['String'];
  role: UserAssociationRole;
  updatedDate: Scalars['String'];
};

/** Connection object used to return a list of Organization objects. */
export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** Items of type `Organization`. */
  items?: Maybe<Array<Maybe<Organization>>>;
  /** Pagination token for the next set of results. */
  nextToken?: Maybe<Scalars['String']>;
  /** Number of items returned in the query (will obey limit filters). */
  returnedCount?: Maybe<Scalars['Int']>;
  /** Total number of results returned in the query. */
  totalResults?: Maybe<Scalars['Int']>;
};

/**
 * An `OrganizationGroup` is a collection of records in the `Organization` table that is used to group
 * together Brands, Performance Platform Subscriptions, and Brew Menu Subscriptions into a single
 * identifiable object. The primary record contains the `id`, `title`, `slug`, and other identifying
 * fields, while the others are simple mapping records to the subscriptions. When a `User` queries an
 * `OrganizationGroup` they will only see the subscriptions they have role access associations with.
 * The `OrganizationGroup` dynamically determines what subscriptions it makes visable based on the
 * current `User` performing the query.
 */
export type OrganizationGroup = {
  __typename?: 'OrganizationGroup';
  /** Brew Menu Sub ID. Used by the resolver. */
  bmSubId?: Maybe<Scalars['ID']>;
  /** Brand ID. Used by the resolver. */
  brandId?: Maybe<Scalars['ID']>;
  /** Frontend managed Cloudinary Asset ID used as this group image. If null, no associated logo. Use this field until the media library is in place. */
  cloudinaryAssetId?: Maybe<Scalars['String']>;
  /** Unique ID for this Organization Group. */
  id: Scalars['ID'];
  /** Performance Platform Sub ID. Used by the resolver. */
  ppSubId?: Maybe<Scalars['ID']>;
  /** Slug generated based on the title. */
  slug: Scalars['String'];
  /** Title of the group. */
  title: Scalars['String'];
};

export type OrganizationMediaLibrary = {
  __typename?: 'OrganizationMediaLibrary';
  /** List of OrganizationAssets. The list will be returned, but not always assets */
  items: Array<Maybe<OrganizationAsset>>;
  /** Pagination Token for getting next N number of assets */
  nextToken?: Maybe<Scalars['String']>;
  /** Total Returned Count */
  returnedCount?: Maybe<Scalars['Int']>;
};

export enum OrganizationStatus {
  /** Org is fully active */
  Active = 'Active',
  /** Org. is permanently disabled. No access to this organization or its data on the front-end. */
  Archived = 'Archived',
  /** Org is temporarily disabled. No access to this organization or its data on the front-end. */
  Paused = 'Paused',
  /** Organization is WIP. i.e. in the process of being created, provisioned/set up. */
  Pending = 'Pending'
}

export type Other = Product & WildCardTitleSearch & {
  __typename?: 'Other';
  /** Alcohol of this cocktail as a percentage with up to 1 decimal. */
  abv?: Maybe<Scalars['Float']>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Product label. Supports multiple assets, but front-end expects only 1. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** PK of a Brand. */
  brand?: Maybe<Brand>;
  /** Enum for the products Carbonation. */
  carbonation?: Maybe<Carbonation>;
  /** Color of the liquid epressed as Tailwind color e.g. `flower-fruity-apple` or `srm-19`. */
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the brand. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Enum of foods to pair for this product. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: Maybe<Scalars['String']>;
  /** Side profile flavors for this product. */
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Ingredients of the product. */
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  /** Boolean to control whether a product is displayed on external customer-facing apps. */
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  /** Boolean if the product is new */
  isNewProduct?: Maybe<Scalars['Boolean']>;
  /** Boolean if product is verified. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** List of locations where this cocktail is served either in draft or packaged form. */
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. Use `locationRelationships` */
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Main profile flavors of thise product. */
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** The product type. */
  productType?: Maybe<Scalars['String']>;
  /** Date the product was first made available to the public. Expects a valid date string such as `11/27/1984` though the front-end may only render the year in most cases. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Enum of serving temperatures for this product. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** List of suggested glassware to use when drinking this product. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of this product. Auto-Generated in createProduct using the title. */
  slug?: Maybe<Scalars['String']>;
  /** The cocktail style information */
  style?: Maybe<Style>;
  /** Description of the product's experience from pour to sip. Limited by the front-end to 300 characters. */
  tastingExperience?: Maybe<Scalars['String']>;
  /** Title of this Product. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits for this product. Contains availability, nutrition, allergens, and otherTraits. */
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export enum OtherProductTraits {
  AlcoholFree = 'Alcohol_Free',
  CertifiedOrganic = 'Certified_Organic',
  ContainsCbd = 'Contains_CBD',
  ContainsThc = 'Contains_THC',
  DairyFree = 'Dairy_Free',
  Draft = 'Draft',
  GlutenFree = 'Gluten_Free',
  GlutenReduced = 'Gluten_Reduced',
  NonAlcoholic = 'Non__Alcoholic'
}

export type PpSettings = {
  __typename?: 'PPSettings';
  batteryReplacementPlan?: Maybe<BatteryReplacementPlan>;
  coolers?: Maybe<Array<Maybe<Scalars['String']>>>;
  couplers?: Maybe<Array<Maybe<Coupler>>>;
  daysOnHandGoal?: Maybe<Scalars['Int']>;
  defaultServingSize?: Maybe<Scalars['Int']>;
  freshnessThreshold?: Maybe<Scalars['Int']>;
  inventoryTurnsGoal?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  newProductDefinition?: Maybe<Scalars['Int']>;
  pricingMethod?: Maybe<PricingMethod>;
  pricingPercentage?: Maybe<Scalars['Int']>;
  productLifeTracking?: Maybe<ProductLifeTracking>;
  seatingCapacity?: Maybe<SeatingCapacity>;
  servingSizes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  standardHours?: Maybe<Array<Maybe<Hours>>>;
  tappedGoal?: Maybe<Scalars['Int']>;
  timeZone?: Maybe<TimeZone>;
  varietyScoreGoal?: Maybe<Scalars['Int']>;
  workWeekStartDay?: Maybe<AbbrevWeekDay>;
};

export type PpSubThroughput = {
  __typename?: 'PPSubThroughput';
  throughputDetails?: Maybe<Array<Maybe<ThroughputDetails>>>;
  topBrand?: Maybe<Brand>;
  topProduct?: Maybe<CompositeProduct>;
  topStyle?: Maybe<Style>;
  totalThroughput?: Maybe<Scalars['Float']>;
};

/** PPSubThroughputDetails returns throughput details for a given PPSub. */
export type PpSubThroughputDetails = {
  __typename?: 'PPSubThroughputDetails';
  /** Throughput details over a given time range. */
  throughputDetails?: Maybe<Array<Maybe<ThroughputDetails>>>;
  /** Total throughput for the PPSub. */
  totalThroughput?: Maybe<Scalars['Float']>;
};

/** PPSubThroughputSummary returns summaries for a given PPSub. */
export type PpSubThroughputSummary = {
  __typename?: 'PPSubThroughputSummary';
  /** Brand with the most throughput. */
  topBrand?: Maybe<Brand>;
  /** Product with the most throughput. */
  topProduct?: Maybe<CompositeProduct>;
  /** Style with the most throughput. */
  topStyle?: Maybe<Style>;
  /** Style label with the most throughput. */
  topStyleLabel?: Maybe<Style>;
};

export type PpSubscriptionAssociation = {
  __typename?: 'PPSubscriptionAssociation';
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  lastUpdatedBy: Scalars['String'];
  ppSubId: Scalars['String'];
  role: PerformancePlatformRole;
  updatedDate: Scalars['String'];
};

export type PpSubscriptionConnection = {
  __typename?: 'PPSubscriptionConnection';
  items?: Maybe<Array<Maybe<PerformancePlatformSubscription>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
};

export enum ParkingOptions {
  BicycleParking = 'Bicycle_Parking',
  EScooterParking = 'E__Scooter_Parking',
  GarageParking = 'Garage_Parking',
  OnsitePrivateParking = 'Onsite_Private_Parking',
  OversizedVehicleParking = 'Oversized_Vehicle_Parking',
  RidesharePickupAndDropoff = 'Rideshare_Pickup_and_Dropoff',
  StreetParking = 'Street_Parking',
  ValetParking = 'Valet_Parking'
}

/** Indicates the reason a keg was taken off tap before it was empty. */
export enum PartialReason {
  BeverageProgramChange = 'Beverage_Program_Change',
  OffTaste = 'Off_Taste',
  PoorPerformance = 'Poor_Performance',
  TapTakeover = 'Tap_Takeover'
}

export enum PatronOption {
  AllPets = 'All_Pets',
  Dogs = 'Dogs',
  FamilyFriendly = 'Family_Friendly',
  FirePit = 'Fire_Pit',
  HappyHour = 'Happy_Hour',
  WiFiAvailable = 'WiFi_Available'
}

/** Avialable methods of payment. */
export enum PaymentMethod {
  /** No payment menthod on file. */
  None = 'None',
  /** Payments handled offline through check, ACH, etc. */
  Offline = 'Offline',
  /** Stripe payment processing platform. */
  Stripe = 'Stripe'
}

export enum PearVariety {
  Barland = 'Barland',
  Bartlett = 'Bartlett',
  Blackeney = 'Blackeney',
  Bosc = 'Bosc',
  Brandy = 'Brandy',
  Butt = 'Butt',
  Comice = 'Comice',
  DAnjou = 'D___anjou',
  Gin = 'Gin',
  HendreHuffcap = 'Hendre_Huffcap',
  Moorcroft = 'Moorcroft',
  Red = 'Red',
  Seckel = 'Seckel',
  Thorn = 'Thorn'
}

export enum PerceivedBitterness {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  None = 'None'
}

export enum PerceivedSaltiness {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  None = 'None'
}

export enum PerceivedSourness {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  None = 'None'
}

export enum PerceivedSpiciness {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  None = 'None'
}

export enum PerceivedSweetness {
  Dry = 'Dry',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  None = 'None',
  OffDry = 'Off__Dry',
  SemiDry = 'Semi__Dry',
  SemiSweet = 'Semi__Sweet',
  Sweet = 'Sweet'
}

export enum PerformancePlatformRole {
  Owner = 'owner',
  Staff = 'staff'
}

export type PerformancePlatformSubscription = {
  __typename?: 'PerformancePlatformSubscription';
  address?: Maybe<Address>;
  /** BrewMenu subscription tier based off BrewMenuSuscriptionTier enum */
  brewMenuTier?: Maybe<BrewMenuSubscriptionTier>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** This is the expiration date of the PP Sub's contract. Note that for actual billing automation this will be duplicated to a SAAS billing platform (as part of PLA). */
  expirationDate?: Maybe<Scalars['String']>;
  /** (Resolved) Performance Platform Sub gateways. */
  gateways?: Maybe<Array<Maybe<Gateway>>>;
  /** ID of HubSpot company record related to this PP Subscription. Can be found in URL when viewing a company detail page. e.g. https://app.hubspot.com/contacts/6413354/company/**9864910597** */
  hubSpotId?: Maybe<Scalars['String']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  locationType?: Maybe<LocationType>;
  /** Asset used as logo for this PerformancePlatformSubscription */
  logo?: Maybe<OrganizationAsset>;
  /** Total number of TapSensors included as part of subscription. Note the number of Tap Sensors isn't specifically validated on the attached Gateway -> TapSensors. This value is displayed to customer as a read-only field. */
  numberOfTapSensors?: Maybe<Scalars['Int']>;
  /** If true, this PP Subscription should use the Priority Brands UI. */
  onlyPriorityBrands?: Maybe<Scalars['Boolean']>;
  /** A Performance Platform Subscriptions Organization. Resolved Field */
  organization?: Maybe<Organization>;
  /** List of brands commonly used by the subscription. Intended to elevate search results on receive keg UX. */
  priorityBrands?: Maybe<Array<Maybe<Brand>>>;
  settings?: Maybe<PpSettings>;
  /** URL slug. Auto-generated. */
  slug?: Maybe<Scalars['String']>;
  /** (Resolved) Performance Platform Sub tap sensors. */
  tapSensors?: Maybe<Array<Maybe<TapSensor>>>;
  /** User friendly name of the PP Sub. */
  title?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

export type PerformancePlatformSubscriptionPublic = {
  __typename?: 'PerformancePlatformSubscriptionPublic';
  address?: Maybe<Address>;
  /** BrewMenu subscription tier based off BrewMenuSuscriptionTier enum */
  brewMenuTier?: Maybe<BrewMenuSubscriptionTier>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** This is the expiration date of the PP Sub's contract. Note that for actual billing automation this will be duplicated to a SAAS billing platform (as part of PLA). */
  expirationDate?: Maybe<Scalars['String']>;
  /** ID of HubSpot company record related to this PP Subscription. Can be found in URL when viewing a company detail page. e.g. https://app.hubspot.com/contacts/6413354/company/**9864910597** */
  hubSpotId?: Maybe<Scalars['String']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  locationType?: Maybe<LocationType>;
  /** Total number of TapSensors included as part of subscription. Note the number of Tap Sensors isn't specifically validated on the attached Gateway -> TapSensors. This value is displayed to customer as a read-only field. */
  numberOfTapSensors?: Maybe<Scalars['Int']>;
  /** If true, this PP Subscription should use the Priority Brands UI. */
  onlyPriorityBrands?: Maybe<Scalars['Boolean']>;
  /** List of brands commonly used by the subscription. Intended to elevate search results on receive keg UX. */
  priorityBrands?: Maybe<Array<Maybe<Brand>>>;
  settings?: Maybe<PpSettings>;
  /** URL slug. Auto-generated. */
  slug?: Maybe<Scalars['String']>;
  /** User friendly name of the PP Sub. */
  title?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

/**
 * Request for a PerformancePlatform Subscription stored in the TYPE QUEUE table and HubSpot.
 * Using `Strings` for fields since these come from a web form with direct user input.
 */
export type PerformancePlatformSubscriptionRequest = {
  __typename?: 'PerformancePlatformSubscriptionRequest';
  /** Email address of user. */
  createdBy?: Maybe<Scalars['String']>;
  /** Auto generated date string, in UTC */
  createdDate?: Maybe<Scalars['String']>;
  /** User's email. */
  email?: Maybe<Scalars['String']>;
  /** Unique record identifier. */
  id: Scalars['ID'];
  /** Email address of user. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** Potential title for the PerformancePlatform provided by the user. */
  locationName?: Maybe<Scalars['String']>;
  /** Optional message provided by the user. */
  message?: Maybe<Scalars['String']>;
  /** User's provided number of taps for the location. */
  numberOfTaps?: Maybe<Scalars['String']>;
  /** Organization Id of user who submitted the request. */
  organizationId?: Maybe<Scalars['ID']>;
  /** Organization Title of user who submitted the request. */
  organizationTitle?: Maybe<Scalars['String']>;
  /** User's provided seating capacity for the location. */
  seatingCapacity?: Maybe<Scalars['String']>;
  /** Auto generated date string, in UTC */
  updatedDate?: Maybe<Scalars['String']>;
};

export enum PhysicalActivity {
  Archery = 'Archery',
  AxeThrowing = 'Axe_Throwing',
  Bowling = 'Bowling',
  CanJam = 'Can_Jam',
  DanceNight = 'Dance_Night',
  Dodgeball = 'Dodgeball',
  EscapeRoom = 'Escape_Room',
  Fitness = 'Fitness',
  Fowling = 'Fowling',
  GoatYoga = 'Goat_Yoga',
  Hiking = 'Hiking',
  Larp = 'LARP',
  LineDancing = 'Line_Dancing',
  MechanicalBullRiding = 'Mechanical_Bull_Riding',
  MiniGolf = 'Mini_Golf',
  Olympics = 'Olympics',
  PaintBall = 'Paint_Ball',
  PingPong = 'Ping_Pong',
  PokemonGo = 'Pokemon_Go',
  RunningClub = 'Running_Club',
  Softball = 'Softball',
  SquareDancing = 'Square_Dancing',
  SwingDancing = 'Swing_Dancing',
  Volleyball = 'Volleyball',
  WalkingClub = 'Walking_Club',
  Yoga = 'Yoga'
}

export enum PourSize {
  '2oz' = '_2oz',
  '4oz' = '_4oz',
  '5oz' = '_5oz',
  '6oz' = '_6oz',
  '8oz' = '_8oz',
  '9oz' = '_9oz',
  '10oz' = '_10oz',
  '12oz' = '_12oz',
  '14oz' = '_14oz',
  '16oz' = '_16oz',
  '20oz' = '_20oz',
  '24oz' = '_24oz',
  '32oz' = '_32oz',
  '64oz' = '_64oz'
}

export enum Preparation {
  AeroPress = 'AeroPress',
  ColdSteep = 'Cold_Steep',
  Drip = 'Drip',
  FrenchPress = 'French_Press',
  Moka = 'Moka',
  Percolator = 'Percolator',
  PourOver = 'Pour_Over'
}

export enum PricingMethod {
  /** Cost of Goods Sold */
  Cogs = 'COGS',
  Cpmu = 'CPMU'
}

/** PricingPreset used for describing the pricing and serving size of a Product */
export type PricingPreset = {
  __typename?: 'PricingPreset';
  /** User that created the PricingPreset */
  createdBy: Scalars['String'];
  /** Date PricingPreset was created */
  createdDate: Scalars['String'];
  /** ID of the PricingPreset */
  id: Scalars['ID'];
  /** Indicates if the PricingPreset is the default */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** User that last updated the PricingPreset */
  lastUpdatedBy: Scalars['String'];
  /** List of servings that will extend the Menu Item servings. Menu Item servings are the source of truth in this case. */
  servings?: Maybe<Array<Maybe<ServingSize>>>;
  /** Title of the PricingPreset */
  title?: Maybe<Scalars['String']>;
  /** Date string, in UTC */
  updatedDate: Scalars['String'];
};

/** Connection object used for returning lists of PricingPreset objects */
export type PricingPresetConnection = {
  __typename?: 'PricingPresetConnection';
  /** PricingPreset objects */
  items?: Maybe<Array<Maybe<PricingPreset>>>;
  /** Next token used for pagination */
  nextToken?: Maybe<Scalars['String']>;
  /** Number of objects returned */
  returnedCount?: Maybe<Scalars['Int']>;
};

export enum Process {
  HoneyPulp = 'Honey___Pulp',
  NaturalDry = 'Natural___Dry',
  WashedWet = 'Washed___Wet'
}

/** Product interface for handling Beer, Kombucha, Cider etc.  */
export type Product = {
  /** Alcohol of the product as a percentage with up to 1 decimal. */
  abv?: Maybe<Scalars['Float']>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Product label. Supports multiple assets, but front-end expects only 1. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** PK of a Brand. */
  brand?: Maybe<Brand>;
  /** Enum for the products Carbonation. */
  carbonation?: Maybe<Carbonation>;
  /** Color of the liquid epressed as Tailwind color e.g. `flower-fruity-apple` or `srm-19`. */
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the product. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Enum of foods to pair with a product. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: Maybe<Scalars['String']>;
  /** Side profile flavors of a product. */
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Ingredients of the product. */
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  /** Boolean to control whether a product is displayed on external customer-facing apps. */
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  /** Boolean for if the product is new. */
  isNewProduct?: Maybe<Scalars['Boolean']>;
  /** Boolean if product is verified. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** List of locations where this product is served either in draft or packaged form. */
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. Use `locationRelationships`. */
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Main flavor profile of a product. */
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** Date the product was first made available to the public. Expects a valid date string such as `11/27/1984` though the front-end may only render the year in most cases. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Enum of serving temperatures for a product. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** List of suggested glassware to use when drinking this product. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of the product. Auto-Generated in createProduct using the title. */
  slug?: Maybe<Scalars['String']>;
  /** Products Style */
  style?: Maybe<Style>;
  /** Description of the product experience from pour to sip. Limited by the front-end to 300 characters. */
  tastingExperience?: Maybe<Scalars['String']>;
  /** Title of the product. */
  title: Scalars['String'];
  /** Traits of a product. Contains availability, nutrition, allergens, and otherTraits. */
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  items?: Maybe<Array<Maybe<CompositeProduct>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export type ProductIds = {
  __typename?: 'ProductIds';
  brandId: Scalars['String'];
  productId: Scalars['String'];
};

/** Type of imagery to be used on a Brew Menu Display. */
export enum ProductImage {
  BrandLogo = 'BrandLogo',
  Glassware = 'Glassware',
  Icon = 'Icon',
  Label = 'Label',
  /** (Default) */
  ProductImage = 'ProductImage',
  Vessel = 'Vessel'
}

export enum ProductLifeTracking {
  BornOnDate = 'Born_on_Date',
  ExpirationDate = 'Expiration_Date',
  NoTracking = 'No_Tracking'
}

export type ProductSummary = {
  __typename?: 'ProductSummary';
  /** Total number of beer type products */
  totalBeerProducts?: Maybe<Scalars['Int']>;
  /** Total number of cider type products */
  totalCiderProducts?: Maybe<Scalars['Int']>;
  /** Total number of komkbucha type products */
  totalKombuchaProducts?: Maybe<Scalars['Int']>;
  /** Total number of unverified type products */
  totalUnverifiedProducts?: Maybe<Scalars['Int']>;
  /** Total number of verified products, any brewtype (other than Unverified) */
  totalVerifiedProducts?: Maybe<Scalars['Int']>;
};

export type ProductThroughput = {
  __typename?: 'ProductThroughput';
  productThroughput?: Maybe<Array<Maybe<ProductThroughputDetail>>>;
};

/** ProductThroughputDetail returned result from the Athena query. */
export type ProductThroughputDetail = {
  __typename?: 'ProductThroughputDetail';
  /** Returns average initial fluid oz for each product. */
  averageInitialFluidOz?: Maybe<Scalars['Float']>;
  /** Average cost for the total throughput of a product. */
  avgCost?: Maybe<Scalars['Float']>;
  /** Returns brand title with most throughput. */
  brandTitle?: Maybe<Scalars['String']>;
  /** Returns product title with most throughput. */
  productTitle?: Maybe<Scalars['String']>;
  /** Returns style title with most throughput. */
  styleTitle?: Maybe<Scalars['String']>;
  /** Return tapNumber for corresponding tapSensor ID. */
  tapNumber?: Maybe<Scalars['Int']>;
  /** Returns tapSensor ID of product. */
  tapSensorId?: Maybe<Scalars['ID']>;
  /** Returns the date for the product's throughput. */
  timestamp?: Maybe<Scalars['String']>;
  /** Returns highest throughput by product. */
  totalThroughput?: Maybe<Scalars['Float']>;
};

export type ProductTraits = {
  __typename?: 'ProductTraits';
  allergens?: Maybe<Array<Maybe<Allergen>>>;
  availability?: Maybe<Availability>;
  nutrition?: Maybe<Nutrition>;
  otherTraits?: Maybe<Array<Maybe<OtherProductTraits>>>;
};

export type Query = {
  __typename?: 'Query';
  getAssetById?: Maybe<Asset>;
  getAuthenticatedUser?: Maybe<User>;
  /** Returns a Board. boardId == itemId. */
  getBoardById?: Maybe<Board>;
  /** Returns all boards associated with the logged in user. */
  getBoardsByAuthenticatedUser?: Maybe<Array<Maybe<Board>>>;
  getBrandById?: Maybe<Brand>;
  getBrandBySlug?: Maybe<Brand>;
  getBrands?: Maybe<BrandConnection>;
  /**
   * Retrieve the Brew Menu from DynamoDB.
   * - `parentId` as Brew Menu Subscription ID
   * - `itemId` as Brew Menu ID
   */
  getBrewMenuById?: Maybe<BrewMenu>;
  /** DDB get that returns the Brew Menu Custom Product by ID. */
  getBrewMenuCustomProductById?: Maybe<BrewMenuCustomProduct>;
  /** DDB query that returns the Brew Menu Custom Products for a Brew Menu Subscription. */
  getBrewMenuCustomProductsByBMSubId?: Maybe<BrewMenuCustomProductConnection>;
  getBrewMenuDisplayById?: Maybe<BrewMenuDisplay>;
  /** DDB query that returns the Brew Menu Display for the provided Brew Menu Subscription slug and Display slug. */
  getBrewMenuDisplayBySlugs?: Maybe<BrewMenuDisplay>;
  getBrewMenuDisplays?: Maybe<BrewMenuDisplayConnection>;
  /** DynamoDB query that returns paginated list of Brew Menu Displays for a specific BM Sub. */
  getBrewMenuDisplaysByBMSubId?: Maybe<BrewMenuDisplayConnection>;
  getBrewMenuItemById?: Maybe<MenuItem>;
  getBrewMenuItems?: Maybe<MenuItemConnection>;
  /** DynamoDB get a `MenuSection` by it's ID. */
  getBrewMenuSectionById?: Maybe<MenuSection>;
  /** DynamoDB query all `MenuSections` for a BM Sub. */
  getBrewMenuSectionsByBMSubId?: Maybe<MenuSectionsConnection>;
  getBrewMenuSubscriptionById?: Maybe<BrewMenuSubscription>;
  getBrewMenuSubscriptionBySlug?: Maybe<BrewMenuSubscription>;
  getBrewMenuSubscriptions?: Maybe<BrewMenuSubscriptionConnection>;
  getBrewMenus?: Maybe<BrewMenuConnection>;
  /** DynamoDB query that returns paginated list of Brew Menus for the specific BM Sub. */
  getBrewMenusByBMSubId?: Maybe<BrewMenuConnection>;
  /** DDB query that returns a single DiscountPreset by ID. */
  getDiscountPresetById?: Maybe<DiscountPreset>;
  /** Query that returns multiple Brew Menu discounts. */
  getDiscountPresets?: Maybe<DiscountPresetConnection>;
  /** DynamoDB query that returns paginated list of `DiscountPresets` for the specific `BrewMenuSubscription`. */
  getDiscountPresetsByBMSubId?: Maybe<DiscountPresetConnection>;
  getKegById?: Maybe<Keg>;
  getKegDepletionPace?: Maybe<Scalars['String']>;
  getKegDepletionPaceByWeek?: Maybe<KegDepletionPace>;
  /** DEPRECATED since 1.10.1. Still in use by CS. EOL TBD. */
  getKegHistory?: Maybe<KegConnection>;
  /** Retrieves a 'KegConnection' for a PPSub by keg status, or all Kegs if no status provided. This is a DDB query. */
  getKegsByStatus?: Maybe<KegConnection>;
  getLocationById?: Maybe<Location>;
  getLocationBySlug?: Maybe<Location>;
  getLocationProducts?: Maybe<LocationProductRelationshipConnection>;
  getMediaLibrary?: Maybe<MediaLibrary>;
  /** DDB Query that returns paginated list of MenuItems. Page count available with returnedCount. totalResults not available. */
  getMenuItemsByBMSubID?: Maybe<MenuItemConnection>;
  getMessagesByPPSubId?: Maybe<MessageCenter>;
  /** Returns all `GuestBoards` associated with the current `User`. */
  getMyGuestBoards?: Maybe<Array<Maybe<GuestBoard>>>;
  /** Get all `OrganizationGroups` for the current `User`. Returns a complete list (pagination not supported). */
  getMyOrganizationGroups?: Maybe<Array<Maybe<OrganizationGroup>>>;
  getOrganizationAssetById?: Maybe<OrganizationAsset>;
  /** Endpoint for retrieving a single orginization, by ID. Used by both superadmins/non superadmins */
  getOrganizationById?: Maybe<Organization>;
  /** Get all `OrganizationGroups` for an `Organization`. Returns a complete list (pagination not supported). */
  getOrganizationGroups?: Maybe<Array<Maybe<OrganizationGroup>>>;
  getOrganizationMediaLibrary?: Maybe<OrganizationMediaLibrary>;
  getOrganizationPricingPresetById?: Maybe<PricingPreset>;
  getOrganizationPricingPresets?: Maybe<PricingPresetConnection>;
  getPPSubById?: Maybe<PerformancePlatformSubscription>;
  getPPSubProfile?: Maybe<PerformancePlatformSubscriptionPublic>;
  /** DynamoDB query that returns previously received kegs for a specific `PerformancePlatformSubscription`. */
  getPreviouslyReceivedKegs?: Maybe<KegConnection>;
  /** @DEPRECATED - Use `getOrganizationPricingPresetById` */
  getPricingPresetById?: Maybe<PricingPreset>;
  /** @DEPRECATED - Use `getOrganizationPricingPresets` */
  getPricingPresets?: Maybe<PricingPresetConnection>;
  getProductById?: Maybe<CompositeProduct>;
  getProductBySlug?: Maybe<CompositeProduct>;
  getProductLocations?: Maybe<LocationProductRelationshipConnection>;
  getProductStyles?: Maybe<StyleConnection>;
  getProductsById?: Maybe<ProductConnection>;
  /** Retrieves a `VarianceReport` for a specific `PerformancePlatformSubscription` by ID. */
  getReport?: Maybe<TrackedVarianceReport>;
  /**
   * Retrieves all `VarianceReports` for a specific `PerformancePlatformSubscription` by status.
   * If status is omitted, all reports are returned for that PP Sub.
   */
  getReports?: Maybe<TrackedVarianceReportConnection>;
  /** DDB query that returns a Board if the requesting User is the owner, or the Board is public. */
  getSharedBoard?: Maybe<Board>;
  getStyles?: Maybe<StyleConnection>;
  getTapSensorById?: Maybe<TapSensor>;
  getTapSensorsForBrewMenu?: Maybe<TapSensorConnectionPublic>;
  /**
   * ### Get Throughput by Brand
   * Query used to get brand's throughput for a single Performance Platform Subscription.
   * DEPRECATED since 1.33.0. Still in use on performance platform app. EOL 1.38.0.
   */
  getThroughputByBrand?: Maybe<BrandThroughput>;
  getThroughputByKegId?: Maybe<KegThroughput>;
  getThroughputByProduct?: Maybe<ProductThroughput>;
  getThroughputDetailsByPPSub?: Maybe<PpSubThroughputDetails>;
  getThroughputSummaryByPPSub?: Maybe<PpSubThroughputSummary>;
  getTotalProductThroughput?: Maybe<TotalProductThroughput>;
  getVesselById?: Maybe<Vessel>;
  getVessels?: Maybe<VesselConnection>;
  searchBPL?: Maybe<WildCardTitleSearchConnection>;
  /** Retrieves the download URL for a raw report file. */
  superAdminDownloadRawReport?: Maybe<Url>;
  superAdminGetAccountRequestById?: Maybe<AccountRequest>;
  superAdminGetAccountRequests?: Maybe<AccountRequestConnection>;
  superAdminGetGatewayById?: Maybe<Gateway>;
  superAdminGetGateways?: Maybe<GatewayConnection>;
  /** Get a single device inventory record, by ID */
  superAdminGetInventoryRecordById?: Maybe<InventoryRecord>;
  /** Get all device inventory records. Does not currently support filters. */
  superAdminGetInventoryRecords?: Maybe<InventoryRecordConnection>;
  superAdminGetMessages?: Maybe<MessageConnection>;
  superAdminGetOrganizations?: Maybe<OrganizationConnection>;
  superAdminGetPPSubscriptions?: Maybe<PpSubscriptionConnection>;
  superAdminGetSummaryOfProducts?: Maybe<ProductSummary>;
  superAdminGetSummaryOfUsers?: Maybe<UserSummary>;
  /** Retrieves `TapSensors` using OpenSearch. Results can be filtered. */
  superAdminGetTapSensors?: Maybe<TapSensorConnection>;
  superAdminGetUserById?: Maybe<User>;
  superAdminGetUsers?: Maybe<UserConnection>;
  verifySlug?: Maybe<VerifiedSlug>;
};


export type QueryGetAssetByIdArgs = {
  input: InputGetAssetById;
};


export type QueryGetAuthenticatedUserArgs = {
  input?: InputMaybe<InputUserFavorites>;
};


export type QueryGetBoardByIdArgs = {
  input?: InputMaybe<InputGetById>;
};


export type QueryGetBoardsByAuthenticatedUserArgs = {
  input?: InputMaybe<InputUserFavorites>;
};


export type QueryGetBrandByIdArgs = {
  input: InputGetById;
};


export type QueryGetBrandBySlugArgs = {
  input: InputGetBySlug;
};


export type QueryGetBrandsArgs = {
  input: InputGetAll;
};


export type QueryGetBrewMenuByIdArgs = {
  input: InputGetById;
};


export type QueryGetBrewMenuCustomProductByIdArgs = {
  input: InputGetBrewMenuCustomProductById;
};


export type QueryGetBrewMenuCustomProductsByBmSubIdArgs = {
  input: InputByBmSubId;
};


export type QueryGetBrewMenuDisplayByIdArgs = {
  input: InputGetById;
};


export type QueryGetBrewMenuDisplayBySlugsArgs = {
  input: InputGetBySlug;
};


export type QueryGetBrewMenuDisplaysArgs = {
  input: InputGetBrewMenuDisplays;
};


export type QueryGetBrewMenuDisplaysByBmSubIdArgs = {
  input: InputByBmSubId;
};


export type QueryGetBrewMenuItemByIdArgs = {
  input: InputGetBrewMenuItemById;
};


export type QueryGetBrewMenuItemsArgs = {
  input: InputGetBrewMenuItems;
};


export type QueryGetBrewMenuSectionByIdArgs = {
  input: InputBmSubAndSectionId;
};


export type QueryGetBrewMenuSectionsByBmSubIdArgs = {
  input: InputByBmSubId;
};


export type QueryGetBrewMenuSubscriptionByIdArgs = {
  input: InputGetBrewMenuSubscriptionById;
};


export type QueryGetBrewMenuSubscriptionBySlugArgs = {
  input?: InputMaybe<InputGetBySlug>;
};


export type QueryGetBrewMenuSubscriptionsArgs = {
  input: InputGetBrewMenuSubscriptions;
};


export type QueryGetBrewMenusArgs = {
  input: InputGetBrewMenus;
};


export type QueryGetBrewMenusByBmSubIdArgs = {
  input: InputByBmSubId;
};


export type QueryGetDiscountPresetByIdArgs = {
  input: InputGetById;
};


export type QueryGetDiscountPresetsArgs = {
  input: InputGetDiscountPresets;
};


export type QueryGetDiscountPresetsByBmSubIdArgs = {
  input: InputByBmSubId;
};


export type QueryGetKegByIdArgs = {
  input: InputGetByPpSubId;
};


export type QueryGetKegDepletionPaceArgs = {
  input: InputGetKegDepletionStats;
};


export type QueryGetKegDepletionPaceByWeekArgs = {
  input: InputGetKegDepletionPaceByWeek;
};


export type QueryGetKegHistoryArgs = {
  input: GetKegHistory;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetKegsByStatusArgs = {
  input?: InputMaybe<InputGetKegsByStatus>;
};


export type QueryGetLocationByIdArgs = {
  input: InputGetById;
};


export type QueryGetLocationBySlugArgs = {
  input: InputGetBySlug;
};


export type QueryGetLocationProductsArgs = {
  input?: InputMaybe<InputGetLocationProducts>;
};


export type QueryGetMediaLibraryArgs = {
  input: InputGetMediaLibrary;
};


export type QueryGetMenuItemsByBmSubIdArgs = {
  input: InputGetById;
};


export type QueryGetMessagesByPpSubIdArgs = {
  input: InputGetMessagesByPpSubId;
};


export type QueryGetMyGuestBoardsArgs = {
  input?: InputMaybe<InputEmpty>;
};


export type QueryGetMyOrganizationGroupsArgs = {
  input?: InputMaybe<InputEmpty>;
};


export type QueryGetOrganizationAssetByIdArgs = {
  input: InputOrganizationAssetById;
};


export type QueryGetOrganizationByIdArgs = {
  input: InputGetOrganizationById;
};


export type QueryGetOrganizationGroupsArgs = {
  input: InputGetOrganizationGroups;
};


export type QueryGetOrganizationMediaLibraryArgs = {
  input: InputGetByOrganizationMediaLibrary;
};


export type QueryGetOrganizationPricingPresetByIdArgs = {
  input: InputGetOrganizationPricingPresetById;
};


export type QueryGetOrganizationPricingPresetsArgs = {
  input: InputGetOrganizationPricingPresets;
};


export type QueryGetPpSubByIdArgs = {
  input: InputGetByPpSubId;
};


export type QueryGetPpSubProfileArgs = {
  input: InputGetById;
};


export type QueryGetPreviouslyReceivedKegsArgs = {
  input: InputGetPreviouslyReceivedKegs;
};


export type QueryGetPricingPresetByIdArgs = {
  input: InputGetPricingPresetById;
};


export type QueryGetPricingPresetsArgs = {
  input: InputGetPricingPresets;
};


export type QueryGetProductByIdArgs = {
  input: InputGetById;
};


export type QueryGetProductBySlugArgs = {
  input: InputGetBySlug;
};


export type QueryGetProductLocationsArgs = {
  input?: InputMaybe<InputGetProductLocations>;
};


export type QueryGetProductStylesArgs = {
  input?: InputMaybe<InputGetStyles>;
};


export type QueryGetProductsByIdArgs = {
  input: InputGetById;
};


export type QueryGetReportArgs = {
  input: InputGetReport;
};


export type QueryGetReportsArgs = {
  input: InputGetReports;
};


export type QueryGetSharedBoardArgs = {
  input: InputSharedBoard;
};


export type QueryGetStylesArgs = {
  input?: InputMaybe<InputGetAll>;
};


export type QueryGetTapSensorByIdArgs = {
  input: InputGetByPpSubId;
};


export type QueryGetTapSensorsForBrewMenuArgs = {
  input: InputGetById;
};


export type QueryGetThroughputByBrandArgs = {
  input: InputGetThroughputByBrand;
};


export type QueryGetThroughputByKegIdArgs = {
  input: InputGetThroughputByKegId;
};


export type QueryGetThroughputByProductArgs = {
  input: InputGetThroughputByProduct;
};


export type QueryGetThroughputDetailsByPpSubArgs = {
  input: InputGetThroughputByPpSub;
};


export type QueryGetThroughputSummaryByPpSubArgs = {
  input: InputGetThroughputByPpSub;
};


export type QueryGetTotalProductThroughputArgs = {
  input: InputGetTotalProductThroughput;
};


export type QueryGetVesselByIdArgs = {
  input: InputGetById;
};


export type QueryGetVesselsArgs = {
  input?: InputMaybe<InputGetAll>;
};


export type QuerySearchBplArgs = {
  input: InputSearchBpl;
};


export type QuerySuperAdminDownloadRawReportArgs = {
  input: InputSuperAdminDownloadRawReport;
};


export type QuerySuperAdminGetAccountRequestByIdArgs = {
  input: InputGetById;
};


export type QuerySuperAdminGetAccountRequestsArgs = {
  input?: InputMaybe<InputSuperAdminGetAccountRequests>;
};


export type QuerySuperAdminGetGatewayByIdArgs = {
  input: InputGetByPpSubId;
};


export type QuerySuperAdminGetGatewaysArgs = {
  input?: InputMaybe<InputSuperAdminGetGateways>;
};


export type QuerySuperAdminGetInventoryRecordByIdArgs = {
  input: InputGetById;
};


export type QuerySuperAdminGetInventoryRecordsArgs = {
  input?: InputMaybe<InputSuperAdminGetInventoryRecords>;
};


export type QuerySuperAdminGetMessagesArgs = {
  input?: InputMaybe<InputSuperAdminGetMessages>;
};


export type QuerySuperAdminGetOrganizationsArgs = {
  input?: InputMaybe<InputSuperAdminGetOrganizations>;
};


export type QuerySuperAdminGetPpSubscriptionsArgs = {
  input: InputGetAll;
};


export type QuerySuperAdminGetSummaryOfProductsArgs = {
  input: InputGetAll;
};


export type QuerySuperAdminGetSummaryOfUsersArgs = {
  input?: InputMaybe<InputGetAll>;
};


export type QuerySuperAdminGetTapSensorsArgs = {
  input?: InputMaybe<InputSuperAdminGetTapSensors>;
};


export type QuerySuperAdminGetUserByIdArgs = {
  input: InputGetById;
};


export type QuerySuperAdminGetUsersArgs = {
  input?: InputMaybe<InputSuperAdminGetUsers>;
};


export type QueryVerifySlugArgs = {
  input: InputVerifySlug;
};

export enum RegistrationSource {
  BrewMenu = 'BrewMenu',
  BreweryDbMobile = 'BreweryDBMobile',
  BreweryDbWeb = 'BreweryDBWeb',
  MarketMyBrewery = 'MarketMyBrewery',
  PerformancePlatform = 'PerformancePlatform',
  PintLabs = 'PintLabs'
}

export enum RoastProfile {
  Dark = 'Dark',
  Flavored = 'Flavored',
  Light = 'Light',
  Medium = 'Medium',
  MediumDark = 'Medium__Dark'
}

/**
 * S3Asset
 *
 * An asset that is uploaded to an S3 Bucket
 * This should be considered a legacy item at this point and no new
 * S3 uploads are expected. Can still read S3 Assets however.
 */
export type S3Asset = Asset & {
  __typename?: 'S3Asset';
  alt?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  bytes?: Maybe<Scalars['Int']>;
  caption?: Maybe<Scalars['String']>;
  category?: Maybe<AssetCategory>;
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  /** filename of the s3 asset to be uploaded. Including this in a mutation will return signed headers for S3 upload.  */
  filename: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lastUpdatedBy: Scalars['String'];
  locations?: Maybe<Array<Maybe<Location>>>;
  path?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<CompositeProduct>>>;
  shared?: Maybe<Scalars['Boolean']>;
  sizes?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  updatedDate: Scalars['String'];
  /** upload headers for the S3 bucket */
  uploadHeaders?: Maybe<Scalars['String']>;
  /** upload URL for the S3 bucket */
  uploadURL?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** Represents the fields required for an S3 presigned upload. */
export type S3UploadFields = {
  __typename?: 'S3UploadFields';
  /** Stringified form fields. */
  fields: Scalars['String'];
  /** URL link. */
  url: Scalars['String'];
};

export type Sake = Product & WildCardTitleSearch & {
  __typename?: 'Sake';
  /** Alcohol of this sake as a percentage with up to 1 decimal. */
  abv?: Maybe<Scalars['Float']>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Product label. Supports multiple assets, but front-end expects only 1. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** PK of a Brand. */
  brand?: Maybe<Brand>;
  /** Enum for the products Carbonation. */
  carbonation?: Maybe<Carbonation>;
  /** Color of the liquid epressed as Tailwind color e.g. `flower-fruity-apple` or `srm-19`. */
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the brand. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Enum of foods to pair with a sake Product. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: Maybe<Scalars['String']>;
  /** Side profile flavors of a sake. */
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Ingredients of the product. */
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  /** Boolean to control whether a product is displayed on external customer-facing apps. */
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  /** Boolean if the sake is new */
  isNewProduct?: Maybe<Scalars['Boolean']>;
  /** Boolean if product is verified. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** List of locations where this sake is served either in draft or packaged form. */
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. Use `locationRelationships` */
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Main profile flavors of a sake. */
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** The product type. */
  productType?: Maybe<Scalars['String']>;
  /** Date the product was first made available to the public. Expects a valid date string such as `11/27/1984` though the front-end may only render the year in most cases. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Enum of serving temperatures for a sake Product. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** List of suggested glassware to use when drinking this product. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of the sake. Auto-Generated in createProduct using the title. */
  slug?: Maybe<Scalars['String']>;
  /** The sake style information */
  style?: Maybe<Style>;
  /** Description of the sake experience from pour to sip. Limited by the front-end to 300 characters. */
  tastingExperience?: Maybe<Scalars['String']>;
  /** Title of the sake Product. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits of a sake Product. Contains availability, nutrition, allergens, and otherTraits. */
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

/** Settings for allowing, disallowing, or forcing dark mode on consumer-facing menus. */
export enum ScreenMode {
  Dark = 'Dark',
  /** (Default) */
  Light = 'Light',
  Media = 'Media'
}

export enum SeatingCapacity {
  '1_50' = '_1__50',
  '51_100' = '_51__100',
  '101_150' = '_101__150',
  '151_200' = '_151__200',
  '201_250' = '_201__250',
  '251_300' = '_251__300',
  '301_350' = '_301__350',
  '351_400' = '_351__400',
  '400_450' = '_400__450',
  '451_500' = '_451__500',
  '500Plus' = '_500_plus'
}

export enum SeatingOption {
  BeerGarden = 'Beer_Garden',
  CallAhead = 'Call_Ahead',
  Indoor = 'Indoor',
  MountainSide = 'Mountain_Side',
  Outdoor = 'Outdoor',
  Patio = 'Patio',
  Reservations = 'Reservations',
  Rooftop = 'Rooftop',
  Waterfront = 'Waterfront'
}

export type Seltzer = Product & WildCardTitleSearch & {
  __typename?: 'Seltzer';
  /** Alcohol of this seltzer as a percentage with up to 1 decimal. */
  abv?: Maybe<Scalars['Float']>;
  /** Enum for the seltzer's acidity. */
  acidity?: Maybe<Acidity>;
  /** Enum for the seltzer's perceived sourness. */
  alcoholBase?: Maybe<AlcoholBase>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Product label. Supports multiple assets, but front-end expects only 1. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** Enum for the seltzer's Body. */
  body?: Maybe<Body>;
  /** PK of a Brand. */
  brand?: Maybe<Brand>;
  /** Enum for the products Carbonation. */
  carbonation?: Maybe<Carbonation>;
  /** Color of the liquid epressed as Tailwind color e.g. `flower-fruity-apple` or `srm-19`. */
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the brand. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Enum of foods to pair with a seltzer Product. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: Maybe<Scalars['String']>;
  /** Side profile flavors of a seltzer. */
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Ingredients of the product. */
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  /** Boolean if the seltzer is new. */
  isNewProduct?: Maybe<Scalars['Boolean']>;
  /** Boolean if product is verified. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** List of locations where this seltzer is served either in draft or packaged form. */
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. Use `locationRelationships`. */
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Enum for the seltzer's perceived sweetness. */
  perceivedSweetness?: Maybe<PerceivedSweetness>;
  /** Main profile flavors of a seltzer. */
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** The product type. */
  productType?: Maybe<Scalars['String']>;
  /** Date the product was first made available to the public. Expects a valid date string such as `11/27/1984` though the front-end may only render the year in most cases. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Enum for the seltzer Color. */
  seltzerColor?: Maybe<SeltzerColor>;
  /** Enum of serving temperatures for a seltzer Product. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** List of suggested glassware to use when drinking this product. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of the seltzer. Auto-Generated in createProduct using the title. */
  slug?: Maybe<Scalars['String']>;
  /** The seltzer style information */
  style?: Maybe<Style>;
  /** Description of the seltzer experience from pour to sip. Limited by the front-end to 300 characters. */
  tastingExperience?: Maybe<Scalars['String']>;
  /** Title of the seltzer Product. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits of a seltzer Product. Contains availability, nutrition, allergens, and otherTraits. */
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export enum SeltzerColor {
  Amber = 'Amber',
  Clear = 'Clear',
  Copper = 'Copper',
  Garnet = 'Garnet',
  Gold = 'Gold',
  Pink = 'Pink',
  Purple = 'Purple',
  Ruby = 'Ruby',
  Straw = 'Straw',
  Yellow = 'Yellow'
}

export type ServingSize = {
  __typename?: 'ServingSize';
  /** Allows disabling a serving size without losing the data to which it is attached. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Sets default pour size for OnTap and BrewMenu. Front-end must enforce only allowing one default per keg. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** Brew Menu option that is used to determine if this serving is displayed on a `MenuItem`. */
  isDisplayed?: Maybe<Scalars['Boolean']>;
  /** Custom display title for this serving size to be used when displaying non-pourable products.  */
  label?: Maybe<Scalars['String']>;
  /** Deprecated for pourSize as of 1.23.0. EOL 1.26.0. */
  oz?: Maybe<Scalars['Int']>;
  /** Size of the pour, in fluid ounces. */
  pourSize?: Maybe<PourSize>;
  /** Cost of this serving size to consumers. */
  price?: Maybe<Scalars['Float']>;
  /** If salePrice is populated it overrides price. */
  salePrice?: Maybe<Scalars['Float']>;
  /** Enum describing the type of serving. Ex. `Taster`, `Pint`, `Growler` */
  servingType?: Maybe<ServingType>;
  /** Display the ServingType. */
  showServingType?: Maybe<Scalars['Boolean']>;
};

export enum ServingTemperature {
  Cellar = 'Cellar',
  Cold = 'Cold',
  Cool = 'Cool',
  Hot = 'Hot',
  Iced = 'Iced',
  VeryCold = 'Very_Cold',
  Warm = 'Warm'
}

export enum ServingType {
  Bottle = 'Bottle',
  Can = 'Can',
  Carafe = 'Carafe',
  Crowler = 'Crowler',
  Double = 'Double',
  EightOzDraft = 'EightOzDraft',
  Glass = 'Glass',
  Growler = 'Growler',
  GrowlerFill = 'GrowlerFill',
  HalfPint = 'HalfPint',
  Howler = 'Howler',
  Pint = 'Pint',
  Pitcher = 'Pitcher',
  Sample = 'Sample',
  ShortPour = 'ShortPour',
  Single = 'Single',
  SixteenOzDraft = 'SixteenOzDraft',
  Sleeve = 'Sleeve',
  Stein = 'Stein',
  Taster = 'Taster',
  TenOzDraft = 'TenOzDraft',
  TwelveOzDraft = 'TwelveOzDraft'
}

export enum ServingVessel {
  Arome = 'Arome',
  Boot = 'Boot',
  Bottle = 'Bottle',
  Can = 'Can',
  CanGlass = 'Can_Glass',
  CappuccinoCup = 'Cappuccino_Cup',
  ChaiBowl = 'Chai_Bowl',
  Chalice = 'Chalice',
  CoffeeCup = 'Coffee_Cup',
  Coupe = 'Coupe',
  CraftMaster = 'Craft_Master',
  Crowler = 'Crowler',
  EspressoCup = 'Espresso_Cup',
  FlaredPint = 'Flared_Pint',
  Flute = 'Flute',
  GlencairnWhiskey = 'Glencairn_Whiskey',
  Goblet = 'Goblet',
  HardCiderGlass = 'Hard_Cider_Glass',
  Highball = 'Highball',
  LargeGrowler = 'Large_Growler',
  LatteGlass = 'Latte_Glass',
  Lowball = 'Lowball',
  Margarita = 'Margarita',
  Martini = 'Martini',
  Mug = 'Mug',
  None = 'None',
  NonicPint = 'Nonic_Pint',
  Pack = 'Pack',
  Pilsner = 'Pilsner',
  Pitcher = 'Pitcher',
  SamAdamsPint = 'Sam_Adams_Pint',
  Sampler = 'Sampler',
  ShakerPint = 'Shaker_Pint',
  SidraGlass = 'Sidra_Glass',
  SmallGrowler = 'Small_Growler',
  Snifter = 'Snifter',
  SpiegelauIpa = 'Spiegelau_IPA',
  SpiegelauStout = 'Spiegelau_Stout',
  SpiegelauWheat = 'Spiegelau_Wheat',
  Stange = 'Stange',
  StemlessWine = 'Stemless_Wine',
  StemmedWine = 'Stemmed_Wine',
  Teku = 'Teku',
  Thistle = 'Thistle',
  Tulip = 'Tulip',
  TulipPint = 'Tulip_Pint',
  Tumbler = 'Tumbler',
  UsShakerPint = 'US_Shaker_Pint',
  Varies = 'Varies',
  Weizen = 'Weizen',
  Willi = 'Willi',
  Wine = 'Wine',
  Yardtube = 'Yardtube'
}

export enum SlugTypes {
  Brand = 'Brand',
  BrewMenuSubscription = 'BrewMenuSubscription',
  Location = 'Location',
  Organization = 'Organization',
  PerformancePlatformSubscription = 'PerformancePlatformSubscription',
  Product = 'Product',
  Style = 'Style',
  User = 'User',
  Vessel = 'Vessel'
}

export type SocialMedia = {
  __typename?: 'SocialMedia';
  handle: Scalars['String'];
  platform: Scalars['String'];
};

export enum Sourness {
  Balanced = 'Balanced',
  Emphasized = 'Emphasized',
  Mild = 'Mild'
}

export type Special = {
  __typename?: 'Special';
  description?: Maybe<Scalars['String']>;
  hours?: Maybe<Array<Maybe<Hours>>>;
  title: Scalars['String'];
};

/** Supported filter types for our OpenSearch endpoints. */
export type StringFilter = {
  /** (Wildcard) Additional filter that must be contained in the item field. */
  containsAnd?: InputMaybe<Scalars['String']>;
  /** (Wildcard) List of filters where item field containing them will be omitted. */
  containsNotList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** (Wildcard) List of filters where you expect at least one of them to be contained in the item field. */
  containsOrList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Only include items where the field does or does not exist. */
  exists?: InputMaybe<Scalars['Boolean']>;
  /** Only include items where the field is greater than the provided value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Only include items where the field is less than the provided value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Additonal filter that must match the item field. */
  matchAnd?: InputMaybe<Scalars['String']>;
};

export type Style = {
  __typename?: 'Style';
  /** Maximum alcohol percentage. */
  abvMax?: Maybe<Scalars['Float']>;
  /** Mimimum alcohol percentage. */
  abvMin?: Maybe<Scalars['Float']>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Grouping of styles. */
  blxGroup?: Maybe<Scalars['String']>;
  body?: Maybe<Array<Maybe<Body>>>;
  carbonation?: Maybe<Array<Maybe<Carbonation>>>;
  category?: Maybe<Category>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the style. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Final Gravity (FG) - Maximum value of the style's Final Gravity. */
  fgMax?: Maybe<Scalars['Float']>;
  /** Final Gravity (FG) - Minimum value of the style's Final Gravity. */
  fgMin?: Maybe<Scalars['Float']>;
  /** Preferred food pairing for the style. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** Enum Hops used in the style. */
  hops?: Maybe<Hops>;
  /** International Bitterness Units (IBU) - Maximum measurement of the style's bitterness. */
  ibuMax?: Maybe<Scalars['Int']>;
  /** International Bitterness Units (IBU) - Minimum measurement of the style's bitterness. */
  ibuMin?: Maybe<Scalars['Int']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Keg Freshness (KF) - The freshness duration of a keg. */
  kegFreshnessDuration?: Maybe<Scalars['Int']>;
  /** Label of the style. */
  label?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** Enum Malt used in the style. */
  malt?: Maybe<Malt>;
  /** Original Gravity (OG) - Maximum value of the style's Original Gravity. */
  ogMax?: Maybe<Scalars['Float']>;
  /** Original Gravity (OG) - Minimum value of the style's Original Gravity. */
  ogMin?: Maybe<Scalars['Float']>;
  perceivedBitterness?: Maybe<Array<Maybe<Scalars['Int']>>>;
  perceivedProductBitterness?: Maybe<Array<Maybe<PerceivedBitterness>>>;
  /** Enum for the styles's perceived sweetness. */
  perceivedSweetness?: Maybe<Array<Maybe<PerceivedSweetness>>>;
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** Product type of the style. Beer, Kombucha, Cider, ext... */
  productType?: Maybe<BrewTypes>;
  /** Preferred serving temperature for the style. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** Serving vessels used for the style. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of the Style. Auto-Generated */
  slug?: Maybe<Scalars['String']>;
  /** Standard Research Method (SRM) - Maximum value of the style's measured color. */
  srmMax?: Maybe<Scalars['Int']>;
  /** Standard Research Method (SRM) - Minimum value of the style's measured color. */
  srmMin?: Maybe<Scalars['Int']>;
  /** Title of the style. */
  title: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Enum Yeast used in the style. */
  yeast?: Maybe<Yeast>;
};

export type StyleConnection = {
  __typename?: 'StyleConnection';
  items?: Maybe<Array<Maybe<Style>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export enum Tannin {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

/**
 * A [tapsensor](https://blx-api-docs-dev.vercel.app/#definition-TapSensor) is a top-level object that defines a sensor.
 *
 * PK: PerformancePlatformSubscription#uuid
 * SK: TapSensor#uuid
 */
export type TapSensor = {
  __typename?: 'TapSensor';
  /** Queue of kegs on deck. Will be promoted to tappedKeg on keg change events. Inline-map-array. */
  assignedKegs?: Maybe<Array<Maybe<Keg>>>;
  /** Optional, to support querying tap sensors by bar title (one location, multiple bars). Not currently used. */
  barTitle?: Maybe<Scalars['String']>;
  /** Connection status based on date/time the sensor last reported data. Auto-generated. */
  connectionStatus?: Maybe<ConnectionStatus>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** Date string, in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** Optional description or notes related to sensor. Intended for internal use by CS. */
  description?: Maybe<Scalars['String']>;
  /** Version number of the firmware. User must manually update, but future digi or logs integration? */
  firmware?: Maybe<Scalars['String']>;
  /** Device ID of gateway to which the sensor is assigned. See [Gateway](#definition-Gateway) def for more details. */
  gatewayDeviceId?: Maybe<Scalars['String']>;
  /** DynamoDB ID of the gateway. */
  gatewayId?: Maybe<Scalars['String']>;
  /** Universal Unique identifier. Represents the SK in DDB. Auto-generated. */
  id: Scalars['ID'];
  /** Last datetime a sensor message was recieved to determine connection status. Auto-generated. */
  lastSensorUpdateDate?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** Certain raw readings may not be valid if there was an unexpected weight increase. This field is to only used to track current valid raw readings. */
  lastValidRawReading?: Maybe<Scalars['Float']>;
  /** Make and model of the sensor. */
  model?: Maybe<Scalars['String']>;
  /** The performancePlatformSubscription to which sensor is assigned. */
  performancePlatformSubscription?: Maybe<PerformancePlatformSubscription>;
  /** Support for custom tap order feature, specifically with two bars. */
  position?: Maybe<Scalars['Int']>;
  /** Field that exists on the TapSensor specifically for observable filtering. No value will be set here in the database. */
  ppSubId?: Maybe<Scalars['ID']>;
  /** Raw reading from the sensor. Should be updated whenever we receive a WeightReading. This key is generally only used by the backend. */
  rawReading?: Maybe<Scalars['Float']>;
  /** This should be an 8 digit string, usually starting with 0000. Last 8 of MAC Address. */
  sensorDeviceId: Scalars['String'];
  /** Signal Strength measured in dbm (decibel milliwatts) of a TapSensor populated from the Digi device messages.  Higher value equals stronger signal. */
  signalStrength?: Maybe<Scalars['Float']>;
  /** Status of the sensor; indicates whether it is currently in use, temporarily disabled or archived (replaced). */
  status?: Maybe<DeviceStatus>;
  /** Tap number, used for display purposes. Nno logic curretly enforces unique values for this field. */
  tapNumber?: Maybe<Scalars['Int']>;
  /** Currently tapped keg. Inline-map. */
  tappedKeg?: Maybe<Keg>;
  /** Optional, to support querying tap sensors by bar title (one location, multiple bars). */
  title?: Maybe<Scalars['String']>;
  /** Transformed reading based on vessel, coupler, etc. Used for troubleshooting. */
  transformedReading?: Maybe<Scalars['Float']>;
  /** Date string, in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** Battery voltage, populated via logs + lambda. See [BatteryStatus](#definition-BatteryStatus) for more details. */
  voltage?: Maybe<Scalars['Float']>;
};

/** Response connection containing `TapSensors`. */
export type TapSensorConnection = {
  __typename?: 'TapSensorConnection';
  /** Items of type `TapSensor`. */
  items?: Maybe<Array<Maybe<TapSensor>>>;
  /** Pagination token for the next set of results. */
  nextToken: Scalars['String'];
  /** Number of items returned in the query (will obey limit filters). */
  returnedCount?: Maybe<Scalars['Int']>;
};

export type TapSensorConnectionPublic = {
  __typename?: 'TapSensorConnectionPublic';
  items?: Maybe<Array<Maybe<TapSensorPublic>>>;
  nextToken?: Maybe<Scalars['String']>;
  returnedCount?: Maybe<Scalars['Int']>;
};

export type TapSensorMessageInfo = {
  __typename?: 'TapSensorMessageInfo';
  barTitle?: Maybe<Scalars['String']>;
  gatewayDeviceId?: Maybe<Scalars['ID']>;
  tapNumber?: Maybe<Scalars['Int']>;
  tapSensorId?: Maybe<Scalars['ID']>;
};

export type TapSensorPublic = {
  __typename?: 'TapSensorPublic';
  /** Queue of kegs on deck. Will be promoted to tappedKeg on keg change events/ */
  assignedKegs?: Maybe<Array<Maybe<KegPublic>>>;
  /** Email address of user. */
  createdBy?: Maybe<Scalars['String']>;
  /** Date string, in UTC. */
  createdDate: Scalars['String'];
  /** Optional description or notes related to sensor. Intended for internal use by CS. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Last datetime a sensor message was recieved to determine connection status. */
  lastSensorUpdateDate?: Maybe<Scalars['String']>;
  /** Email address of user. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** Certain raw readings may not be valid if there was an unexpected weight increase. This field is to only used to track current valid raw readings. */
  lastValidRawReading?: Maybe<Scalars['Float']>;
  /** Make and model of the sensor. */
  model?: Maybe<Scalars['String']>;
  performancePlatformSubscription?: Maybe<PerformancePlatformSubscriptionPublic>;
  /** Support for custom tap order feature, specifically with two bars. */
  position?: Maybe<Scalars['Int']>;
  /** Field that exists on the TapSensor specifically for observable filtering. No value will be set here in the database */
  ppSubId?: Maybe<Scalars['ID']>;
  /** Raw reading from the sensor. Should be updated whenever we receive a WeightReading. This key is generally only used by the backend. */
  rawReading?: Maybe<Scalars['Float']>;
  /** Tap number, used for display purposes. Nno logic curretly enforces unique values for this field. */
  tapNumber?: Maybe<Scalars['Int']>;
  /** Currently tapped keg */
  tappedKeg?: Maybe<KegPublic>;
  /** Optional, to support querying tap sensors by bar title (one location, multiple bars). */
  title?: Maybe<Scalars['String']>;
  /** Transformed reading based on vessel, coupler, etc. Used for troubleshooting. */
  transformedReading?: Maybe<Scalars['Float']>;
  /** Date string, in UTC. */
  updatedDate: Scalars['String'];
};

export enum TeaCharacter {
  Emphasized = 'Emphasized',
  Mild = 'Mild',
  Noticeable = 'Noticeable'
}

export enum TenantType {
  Brand = 'Brand',
  BrewMenuSubscription = 'BrewMenuSubscription',
  PerformancePlatformSubscription = 'PerformancePlatformSubscription'
}

export type ThroughputDetails = {
  __typename?: 'ThroughputDetails';
  throughput?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['String']>;
};

export enum TimeFrame {
  Day = 'DAY',
  Hour = 'HOUR'
}

export enum TimeZone {
  AfricaAbidjan = 'Africa___Abidjan',
  AfricaAccra = 'Africa___Accra',
  AfricaAddisAbaba = 'Africa___Addis_Ababa',
  AfricaAlgiers = 'Africa___Algiers',
  AfricaAsmara = 'Africa___Asmara',
  AfricaAsmera = 'Africa___Asmera',
  AfricaBamako = 'Africa___Bamako',
  AfricaBangui = 'Africa___Bangui',
  AfricaBanjul = 'Africa___Banjul',
  AfricaBissau = 'Africa___Bissau',
  AfricaBlantyre = 'Africa___Blantyre',
  AfricaBrazzaville = 'Africa___Brazzaville',
  AfricaBujumbura = 'Africa___Bujumbura',
  AfricaCairo = 'Africa___Cairo',
  AfricaCasablanca = 'Africa___Casablanca',
  AfricaCeuta = 'Africa___Ceuta',
  AfricaConakry = 'Africa___Conakry',
  AfricaDakar = 'Africa___Dakar',
  AfricaDarEsSalaam = 'Africa___Dar_es_Salaam',
  AfricaDjibouti = 'Africa___Djibouti',
  AfricaDouala = 'Africa___Douala',
  AfricaElAaiun = 'Africa___El_Aaiun',
  AfricaFreetown = 'Africa___Freetown',
  AfricaGaborone = 'Africa___Gaborone',
  AfricaHarare = 'Africa___Harare',
  AfricaJohannesburg = 'Africa___Johannesburg',
  AfricaJuba = 'Africa___Juba',
  AfricaKampala = 'Africa___Kampala',
  AfricaKhartoum = 'Africa___Khartoum',
  AfricaKigali = 'Africa___Kigali',
  AfricaKinshasa = 'Africa___Kinshasa',
  AfricaLagos = 'Africa___Lagos',
  AfricaLibreville = 'Africa___Libreville',
  AfricaLome = 'Africa___Lome',
  AfricaLuanda = 'Africa___Luanda',
  AfricaLubumbashi = 'Africa___Lubumbashi',
  AfricaLusaka = 'Africa___Lusaka',
  AfricaMalabo = 'Africa___Malabo',
  AfricaMaputo = 'Africa___Maputo',
  AfricaMaseru = 'Africa___Maseru',
  AfricaMbabane = 'Africa___Mbabane',
  AfricaMogadishu = 'Africa___Mogadishu',
  AfricaMonrovia = 'Africa___Monrovia',
  AfricaNairobi = 'Africa___Nairobi',
  AfricaNdjamena = 'Africa___Ndjamena',
  AfricaNiamey = 'Africa___Niamey',
  AfricaNouakchott = 'Africa___Nouakchott',
  AfricaOuagadougou = 'Africa___Ouagadougou',
  AfricaPortoNovo = 'Africa___Porto__Novo',
  AfricaSaoTome = 'Africa___Sao_Tome',
  AfricaTimbuktu = 'Africa___Timbuktu',
  AfricaTripoli = 'Africa___Tripoli',
  AfricaTunis = 'Africa___Tunis',
  AfricaWindhoek = 'Africa___Windhoek',
  AmericaAdak = 'America___Adak',
  AmericaAnchorage = 'America___Anchorage',
  AmericaAnguilla = 'America___Anguilla',
  AmericaAntigua = 'America___Antigua',
  AmericaAraguaina = 'America___Araguaina',
  AmericaArgentinaBuenosAires = 'America___Argentina___Buenos_Aires',
  AmericaArgentinaCatamarca = 'America___Argentina___Catamarca',
  AmericaArgentinaComodRivadavia = 'America___Argentina___ComodRivadavia',
  AmericaArgentinaCordoba = 'America___Argentina___Cordoba',
  AmericaArgentinaJujuy = 'America___Argentina___Jujuy',
  AmericaArgentinaLaRioja = 'America___Argentina___La_Rioja',
  AmericaArgentinaMendoza = 'America___Argentina___Mendoza',
  AmericaArgentinaRioGallegos = 'America___Argentina___Rio_Gallegos',
  AmericaArgentinaSalta = 'America___Argentina___Salta',
  AmericaArgentinaSanJuan = 'America___Argentina___San_Juan',
  AmericaArgentinaSanLuis = 'America___Argentina___San_Luis',
  AmericaArgentinaTucuman = 'America___Argentina___Tucuman',
  AmericaArgentinaUshuaia = 'America___Argentina___Ushuaia',
  AmericaAruba = 'America___Aruba',
  AmericaAsuncion = 'America___Asuncion',
  AmericaAtikokan = 'America___Atikokan',
  AmericaAtka = 'America___Atka',
  AmericaBahia = 'America___Bahia',
  AmericaBahiaBanderas = 'America___Bahia_Banderas',
  AmericaBarbados = 'America___Barbados',
  AmericaBelem = 'America___Belem',
  AmericaBelize = 'America___Belize',
  AmericaBlancSablon = 'America___Blanc__Sablon',
  AmericaBoaVista = 'America___Boa_Vista',
  AmericaBogota = 'America___Bogota',
  AmericaBoise = 'America___Boise',
  AmericaBuenosAires = 'America___Buenos_Aires',
  AmericaCambridgeBay = 'America___Cambridge_Bay',
  AmericaCampoGrande = 'America___Campo_Grande',
  AmericaCancun = 'America___Cancun',
  AmericaCaracas = 'America___Caracas',
  AmericaCatamarca = 'America___Catamarca',
  AmericaCayenne = 'America___Cayenne',
  AmericaCayman = 'America___Cayman',
  AmericaChicago = 'America___Chicago',
  AmericaChihuahua = 'America___Chihuahua',
  AmericaCoralHarbour = 'America___Coral_Harbour',
  AmericaCordoba = 'America___Cordoba',
  AmericaCostaRica = 'America___Costa_Rica',
  AmericaCreston = 'America___Creston',
  AmericaCuiaba = 'America___Cuiaba',
  AmericaCuracao = 'America___Curacao',
  AmericaDanmarkshavn = 'America___Danmarkshavn',
  AmericaDawson = 'America___Dawson',
  AmericaDawsonCreek = 'America___Dawson_Creek',
  AmericaDenver = 'America___Denver',
  AmericaDetroit = 'America___Detroit',
  AmericaDominica = 'America___Dominica',
  AmericaEdmonton = 'America___Edmonton',
  AmericaEirunepe = 'America___Eirunepe',
  AmericaElSalvador = 'America___El_Salvador',
  AmericaEnsenada = 'America___Ensenada',
  AmericaFortNelson = 'America___Fort_Nelson',
  AmericaFortWayne = 'America___Fort_Wayne',
  AmericaFortaleza = 'America___Fortaleza',
  AmericaGlaceBay = 'America___Glace_Bay',
  AmericaGodthab = 'America___Godthab',
  AmericaGooseBay = 'America___Goose_Bay',
  AmericaGrandTurk = 'America___Grand_Turk',
  AmericaGrenada = 'America___Grenada',
  AmericaGuadeloupe = 'America___Guadeloupe',
  AmericaGuatemala = 'America___Guatemala',
  AmericaGuayaquil = 'America___Guayaquil',
  AmericaGuyana = 'America___Guyana',
  AmericaHalifax = 'America___Halifax',
  AmericaHavana = 'America___Havana',
  AmericaHermosillo = 'America___Hermosillo',
  AmericaIndianaIndianapolis = 'America___Indiana___Indianapolis',
  AmericaIndianaKnox = 'America___Indiana___Knox',
  AmericaIndianaMarengo = 'America___Indiana___Marengo',
  AmericaIndianaPetersburg = 'America___Indiana___Petersburg',
  AmericaIndianaTellCity = 'America___Indiana___Tell_City',
  AmericaIndianaVevay = 'America___Indiana___Vevay',
  AmericaIndianaVincennes = 'America___Indiana___Vincennes',
  AmericaIndianaWinamac = 'America___Indiana___Winamac',
  AmericaIndianapolis = 'America___Indianapolis',
  AmericaInuvik = 'America___Inuvik',
  AmericaIqaluit = 'America___Iqaluit',
  AmericaJamaica = 'America___Jamaica',
  AmericaJujuy = 'America___Jujuy',
  AmericaJuneau = 'America___Juneau',
  AmericaKentuckyLouisville = 'America___Kentucky___Louisville',
  AmericaKentuckyMonticello = 'America___Kentucky___Monticello',
  AmericaKnoxIn = 'America___Knox_IN',
  AmericaKralendijk = 'America___Kralendijk',
  AmericaLaPaz = 'America___La_Paz',
  AmericaLima = 'America___Lima',
  AmericaLosAngeles = 'America___Los_Angeles',
  AmericaLouisville = 'America___Louisville',
  AmericaLowerPrinces = 'America___Lower_Princes',
  AmericaMaceio = 'America___Maceio',
  AmericaManagua = 'America___Managua',
  AmericaManaus = 'America___Manaus',
  AmericaMarigot = 'America___Marigot',
  AmericaMartinique = 'America___Martinique',
  AmericaMatamoros = 'America___Matamoros',
  AmericaMazatlan = 'America___Mazatlan',
  AmericaMendoza = 'America___Mendoza',
  AmericaMenominee = 'America___Menominee',
  AmericaMerida = 'America___Merida',
  AmericaMetlakatla = 'America___Metlakatla',
  AmericaMexicoCity = 'America___Mexico_City',
  AmericaMiquelon = 'America___Miquelon',
  AmericaMoncton = 'America___Moncton',
  AmericaMonterrey = 'America___Monterrey',
  AmericaMontevideo = 'America___Montevideo',
  AmericaMontreal = 'America___Montreal',
  AmericaMontserrat = 'America___Montserrat',
  AmericaNassau = 'America___Nassau',
  AmericaNewYork = 'America___New_York',
  AmericaNipigon = 'America___Nipigon',
  AmericaNome = 'America___Nome',
  AmericaNoronha = 'America___Noronha',
  AmericaNorthDakotaBeulah = 'America___North_Dakota___Beulah',
  AmericaNorthDakotaCenter = 'America___North_Dakota___Center',
  AmericaNorthDakotaNewSalem = 'America___North_Dakota___New_Salem',
  AmericaOjinaga = 'America___Ojinaga',
  AmericaPanama = 'America___Panama',
  AmericaPangnirtung = 'America___Pangnirtung',
  AmericaParamaribo = 'America___Paramaribo',
  AmericaPhoenix = 'America___Phoenix',
  AmericaPortAuPrince = 'America___Port__au__Prince',
  AmericaPortOfSpain = 'America___Port_of_Spain',
  AmericaPortoAcre = 'America___Porto_Acre',
  AmericaPortoVelho = 'America___Porto_Velho',
  AmericaPuertoRico = 'America___Puerto_Rico',
  AmericaPuntaArenas = 'America___Punta_Arenas',
  AmericaRainyRiver = 'America___Rainy_River',
  AmericaRankinInlet = 'America___Rankin_Inlet',
  AmericaRecife = 'America___Recife',
  AmericaRegina = 'America___Regina',
  AmericaResolute = 'America___Resolute',
  AmericaRioBranco = 'America___Rio_Branco',
  AmericaRosario = 'America___Rosario',
  AmericaSantaIsabel = 'America___Santa_Isabel',
  AmericaSantarem = 'America___Santarem',
  AmericaSantiago = 'America___Santiago',
  AmericaSantoDomingo = 'America___Santo_Domingo',
  AmericaSaoPaulo = 'America___Sao_Paulo',
  AmericaScoresbysund = 'America___Scoresbysund',
  AmericaShiprock = 'America___Shiprock',
  AmericaSitka = 'America___Sitka',
  AmericaStBarthelemy = 'America___St_Barthelemy',
  AmericaStJohns = 'America___St_Johns',
  AmericaStKitts = 'America___St_Kitts',
  AmericaStLucia = 'America___St_Lucia',
  AmericaStThomas = 'America___St_Thomas',
  AmericaStVincent = 'America___St_Vincent',
  AmericaSwiftCurrent = 'America___Swift_Current',
  AmericaTegucigalpa = 'America___Tegucigalpa',
  AmericaThule = 'America___Thule',
  AmericaThunderBay = 'America___Thunder_Bay',
  AmericaTijuana = 'America___Tijuana',
  AmericaToronto = 'America___Toronto',
  AmericaTortola = 'America___Tortola',
  AmericaVancouver = 'America___Vancouver',
  AmericaVirgin = 'America___Virgin',
  AmericaWhitehorse = 'America___Whitehorse',
  AmericaWinnipeg = 'America___Winnipeg',
  AmericaYakutat = 'America___Yakutat',
  AmericaYellowknife = 'America___Yellowknife',
  AntarcticaCasey = 'Antarctica___Casey',
  AntarcticaDavis = 'Antarctica___Davis',
  AntarcticaDumontDUrville = 'Antarctica___DumontDUrville',
  AntarcticaMacquarie = 'Antarctica___Macquarie',
  AntarcticaMawson = 'Antarctica___Mawson',
  AntarcticaMcMurdo = 'Antarctica___McMurdo',
  AntarcticaPalmer = 'Antarctica___Palmer',
  AntarcticaRothera = 'Antarctica___Rothera',
  AntarcticaSouthPole = 'Antarctica___South_Pole',
  AntarcticaSyowa = 'Antarctica___Syowa',
  AntarcticaTroll = 'Antarctica___Troll',
  AntarcticaVostok = 'Antarctica___Vostok',
  ArcticLongyearbyen = 'Arctic___Longyearbyen',
  AsiaAden = 'Asia___Aden',
  AsiaAlmaty = 'Asia___Almaty',
  AsiaAmman = 'Asia___Amman',
  AsiaAnadyr = 'Asia___Anadyr',
  AsiaAqtau = 'Asia___Aqtau',
  AsiaAqtobe = 'Asia___Aqtobe',
  AsiaAshgabat = 'Asia___Ashgabat',
  AsiaAshkhabad = 'Asia___Ashkhabad',
  AsiaAtyrau = 'Asia___Atyrau',
  AsiaBaghdad = 'Asia___Baghdad',
  AsiaBahrain = 'Asia___Bahrain',
  AsiaBaku = 'Asia___Baku',
  AsiaBangkok = 'Asia___Bangkok',
  AsiaBarnaul = 'Asia___Barnaul',
  AsiaBeirut = 'Asia___Beirut',
  AsiaBishkek = 'Asia___Bishkek',
  AsiaBrunei = 'Asia___Brunei',
  AsiaCalcutta = 'Asia___Calcutta',
  AsiaChita = 'Asia___Chita',
  AsiaChoibalsan = 'Asia___Choibalsan',
  AsiaChongqing = 'Asia___Chongqing',
  AsiaChungking = 'Asia___Chungking',
  AsiaColombo = 'Asia___Colombo',
  AsiaDacca = 'Asia___Dacca',
  AsiaDamascus = 'Asia___Damascus',
  AsiaDhaka = 'Asia___Dhaka',
  AsiaDili = 'Asia___Dili',
  AsiaDubai = 'Asia___Dubai',
  AsiaDushanbe = 'Asia___Dushanbe',
  AsiaGaza = 'Asia___Gaza',
  AsiaHarbin = 'Asia___Harbin',
  AsiaHebron = 'Asia___Hebron',
  AsiaHoChiMinh = 'Asia___Ho_Chi_Minh',
  AsiaHongKong = 'Asia___Hong_Kong',
  AsiaHovd = 'Asia___Hovd',
  AsiaIrkutsk = 'Asia___Irkutsk',
  AsiaIstanbul = 'Asia___Istanbul',
  AsiaJakarta = 'Asia___Jakarta',
  AsiaJayapura = 'Asia___Jayapura',
  AsiaJerusalem = 'Asia___Jerusalem',
  AsiaKabul = 'Asia___Kabul',
  AsiaKamchatka = 'Asia___Kamchatka',
  AsiaKarachi = 'Asia___Karachi',
  AsiaKashgar = 'Asia___Kashgar',
  AsiaKathmandu = 'Asia___Kathmandu',
  AsiaKatmandu = 'Asia___Katmandu',
  AsiaKhandyga = 'Asia___Khandyga',
  AsiaKolkata = 'Asia___Kolkata',
  AsiaKrasnoyarsk = 'Asia___Krasnoyarsk',
  AsiaKualaLumpur = 'Asia___Kuala_Lumpur',
  AsiaKuching = 'Asia___Kuching',
  AsiaKuwait = 'Asia___Kuwait',
  AsiaMacao = 'Asia___Macao',
  AsiaMacau = 'Asia___Macau',
  AsiaMagadan = 'Asia___Magadan',
  AsiaMakassar = 'Asia___Makassar',
  AsiaManila = 'Asia___Manila',
  AsiaMuscat = 'Asia___Muscat',
  AsiaNicosia = 'Asia___Nicosia',
  AsiaNovokuznetsk = 'Asia___Novokuznetsk',
  AsiaNovosibirsk = 'Asia___Novosibirsk',
  AsiaOmsk = 'Asia___Omsk',
  AsiaOral = 'Asia___Oral',
  AsiaPhnomPenh = 'Asia___Phnom_Penh',
  AsiaPontianak = 'Asia___Pontianak',
  AsiaPyongyang = 'Asia___Pyongyang',
  AsiaQatar = 'Asia___Qatar',
  AsiaQyzylorda = 'Asia___Qyzylorda',
  AsiaRangoon = 'Asia___Rangoon',
  AsiaRiyadh = 'Asia___Riyadh',
  AsiaSaigon = 'Asia___Saigon',
  AsiaSakhalin = 'Asia___Sakhalin',
  AsiaSamarkand = 'Asia___Samarkand',
  AsiaSeoul = 'Asia___Seoul',
  AsiaShanghai = 'Asia___Shanghai',
  AsiaSingapore = 'Asia___Singapore',
  AsiaSrednekolymsk = 'Asia___Srednekolymsk',
  AsiaTaipei = 'Asia___Taipei',
  AsiaTashkent = 'Asia___Tashkent',
  AsiaTbilisi = 'Asia___Tbilisi',
  AsiaTehran = 'Asia___Tehran',
  AsiaTelAviv = 'Asia___Tel_Aviv',
  AsiaThimbu = 'Asia___Thimbu',
  AsiaThimphu = 'Asia___Thimphu',
  AsiaTokyo = 'Asia___Tokyo',
  AsiaTomsk = 'Asia___Tomsk',
  AsiaUjungPandang = 'Asia___Ujung_Pandang',
  AsiaUlaanbaatar = 'Asia___Ulaanbaatar',
  AsiaUlanBator = 'Asia___Ulan_Bator',
  AsiaUrumqi = 'Asia___Urumqi',
  AsiaUstNera = 'Asia___Ust__Nera',
  AsiaVientiane = 'Asia___Vientiane',
  AsiaVladivostok = 'Asia___Vladivostok',
  AsiaYakutsk = 'Asia___Yakutsk',
  AsiaYangon = 'Asia___Yangon',
  AsiaYekaterinburg = 'Asia___Yekaterinburg',
  AsiaYerevan = 'Asia___Yerevan',
  AtlanticAzores = 'Atlantic___Azores',
  AtlanticBermuda = 'Atlantic___Bermuda',
  AtlanticCanary = 'Atlantic___Canary',
  AtlanticCapeVerde = 'Atlantic___Cape_Verde',
  AtlanticFaeroe = 'Atlantic___Faeroe',
  AtlanticFaroe = 'Atlantic___Faroe',
  AtlanticJanMayen = 'Atlantic___Jan_Mayen',
  AtlanticMadeira = 'Atlantic___Madeira',
  AtlanticReykjavik = 'Atlantic___Reykjavik',
  AtlanticSouthGeorgia = 'Atlantic___South_Georgia',
  AtlanticStHelena = 'Atlantic___St_Helena',
  AtlanticStanley = 'Atlantic___Stanley',
  AustraliaAct = 'Australia___ACT',
  AustraliaAdelaide = 'Australia___Adelaide',
  AustraliaBrisbane = 'Australia___Brisbane',
  AustraliaBrokenHill = 'Australia___Broken_Hill',
  AustraliaCanberra = 'Australia___Canberra',
  AustraliaCurrie = 'Australia___Currie',
  AustraliaDarwin = 'Australia___Darwin',
  AustraliaEucla = 'Australia___Eucla',
  AustraliaHobart = 'Australia___Hobart',
  AustraliaLhi = 'Australia___LHI',
  AustraliaLindeman = 'Australia___Lindeman',
  AustraliaLordHowe = 'Australia___Lord_Howe',
  AustraliaMelbourne = 'Australia___Melbourne',
  AustraliaNsw = 'Australia___NSW',
  AustraliaNorth = 'Australia___North',
  AustraliaPerth = 'Australia___Perth',
  AustraliaQueensland = 'Australia___Queensland',
  AustraliaSouth = 'Australia___South',
  AustraliaSydney = 'Australia___Sydney',
  AustraliaTasmania = 'Australia___Tasmania',
  AustraliaVictoria = 'Australia___Victoria',
  AustraliaWest = 'Australia___West',
  AustraliaYancowinna = 'Australia___Yancowinna',
  BrazilAcre = 'Brazil___Acre',
  BrazilDeNoronha = 'Brazil___DeNoronha',
  BrazilEast = 'Brazil___East',
  BrazilWest = 'Brazil___West',
  Cet = 'CET',
  Cst6Cdt = 'CST6CDT',
  CanadaAtlantic = 'Canada___Atlantic',
  CanadaCentral = 'Canada___Central',
  CanadaEastern = 'Canada___Eastern',
  CanadaMountain = 'Canada___Mountain',
  CanadaNewfoundland = 'Canada___Newfoundland',
  CanadaPacific = 'Canada___Pacific',
  CanadaSaskatchewan = 'Canada___Saskatchewan',
  CanadaYukon = 'Canada___Yukon',
  ChileContinental = 'Chile___Continental',
  ChileEasterIsland = 'Chile___EasterIsland',
  Cuba = 'Cuba',
  Eet = 'EET',
  Est5Edt = 'EST5EDT',
  Egypt = 'Egypt',
  Eire = 'Eire',
  EuropeAmsterdam = 'Europe___Amsterdam',
  EuropeAndorra = 'Europe___Andorra',
  EuropeAstrakhan = 'Europe___Astrakhan',
  EuropeAthens = 'Europe___Athens',
  EuropeBelfast = 'Europe___Belfast',
  EuropeBelgrade = 'Europe___Belgrade',
  EuropeBerlin = 'Europe___Berlin',
  EuropeBratislava = 'Europe___Bratislava',
  EuropeBrussels = 'Europe___Brussels',
  EuropeBucharest = 'Europe___Bucharest',
  EuropeBudapest = 'Europe___Budapest',
  EuropeBusingen = 'Europe___Busingen',
  EuropeChisinau = 'Europe___Chisinau',
  EuropeCopenhagen = 'Europe___Copenhagen',
  EuropeDublin = 'Europe___Dublin',
  EuropeGibraltar = 'Europe___Gibraltar',
  EuropeGuernsey = 'Europe___Guernsey',
  EuropeHelsinki = 'Europe___Helsinki',
  EuropeIsleOfMan = 'Europe___Isle_of_Man',
  EuropeIstanbul = 'Europe___Istanbul',
  EuropeJersey = 'Europe___Jersey',
  EuropeKaliningrad = 'Europe___Kaliningrad',
  EuropeKiev = 'Europe___Kiev',
  EuropeKirov = 'Europe___Kirov',
  EuropeLisbon = 'Europe___Lisbon',
  EuropeLjubljana = 'Europe___Ljubljana',
  EuropeLondon = 'Europe___London',
  EuropeLuxembourg = 'Europe___Luxembourg',
  EuropeMadrid = 'Europe___Madrid',
  EuropeMalta = 'Europe___Malta',
  EuropeMariehamn = 'Europe___Mariehamn',
  EuropeMinsk = 'Europe___Minsk',
  EuropeMonaco = 'Europe___Monaco',
  EuropeMoscow = 'Europe___Moscow',
  EuropeNicosia = 'Europe___Nicosia',
  EuropeOslo = 'Europe___Oslo',
  EuropeParis = 'Europe___Paris',
  EuropePodgorica = 'Europe___Podgorica',
  EuropePrague = 'Europe___Prague',
  EuropeRiga = 'Europe___Riga',
  EuropeRome = 'Europe___Rome',
  EuropeSamara = 'Europe___Samara',
  EuropeSanMarino = 'Europe___San_Marino',
  EuropeSarajevo = 'Europe___Sarajevo',
  EuropeSimferopol = 'Europe___Simferopol',
  EuropeSkopje = 'Europe___Skopje',
  EuropeSofia = 'Europe___Sofia',
  EuropeStockholm = 'Europe___Stockholm',
  EuropeTallinn = 'Europe___Tallinn',
  EuropeTirane = 'Europe___Tirane',
  EuropeTiraspol = 'Europe___Tiraspol',
  EuropeUlyanovsk = 'Europe___Ulyanovsk',
  EuropeUzhgorod = 'Europe___Uzhgorod',
  EuropeVaduz = 'Europe___Vaduz',
  EuropeVatican = 'Europe___Vatican',
  EuropeVienna = 'Europe___Vienna',
  EuropeVilnius = 'Europe___Vilnius',
  EuropeVolgograd = 'Europe___Volgograd',
  EuropeWarsaw = 'Europe___Warsaw',
  EuropeZagreb = 'Europe___Zagreb',
  EuropeZaporozhye = 'Europe___Zaporozhye',
  EuropeZurich = 'Europe___Zurich',
  Gb = 'GB',
  GbEire = 'GB__Eire',
  Hongkong = 'Hongkong',
  Iceland = 'Iceland',
  IndianAntananarivo = 'Indian___Antananarivo',
  IndianChagos = 'Indian___Chagos',
  IndianChristmas = 'Indian___Christmas',
  IndianCocos = 'Indian___Cocos',
  IndianComoro = 'Indian___Comoro',
  IndianKerguelen = 'Indian___Kerguelen',
  IndianMahe = 'Indian___Mahe',
  IndianMaldives = 'Indian___Maldives',
  IndianMauritius = 'Indian___Mauritius',
  IndianMayotte = 'Indian___Mayotte',
  IndianReunion = 'Indian___Reunion',
  Iran = 'Iran',
  Israel = 'Israel',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Kwajalein = 'Kwajalein',
  Libya = 'Libya',
  Met = 'MET',
  Mst7Mdt = 'MST7MDT',
  MexicoBajaNorte = 'Mexico___BajaNorte',
  MexicoBajaSur = 'Mexico___BajaSur',
  MexicoGeneral = 'Mexico___General',
  Nz = 'NZ',
  NzChat = 'NZ__CHAT',
  Navajo = 'Navajo',
  Prc = 'PRC',
  Pst8Pdt = 'PST8PDT',
  PacificApia = 'Pacific___Apia',
  PacificAuckland = 'Pacific___Auckland',
  PacificBougainville = 'Pacific___Bougainville',
  PacificChatham = 'Pacific___Chatham',
  PacificChuuk = 'Pacific___Chuuk',
  PacificEaster = 'Pacific___Easter',
  PacificEfate = 'Pacific___Efate',
  PacificEnderbury = 'Pacific___Enderbury',
  PacificFakaofo = 'Pacific___Fakaofo',
  PacificFiji = 'Pacific___Fiji',
  PacificFunafuti = 'Pacific___Funafuti',
  PacificGalapagos = 'Pacific___Galapagos',
  PacificGambier = 'Pacific___Gambier',
  PacificGuadalcanal = 'Pacific___Guadalcanal',
  PacificGuam = 'Pacific___Guam',
  PacificHonolulu = 'Pacific___Honolulu',
  PacificJohnston = 'Pacific___Johnston',
  PacificKiritimati = 'Pacific___Kiritimati',
  PacificKosrae = 'Pacific___Kosrae',
  PacificKwajalein = 'Pacific___Kwajalein',
  PacificMajuro = 'Pacific___Majuro',
  PacificMarquesas = 'Pacific___Marquesas',
  PacificMidway = 'Pacific___Midway',
  PacificNauru = 'Pacific___Nauru',
  PacificNiue = 'Pacific___Niue',
  PacificNorfolk = 'Pacific___Norfolk',
  PacificNoumea = 'Pacific___Noumea',
  PacificPagoPago = 'Pacific___Pago_Pago',
  PacificPalau = 'Pacific___Palau',
  PacificPitcairn = 'Pacific___Pitcairn',
  PacificPohnpei = 'Pacific___Pohnpei',
  PacificPonape = 'Pacific___Ponape',
  PacificPortMoresby = 'Pacific___Port_Moresby',
  PacificRarotonga = 'Pacific___Rarotonga',
  PacificSaipan = 'Pacific___Saipan',
  PacificSamoa = 'Pacific___Samoa',
  PacificTahiti = 'Pacific___Tahiti',
  PacificTarawa = 'Pacific___Tarawa',
  PacificTongatapu = 'Pacific___Tongatapu',
  PacificTruk = 'Pacific___Truk',
  PacificWake = 'Pacific___Wake',
  PacificWallis = 'Pacific___Wallis',
  PacificYap = 'Pacific___Yap',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Rok = 'ROK',
  Singapore = 'Singapore',
  Turkey = 'Turkey',
  UsAlaska = 'US___Alaska',
  UsAleutian = 'US___Aleutian',
  UsArizona = 'US___Arizona',
  UsCentral = 'US___Central',
  UsEastIndiana = 'US___East__Indiana',
  UsEastern = 'US___Eastern',
  UsHawaii = 'US___Hawaii',
  UsIndianaStarke = 'US___Indiana__Starke',
  UsMichigan = 'US___Michigan',
  UsMountain = 'US___Mountain',
  UsPacific = 'US___Pacific',
  UsPacificNew = 'US___Pacific__New',
  UsSamoa = 'US___Samoa',
  Wet = 'WET',
  WSu = 'W__SU'
}

export type TotalProductThroughput = {
  __typename?: 'TotalProductThroughput';
  /** Throughput returns a list of results generated from the Athena query. */
  throughput?: Maybe<Array<Maybe<TotalProductThroughputDetail>>>;
};

/** TotalProductThroughput returned result from the Athena query */
export type TotalProductThroughputDetail = {
  __typename?: 'TotalProductThroughputDetail';
  /** Maximum alcohol percentage. */
  avgAbvMax?: Maybe<Scalars['Float']>;
  /** Mimimum alcohol percentage. */
  avgAbvMin?: Maybe<Scalars['Float']>;
  /** Average cost for the total throughput of a product. */
  avgCost?: Maybe<Scalars['Float']>;
  /** The returned title of the brand. */
  brandTitle?: Maybe<Scalars['String']>;
  /** The end of the time window */
  endDate?: Maybe<Scalars['String']>;
  /** The ID for the performance platform */
  performacePlatformId?: Maybe<Scalars['String']>;
  /** The ID for the product */
  productId?: Maybe<Scalars['String']>;
  /** Returns product title for the throughput. */
  productTitle?: Maybe<Scalars['String']>;
  /** The product type. */
  productType?: Maybe<Scalars['String']>;
  /** The start of the time window */
  startDate?: Maybe<Scalars['String']>;
  /** Returns style label for the throughput. */
  styleLabel?: Maybe<Scalars['String']>;
  /** Returns style title for the throughput. */
  styleTitle?: Maybe<Scalars['String']>;
  /** The returned throughput of the product. */
  throughput?: Maybe<Scalars['Float']>;
};

export type Tour = {
  __typename?: 'Tour';
  cost?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  hours?: Maybe<Array<Maybe<Hours>>>;
  mustBeLegalDrinkingAge?: Maybe<Scalars['Boolean']>;
  toursAvailable: Scalars['Boolean'];
};

/**
 * The tracking record that is used to manage the status and other attributes of a `VarianceReport`.
 * This record will contain the results of the report once all stages of processing are complete.
 */
export type TrackedVarianceReport = {
  __typename?: 'TrackedVarianceReport';
  /** Email address of user. Auto-generated. */
  createdBy: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** Latest date of the report data. */
  endDate: Scalars['String'];
  /** Stringified errors and warnings that occured during processing. */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Report ID */
  id: Scalars['ID'];
  /** Email address of user. Auto-generated. */
  lastUpdatedBy: Scalars['String'];
  /** Processed report. Only valid when status is `Ready`. */
  report?: Maybe<VarianceReport>;
  /** Pre-processed file S3 key. */
  s3KeyPreProcessed?: Maybe<Scalars['String']>;
  /** Raw PoS files S3 keys. */
  s3KeysRaw?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Pre-processed file upload link provided by S3. */
  s3PreProcessedUploadFields?: Maybe<S3UploadFields>;
  /** Raw PoS files upload link provided by S3. */
  s3RawUploadFields?: Maybe<S3UploadFields>;
  /** Earliest date of the report data. */
  startDate: Scalars['String'];
  /** Current status of the report. */
  status: VarianceReportStatus;
  /** Title of the report. */
  title: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

export type TrackedVarianceReportConnection = {
  __typename?: 'TrackedVarianceReportConnection';
  /** List of reports. */
  items?: Maybe<Array<Maybe<TrackedVarianceReport>>>;
  /** Token for next page. */
  nextToken?: Maybe<Scalars['String']>;
  /** Count of returned items. */
  returnedCount?: Maybe<Scalars['Int']>;
};

/** Union of the various brew types supported as custom products. */
export type UBrewMenuCustomBeverage = Beer | Cider | Cocktail | Coffee | Kombucha | Mead | Seltzer | Wine;

/** Simple URL object type. */
export type Url = {
  __typename?: 'URL';
  /** URL string. */
  url: Scalars['String'];
};

export type Unverified = Product & WildCardTitleSearch & {
  __typename?: 'Unverified';
  abv?: Maybe<Scalars['Float']>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  brand?: Maybe<Brand>;
  brandName?: Maybe<Scalars['String']>;
  /** brandTitle if the user included the title of the brand */
  brandTitle?: Maybe<Scalars['String']>;
  carbonation?: Maybe<Carbonation>;
  color?: Maybe<Scalars['String']>;
  /** any additional comments from the user */
  comment?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** custom brew type if the user provided this information */
  customBrewType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  guid?: Maybe<Scalars['String']>;
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  ibu?: Maybe<Scalars['Int']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  /** Boolean to control whether a product is displayed on external customer-facing apps. */
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  isNewProduct?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  originalGravity?: Maybe<Scalars['Float']>;
  perceivedBitterness?: Maybe<Scalars['Int']>;
  perceivedProductBitterness?: Maybe<PerceivedBitterness>;
  /** ppSubId if this unverified product is associated with a Performance Platform Subscription */
  ppSubId?: Maybe<Scalars['String']>;
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  releaseDate?: Maybe<Scalars['String']>;
  servingTemperature?: Maybe<ServingTemperature>;
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  slug?: Maybe<Scalars['String']>;
  srm?: Maybe<Scalars['Int']>;
  /** The unverified product style information */
  style?: Maybe<Style>;
  tastingExperience?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export type UnverifiedProduct = Product & WildCardTitleSearch & {
  __typename?: Scalars['String'];
  abv?: Maybe<Scalars['Float']>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  brand?: Maybe<Brand>;
  brandName?: Maybe<Scalars['String']>;
  carbonation?: Maybe<Carbonation>;
  color?: Maybe<Scalars['String']>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  guid?: Maybe<Scalars['String']>;
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  ibu?: Maybe<Scalars['Int']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  /** Boolean to control whether a product is displayed on external customer-facing apps. */
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  isNewProduct?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  originalGravity?: Maybe<Scalars['Float']>;
  perceivedBitterness?: Maybe<Scalars['Int']>;
  perceivedProductBitterness?: Maybe<PerceivedBitterness>;
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  releaseDate?: Maybe<Scalars['String']>;
  servingTemperature?: Maybe<ServingTemperature>;
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  slug?: Maybe<Scalars['String']>;
  srm?: Maybe<Scalars['Int']>;
  /** The unverified product style information */
  style?: Maybe<Style>;
  tastingExperience?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Address>;
  /** BDB Access - Always true and not currently used for anything */
  bdbAccess?: Maybe<Scalars['Boolean']>;
  /** (Resolved) Brew Menu Subscription Associations of a User. */
  bmSubscriptions?: Maybe<Array<Maybe<BmSubscriptionAssociation>>>;
  /** [NOT IMPLEMENTED] (Resolved) List of Boards for a user. */
  boards?: Maybe<Array<Maybe<Board>>>;
  /** Flag to determine if user is a superadmin */
  brewlogixSuperadmin?: Maybe<Scalars['Boolean']>;
  contact?: Maybe<Contact>;
  /** Preferred order of contact for user */
  contactPreference?: Maybe<Array<Maybe<ContactPreference>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  /** Lets end user set a default subscription to automatically select on login. */
  defaultSubscription?: Maybe<Scalars['String']>;
  /** User description. */
  description?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  /** An end users selection of favorite Brand/Product/Location */
  favorites?: Maybe<Favorites>;
  givenName?: Maybe<Scalars['String']>;
  /** User id; correlates with Cognito Sub (aka cognito id) */
  id: Scalars['ID'];
  /** Determines if a user is active or disabled. */
  isActive: Scalars['Boolean'];
  /** Users job title. Currently is adjustable by end user */
  jobTitle?: Maybe<JobTitle>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** Additional, arbitrary context on a User if required for temporary feature. */
  meta?: Maybe<Scalars['String']>;
  /** (Resolved) MarketMyBrewery Brand Associations. Authorization field for MMB. */
  mmbBrands?: Maybe<Array<Maybe<BrandAssociation>>>;
  /** The Users OrganizationAssociation. Resolved Field */
  organization?: Maybe<OrganizationAssociation>;
  /** (Resolved) Performance Platform Subscription Associations. Authorization field for PPB. */
  ppSubscriptions?: Maybe<Array<Maybe<PpSubscriptionAssociation>>>;
  /** The source of the user's registration. */
  registrationSource?: Maybe<RegistrationSource>;
  /** End user collection of routes */
  routes?: Maybe<Array<Maybe<BreweryRoute>>>;
  slug?: Maybe<Scalars['String']>;
  /** Determines access to internal tools and features, such as the Docs site. */
  trustedUser?: Maybe<Scalars['Boolean']>;
  updatedDate: Scalars['String'];
};

export enum UserAssociationRole {
  Owner = 'owner',
  Staff = 'staff'
}

export type UserConnection = {
  __typename?: 'UserConnection';
  items?: Maybe<Array<Maybe<User>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export type UserSummary = {
  __typename?: 'UserSummary';
  /** Total number of users with access to BreweryDB, `bdbAccess:true` */
  bdbUsers?: Maybe<Scalars['Int']>;
  /** Total number of users with access to at least one BrewMenu Subscription */
  bmUsers?: Maybe<Scalars['Int']>;
  /** Total number of users with access to at least one MMB Brand, `mmbBrands!=null` */
  mmbUsers?: Maybe<Scalars['Int']>;
  /** Total number of users with access to at least one PP Subscription, `ppSubscriptions!=null` */
  ppUsers?: Maybe<Scalars['Int']>;
  /** Total number of Super Admin users, `brewlogixSuperadmin:true` */
  saUsers?: Maybe<Scalars['Int']>;
  /** Total number of users in BrewLogix ecosystem */
  totalUsers?: Maybe<Scalars['Int']>;
};

/** Contains all the finalized data for a `VarianceReport`. */
export type VarianceReport = {
  __typename?: 'VarianceReport';
  /** Computed metadata about the report. */
  metadata: VarianceReportMetadata;
  /** List of all data rows for the report. */
  rows: Array<Maybe<VarianceReportRow>>;
  /** Computed totals for the report. */
  totals: VarianceReportRow;
};

/** Provides some computed metadata for a `VarianceReport`. Values casted to strings for FE. */
export type VarianceReportMetadata = {
  __typename?: 'VarianceReportMetadata';
  avgYield?: Maybe<Scalars['String']>;
  totalWaste?: Maybe<Scalars['String']>;
  totalYield?: Maybe<Scalars['String']>;
};

/** A single row of data for a `VarianceReport`. Values casted to strings for FE. */
export type VarianceReportRow = {
  __typename?: 'VarianceReportRow';
  /** Variance percent for this line item. */
  computedVariance?: Maybe<Scalars['String']>;
  /** Yield percent for this line item. */
  computedYield?: Maybe<Scalars['String']>;
  /** Oz poured for this line item. */
  ozPoured?: Maybe<Scalars['String']>;
  /** Oz sold for this line item. */
  ozSold?: Maybe<Scalars['String']>;
  /** Title for this line item. */
  title?: Maybe<Scalars['String']>;
};

/** Valid status for `VarianceReports`. Used as an index for the `PPSubReports` table. */
export enum VarianceReportStatus {
  /**
   * CS has indicated that they are ready to upload the pre-processed PoS report data, but it has not
   * been uploaded yet.
   */
  PendingCsUpload = 'PendingCSUpload',
  /**
   * The initial DB record to track the report has been created, but the raw PoS data has not been
   * uploaded yet.
   */
  PendingRawPosUpload = 'PendingRawPosUpload',
  /**
   * The PUT bucket trigger has updated the DB record status to indicate the pre-processed PoS data
   * is being processed.
   */
  Processing = 'Processing',
  /**
   * The raw PoS report data has been uploaded and a PUT bucket trigger has updated the DB record
   * status.
   */
  RawPosFiles = 'RawPosFiles',
  /** Once the report has been fully processed, the status is updated to indicate the report is ready. */
  Ready = 'Ready'
}

export type VerifiedSlug = {
  __typename?: 'VerifiedSlug';
  input: Scalars['String'];
  slug: Scalars['String'];
  unique: Scalars['Boolean'];
};

export type Vessel = {
  __typename?: 'Vessel';
  awrSleep?: Maybe<Scalars['String']>;
  awrWeight?: Maybe<Scalars['String']>;
  couplerWeight?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  iconSlug?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  material?: Maybe<Material>;
  shortTitle?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  suggestedTotalVolume?: Maybe<Scalars['Float']>;
  tareWeight?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  totalVolume?: Maybe<Scalars['Float']>;
  updatedDate: Scalars['String'];
};

export type VesselConnection = {
  __typename?: 'VesselConnection';
  items?: Maybe<Array<Maybe<Vessel>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export type WildCardTitleSearch = {
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
};

export type WildCardTitleSearchConnection = {
  __typename?: 'WildCardTitleSearchConnection';
  items?: Maybe<Array<Maybe<WildCardTitleSearch>>>;
  nextToken: Scalars['String'];
  returnedCount?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export type Wine = Product & WildCardTitleSearch & {
  __typename?: 'Wine';
  /** Alcohol of this wine as a percentage with up to 1 decimal. */
  abv?: Maybe<Scalars['Float']>;
  /** Acidity of the wine. */
  acidity?: Maybe<Acidity>;
  /** Enum has the Alcohol level for the wine. */
  alcohol?: Maybe<Alcohol>;
  /** Boolean if the product has alcohol. */
  alcoholFree?: Maybe<Scalars['Boolean']>;
  /** Product label. Supports multiple assets, but front-end expects only 1. */
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** Enum for the wine Body. */
  body?: Maybe<Body>;
  /** PK of a Brand. */
  brand?: Maybe<Brand>;
  /** Enum for the wine Carbonation. */
  carbonation?: Maybe<Carbonation>;
  /** Color of the liquid epressed as Tailwind color e.g. `flower-fruity-apple` or `srm-19`. */
  color?: Maybe<Scalars['String']>;
  /** Enum for the wine color intensity. */
  colorIntensity?: Maybe<ColorIntensity>;
  /** Email address of user. Auto-generated. */
  createdBy?: Maybe<Scalars['String']>;
  /** ISO Date string in UTC. Auto-generated. */
  createdDate: Scalars['String'];
  /** General description of the brand. Limited on the front-end to 800 characters. */
  description?: Maybe<Scalars['String']>;
  /** Enum for the wine aftertaste. */
  finish?: Maybe<Finish>;
  /** Enum for the wine flavor intensity. */
  flavorIntensity?: Maybe<FlavorIntensity>;
  /** Enum of foods to pair with a wine Product. */
  foodPairingList?: Maybe<Array<Maybe<FoodPairing>>>;
  /** Enum origin of grapes. */
  grapesOrigin?: Maybe<Array<Maybe<GrapeOrigin>>>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: Maybe<Scalars['String']>;
  /** Side profile flavors of a wine. */
  hintsOfFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** UUID. Auto generated. */
  id: Scalars['ID'];
  /** Ingredients of the product. */
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  isHiddenExternally?: Maybe<Scalars['Boolean']>;
  /** Boolean if the wine is new */
  isNewProduct?: Maybe<Scalars['Boolean']>;
  /** Boolean if product is verified. */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Email address of user. Auto-generated. */
  lastUpdatedBy?: Maybe<Scalars['String']>;
  /** List of locations where this wine is served either in draft or packaged form. */
  locationRelationships?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** DEPRECATED. Use `locationRelationships` 1.27.0. EOL 1.30.0. */
  locationsAvailable?: Maybe<Array<Maybe<LocationProductRelationship>>>;
  /** Enum for the wines perceived sweetness. */
  perceivedSweetness?: Maybe<PerceivedSweetness>;
  /** Main profile flavors of a wine. */
  primaryFlavorNotes?: Maybe<Array<Maybe<FlavorNote>>>;
  /** The product type. */
  productType?: Maybe<Scalars['String']>;
  /** Date the product was first made available to the public. Expects a valid date string such as `11/27/1984` though the front-end may only render the year in most cases. */
  releaseDate?: Maybe<Scalars['String']>;
  /** Enum of serving temperatures for a wine Product. */
  servingTemperature?: Maybe<ServingTemperature>;
  /** List of suggested glassware to use when drinking this product. */
  servingVessels?: Maybe<Array<Maybe<ServingVessel>>>;
  /** Slug of the wine. Auto-Generated in createProduct using the title. */
  slug?: Maybe<Scalars['String']>;
  /** The wine style information */
  style?: Maybe<Style>;
  /** Description of the wine experience from pour to sip. Limited by the front-end to 300 characters. */
  tastingExperience?: Maybe<Scalars['String']>;
  /** Title of the wine Product. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits of a wine Product. Contains availability, nutrition, allergens, and otherTraits. */
  traits?: Maybe<ProductTraits>;
  /** ISO Date string in UTC. Auto-generated. */
  updatedDate: Scalars['String'];
  /** The current product version. */
  version?: Maybe<Scalars['Int']>;
  /** Vintage is a 4-digit year integer. */
  vintage?: Maybe<Scalars['Int']>;
  /** Enum for the wine color. */
  wineColor?: Maybe<WineColor>;
};

export enum WineColor {
  Red = 'Red',
  Rose = 'Rose',
  White = 'White'
}

export enum Yeast {
  AbbeyAle = 'Abbey_Ale',
  AbbeyIvAle = 'Abbey_IV_Ale',
  AmericanAle = 'American_Ale',
  AmericanAleBlend = 'American_Ale_Blend',
  AmericanAleIi = 'American_Ale_II',
  AmericanHefeweizenAle = 'American_Hefeweizen_Ale',
  AmericanLager = 'American_Lager',
  AmericanMegabrewery = 'American_Megabrewery',
  AmericanMicrobreweryAleNo_1 = 'American_Microbrewery_Ale_No_1',
  AmericanMicrobreweryAleNo_2 = 'American_Microbrewery_Ale_No_2',
  AmericanMicrobreweryLager = 'American_Microbrewery_Lager',
  AmericanWheat = 'American_Wheat',
  AmericanWheatAle = 'American_Wheat_Ale',
  AmericanWhiteAle = 'American_White_Ale',
  AntwerpAle = 'Antwerp_Ale',
  AssmanshausenWine = 'Assmanshausen_Wine',
  Attenuation_80Percent = 'Attenuation_80_percent',
  AustralianAle = 'Australian_Ale',
  AvizeWine = 'Avize_Wine',
  BastogneBelgianAle = 'Bastogne_Belgian_Ale',
  BavarianLager = 'Bavarian_Lager',
  BavarianWeizen = 'Bavarian_Weizen',
  BavarianWheat = 'Bavarian_Wheat',
  BavarianWheatBlend = 'Bavarian_Wheat_Blend',
  BavarianWheatYeast = 'Bavarian_Wheat_Yeast',
  BedfordBritish = 'Bedford_British',
  BelgianAbbey = 'Belgian_Abbey',
  BelgianAbbeyIi = 'Belgian_Abbey_II',
  BelgianAle = 'Belgian_Ale',
  BelgianAleNo_1 = 'Belgian_Ale_No_1',
  BelgianAleNo_2 = 'Belgian_Ale_No_2',
  BelgianAleNo_3 = 'Belgian_Ale_No_3',
  BelgianArdennes = 'Belgian_Ardennes',
  BelgianBastogneAle = 'Belgian_Bastogne_Ale',
  BelgianGoldenAle = 'Belgian_Golden_Ale',
  BelgianLager = 'Belgian_Lager',
  BelgianLambicBlend = 'Belgian_Lambic_Blend',
  BelgianSaison = 'Belgian_Saison',
  BelgianSaisonI = 'Belgian_Saison_I',
  BelgianSaisonIi = 'Belgian_Saison_II',
  BelgianSaisonIAle = 'Belgian_Saison_I_Ale',
  BelgianSaisonYeast = 'Belgian_Saison_Yeast',
  BelgianScheldeAleYeast = 'Belgian_Schelde_Ale_Yeast',
  BelgianStrongAle = 'Belgian_Strong_Ale',
  BelgianStyleAleBlend = 'Belgian_Style_Ale_Blend',
  BelgianStyleAleYeastBlend = 'Belgian_Style_Ale_Yeast_Blend',
  BelgianStyleSaisonAleBlend = 'Belgian_Style_Saison_Ale_Blend',
  BelgianWheat = 'Belgian_Wheat',
  BelgianWheatYeast = 'Belgian_Wheat_Yeast',
  BelgianWitAle = 'Belgian_Wit_Ale',
  BelgianWitIiAle = 'Belgian_Wit_II_Ale',
  BelgianWitbier = 'Belgian_Witbier',
  BellSOberon = 'Bell___s_Oberon',
  BiereDeGarde = 'Biere_De_Garde',
  BohemianLager = 'Bohemian_Lager',
  Bordeaux = 'Bordeaux',
  BreadYeastAnyVariety = 'Bread_yeast__any_variety',
  Brettanomyces = 'Brettanomyces',
  BrettanomycesBruxellensis = 'Brettanomyces_Bruxellensis',
  BrettanomycesClaussenii = 'Brettanomyces_Claussenii',
  BrettanomycesLambicus = 'Brettanomyces_Lambicus',
  BrewfermBlancheDryYeast = 'Brewferm_Blanche_Dry_Yeast',
  BrewfermLagerDryYeast = 'Brewferm_Lager_Dry_Yeast',
  BritishAle = 'British_Ale',
  BritishAleIi = 'British_Ale_II',
  BritishCaskAle = 'British_Cask_Ale',
  BritishDraftAle = 'British_Draft_Ale',
  BritishMicrobreweryAle = 'British_Microbrewery_Ale',
  BritishPaleAleNo_1 = 'British_Pale_Ale_No_1',
  BritishPaleAleNo_2 = 'British_Pale_Ale_No_2',
  BritishRealAle = 'British_Real_Ale',
  BudvarLager = 'Budvar_Lager',
  BudvarLagerYeast = 'Budvar_Lager_Yeast',
  BurtonAle = 'Burton_Ale',
  CabernetRedWine = 'Cabernet_Red_Wine',
  CaliforniaAle = 'California_Ale',
  CaliforniaAleV = 'California_Ale_V',
  CaliforniaEsteem = 'California_Esteem',
  CaliforniaLager = 'California_Lager',
  CaliforniaPubAle = 'California_Pub_Ale',
  CanadianAle = 'Canadian_Ale',
  CanadianBelgianStyleYeast = 'Canadian_Belgian_Style_Yeast',
  Chablis = 'Chablis',
  Champagne = 'Champagne',
  ChampagneYeast = 'Champagne_Yeast',
  ChardonnayWhiteWine = 'Chardonnay_White_Wine',
  ChateauRed = 'Chateau_Red',
  Chianti = 'Chianti',
  Chico_1056 = 'Chico_1056',
  ChimayBottleCulture = 'Chimay_bottle_culture',
  ClassicBritishAle = 'Classic_British_Ale',
  CooperAle = 'Cooper_Ale',
  CooperSAleDryYeast = 'Cooper___s_Ale_Dry_Yeast',
  CreamAleBlend = 'Cream_Ale_Blend',
  CryHavocAle = 'Cry_Havoc__Ale',
  CryHavocLager = 'Cry_Havoc__Lager',
  CzechBudejoviceLager = 'Czech_Budejovice_Lager',
  CzechPils = 'Czech_Pils',
  CzechPilsnerLager = 'Czech_Pilsner_Lager',
  DanishLager = 'Danish_Lager',
  DennySFavorite_50 = 'Denny___s_Favorite_50',
  Diamond = 'Diamond',
  DoricAle = 'Doric_Ale',
  DregsJwLeesHarvestAle = 'Dregs__JW_Lees_Harvest_Ale',
  DryEnglishAle = 'Dry_English_Ale',
  DryMeadYeastWYeast_4362 = 'Dry_Mead_Yeast__WYeast_4362',
  DusseldorfAlt = 'Dusseldorf_Alt',
  DutchCastle = 'Dutch_Castle',
  Ecy01BugFarm = 'ECY01_BugFarm',
  Ecy02FlemishAle = 'ECY02_Flemish_Ale',
  Ecy03FarmhouseBrett = 'ECY03_Farmhouse_Brett',
  Ecy04BrettBlend_1 = 'ECY04_BRETT_Blend_1',
  Ecy05BrettBlend_9 = 'ECY05_BRETT_Blend_9',
  Ecy07ScottishHeavy = 'ECY07_Scottish_Heavy',
  Ecy08SaisonBrasserieBlend = 'ECY08_Saison_Brasserie_Blend',
  Ecy10OldNewarkAle = 'ECY10_Old_Newark_Ale',
  Ecy12OldNewarkBeer = 'ECY12_Old_Newark_Beer',
  Ecy13TrappistAle = 'ECY13_Trappist_Ale',
  EastCoastAle = 'East_Coast_Ale',
  EastEuropeanLager = 'East_European_Lager',
  EdinburghScottishAle = 'Edinburgh_Scottish_Ale',
  EdmeAleDryYeast = 'Edme_Ale_Dry_Yeast',
  EdmeAleYeast = 'Edme_Ale_Yeast',
  EnglishAle = 'English_Ale',
  EnglishAleIi = 'English_Ale_II',
  EnglishCider = 'English_Cider',
  EnglishSpecialBitter = 'English_Special_Bitter',
  EssexAle = 'Essex_Ale',
  EuropeanAle = 'European_Ale',
  EuropeanLagerIi = 'European_Lager_II',
  FarmhouseAle = 'Farmhouse_Ale',
  FermentisSafealeS_04 = 'Fermentis_Safeale_S__04',
  FermentisSaflagerS_23 = 'Fermentis_Saflager_S__23',
  FermentisUs_05 = 'Fermentis_US__05',
  FermentisWb_06DryWheatBeerYeast = 'Fermentis_WB__06_Dry_Wheat_Beer_Yeast',
  FlorSherry = 'Flor_Sherry',
  FlyingDogAleYeast = 'Flying_Dog_Ale_Yeast',
  ForbiddenFruit = 'Forbidden_Fruit',
  FrenchRedWine = 'French_Red_Wine',
  FrenchSaison = 'French_Saison',
  FrenchWhiteWine = 'French_White_Wine',
  GambrinusLagerYeast = 'Gambrinus_Lager_Yeast',
  GermanAle = 'German_Ale',
  GermanAleIi = 'German_Ale_II',
  GermanAleKolsch = 'German_Ale_Kolsch',
  GermanBockLager = 'German_Bock_Lager',
  GermanLager = 'German_Lager',
  GermanWeiss = 'German_Weiss',
  GermanWheat = 'German_Wheat',
  GervinEnglishAleYeast = 'Gervin_English_Ale_Yeast',
  HefeweizenAle = 'Hefeweizen_Ale',
  HefeweizenIvAle = 'Hefeweizen_IV_Ale',
  HoegaardenBottleCultured = 'Hoegaarden__bottle_cultured',
  HouseAleYeast = 'House_Ale_Yeast',
  IngelmunsterAle = 'Ingelmunster_Ale',
  IrishAle = 'Irish_Ale',
  IrishDryStout = 'Irish_Dry_Stout',
  JollyPumpkinBamBiereYeast = 'Jolly_Pumpkin_Bam_Biere_Yeast',
  KellerweisSierraNevadaCulture = 'Kellerweis__Sierra_Nevada_culture',
  KlassicAleYeast = 'Klassic_Ale_Yeast',
  Kolsch = 'Kolsch',
  KolschYeast = 'Kolsch_Yeast',
  LactobacillusDelbrueckii = 'Lactobacillus_Delbrueckii',
  Lalvin_71B_1122 = 'Lalvin_71B__1122',
  LalvinD_47 = 'Lalvin_D__47',
  LalvinEc_1118 = 'Lalvin_EC__1118',
  LalvinRc_212Bourgovin = 'Lalvin_RC_212__Bourgovin',
  LalvinK1V_1116 = 'Lalvin__K1V__1116',
  LeuvenPaleAleYeast = 'Leuven_Pale_Ale_Yeast',
  LocalBreweryYeast = 'Local_brewery_Yeast',
  London = 'London',
  LondonAle = 'London_Ale',
  LondonAleDryYeast = 'London_Ale_Dry_Yeast',
  LondonAleIii = 'London_Ale_III',
  LondonEsbAle = 'London_ESB_Ale',
  ManchesterAle = 'Manchester_Ale',
  MauriBrew = 'Mauri_Brew',
  MerlotRedWine = 'Merlot_Red_Wine',
  MexicanLager = 'Mexican_Lager',
  Munich = 'Munich',
  MunichGermanWheatDryAleYeast = 'Munich_German_Wheat_Dry_Ale_Yeast',
  MunichLager = 'Munich_Lager',
  MunichLagerIi = 'Munich_Lager_II',
  MuntonFisonAle = 'Munton_Fison_Ale',
  MuntonSDryYeast = 'Munton___s_Dry_Yeast',
  MuntonsPremiumGold = 'Muntons_Premium_Gold',
  MuteDogFeralYeastBlend = 'Mute_Dog_Feral_Yeast_Blend',
  NorthAmericanLager = 'North_American_Lager',
  NorthEasternMicroAle = 'North__Eastern_Micro_Ale',
  NorthernGermanLager = 'Northern_German_Lager',
  NorthwestAle = 'Northwest_Ale',
  Nottingham = 'Nottingham',
  NottinghamAle = 'Nottingham_Ale',
  NottinghamAleDryYeast = 'Nottingham_Ale_Dry_Yeast',
  NottinghamYeast = 'Nottingham_Yeast',
  OctoberfestLagerBlend = 'Octoberfest_Lager_Blend',
  OctoberfestMarzenLager = 'Octoberfest_Marzen_Lager',
  OktoberfestMarzenLager = 'Oktoberfest_Marzen_Lager',
  OldBavarianLager = 'Old_Bavarian_Lager',
  OldGermanAle = 'Old_German_Ale',
  OldeSuffolk = 'Olde_Suffolk',
  OriginalPilsner = 'Original_Pilsner',
  OrvalCulture = 'Orval_Culture',
  OrvalDregs = 'Orval_Dregs',
  PacificAle = 'Pacific_Ale',
  PasteurChampagne = 'Pasteur_Champagne',
  PasteurRedWine = 'Pasteur_Red_Wine',
  Pediococcus = 'Pediococcus',
  PediococcusCerevisiae = 'Pediococcus_Cerevisiae',
  PediococcusDamnosus = 'Pediococcus_Damnosus',
  PilsenLager = 'Pilsen_Lager',
  PilsnerLager = 'Pilsner_Lager',
  PilsnerUrqell = 'Pilsner_Urqell',
  PortWine = 'Port_Wine',
  PremiumBitterAle = 'Premium_Bitter_Ale',
  RealBrewersYeastLuckyNo_7 = 'Real_Brewers_Yeast__Lucky_No__7',
  RealBrewersYeastTheOne = 'Real_Brewers_Yeast__The_One',
  RealBrewersYeastYeOldEnglish = 'Real_Brewers_Yeast__Ye_Old_English',
  RedStarAle = 'Red_Star_Ale',
  RedStarCotesDeBlancs = 'Red_Star_Cotes_De_Blancs',
  RedStarDryPasteurChampagneYeast = 'Red_Star_Dry_Pasteur_Champagne_Yeast',
  RedStarPremierCuvee = 'Red_Star_Premier_Cuvee',
  RingwoodAle = 'Ringwood_Ale',
  RoeselareAleBlend = 'Roeselare_Ale_Blend',
  RoeselareBelgianBlend = 'Roeselare_Belgian_Blend',
  RoguePacmanAleYeast = 'Rogue_Pacman_Ale_Yeast',
  Rudesheimer = 'Rudesheimer',
  SafAleGermanAle = 'SafAle_German_Ale',
  SafBrewAle = 'SafBrew_Ale',
  SafLagerWeinhenstephanLager = 'SafLager_Weinhenstephan_Lager',
  SafLagerWestEuropeanLager = 'SafLager_West_European_Lager',
  SafaleAmericanAle = 'Safale_American_Ale',
  SafaleEnglishAle = 'Safale_English_Ale',
  SafaleK_97 = 'Safale_K__97',
  SafaleWheatBeer = 'Safale_Wheat_Beer',
  SafbrewSpecialtyAle = 'Safbrew_Specialty_Ale',
  Saflager_34_70Weihenstephan = 'Saflager_34_70__Weihenstephan',
  Saflager_189 = 'Saflager_189',
  SaflagerGermanLager = 'Saflager_German_Lager',
  SaflagerS_23DryYeast = 'Saflager_S__23_Dry_Yeast',
  SaflagerSwissLager = 'Saflager_Swiss_Lager',
  SaflagerW_34_70 = 'Saflager_w_34_70',
  Saison = 'Saison',
  Sake = 'Sake',
  SakeNo_9 = 'Sake_No_9',
  SamuelAdamsAleYeast = 'Samuel_Adams_Ale_Yeast',
  SamuelAdamsLagerYeast = 'Samuel_Adams_lager_yeast',
  SanFranciscoLager = 'San_Francisco_Lager',
  ScottishAle = 'Scottish_Ale',
  ScottishAleYeastCake = 'Scottish_Ale_Yeast_Cake',
  ScottishBitter = 'Scottish_Bitter',
  SouthernGermanLager = 'Southern_German_Lager',
  SouthwoldAle = 'Southwold_Ale',
  SpecialAle = 'Special_Ale',
  SpontaneousWild = 'Spontaneous_wild',
  StaroPragueLager = 'Staro_Prague_Lager',
  SteinbergGeisenheimWine = 'Steinberg__Geisenheim_Wine',
  SuperHighGravityAle = 'Super_High_Gravity_Ale',
  SuremainBurgundyWine = 'Suremain_Burgundy_Wine',
  SweetMeadWine = 'Sweet_Mead_Wine',
  ThamesValleyAle = 'Thames_Valley_Ale',
  ThamesValleyAleYeastIi = 'Thames_Valley_Ale_Yeast_II',
  TrappistAle = 'Trappist_Ale',
  TrappistHighGravity = 'Trappist_High_Gravity',
  UrquellLager = 'Urquell_Lager',
  VintnerSHarvestSn9 = 'Vintner___s_Harvest_SN9',
  Wlp645BrettanomycesClaussenii = 'WLP645_Brettanomyces_claussenii',
  WeihenstephanWeizen = 'Weihenstephan_Weizen',
  WestYorkshireAle = 'West_Yorkshire_Ale',
  WhitbreadAle = 'Whitbread_Ale',
  WhitbreadAleDryYeast = 'Whitbread_Ale_Dry_Yeast',
  WhiteLabsBrettanomycesBruxellensisWlp_650 = 'White_LabsBrettanomyces_bruxellensis__WLP_650',
  WhiteLabsAmericanAleBlendWlp060 = 'White_Labs_American_Ale_Blend__WLP060',
  WhiteLabsAmericanFarmhouseBlendWlp670 = 'White_Labs_American_Farmhouse_Blend__WLP670',
  WhiteLabsAmericanHefeweizenAleWlp320 = 'White_Labs_American_Hefeweizen_Ale__WLP320',
  WhiteLabsBedfordBritishWlp006 = 'White_Labs_Bedford_British__WLP006',
  WhiteLabsBelgianSourMix_1Wlp655 = 'White_Labs_Belgian_Sour_Mix_1__WLP655',
  WhiteLabsBelgianWitAleWlp400 = 'White_Labs_Belgian_Wit_Ale__WLP400',
  WhiteLabsBelgianWitIiAleWlp410 = 'White_Labs_Belgian_Wit_II_Ale__WLP410',
  WhiteLabsBerlinerWeisseBlendWlp630 = 'White_Labs_Berliner_Weisse_Blend__WLP630',
  WhiteLabsBrettanomycesLambicusWlp_653 = 'White_Labs_Brettanomyces_lambicus__WLP_653',
  WhiteLabsBritishAleWlp005 = 'White_Labs_British_Ale__WLP005',
  WhiteLabsBurtonAleWlp023 = 'White_Labs_Burton_Ale__WLP023',
  WhiteLabsCaliforniaAleVWlp051 = 'White_Labs_California_Ale_V__WLP051',
  WhiteLabsCaliforniaAleWlp001 = 'White_Labs_California_Ale__WLP001',
  WhiteLabsDryEnglishAleWlp007 = 'White_Labs_Dry_English_Ale__WLP007',
  WhiteLabsEastCoastAleWlp008 = 'White_Labs_East_Coast_Ale__WLP008',
  WhiteLabsEdinburghScottishAleWlp028 = 'White_Labs_Edinburgh_Scottish_Ale__WLP028',
  WhiteLabsEnglishAleBlendWlp085 = 'White_Labs_English_Ale_Blend__WLP085',
  WhiteLabsEnglishAleWlp002 = 'White_Labs_English_Ale__WLP002',
  WhiteLabsEssexAleWlp022 = 'White_Labs_Essex_Ale__WLP022',
  WhiteLabsEuropeanAleWlp011 = 'White_Labs_European_Ale__WLP011',
  WhiteLabsFrenchAleWlp072 = 'White_Labs_French_Ale__WLP072',
  WhiteLabsGermanAleKolshWlp029 = 'White_Labs_German_Ale_Kolsh__WLP029',
  WhiteLabsGermanAleWlp003 = 'White_Labs_German_Ale__WLP003',
  WhiteLabsHefeweizenIvAleWlp380 = 'White_Labs_Hefeweizen_IV_Ale__WLP380',
  WhiteLabsIrishAleWlp004 = 'White_Labs_Irish_Ale__WLP004',
  WhiteLabsLondonAleWlp013 = 'White_Labs_London_Ale__WLP013',
  WhiteLabsSanDiegoSuperYeast = 'White_Labs_San_Diego_Super_Yeast',
  Windsor = 'Windsor',
  WindsorAleDryYeast = 'Windsor_Ale_Dry_Yeast',
  WindsorYeast = 'Windsor_Yeast',
  Wyeast_1056AmericanAle = 'Wyeast_1056_American_Ale',
  Wyeast_1737FlyingDogAleYeast = 'Wyeast_1737__Flying_Dog_Ale_Yeast',
  Wyeast_1882ThamesValleyIi = 'Wyeast_1882_Thames_Valley_II',
  Wyeast_2252PcRasenmaherLagerYeast = 'Wyeast_2252__PC_Rasenmaher_Lager_Yeast',
  Wyeast_3725BierDeGardePrivateCollection = 'Wyeast_3725_Bier_De_Garde_Private_Collection',
  Wyeast_3725PcBierDeGardeYeast = 'Wyeast_3725__PC_Bier_de_Garde_Yeast',
  Wyeast_3789PcTrappistBlend = 'Wyeast_3789__PC_Trappist_Blend',
  Wyeast_3864CanadianBelgian = 'Wyeast_3864_Canadian_Belgian',
  Wyeast_4347EauDeVie = 'Wyeast_4347_Eau_de_Vie',
  WyeastAleBlend = 'Wyeast_Ale_Blend',
  WyeastBelgianAle_1214 = 'Wyeast_Belgian_Ale__1214',
  WyeastBelgianLambicBlend_3278 = 'Wyeast_Belgian_Lambic_Blend__3278',
  WyeastBerlinerWeisseBlend_3191 = 'Wyeast_Berliner_Weisse_Blend__3191',
  WyeastBrettanomycesBruxellensis_5112 = 'Wyeast_Brettanomyces_bruxellensis__5112',
  WyeastBrettanomycesClaussenii = 'Wyeast_Brettanomyces_claussenii',
  WyeastBrettanomycesLambicus_5526 = 'Wyeast_Brettanomyces_lambicus__5526',
  WyeastBritishCaskAle_1026 = 'Wyeast_British_Cask_Ale__1026',
  WyeastCider_4766 = 'Wyeast_Cider__4766',
  WyeastDennysFavorite_50 = 'Wyeast_Dennys_Favorite_50',
  WyeastFarmhouse_3726 = 'Wyeast_Farmhouse_3726',
  WyeastFlandersGoldenAle_3739Pc = 'Wyeast_Flanders_Golden_Ale__3739__PC',
  WyeastGfAmericanAleIi_1272 = 'Wyeast_GF_American_Ale_II__1272',
  WyeastGfBavarianLager_2206 = 'Wyeast_GF_Bavarian_Lager__2206',
  WyeastGreenbelt = 'Wyeast_Greenbelt',
  WyeastHeadwatersAle_1000 = 'Wyeast_Headwaters_Ale__1000',
  WyeastHellaBock_2487 = 'Wyeast_Hella_Bock__2487',
  WyeastImperialBlend_9093 = 'Wyeast_Imperial_Blend__9093',
  WyeastKolschIi = 'Wyeast_Kolsch_II',
  WyeastKolschIi_2575 = 'Wyeast_Kolsch_II__2575',
  WyeastKolsch_2565 = 'Wyeast_Kolsch__2565',
  WyeastLactobacills_5335 = 'Wyeast_Lactobacills__5335',
  WyeastLactobacillus_5335 = 'Wyeast_Lactobacillus__5335',
  WyeastLactobacillusDelbruckii_4335 = 'Wyeast_Lactobacillus_delbruckii__4335',
  WyeastLagerBlend = 'Wyeast_Lager_Blend',
  WyeastLeuvenPaleAle_3538 = 'Wyeast_Leuven_Pale_Ale__3538',
  WyeastNbNeoBritannia = 'Wyeast_NB_NeoBritannia',
  WyeastOldAleBlend_9097 = 'Wyeast_Old_Ale_Blend__9097',
  WyeastPacMan = 'Wyeast_PacMan',
  WyeastPediococcus_5733 = 'Wyeast_Pediococcus_5733',
  WyeastWestYorkshireSeasonal_1469 = 'Wyeast_West_Yorkshire__seasonal__1469',
  WyeastWhitbreadAle_1099 = 'Wyeast_Whitbread_Ale__1099',
  WyeastPacMan_1764 = 'Wyeast__Pac_Man__1764',
  WyeastRoguePacman_1764 = 'Wyeast__Rogue_Pacman__1764',
  Yeast = 'Yeast',
  YorkshireSquareAle = 'Yorkshire_Square_Ale',
  Zinfandel = 'Zinfandel',
  ZurichLager = 'Zurich_Lager',
  ZymofermZ039BelgischeKlosterbiereUAles = 'Zymoferm_Z039_Belgische_Klosterbiere_u__Ales',
  ZymofermZ041WeissbierIiiTypBestfrau = 'Zymoferm_Z041_Weissbier_III_Typ_Bestfrau',
  '10thAnniversaryBlend' = '_10th_Anniversary_Blend'
}

export type AddAssignedKeg = {
  kegId: Scalars['ID'];
  ppSubId: Scalars['ID'];
  tapSensorId: Scalars['ID'];
};

export type AddKegOnDeck = {
  kegId: Scalars['ID'];
  tapSensorId: Scalars['ID'];
};

export type AddKegOnTap = {
  kegId: Scalars['ID'];
  tapSensorId: Scalars['ID'];
};

export type AddMessage = {
  barTitle?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<MessageCode>;
  gatewayDeviceId?: InputMaybe<Scalars['ID']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isAutoClearable?: InputMaybe<Scalars['Boolean']>;
  kegId?: InputMaybe<Scalars['String']>;
  lastConnection?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  ppSubId: Scalars['ID'];
  ppSubTitle?: InputMaybe<Scalars['String']>;
  sensorDeviceId?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<MessageSource>;
  tapNumber?: InputMaybe<Scalars['Int']>;
  tapSensorId?: InputMaybe<Scalars['ID']>;
  tapSensorsInfo?: InputMaybe<Array<InputMaybe<InputTapSensorMessageInfo>>>;
  timestamp?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MessageType>;
};

export type ArchiveBrand = {
  /** Brand ID. PK. */
  brandId: Scalars['ID'];
};

export type ArchiveMessage = {
  messageId: Scalars['ID'];
  ppSubId: Scalars['ID'];
};

export type BrandSlugInput = {
  brandId: Scalars['ID'];
  locationId?: InputMaybe<Scalars['ID']>;
  productId?: InputMaybe<Scalars['ID']>;
};

export type CategoryInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CloseLocation = {
  brandId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type CreateBeer = {
  abv?: InputMaybe<Scalars['Float']>;
  allergens?: InputMaybe<Scalars['String']>;
  assets?: InputMaybe<Array<InputMaybe<InputAssetIds>>>;
  brandId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  foodPairingList?: InputMaybe<Array<InputMaybe<InputFoodPairing>>>;
  guid?: InputMaybe<Scalars['String']>;
  hintsOfFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  ibu?: InputMaybe<Scalars['Int']>;
  ingredients?: InputMaybe<Array<InputMaybe<InputIngredient>>>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  originalGravity?: InputMaybe<Scalars['Float']>;
  perceivedBitterness?: InputMaybe<Scalars['Int']>;
  primaryFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  releaseDate?: InputMaybe<Scalars['String']>;
  servingTemperature?: InputMaybe<ServingTemperature>;
  servingVessels?: InputMaybe<Array<InputMaybe<ServingVessel>>>;
  srm?: InputMaybe<Scalars['Int']>;
  styleId: Scalars['ID'];
  tastingExperience?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  traits?: InputMaybe<InputProductTraits>;
};

/** CRUD input for creating a new Brand. */
export type CreateBrand = {
  /** Address information for a Brand. */
  address?: InputMaybe<InputAddress>;
  /** Assets tied to this specific Brand. Contains a list of brandId's and assetId's. */
  assets?: InputMaybe<Array<InputMaybe<InputAssetIds>>>;
  /** Brewers association ID */
  baId?: InputMaybe<Scalars['String']>;
  /** Contact information for a Brand. */
  contact?: InputMaybe<CreateContact>;
  /** General description of the Brand. Limited on the front-end to 800 characters. */
  description?: InputMaybe<Scalars['String']>;
  /** Overrides the title on consumer-facing apps. */
  displayTitle?: InputMaybe<Scalars['String']>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: InputMaybe<Scalars['String']>;
  /** Social media platforms associated with the Brand. */
  socialMedia?: InputMaybe<Array<InputMaybe<InputSocialMedia>>>;
  /** Additional information about a Brand. */
  subtitle?: InputMaybe<Scalars['String']>;
  /** Title of the brand. Used everywhere in public display unless overridden by `displayTitle`. */
  title: Scalars['String'];
  /** Traits of a Brand. inputBrandTraits contains status, brandStatus, brandTypes, brewTypes, isIndepentdent, and isContract. */
  traits?: InputMaybe<InputBrandTraits>;
  /** Website associated with the Brand. */
  website?: InputMaybe<Scalars['String']>;
  /** Year that the Brand was established. */
  yearEstablished?: InputMaybe<Scalars['String']>;
};

export type CreateCategory = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateCider = {
  acidity?: InputMaybe<Array<InputMaybe<Acidity>>>;
  appleVariety?: InputMaybe<Array<InputMaybe<AppleVariety>>>;
  assets?: InputMaybe<Array<InputMaybe<InputAssetIds>>>;
  body?: InputMaybe<Array<InputMaybe<Body>>>;
  brandId: Scalars['ID'];
  brixCount?: InputMaybe<Scalars['Float']>;
  ciderColor?: InputMaybe<CiderColor>;
  ciderFamily?: InputMaybe<Array<InputMaybe<CiderFamily>>>;
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  foodPairingList?: InputMaybe<Array<InputMaybe<InputFoodPairing>>>;
  fruitOrigin?: InputMaybe<Array<InputMaybe<FruitOrigin>>>;
  guid?: InputMaybe<Scalars['String']>;
  hintsOfFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  pearVariety?: InputMaybe<Array<InputMaybe<PearVariety>>>;
  perceivedSweetness?: InputMaybe<Array<InputMaybe<PerceivedSweetness>>>;
  primaryFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  releaseDate?: InputMaybe<Scalars['String']>;
  servingTemperature?: InputMaybe<ServingTemperature>;
  servingVessels?: InputMaybe<Array<InputMaybe<ServingVessel>>>;
  tannin?: InputMaybe<Array<InputMaybe<Tannin>>>;
  title: Scalars['String'];
  traits?: InputMaybe<InputProductTraits>;
};

/** Contact information for creating a user. */
export type CreateContact = {
  /** Email address of user. */
  email?: InputMaybe<Scalars['String']>;
  /** Phone number of a user. */
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateHours = {
  day?: InputMaybe<AbbrevWeekDay>;
  hours?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
};

export type CreateIngredient = {
  title: Scalars['String'];
  type: Scalars['String'];
};

export type CreateKeg = {
  bornOnDate?: InputMaybe<Scalars['String']>;
  brandId: Scalars['ID'];
  cooler?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  coupler?: InputMaybe<Scalars['String']>;
  couplerType?: InputMaybe<CouplerType>;
  currentFluidOz?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED in favor of `servings[n].isDefault`. Sets the default serving size for this keg. */
  defaultServingSize?: InputMaybe<Scalars['Int']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  initialFluidOz?: InputMaybe<Scalars['Float']>;
  isEstimate?: InputMaybe<Scalars['Boolean']>;
  isPartial?: InputMaybe<Scalars['Boolean']>;
  isTemporary?: InputMaybe<Scalars['Boolean']>;
  kegStatus?: InputMaybe<KegStatus>;
  ppSubId: Scalars['ID'];
  productId: Scalars['ID'];
  receivedDate?: InputMaybe<Scalars['String']>;
  /** DEPRECATED in favor of `servings`. Serving sizes, in ounces. */
  servingSizes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Servings sizes and price for this keg. Used for BrewMenu. */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  vesselId: Scalars['ID'];
};

export type CreateKegs = {
  ppSubId: Scalars['ID'];
  unverifiedProduct?: InputMaybe<CreateKegsUnverifiedProduct>;
  verifiedProduct?: InputMaybe<CreateKegsVerifiedProduct>;
};

export type CreateKegsUnverifiedProduct = {
  bornOnDate?: InputMaybe<Scalars['String']>;
  cooler?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  coupler?: InputMaybe<Scalars['String']>;
  couplerType?: InputMaybe<CouplerType>;
  currentFluidOz?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED in favor of `servings[n].isDefault`. Sets the default serving size for this keg. */
  defaultServingSize?: InputMaybe<Scalars['Int']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  initialFluidOz?: InputMaybe<Scalars['Float']>;
  isEstimate?: InputMaybe<Scalars['Boolean']>;
  isPartial?: InputMaybe<Scalars['Boolean']>;
  isTemporary?: InputMaybe<Scalars['Boolean']>;
  kegStatus?: InputMaybe<KegStatus>;
  ppSubId: Scalars['ID'];
  quantity: Scalars['Int'];
  receivedDate?: InputMaybe<Scalars['String']>;
  /** DEPRECATED in favor of `servings`. Serving sizes, in ounces. */
  servingSizes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Servings sizes and price for this keg. Used for BrewMenu. */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  unverifiedProductId: Scalars['ID'];
  vesselId: Scalars['ID'];
};

export type CreateKegsVerifiedProduct = {
  bornOnDate?: InputMaybe<Scalars['String']>;
  brandId: Scalars['ID'];
  cooler?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  coupler?: InputMaybe<Scalars['String']>;
  couplerType?: InputMaybe<CouplerType>;
  currentFluidOz?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED in favor of `servings[n].isDefault`. Sets the default serving size for this keg. */
  defaultServingSize?: InputMaybe<Scalars['Int']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  initialFluidOz?: InputMaybe<Scalars['Float']>;
  isEstimate?: InputMaybe<Scalars['Boolean']>;
  isPartial?: InputMaybe<Scalars['Boolean']>;
  isTemporary?: InputMaybe<Scalars['Boolean']>;
  kegStatus?: InputMaybe<KegStatus>;
  ppSubId: Scalars['ID'];
  /** Optional pricingPresetId to apply to the keg */
  pricingPresetId?: InputMaybe<Scalars['ID']>;
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  receivedDate?: InputMaybe<Scalars['String']>;
  /** DEPRECATED in favor of `servings`. Serving sizes, in ounces. */
  servingSizes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Servings sizes and price for this keg. Used for BrewMenu. */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  vesselId: Scalars['ID'];
};

export type CreateLocation = {
  address?: InputMaybe<InputAddress>;
  assets?: InputMaybe<Array<InputMaybe<InputAssetIds>>>;
  brandId: Scalars['String'];
  contact?: InputMaybe<CreateContact>;
  description?: InputMaybe<Scalars['String']>;
  guid?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Array<InputMaybe<InputHours>>>;
  locationHighlights?: InputMaybe<Array<InputMaybe<InputLocationHighlight>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<TimeZone>;
  title: Scalars['String'];
  traits?: InputMaybe<InputLocationTraits>;
  yearClosed?: InputMaybe<Scalars['String']>;
  yearOpened?: InputMaybe<Scalars['String']>;
};

export type CreateProduct = {
  beerFields?: InputMaybe<InputBeerFields>;
  /** brandId, for creating a normal product. either a brandId or a ppSubId must be provided */
  brandId?: InputMaybe<Scalars['ID']>;
  ciderFields?: InputMaybe<InputCiderFields>;
  cocktailFields?: InputMaybe<InputCocktailFields>;
  coffeeFields?: InputMaybe<InputCoffeeFields>;
  kombuchaFields?: InputMaybe<InputKombuchaFields>;
  meadFields?: InputMaybe<InputMeadFields>;
  nonAlcoholicFields?: InputMaybe<InputNonAlcoholicFields>;
  /** ppSubId, for creating an unverified product. either a brandId or a ppSubId must be provided */
  ppSubId?: InputMaybe<Scalars['ID']>;
  /** Shared input fields for all products */
  productFields: InputProductFields;
  productType: BrewTypes;
  seltzerFields?: InputMaybe<InputSeltzerFields>;
  unverifiedFields?: InputMaybe<InputUnverifiedFields>;
  wineFields?: InputMaybe<InputWineFields>;
};

export type CreateStyle = {
  /** Maximum alcohol percentage. */
  abvMax?: InputMaybe<Scalars['Float']>;
  /** Mimimum alcohol percentage. */
  abvMin?: InputMaybe<Scalars['Float']>;
  /** Grouping of styles. */
  blxGroup?: InputMaybe<Scalars['String']>;
  /** Category of the style. */
  category?: InputMaybe<CreateCategory>;
  /** General description of the style. Limited on the front-end to 800 characters. */
  description?: InputMaybe<Scalars['String']>;
  /** Final Gravity (FG) - Maximum value of the style's Final Gravity. */
  fgMax?: InputMaybe<Scalars['Float']>;
  /** Final Gravity (FG) - Minimum value of the style's Final Gravity. */
  fgMin?: InputMaybe<Scalars['Float']>;
  /** List of food pairings for the style. */
  foodPairingList?: InputMaybe<Array<InputMaybe<InputFoodPairing>>>;
  /** List of hints of flavor notes for the style. */
  hintsOfFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  /** List of hops used in the style. */
  hops?: InputMaybe<Hops>;
  /** International Bitterness Units (IBU) - Maximum measurement of the style's bitterness. */
  ibuMax?: InputMaybe<Scalars['Int']>;
  /** International Bitterness Units (IBU) - Minimum measurement of the style's bitterness. */
  ibuMin?: InputMaybe<Scalars['Int']>;
  /** Keg Freshness (KF) - The freshness duration of a keg. */
  kegFreshnessDuration?: InputMaybe<Scalars['Int']>;
  /** Label of the style. */
  label?: InputMaybe<Scalars['String']>;
  /** List of malts used in the style. */
  malt?: InputMaybe<Malt>;
  /** Original Gravity (OG) - Maximum value of the style's Original Gravity. */
  ogMax?: InputMaybe<Scalars['Float']>;
  /** Original Gravity (OG) - Minimum value of the style's Original Gravity. */
  ogMin?: InputMaybe<Scalars['Float']>;
  /** List of primary flavor notes for the style. */
  primaryFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  /** Serving temperature of the style. */
  servingTemperature?: InputMaybe<ServingTemperature>;
  /** Serving vessels used for the style. */
  servingVessels?: InputMaybe<Array<InputMaybe<ServingVessel>>>;
  /** Slug of the Style. Auto-Generated */
  slug?: InputMaybe<Scalars['String']>;
  /** Standard Research Method (SRM) - Maximum value of the style's measured color. */
  srmMax?: InputMaybe<Scalars['Int']>;
  /** Standard Research Method (SRM) - Minimum value of the style's measured color. */
  srmMin?: InputMaybe<Scalars['Int']>;
  /** Title of the style. */
  title: Scalars['String'];
  /** List of yeasts used in the style. */
  yeast?: InputMaybe<Yeast>;
};

export type CreateTour = {
  cost?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  hours: Array<InputMaybe<InputHours>>;
  mustBeLegalDrinkingAge?: InputMaybe<Scalars['Boolean']>;
  toursAvailable: Scalars['Boolean'];
};

export type CreateVessel = {
  awrSleep?: InputMaybe<Scalars['String']>;
  awrWeight?: InputMaybe<Scalars['String']>;
  couplerWeight?: InputMaybe<Scalars['Float']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  iconSlug?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Material>;
  shortTitle?: InputMaybe<Scalars['String']>;
  suggestedTotalVolume?: InputMaybe<Scalars['Float']>;
  tareWeight?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
  totalVolume?: InputMaybe<Scalars['Float']>;
};

export type DeleteBrand = {
  brandId: Scalars['ID'];
};

export type DeleteGateway = {
  /** ID of Gateway */
  gatewayId: Scalars['ID'];
  /** ID of Performance Platform Subscription */
  ppSubId: Scalars['ID'];
};

export type DeleteKeg = {
  kegId: Scalars['ID'];
  ppSubId: Scalars['ID'];
};

export type DeleteLocation = {
  brandId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type DeletePpSub = {
  ppSubId: Scalars['ID'];
};

export type DeleteProduct = {
  brandId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type DeleteStyle = {
  id: Scalars['ID'];
};

export type DeleteVessel = {
  id: Scalars['ID'];
};

export type FilterBpLs = {
  guid?: InputMaybe<StringFilter>;
};

export type FilterBrands = {
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on city address. */
  address_city?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on state address. */
  address_state?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on street adrress. */
  address_street?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on zip address. */
  address_zip?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on created date. */
  createdDate?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on description. */
  description?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on guid. */
  guid?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on brand status. */
  traits_brandStatus?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on brand types. */
  traits_brandTypes?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` brands functionality, the filter statements can be customized to filter on updated date. */
  updatedDate?: InputMaybe<StringFilter>;
};

export type FilterGetBrewMenuDisplays = {
  /** ID of the Brew Menu Display */
  id?: InputMaybe<StringFilter>;
  /** Slug of the Brew Menu Display */
  slug?: InputMaybe<StringFilter>;
  /** The status of the Brew Menu Display */
  status?: InputMaybe<StringFilter>;
  /** Title of the Brew Menu Display */
  title?: InputMaybe<StringFilter>;
};

export type FilterGetBrewMenuItems = {
  /** Availability of the Menu Item (true/false) */
  availability_available?: InputMaybe<StringFilter>;
  /** ID of Menu Item */
  id?: InputMaybe<StringFilter>;
  /** Status of the Menu Item */
  status?: InputMaybe<StringFilter>;
  /** Menu Item title */
  title?: InputMaybe<StringFilter>;
};

export type FilterGetBrewMenuSubscriptions = {
  /** ID of BmSub */
  id?: InputMaybe<StringFilter>;
  /** Brand ID to which the BMSub is attached */
  slug?: InputMaybe<StringFilter>;
  /** PPSub ID to which the BMSub is attached */
  title?: InputMaybe<StringFilter>;
};

export type FilterGetBrewMenus = {
  /** ID of Brew Menu */
  id?: InputMaybe<StringFilter>;
  /** Status of the Brew Menu */
  status?: InputMaybe<StringFilter>;
  /** Brew Menu title */
  title?: InputMaybe<StringFilter>;
};

export type FilterGetDiscountPresets = {
  /** User that created the DiscountPreset */
  createdBy?: InputMaybe<StringFilter>;
  /** Date DiscountPreset was created */
  createdDate?: InputMaybe<StringFilter>;
  /** Boolean determining if the menu item is available on any date */
  dateTimeActive_anyDate?: InputMaybe<StringFilter>;
  /** Boolean determining if the menu item is available all days and hours */
  dateTimeActive_days?: InputMaybe<StringFilter>;
  /** End date of menu item availability */
  dateTimeActive_endDate?: InputMaybe<StringFilter>;
  /** Start date of menu item availability */
  dateTimeActive_startDate?: InputMaybe<StringFilter>;
  /** Fixed amount of the discount */
  fixedAmount?: InputMaybe<StringFilter>;
  /** Filter by DiscountPresets ID */
  id?: InputMaybe<StringFilter>;
  /** User that last updated the DiscountPreset */
  lastUpdatedBy?: InputMaybe<StringFilter>;
  /** Percent discount */
  percent?: InputMaybe<StringFilter>;
  /** Filter by DiscountPresets Title */
  title?: InputMaybe<StringFilter>;
  /** DiscountPreset logic to apply using the `value`. */
  type?: InputMaybe<StringFilter>;
  /** Date string, in UTC */
  updatedDate?: InputMaybe<StringFilter>;
};

export type FilterLocations = {
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on city. */
  address_city?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on country. */
  address_country?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on postal code. */
  address_postalCode?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on state. */
  address_state?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on street. */
  address_street?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on street2. */
  address_street2?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on brand id. */
  brandObject_id?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on brand types. */
  brandObject_traits_brandTypes?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on description. */
  description?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on guid. */
  guid?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on title. */
  title?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` locations functionality, the filter statements can be customized to filter on traits. */
  traits_type?: InputMaybe<StringFilter>;
};

export type FilterProducts = {
  /** Brew Menu Subscription ID. */
  bmSubId?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` products functionality, the filter statements can be customized to filter on brand id. */
  brandObject_id?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` products functionality, the filter statements can be customized to filter on created date. */
  createdDate?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` products functionality, the filter statements can be customized to filter on description. */
  description?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` products functionality, the filter statements can be customized to filter on guid. */
  guid?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` products functionality, the filter statements can be customized to filter on style title. */
  style_title?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` products functionality, the filter statements can be customized to filter on title. */
  title?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` products functionality, the filter statements can be customized to filter on availability. */
  traits_availability?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` products functionality, the filter statements can be customized to filter on typename. */
  typename?: InputMaybe<StringFilter>;
  /** This is used with the searchBPL `filter` products functionality, the filter statements can be customized to filter on updated date. */
  updatedDate?: InputMaybe<StringFilter>;
};

export type FilterStyles = {
  /** Filter by style ID */
  id?: InputMaybe<StringFilter>;
  /** Filter by style product type */
  productType?: InputMaybe<StringFilter>;
};

export type FilterSuperAdminGetGateways = {
  PerformancePlatformSubscription_id?: InputMaybe<StringFilter>;
  PerformancePlatformSubscription_title?: InputMaybe<StringFilter>;
  connectionStatus?: InputMaybe<StringFilter>;
  digiDeviceId?: InputMaybe<StringFilter>;
  firmware?: InputMaybe<StringFilter>;
  firmwareStatus?: InputMaybe<StringFilter>;
  gatewayDeviceId?: InputMaybe<StringFilter>;
  health?: InputMaybe<StringFilter>;
  ip?: InputMaybe<StringFilter>;
  lastConnect?: InputMaybe<StringFilter>;
  lastDisconnect?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type FilterSuperAdminGetMessages = {
  PerformancePlatformSubscription_id?: InputMaybe<StringFilter>;
  PerformancePlatformSubscription_title?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  isArchived?: InputMaybe<StringFilter>;
  timeResolved?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
};

export type FilterSuperAdminGetOrganizations = {
  /** Filter by Organization ID. */
  id?: InputMaybe<StringFilter>;
  /** Filter by Owner Contact Email. */
  owner_contact_email?: InputMaybe<StringFilter>;
  /** Filter by PP Subscription Title. */
  ppSubscription_title?: InputMaybe<StringFilter>;
};

export type FilterSuperAdminGetTapSensors = {
  /** Filter by PP Subscription ID. */
  PerformancePlatformSubscription_id?: InputMaybe<StringFilter>;
  /** Filter by PP Subscription Title. */
  PerformancePlatformSubscription_title?: InputMaybe<StringFilter>;
  /** Filter by Gateway connection status. */
  connectionStatus?: InputMaybe<StringFilter>;
  /** Filter by Gateway Device ID. */
  gatewayDeviceId?: InputMaybe<StringFilter>;
  /** Filter by Gateway ID. */
  id?: InputMaybe<StringFilter>;
  /** Filter by Sensor Device ID. */
  sensorDeviceId?: InputMaybe<StringFilter>;
  /** Filter by Gateway signal strength. */
  signalStrength?: InputMaybe<StringFilter>;
  /** Filter by Tapsensor status. */
  status?: InputMaybe<StringFilter>;
  /** Filter by Tap Number. */
  title?: InputMaybe<StringFilter>;
  /** Filter by voltage. */
  voltage?: InputMaybe<StringFilter>;
};

export type FilterSuperAdminGetUsers = {
  bdbAccess?: InputMaybe<StringFilter>;
  brewlogixSuperadmin?: InputMaybe<StringFilter>;
  contact_email?: InputMaybe<StringFilter>;
  contact_phone?: InputMaybe<StringFilter>;
  mmbBrands_brandId?: InputMaybe<StringFilter>;
  mmbBrands_roe?: InputMaybe<StringFilter>;
  ppSubscriptions_ppSubId?: InputMaybe<StringFilter>;
  ppSubscriptions_role?: InputMaybe<StringFilter>;
};

export type FloatRangeInput = {
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type GeoBoxSearchInput = {
  geoNEPoint: GeoCoordInput;
  geoSWPoint: GeoCoordInput;
};

export type GeoCoordInput = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  title?: InputMaybe<Scalars['String']>;
};

export type GeoRadiusSearchInput = {
  geoPoint: GeoCoordInput;
  geoRadius: Scalars['Float'];
};

/** DEPRECATED since 1.10.1. FE use [getThroughputByKegId](#query-getThroughputByKegId). No EOL, do not delete as it is used by CS until a suitable replacement is found. */
export type GetKegHistory = {
  kegId: Scalars['ID'];
  ppSubId: Scalars['ID'];
  sortAscending?: InputMaybe<Scalars['Boolean']>;
};

export type InputAddOrRemoveBmSubscriptionAssociation = {
  bmSubId: Scalars['String'];
  role: BrewMenuRole;
  userId: Scalars['String'];
};

export type InputAddOrRemoveBrandAssociation = {
  brandId: Scalars['String'];
  role: MarketMyBreweryRole;
  userId: Scalars['String'];
};

export type InputAddOrRemovePpSubscriptionAssociation = {
  ppSubId: Scalars['String'];
  role: PerformancePlatformRole;
  userId: Scalars['String'];
};

export type InputAddress = {
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
};

export type InputAssetIds = {
  assetId: Scalars['ID'];
  brandId: Scalars['ID'];
};

/** Input with `bmSubId` and `menuSectionId`. */
export type InputBmSubAndSectionId = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /** Menu Section ID. */
  menuSectionId: Scalars['ID'];
};

export type InputBmSubSlug = {
  bmSubId: Scalars['ID'];
};

export type InputBatchDeleteMenuItem = {
  /** UUID. SK for MenuItem. */
  menuItemId: Scalars['ID'];
};

export type InputBeerFields = {
  ibu?: InputMaybe<Scalars['Int']>;
  originalGravity?: InputMaybe<Scalars['Float']>;
  perceivedBitterness?: InputMaybe<Scalars['Int']>;
  srm?: InputMaybe<Scalars['Int']>;
  styleAlias?: InputMaybe<Scalars['String']>;
  styleId?: InputMaybe<Scalars['ID']>;
};

/** Input for `BillingPolicy` type. */
export type InputBillingPolicy = {
  /** Billing address. */
  address?: InputMaybe<InputAddress>;
  /** How often a `PaymentMethod` will be billed. */
  billingCycle?: InputMaybe<BillingCycle>;
  /** Unique ID associated with the payment processing platform for this policy. */
  billingId?: InputMaybe<Scalars['String']>;
  /** Contact information. */
  contact?: InputMaybe<InputContact>;
  /** Last date a payment was recieved. ISO Date string in UTC. */
  lastPaymentDate?: InputMaybe<Scalars['String']>;
  /** Nest date a payment is due. ISO Date string in UTC. */
  nextPaymentDate?: InputMaybe<Scalars['String']>;
  /** Method of payment currently selected for this policy. */
  paymentMethod?: InputMaybe<PaymentMethod>;
};

export type InputBoardItem = {
  /** ID of the Board to update */
  boardId: Scalars['ID'];
  /** Id of Brand, Product, Location, or Route. */
  itemId: Scalars['String'];
  /** Enum that can be a Brand, Product, Location, or Route */
  itemType: BoardItemType;
  /** Optional, but required for adding a Product or Location. In that case should be the Brand Id. */
  parentId?: InputMaybe<Scalars['String']>;
};

export type InputBrandProductAvailability = {
  /** Update all product availability for this Brand to this value (enum) */
  availability: Availability;
  /** The Brand's ID */
  brandId: Scalars['ID'];
};

export type InputBrandSlug = {
  brandId: Scalars['ID'];
  locationId?: InputMaybe<Scalars['ID']>;
  productId?: InputMaybe<Scalars['ID']>;
};

export type InputBrandTraits = {
  brandStatus?: InputMaybe<BrandStatus>;
  brandTypes?: InputMaybe<BrandType>;
  brewTypes?: InputMaybe<Array<InputMaybe<BrewTypes>>>;
  isContract?: InputMaybe<Scalars['Boolean']>;
  isIndependent?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED since 1.19 use 'brandStatus' instead EOL 1.21 */
  status?: InputMaybe<BrandStatus>;
};

export type InputBrandsAssociation = {
  brandId: Scalars['String'];
  role: MarketMyBreweryRole;
};

/** Configurations that control the display and styles of the Brew Menu. */
export type InputBrewMenuDisplaySettings = {
  /** Does this menu paginate. Defaults to `true`. */
  allowPagination: Scalars['Boolean'];
  /** Background color for displays. Defaults to `#FFFFFF`. */
  backgroundColor: Scalars['String'];
  /** Color themes for menu. */
  colorScheme: ColorScheme;
  /** Number of menu columns. */
  columns: DisplayColumns;
  /** Fallback product image type. */
  fallbackImage: ProductImage;
  /** Font for Brew Menu Display. */
  font: DisplayFont;
  /** Brew Menu Display font size. Defaults to `M`. */
  fontSize?: InputMaybe<FontSize>;
  /** Type of media to used on Brew Menu Display. */
  mediaType: MediaType;
  /** Number of menu items per screen. Defaults to 6. */
  numberOfItemsPerScreen?: InputMaybe<Scalars['Int']>;
  /** Menu orientation. */
  orientation: DisplayOrientation;
  /** Primary font color for displays. Defaults to `#CBF17A`. */
  primaryFontColor: Scalars['String'];
  /** Primary product image type. */
  productImage: ProductImage;
  /** Time in seconds between display rotations. */
  rotationInterval: DisplayRotationInterval;
  /** Enforced screen mode for customer facing menus. */
  screenMode: ScreenMode;
  /** Secondary font color for displays. Defaults to `#FFFFFF`. */
  secondaryFontColor: Scalars['String'];
  /** Background color of sections. Defaults to `#000000`. */
  sectionBackgroundColor: Scalars['String'];
  /** Color of sections. Defaults to `#FFFFFF`. */
  sectionFontColor: Scalars['String'];
  /** Font size for the section title. Defaults to 24px. */
  sectionTitleFontSize?: InputMaybe<Scalars['String']>;
  /** Determines if ABV should be displayed. Defaults to `true`. */
  showABV: Scalars['Boolean'];
  /** Display the brewery. Defaults to `true`. */
  showBrewery: Scalars['Boolean'];
  /** Determines if the description should be displayed. Defaults to `true`. */
  showDescription?: InputMaybe<Scalars['Boolean']>;
  /** Determines if IBU should be displayed. Defaults to `true`. */
  showIBU: Scalars['Boolean'];
  /** Display the breweries city and state. Defaults to `true`. */
  showLocation?: InputMaybe<Scalars['Boolean']>;
  /** If the logo will be shown on all menu pages or just the first. Defaults to `true`. */
  showLogoOnAllPages?: InputMaybe<Scalars['Boolean']>;
  /** Determines if On Deck Kegs should be displayed. Defaults to `true`. */
  showOnDeck: Scalars['Boolean'];
  /** Display pricing information. Defaults to `true`. */
  showPricing: Scalars['Boolean'];
  /** Determines if Primary Flavor Notes should be displayed. Defaults to `true`. */
  showPrimaryFlavorNotes: Scalars['Boolean'];
  /** Display the product name. Defaults to `true`. */
  showProductName: Scalars['Boolean'];
  /** Display a sales price, or discounted price. */
  showReducedPrice?: InputMaybe<Scalars['Boolean']>;
  /** Display the SRM value. Defaults to `true`. */
  showSRM: Scalars['Boolean'];
  /** Show menu sectioning. Defaults to `true`. */
  showSection: Scalars['Boolean'];
  /** Determines if Serving Types should be displayed. Defaults to `true`. */
  showServingType?: InputMaybe<Scalars['Boolean']>;
  /** Display size. Defaults to `true`. */
  showSize: Scalars['Boolean'];
  /** Deterimines if Product Style should be displayed. Defaults to `true`. */
  showStyle: Scalars['Boolean'];
  /** Determines if the Tap Number should be displayed. Defaults to `true`. */
  showTapNumber?: InputMaybe<Scalars['Boolean']>;
  /** Determines if Unknown Kegs should be displayed. Defaults to `true`. */
  showUnknownKegs: Scalars['Boolean'];
  /** Tertiary font color for displays. Defaults to `#B5B5B5`. */
  tertiaryFontColor: Scalars['String'];
};

/** DEPRECATED, EOL v1.29. Input for adding or removing a `MenuItem` or `MenuSection` from a `BrewMenu`. */
export type InputBrewMenuItemOrSection = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /** Brew Menu Id. */
  brewMenuId: Scalars['ID'];
  /** Type/id pair for a `MenuItem` or `MenuSection`. */
  menuItemOrSection?: InputMaybe<InputMenuItemOrSection>;
};

export type InputBrewMenuSubscriptionSettings = {
  /** Address of BrewMenuSubscription, if not attached to PPSub or Brand location */
  address?: InputMaybe<InputAddress>;
  /** Contact information for BrewMenuSubscription, if not attached to PPSub or Brand location */
  contact?: InputMaybe<InputContact>;
};

export type InputBreweryRouteGeoCoordInput = {
  address?: InputMaybe<InputAddress>;
  lat: Scalars['Float'];
  location?: InputMaybe<InputLocationIds>;
  lon: Scalars['Float'];
  placeName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Input for any `ByBMSubId` endpoints. */
export type InputByBmSubId = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
};

export type InputCiderFields = {
  acidity?: InputMaybe<Acidity>;
  appleVariety?: InputMaybe<Array<InputMaybe<AppleVariety>>>;
  body?: InputMaybe<Body>;
  brixCount?: InputMaybe<Scalars['Float']>;
  ciderColor?: InputMaybe<CiderColor>;
  ciderFamily?: InputMaybe<CiderFamily>;
  fruitOrigin?: InputMaybe<Array<InputMaybe<FruitOrigin>>>;
  pearVariety?: InputMaybe<Array<InputMaybe<PearVariety>>>;
  perceivedSweetness?: InputMaybe<PerceivedSweetness>;
  tannin?: InputMaybe<Tannin>;
};

export type InputCloudinaryAsset = {
  cloudinaryId?: InputMaybe<Scalars['String']>;
};

export type InputCloudinaryAssetIdAndName = {
  cloudinaryAssetFileName?: InputMaybe<Scalars['String']>;
  cloudinaryAssetId?: InputMaybe<Scalars['String']>;
};

export type InputCocktailFields = {
  alcohol?: InputMaybe<Alcohol>;
  baseSpirit?: InputMaybe<Array<InputMaybe<BaseSpirit>>>;
  body?: InputMaybe<Body>;
  cocktailColor?: InputMaybe<CocktailColor>;
  garnish?: InputMaybe<Array<InputMaybe<Garnish>>>;
  mixer?: InputMaybe<Array<InputMaybe<Mixer>>>;
  perceivedBitterness?: InputMaybe<PerceivedBitterness>;
  perceivedSaltiness?: InputMaybe<PerceivedSaltiness>;
  perceivedSourness?: InputMaybe<PerceivedSourness>;
  perceivedSpiciness?: InputMaybe<PerceivedSpiciness>;
  perceivedSweetness?: InputMaybe<PerceivedSweetness>;
};

export type InputCoffeeFields = {
  placeholder?: InputMaybe<Scalars['String']>;
};

/** Contact information for a user. */
export type InputContact = {
  /** Email address of user. */
  email?: InputMaybe<Scalars['String']>;
  /** Phone number of a user. */
  phone?: InputMaybe<Scalars['String']>;
};

export type InputCreateAccountRequest = {
  additionalData?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  claimedBrand?: InputMaybe<Scalars['ID']>;
  contact: CreateContact;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  newBrandAddress?: InputMaybe<InputAddress>;
  newBrandTitle?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  requestedRole?: InputMaybe<AccountRequestRole>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type InputCreateAsset = {
  /** Alt text of the image, required for accesibility */
  alt?: InputMaybe<Scalars['String']>;
  /** Brand this asset is associated with. Indicates the asset serves as the brand logo */
  brand?: InputMaybe<Scalars['ID']>;
  /** ID of the brand's media library you want to add this asset to. Required. */
  brandId: Scalars['ID'];
  /** File size, in bytes, of the unmodified asset */
  bytes?: InputMaybe<Scalars['Int']>;
  caption?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<AssetCategory>;
  cloudinaryAssetFields?: InputMaybe<InputCloudinaryAsset>;
  /** Height, in pixels, of the full-size asset */
  height?: InputMaybe<Scalars['Int']>;
  /** List of locations the asset is associated with/uses */
  locations?: InputMaybe<Array<InputMaybe<InputLocationIds>>>;
  /** List of products the asset is associated with/uses */
  products?: InputMaybe<Array<InputMaybe<InputProductIds>>>;
  s3AssetFields?: InputMaybe<InputS3Asset>;
  /** indicates whether asset can be viewed by other users */
  shared?: InputMaybe<Scalars['Boolean']>;
  /** organizational tags used to sort/filter assets in media library */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** MIME type of asset */
  type?: InputMaybe<Scalars['String']>;
  /** Width, in pixels, of the full-size asset */
  width?: InputMaybe<Scalars['Int']>;
};

export type InputCreateBoard = {
  /** Description of the board. */
  description?: InputMaybe<Scalars['String']>;
  /** Not required. Assumed False by other Board logic. */
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  /** Title of the Board */
  title: Scalars['String'];
};

export type InputCreateBrewMenu = {
  /** If used provides a date range for which the menu is active to avoid showing inactive menus and help with scheduling. `[startDate,endDate]` Expects null or 2 dates. Date strings, in UTC of when the menu is active. Start and end dates, respectively. */
  activeDates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The id of associated BrewMenuSubscription */
  bmSubId: Scalars['ID'];
  /** BDB location to which the menu is connected, if any. Crucial for populating information about the location such as hours, address, logos, etc. */
  locationId?: InputMaybe<Scalars['ID']>;
  /** PP Subscription to which the menu is connected, if any. Crucial for populating information about the location such as hours, address, logos, etc. */
  ppSubId?: InputMaybe<Scalars['ID']>;
  /** See BrewMenuStatus enum for status options. */
  status: BrewMenuStatus;
  /** Name of the menu. */
  title: Scalars['String'];
};

export type InputCreateBrewMenuDisplay = {
  /** Cloudinary Asset ID used as background watermark. Frontend should manage uploading and fetching this field. */
  backgroundWatermarkAssetId?: InputMaybe<Scalars['String']>;
  /** Associated BrewMenuSubscription Id. */
  bmSubId: Scalars['ID'];
  /** Menu content to display. */
  brewMenuId?: InputMaybe<Scalars['ID']>;
  /** Cloudinary Asset ID. Frontend should manage uploading and fetching this field. */
  cloudinaryAssetId?: InputMaybe<Scalars['String']>;
  /** Overrides the title on consumer-facing apps. */
  displayTitle?: InputMaybe<Scalars['String']>;
  /** The type of the display. Used to control which settings are exposed in the admin and which templates are used to render the menu on the front-end. */
  displayType: DisplayType;
  /** DEPRECATED */
  promotionalImageAssetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** List of Frontend managed Cloudinary Asset IDs and filenames used as promotional images on the display. If null or empty, no associated images. */
  promotionalImageAssets?: InputMaybe<Array<InputMaybe<InputCloudinaryAssetIdAndName>>>;
  /** See type BrewMenuDisplaySettings for more information. */
  settings?: InputMaybe<InputBrewMenuDisplaySettings>;
  /** URL slug for Display. */
  slug?: InputMaybe<Scalars['String']>;
  /** See enum BrewMenuStatus for available options. */
  status: BrewMenuStatus;
  /** Title of the display. Used to generate the slug and for admin purposes. */
  title: Scalars['String'];
};

export type InputCreateBrewMenuProductAvailability = {
  /** Main switch: if this is false the rest of the data is ignored. */
  available: Scalars['Boolean'];
  bottled?: InputMaybe<Scalars['Boolean']>;
  canned?: InputMaybe<Scalars['Boolean']>;
  cask?: InputMaybe<Scalars['Boolean']>;
  crowler?: InputMaybe<Scalars['Boolean']>;
  growler?: InputMaybe<Scalars['Boolean']>;
  nitro?: InputMaybe<Scalars['Boolean']>;
  onTap?: InputMaybe<Scalars['Boolean']>;
  /** Redundant to canned and bottled, but provides a more generic option */
  packaged?: InputMaybe<Scalars['Boolean']>;
};

export type InputCreateBrewMenuSubscription = {
  /** ID of the Organization this Brew Menu Subscription belongs to */
  organizationId: Scalars['ID'];
  /** Input for Brew Menu Subscription settings. */
  settings?: InputMaybe<InputBrewMenuSubscriptionSettings>;
  /** Unique URL-safe identifier; if omitted will be autogenerated based on the title. Serves as the base path for subscription content. e.g. `/[BrewMenuSubscription.slug]/display/1` */
  slug?: InputMaybe<Scalars['String']>;
  /** The stripe checkout ID that paid for this brew menu subscription. Will be validated on submission and will set the status of the brew menu subscription appropriately */
  stripeCheckoutId: Scalars['String'];
  /** Subscription display title. */
  title?: InputMaybe<Scalars['String']>;
};

export type InputCreateBreweryRoute = {
  coordinates?: InputMaybe<Array<InputMaybe<InputBreweryRouteGeoCoordInput>>>;
  description?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['Float']>;
  duration?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  /** routeId provided by MapBox */
  routeId: Scalars['String'];
  title: Scalars['String'];
};

/** Input for creating a new Brew Menu Custom Product. */
export type InputCreateCustomProduct = {
  /** Beer specfic attributes. */
  beerFields?: InputMaybe<InputBeerFields>;
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /** Cider specific attributes. */
  ciderFields?: InputMaybe<InputCiderFields>;
  /** Cocktail specific attributes. */
  cocktailFields?: InputMaybe<InputCocktailFields>;
  /** Coffee specific attributes. */
  coffeeFields?: InputMaybe<InputCoffeeFields>;
  /** Description of the BrewMenuCustomProduct. */
  description?: InputMaybe<Scalars['String']>;
  /** Kombucha specific attributes. */
  kombuchaFields?: InputMaybe<InputKombuchaFields>;
  /** Mead specific attributes. */
  meadFields?: InputMaybe<InputMeadFields>;
  /** Nonalcoholic specific attributes. */
  nonAlcoholicFields?: InputMaybe<InputNonAlcoholicFields>;
  /** Input for generic Product attributes. */
  productFields?: InputMaybe<InputProductFields>;
  /** See enum CustomProductType for available options. */
  productType: CustomProductType;
  /** Seltzer specific attributes. */
  seltzerFields?: InputMaybe<InputSeltzerFields>;
  /** List of available serving sizes and prices for this product. */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  /** Additional information about a BrewMenuCustomProduct. */
  subtitle?: InputMaybe<Scalars['String']>;
  /** Name of the BrewMenuCustomProduct. */
  title: Scalars['String'];
  /** Unverified specific attributes. */
  unverifiedFields?: InputMaybe<InputUnverifiedFields>;
  /** Wine specific attributes. */
  wineFields?: InputMaybe<InputWineFields>;
};

/** Input for creating a new Discount Preset. */
export type InputCreateDiscountPreset = {
  /** ID of the Brew Menu Subscription */
  bmSubId: Scalars['ID'];
  /** Dates, hours and days of the week the discount is available */
  dateTimeActive?: InputMaybe<InputDateAvailability>;
  /** Fixed amount of the discount */
  fixedAmount?: InputMaybe<Scalars['Float']>;
  /** Percent discount */
  percent?: InputMaybe<Scalars['Float']>;
  /** Pour sizes that this discount will apply to if added to a Menu Item. */
  pourSizes?: InputMaybe<Array<InputMaybe<PourSize>>>;
  /** See enum CustomProductType for available options. */
  productType?: InputMaybe<CustomProductType>;
  /** Title of the Discount */
  title: Scalars['String'];
  /** Discount logic to apply using the `value`. */
  type?: InputMaybe<DiscountPresetType>;
};

export type InputCreateInventoryRecord = {
  description?: InputMaybe<Scalars['String']>;
  /** Estimated time the incoming devices will arrive. */
  incomingEta?: InputMaybe<Scalars['String']>;
  inventoryType?: InputMaybe<InventoryType>;
  partNumber?: InputMaybe<Scalars['String']>;
  /** Number of devices on order */
  quantityIncoming?: InputMaybe<Scalars['Int']>;
  /** Number of devices available in the warehouse */
  quantityOnHand?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<InventoryStatus>;
  /** Quantity level used to determine reorder status */
  statusThreshold?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type InputCreateMenuItem = {
  /** [Form factors](#definition-BrewMenuProductAvailability) in which the product is offered. */
  availability: InputCreateBrewMenuProductAvailability;
  /** Associated BrewMenuSub Id. */
  bmSubId: Scalars['ID'];
  /** Go-to serving vessel for the menu item. May be overridden if `BrewMenuProductAvailability` does not include `OnTap`  */
  glassware?: InputMaybe<ServingVessel>;
  /** Id of Product, CustomProduct, or TapSensor. */
  itemId: Scalars['ID'];
  /** One of Product, CustomProduct, or TapSensor */
  menuItemType: MenuItemType;
  /** Id of Brand, BMSub (same as bmSubId), or PPSub. */
  parentId: Scalars['ID'];
  /** List of `MenuItemTags` assoicated with this `MenuItem`. */
  tags?: InputMaybe<Array<InputMaybe<InputMenuItemTag>>>;
};

/** Input for `createMenuSection` endpoint. */
export type InputCreateMenuSection = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /**
   * List of `BrewMenuDisplay` IDs that should hide this section.
   * - Omit/null = []
   */
  hiddenOnDisplays?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Ordered list of `MenuItem` IDs for this section. */
  menuItemIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Title of the section. */
  title?: InputMaybe<Scalars['String']>;
};

export type InputCreateOrganizationAsset = {
  /** Cloudinary ID */
  cloudinaryId: Scalars['String'];
  /** Organization ID */
  organizationId: Scalars['ID'];
  /** Do we need this field right now? */
  shared?: InputMaybe<Scalars['Boolean']>;
  /** Tags added/edited by users for this asset */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InputCreateOrganizationForSelf = {
  /** Address of the org. e.g. the main office */
  address?: InputMaybe<InputAddress>;
  /** General contact info for the org. e.g. customer support or main office */
  contact?: InputMaybe<InputContact>;
  /** Optional - Will default to 'New Organization' */
  title: Scalars['String'];
};

export type InputCreateOrganizationGroup = {
  /** Brew Menu Sub ID. */
  bmSubId?: InputMaybe<Scalars['ID']>;
  /** Brand ID. */
  brandId?: InputMaybe<Scalars['ID']>;
  /** Frontend managed Cloudinary Asset ID used as this group image. If null, no associated logo. Use this field until the media library is in place. */
  cloudinaryAssetId?: InputMaybe<Scalars['String']>;
  /** Organization ID. */
  organizationId: Scalars['ID'];
  /** Performance Platform Sub ID. */
  ppSubId?: InputMaybe<Scalars['ID']>;
  /** Group title. */
  title: Scalars['String'];
};

/** Input for `createOrganizationPricingPreset` endpoint. */
export type InputCreateOrganizationPricingPreset = {
  /** Indicates if the PricingPreset is the default */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** ID of the Organization */
  organizationId: Scalars['ID'];
  /** List of ServingSize objects */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  /** Title of the PricingPreset */
  title?: InputMaybe<Scalars['String']>;
};

/**
 * Input for request for a PerformancePlatform Subscription.
 * Using `Strings` for fields since these come from a web form with direct user input.
 */
export type InputCreatePerformancePlatformSubscriptionRequest = {
  /** Potential title for the PerformancePlatform provided by the user. */
  locationName?: InputMaybe<Scalars['String']>;
  /** Optional message provided by the user. */
  message?: InputMaybe<Scalars['String']>;
  /** User's provided number of taps for the location. */
  numberOfTaps?: InputMaybe<Scalars['String']>;
  /** Organization Id of user who submitted the request. */
  organizationId: Scalars['ID'];
  /** User's provided seating capacity for the location. */
  seatingCapacity?: InputMaybe<Scalars['String']>;
};

/**
 * Input for `createPricingPreset` endpoint.
 * @DEPRECATED - Use `inputCreateOrganizationPricingPreset`
 */
export type InputCreatePricingPreset = {
  /** ID of the Brew Menu Subscription */
  bmSubId?: InputMaybe<Scalars['ID']>;
  /** Indicates if the PricingPreset is the default */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** ID of the PricingPreset */
  ppSubId?: InputMaybe<Scalars['ID']>;
  /** List of ServingSize objects */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  /** Title of the PricingPreset */
  title?: InputMaybe<Scalars['String']>;
};

export type InputCreateReport = {
  /** Latest date of the report data. */
  endDate: Scalars['String'];
  /** Required for matching the upload files to the DB record. */
  filenames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Performance Platform Subscription ID. */
  ppSubId: Scalars['ID'];
  /** Earliest date of the report data. */
  startDate: Scalars['String'];
  /** Title of the report. */
  title: Scalars['String'];
};

/** Input for creating a DateAvailability object. */
export type InputDateAvailability = {
  /** Boolean determining if the menu item is available on any date */
  anyDate?: InputMaybe<Scalars['Boolean']>;
  /** Boolean determining if the menu item is available all days and hours */
  days?: InputMaybe<Scalars['Boolean']>;
  /** End date of menu item availability */
  endDate?: InputMaybe<Scalars['String']>;
  /** Days and hours of the week the menu item is available */
  hoursAvailable?: InputMaybe<Array<InputMaybe<InputHours>>>;
  /** Start date of menu item availability */
  startDate?: InputMaybe<Scalars['String']>;
};

export type InputDateRange = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

/** Empty input for deleting the account you are currently logged in with. */
export type InputDeleteAuthenticatedUser = {
  id?: InputMaybe<Scalars['String']>;
};

/** Input for `deleteMenuItems` endpoint. */
export type InputDeleteBmMenuItems = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /** List of `MenuItem` IDs. */
  menuItemIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** Input for `deleteMenuSections` endpoint. */
export type InputDeleteBmMenuSections = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /** List of `MenuSection` IDs. */
  sectionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type InputDeleteBoard = {
  /** ID of the Board to delete */
  boardId: Scalars['ID'];
};

export type InputDeleteBrewMenu = {
  /** id of associated BrewMenuSubscription. */
  bmSubId: Scalars['ID'];
  /** id of BrewMenu to delete. */
  brewMenuId: Scalars['ID'];
};

export type InputDeleteBrewMenuDisplay = {
  /** Associated BrewMenuSubscription Id. */
  bmSubId: Scalars['ID'];
  /** BrewMenuDisplayId. */
  brewMenuDisplayId: Scalars['ID'];
};

export type InputDeleteBreweryRoute = {
  id: Scalars['ID'];
};

export type InputDeleteCustomProduct = {
  /** UUID. SK of BrewMenuCustomProduct. */
  bmCustomProductId: Scalars['ID'];
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
};

/** Input object for deleting a brew menu discount. */
export type InputDeleteDiscountPreset = {
  /** ID of the Brew Menu Subscription */
  bmSubId: Scalars['ID'];
  /** ID of the DiscountPreset */
  discountPresetId: Scalars['ID'];
};

export type InputDeleteFavorite = {
  /** PK of a Brand. */
  brandId: Scalars['String'];
  /** Enum that can be a Brand, Product, or Location */
  favoriteType: FavoriteType;
  /** SK of a Location or Product */
  itemId?: InputMaybe<Scalars['String']>;
};

export type InputDeleteInventoryRecord = {
  /** ID of inventory record to delete */
  inventoryRecordId: Scalars['ID'];
};

/** DEPRECATED, EOL v1.28, new enpoint uses a different input. */
export type InputDeleteMenuItem = {
  /** Associated BrewMenuSub Id. */
  bmSubId: Scalars['ID'];
  /** MenuItem Id. */
  menuItemId: Scalars['ID'];
};

/**
 * Input for deleting multiple MenuItems at once.
 * DEPRECATED, EOL v1.28, new endpoint uses a different input.
 */
export type InputDeleteMenuItems = {
  /** Associated BrewMenuSubscription Id. */
  bmSubId: Scalars['ID'];
  /** Array of menu item IDs to be updated */
  menuItems?: InputMaybe<Array<InputMaybe<InputBatchDeleteMenuItem>>>;
};

/** DEPRECATED, EOL v1.29. Input for `deleteMenuItemsOrSections` endpoint. */
export type InputDeleteMenuItemsOrSections = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /** List of type/id pairs for `MenuItems` or `MenuSections` that you want to delete. */
  menuItemsOrSections?: InputMaybe<Array<InputMaybe<InputMenuItemOrSection>>>;
};

export type InputDeleteOrganizationGroup = {
  /** Organization Group ID. */
  organizationGroupId: Scalars['ID'];
  /** Organization ID. */
  organizationId: Scalars['ID'];
};

/** Input for `deleteOrganizationPricingPreset` endpoint. */
export type InputDeleteOrganizationPricingPreset = {
  /** ID of the Organization */
  organizationId: Scalars['ID'];
  /** ID of the PrcingPreset */
  pricingPresetId: Scalars['ID'];
};

/**
 * Input for `deletePricingPreset` endpoint.
 * @DEPRECATED - Use `inputDeleteOrganizationPricingPreset`
 */
export type InputDeletePricingPreset = {
  /** ID of the Brew Menu Subscription */
  bmSubId?: InputMaybe<Scalars['ID']>;
  /** ID of the PrcingPreset */
  itemId: Scalars['ID'];
  /** ID of the PricingPreset */
  ppSubId?: InputMaybe<Scalars['ID']>;
};

/**
 * Empty input used to prevent non-input needing endpoints from breaking Vercel builds.
 * A single optional field is required to prevent schema errors.
 */
export type InputEmpty = {
  dummy?: InputMaybe<Scalars['String']>;
};

export type InputFavorite = {
  /** PK of a Brand. */
  brandId: Scalars['String'];
  /** Enum that can be a Brand, Product, or Location */
  favoriteType: FavoriteType;
  /** Boolean to toggle mark item as favorited. Not currently in use. */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** SK of a Location or Product */
  itemId?: InputMaybe<Scalars['String']>;
  /** Note about favorite logged by user. */
  notes?: InputMaybe<Scalars['String']>;
};

/** Object to store ddb keys for Brand. */
export type InputFavoriteBrand = {
  /** Auto-generated Id. PK of a Brand. */
  brandId: Scalars['String'];
  /** Notes about favorite logged by user. */
  notes?: InputMaybe<Scalars['String']>;
};

/** Object to store ddb keys for Location. */
export type InputFavoriteLocation = {
  /** Auto-generated Id. PK of a Location. */
  brandId: Scalars['String'];
  /** Auto-generated Id. SK of a Location */
  locationId: Scalars['String'];
  /** Notes about favorite logged by user. */
  notes?: InputMaybe<Scalars['String']>;
};

/** Object to store ddb keys for Product. */
export type InputFavoriteProduct = {
  /** Auto-generated Id. PK of a Product. */
  brandId: Scalars['String'];
  /** Notes about favorite logged by user. */
  notes?: InputMaybe<Scalars['String']>;
  /** Auto-generated Id. SK of a Product. */
  productId: Scalars['String'];
};

export type InputFavorites = {
  /** Deprecated as of 1.12.0. */
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** List of brands saved by User. */
  favoriteBrands?: InputMaybe<Array<InputMaybe<InputFavoriteBrand>>>;
  /** List of locations saved by User. */
  favoriteLocations?: InputMaybe<Array<InputMaybe<InputFavoriteLocation>>>;
  /** List of products saved by User. */
  favoriteProducts?: InputMaybe<Array<InputMaybe<InputFavoriteProduct>>>;
  /** Deprecated as of 1.12.0. */
  locations?: InputMaybe<Array<InputMaybe<InputLocationIds>>>;
  /** Deprecated as of 1.12.0. */
  products?: InputMaybe<Array<InputMaybe<InputProductIds>>>;
};

export type InputFoodPairing = {
  category?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InputGeoPolygonSearch = {
  geoPoints?: InputMaybe<Array<GeoCoordInput>>;
  geoRadius?: InputMaybe<Scalars['Float']>;
};

export type InputGetAll = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type InputGetAssetById = {
  assetId: Scalars['ID'];
  brandId: Scalars['ID'];
};

/** Input for `getBrewMenuCustomProductById` endpoint. */
export type InputGetBrewMenuCustomProductById = {
  /** Brew Menu Custom Product ID. */
  bmCustomProductId: Scalars['ID'];
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
};

export type InputGetBrewMenuDisplays = {
  /** Search BrewMenuSubscriptions by this keyword. Includes title, id, slug. */
  filters?: InputMaybe<FilterGetBrewMenuDisplays>;
  /** Max number of results to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** Token for paginating results */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Sort results by ASC or DESC */
  order?: InputMaybe<Order>;
  /** The field to order the results by */
  orderBy?: InputMaybe<Scalars['String']>;
};

export type InputGetBrewMenuItemById = {
  /** Id of the BrewMenuSubscription */
  bmSubId: Scalars['ID'];
  /** Id of the MenuItem */
  menuItemId: Scalars['ID'];
  /** DEPRECATED - EOL v1.28.0. Type of MenuItem: Product, CustomProduct, or TapSensor. */
  menuItemType?: InputMaybe<MenuItemType>;
};

export type InputGetBrewMenuItems = {
  /** Search BrewMenuItems by this keyword. Includes title, id, slug. */
  filters?: InputMaybe<FilterGetBrewMenuItems>;
  /** Max number of results to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** Token for paginating results */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Sort results by ASC or DESC */
  order?: InputMaybe<Order>;
  /** The field to order the results by */
  orderBy?: InputMaybe<Scalars['String']>;
};

/**
 * For querying BMSubs by one of the following values.
 * Only one field is needed to query for a BrewMenuSubscription.
 * If multiple fields are provided, the order in which they are used
 * is id, ppSubId, and finally brandId.
 */
export type InputGetBrewMenuSubscriptionById = {
  /** ID of BmSub */
  bmSubId?: InputMaybe<Scalars['ID']>;
  /** Brand ID to which the BMSub is attached */
  brandId?: InputMaybe<Scalars['ID']>;
  /** Deprecated in place of bmSubID. ID of BmSub */
  id?: InputMaybe<Scalars['ID']>;
  /** PPSub ID to which the BMSub is attached */
  ppSubId?: InputMaybe<Scalars['ID']>;
};

export type InputGetBrewMenuSubscriptions = {
  /** Search BrewMenuSubscriptions by this keyword. Includes title, id, slug. */
  filters?: InputMaybe<FilterGetBrewMenuSubscriptions>;
  /** Max number of results to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** Token for paginating results */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Sort results by ASC or DESC */
  order?: InputMaybe<Order>;
  /** The field to order the results by */
  orderBy?: InputMaybe<Scalars['String']>;
};

export type InputGetBrewMenus = {
  /** Search BrewMenus by this keyword. Includes title, id, slug. */
  filters?: InputMaybe<FilterGetBrewMenus>;
  /** Max number of results to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** Token for paginating results */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Sort results by ASC or DESC */
  order?: InputMaybe<Order>;
  /** The field to order the results by */
  orderBy?: InputMaybe<Scalars['String']>;
};

/** Generic input for Queries. */
export type InputGetById = {
  /** (Sort Key) Id of Type being queried for i.e. locationId for Location */
  itemId: Scalars['ID'];
  /** (Optional) Items per page if more than one Item is returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** (Optional) Token for next page */
  nextToken?: InputMaybe<Scalars['String']>;
  /** (Primary Key) i.e. brandId if querying for Location or Product */
  parentId?: InputMaybe<Scalars['ID']>;
};

export type InputGetByOrganizationMediaLibrary = {
  /** Limit on number of objects to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** Optional Pagination Token */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Organization ID */
  organizationId: Scalars['ID'];
};

export type InputGetByPpSubId = {
  /**  sk: Id of Item being queiried i.e. gatewayId */
  itemId?: InputMaybe<Scalars['ID']>;
  /**  (Optional) Items per page if more than one Item is returned */
  limit?: InputMaybe<Scalars['Int']>;
  /**  (Optional) Token for next page */
  nextToken?: InputMaybe<Scalars['String']>;
  /**  pk: Performance Platform Subscription Id */
  ppSubId: Scalars['ID'];
};

/** Generic input for getting items by one or more slugs. */
export type InputGetBySlug = {
  /** Item slug is always required. */
  itemSlug: Scalars['String'];
  /** (Optional) Items per page if more than one Item is returned. */
  limit?: InputMaybe<Scalars['Int']>;
  /** (Optional) Token for next page. */
  nextToken?: InputMaybe<Scalars['String']>;
  /** (Optional) Parent slug is only needed in cases where multiple slugs are required for item retrieval. */
  parentSlug?: InputMaybe<Scalars['String']>;
};

/** Input object for getting Discounts for a specific BrewMenuSubscription. */
export type InputGetDiscountPresets = {
  /** ID of the BrewMenuSubscription */
  bmSubId: Scalars['ID'];
  /** Filter for searching DiscountPresets */
  filters?: InputMaybe<FilterGetDiscountPresets>;
  /** Max number of results to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** Token for paginating results */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Sort results by ASC or DESC */
  order?: InputMaybe<Order>;
  /** The field to order the results by */
  orderBy?: InputMaybe<Scalars['String']>;
};

/**
 * inputGetKegDepletionPaceByWeek
 *
 * kegId input is required to query the datalake `get_keg_depletion_pace_week` query.
 */
export type InputGetKegDepletionPaceByWeek = {
  /** Unique Keg Id */
  kegId: Scalars['ID'];
};

export type InputGetKegDepletionStats = {
  /** Unique Id for each keg */
  kegId: Scalars['ID'];
  /** Performance Platform Subscription Id */
  ppSubId: Scalars['ID'];
};

/** Input allows a single KegStatus, or no status if you want Kegs of any status. DDB Query. */
export type InputGetKegsByStatus = {
  /** Keg status you want to query for. Omit to retrieve any keg. */
  kegStatus?: InputMaybe<KegStatus>;
  /** Limit the number of results you want returned. Defaults to 1000. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Pagination token if additional results are available beyond the limit. */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Performance Platform Subscription to find Kegs on. */
  ppSubId: Scalars['ID'];
};

export type InputGetLocationProducts = {
  available?: InputMaybe<Scalars['Boolean']>;
  brandId: Scalars['ID'];
  canned?: InputMaybe<Scalars['Boolean']>;
  growler?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  locationId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  onTap?: InputMaybe<Scalars['Boolean']>;
  onlyBrand?: InputMaybe<Scalars['Boolean']>;
};

export type InputGetMediaLibrary = {
  assetCategory?: InputMaybe<AssetCategory>;
  brandId: Scalars['ID'];
  createdBy?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  shared?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InputGetMessagesByPpSubId = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  ppSubId: Scalars['ID'];
  sortAscending?: InputMaybe<Scalars['Boolean']>;
};

export type InputGetOrganizationById = {
  /** Organization ID */
  organizationId: Scalars['String'];
};

export type InputGetOrganizationGroups = {
  /** Organization ID. */
  organizationId: Scalars['ID'];
};

/** Input for `getOrganizationPricingPresetById` endpoint. */
export type InputGetOrganizationPricingPresetById = {
  /** ID of the Organization */
  organizationId: Scalars['ID'];
  /** ID of the PrcingPreset */
  pricingPresetId: Scalars['ID'];
};

/** Input for `getPricingPresets` endpoint. */
export type InputGetOrganizationPricingPresets = {
  /** (Optional) Items per page if more than one Item is returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** (Optional) Token for next page */
  nextToken?: InputMaybe<Scalars['String']>;
  /** ID of the Organization */
  organizationId: Scalars['ID'];
};

/** Input for `getPreviouslyReceivedKegs` endpoint. Does not support `limit` due to results filtering. */
export type InputGetPreviouslyReceivedKegs = {
  /** (Optional) Token for next page of results. */
  nextToken?: InputMaybe<Scalars['String']>;
  /** ID of the Performance Platform Subscription. */
  ppSubId: Scalars['ID'];
  /** Number of days to look back for received kegs (MAX: 365, Min: 1, Default: 90). */
  receivedInLastXDays?: InputMaybe<Scalars['Int']>;
};

/**
 * Input for `getPricingPresetById` endpoint.
 * @DEPRECATED - Use `inputGetOrganizationPricingPresetById`
 */
export type InputGetPricingPresetById = {
  /**  pk: Brew Menu Subscription Id */
  bmSubId?: InputMaybe<Scalars['ID']>;
  /**  the actual item id  */
  itemId: Scalars['ID'];
  /**  pk: Performance Platform Subscription Id */
  ppSubId?: InputMaybe<Scalars['ID']>;
};

/**
 * Input for `getPricingPresets` endpoint.
 * @DEPRECATED - Use `inputGetOrganizationPricingPresets`
 */
export type InputGetPricingPresets = {
  /**  pk: Brew Menu Subscription Id */
  bmSubId?: InputMaybe<Scalars['ID']>;
  /**  (Optional) Items per page if more than one Item is returned */
  limit?: InputMaybe<Scalars['Int']>;
  /**  (Optional) Token for next page */
  nextToken?: InputMaybe<Scalars['String']>;
  /**  pk: Performance Platform Subscription Id */
  ppSubId?: InputMaybe<Scalars['ID']>;
};

export type InputGetProductLocations = {
  available?: InputMaybe<Scalars['Boolean']>;
  brandId: Scalars['ID'];
  canned?: InputMaybe<Scalars['Boolean']>;
  growler?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  onTap?: InputMaybe<Scalars['Boolean']>;
  onlyBrand?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['ID'];
};

export type InputGetReport = {
  /** Performance Platform Subscription ID. */
  ppSubId: Scalars['ID'];
  /** Report ID. */
  reportId: Scalars['ID'];
};

export type InputGetReports = {
  /** (Optional) Token for next page of results. */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Performance Platform Subscription ID. */
  ppSubId: Scalars['ID'];
  /** Status of the report. Omit to get reports regardless of status. */
  status?: InputMaybe<VarianceReportStatus>;
};

export type InputGetStyles = {
  /** Filter for searching Styles */
  filters?: InputMaybe<FilterStyles>;
  /** Max number of results to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** Token for paginating results */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Sort results by ASC or DESC */
  order?: InputMaybe<Order>;
  /** The field to order the results by */
  orderBy?: InputMaybe<Scalars['String']>;
};

export type InputGetThroughputByBrand = {
  /**
   * Date range, start date and end date for input. Time strings must include a timezone offset.
   * Additionally, to include a full 24 hours (single day) it should be formatted like the following:
   * `{"startDate": "2023-08-08T00:00:00.000-05:00", "endDate": "2023-08-08T23:59:59.000-05:00"}`
   */
  dateRange: InputDateRange;
  /** Performance Platform Subscription Id */
  ppSubId: Scalars['ID'];
  /** Sorting the results by True (ascending) or False (descending) */
  sortAscending: Scalars['Boolean'];
  /** DEPRECATED since 1.38.0. EOL 1.43.0. */
  timeZone?: InputMaybe<TimeZone>;
};

export type InputGetThroughputByKegId = {
  /**
   * Date range, start date and end date for input. Time strings must include a timezone offset.
   * Additionally, to include a full 24 hours (single day) it should be formatted like the following:
   * `{"startDate": "2023-08-08T00:00:00.000-05:00", "endDate": "2023-08-08T23:59:59.000-05:00"}`
   */
  dateRange: InputDateRange;
  /** Unique Keg Id */
  kegId: Scalars['ID'];
  /** Performance Platform Subscription Id */
  ppSubId: Scalars['ID'];
  /** Sorting the results by True (ascending) or False (descending) */
  sortAscending: Scalars['Boolean'];
  /** Time frame can be Day or Hour */
  timeFrame: TimeFrame;
  /** DEPRECATED since 1.38.0. EOL 1.43.0. */
  timeZone?: InputMaybe<TimeZone>;
};

export type InputGetThroughputByPpSub = {
  /**
   * Date range, start date and end date for input. Time strings must include a timezone offset.
   * Additionally, to include a full 24 hours (single day) it should be formatted like the following:
   * `{"startDate": "2023-08-08T00:00:00.000-05:00", "endDate": "2023-08-08T23:59:59.000-05:00"}`
   */
  dateRange: InputDateRange;
  /** Performance Platform Subscription Id */
  ppSubId: Scalars['ID'];
  /** Sorting the results by True (ascending) or False (descending) */
  sortAscending: Scalars['Boolean'];
  /** Time frame can be Day or Hour */
  timeFrame: TimeFrame;
  /** DEPRECATED since 1.38.0. EOL 1.43.0. */
  timeZone?: InputMaybe<TimeZone>;
};

export type InputGetThroughputByProduct = {
  /**
   * Date range, start date and end date for input. Time strings must include a timezone offset.
   * Additionally, to include a full 24 hours (single day) it should be formatted like the following:
   * `{"startDate": "2023-08-08T00:00:00.000-05:00", "endDate": "2023-08-08T23:59:59.000-05:00"}`
   */
  dateRange: InputDateRange;
  /** Performance Platform Subscription Id */
  ppSubId: Scalars['ID'];
  /** Sorting the results by True (ascending) or False (descending) */
  sortAscending: Scalars['Boolean'];
  /** Time frame can be Day, Month, or Year */
  timeFrame: TimeFrame;
  /** DEPRECATED since 1.38.0. EOL 1.43.0. */
  timeZone?: InputMaybe<TimeZone>;
};

export type InputGetTotalProductThroughput = {
  /**
   * Date range, start date and end date for input. Time strings must include a timezone offset.
   * Additionally, to include a full 24 hours (single day) it should be formatted like the following:
   * `{"startDate": "2023-08-08T00:00:00.000-05:00", "endDate": "2023-08-08T23:59:59.000-05:00"}`
   */
  dateRange: InputDateRange;
  /** Performance Platform Subscription Id */
  ppSubId: Scalars['ID'];
};

export type InputHours = {
  day?: InputMaybe<AbbrevWeekDay>;
  hours?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
};

export type InputIngredient = {
  title: Scalars['String'];
  type: IngredientType;
};

export type InputInvalidSensorReading = {
  /** ID of the Device. */
  deviceId: Scalars['String'];
  /** ID of the Gateway Device. */
  gatewayDeviceId: Scalars['String'];
  /** ID of the Keg. */
  kegId: Scalars['String'];
  /** ID of the Performance Platform Subscription. */
  ppSubId: Scalars['String'];
  /** Reading value. */
  reading: Scalars['Int'];
  /** ID of the Tap Sensor. */
  tapId: Scalars['String'];
  /** Timestamp for the given reading. */
  timestamp: Scalars['Int'];
};

export type InputKegTappedEvent = {
  /** ID of the Device. */
  deviceId: Scalars['String'];
  /** ID of the Keg. */
  kegId: Scalars['String'];
  /** ID of the Performance Platform Subscription. */
  ppSubId: Scalars['String'];
  /** ID of the Tap Sensor. */
  tapId: Scalars['String'];
  /** Timestamp for the given reading. */
  timestamp: Scalars['Int'];
};

export type InputKombuchaFields = {
  acidity?: InputMaybe<Acidity>;
  appearance?: InputMaybe<Appearance>;
  body?: InputMaybe<Body>;
  brixCount?: InputMaybe<Scalars['Float']>;
  carbonation?: InputMaybe<Carbonation>;
  kombuchaFamily?: InputMaybe<KombuchaFamily>;
  perceivedSweetness?: InputMaybe<PerceivedSweetness>;
  probiotics?: InputMaybe<Scalars['String']>;
  sourness?: InputMaybe<Sourness>;
  teaCharacter?: InputMaybe<TeaCharacter>;
};

export type InputLocationHighlight = {
  customHeadline?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<LocationHighlightHeadline>;
};

export type InputLocationHours = {
  currentlyOpen?: InputMaybe<Scalars['Boolean']>;
};

/** Object to store ddb keys for Location. */
export type InputLocationIds = {
  brandId: Scalars['String'];
  locationId: Scalars['String'];
};

export type InputLocationProductRelationship = {
  brandId: Scalars['ID'];
  locationId: Scalars['ID'];
  products: Array<InputMaybe<InputProductRelationship>>;
};

export type InputLocationTraits = {
  brewOptions?: InputMaybe<Array<InputMaybe<BrewOption>>>;
  draftTaps?: InputMaybe<Scalars['Int']>;
  educationalActivity?: InputMaybe<Array<InputMaybe<EducationalActivity>>>;
  entertainmentActivity?: InputMaybe<Array<InputMaybe<EntertainmentActivity>>>;
  foodOptions?: InputMaybe<Array<InputMaybe<FoodOption>>>;
  gameActivity?: InputMaybe<Array<InputMaybe<GameActivity>>>;
  isOpenToPublic?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isProductionFacility?: InputMaybe<Scalars['Boolean']>;
  miscActivity?: InputMaybe<Array<InputMaybe<MiscActivity>>>;
  nearbyAttractions?: InputMaybe<Array<InputMaybe<InputNearbyAttraction>>>;
  parkingOptions?: InputMaybe<Array<InputMaybe<ParkingOptions>>>;
  patronOptions?: InputMaybe<Array<InputMaybe<PatronOption>>>;
  physicalActivity?: InputMaybe<Array<InputMaybe<PhysicalActivity>>>;
  seatingOptions?: InputMaybe<Array<InputMaybe<SeatingOption>>>;
  socialMedia?: InputMaybe<Array<InputMaybe<InputSocialMedia>>>;
  status?: InputMaybe<LocationStatus>;
  tourInfo?: InputMaybe<Array<InputMaybe<TourInput>>>;
  type?: InputMaybe<FacilityType>;
  website?: InputMaybe<Scalars['String']>;
};

export type InputMeadFields = {
  placeholder?: InputMaybe<Scalars['String']>;
};

/** DEPRECATED - Replaced by Menu Sections related inputs. */
export type InputMenuItem = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /** Brew Menu ID. */
  brewMenuId: Scalars['ID'];
  /** Menu Item ID. */
  menuItemId: Scalars['ID'];
};

/** Input for `MenuItem` or `MenuSection` used when updating combined types on `BrewMenu`. */
export type InputMenuItemOrSection = {
  /** The `MenuItem` or `MenuSection` ID. */
  id: Scalars['ID'];
  /** Type of item being added. */
  type: BrewMenuItemType;
};

export type InputMenuItemTag = {
  /** Color of the tag. */
  color: Scalars['String'];
  /** Text label for the tag. */
  label: Scalars['String'];
};

export type InputMenuItemVisibility = {
  /** Determines visibility of a MenuItem on its parent Menu. */
  isVisible: Scalars['Boolean'];
  /** UUID. SK for MenuItem. */
  menuItemId: Scalars['ID'];
};

export type InputMessageCenterEvents = {
  ppSubId: Scalars['ID'];
};

export type InputNearbyAttraction = {
  approximateDistance: Array<InputMaybe<ApproximateDistance>>;
  title: Scalars['String'];
};

export type InputNonAlcoholicFields = {
  placeholder?: InputMaybe<Scalars['String']>;
};

export type InputNutrition = {
  calories?: InputMaybe<Scalars['Float']>;
  carbohydrates?: InputMaybe<Scalars['Float']>;
  cholesterol?: InputMaybe<Scalars['Float']>;
  dietaryFiber?: InputMaybe<Scalars['Float']>;
  fat?: InputMaybe<Scalars['Float']>;
  protein?: InputMaybe<Scalars['Float']>;
  saturatedFat?: InputMaybe<Scalars['Float']>;
  sodium?: InputMaybe<Scalars['Float']>;
  sugars?: InputMaybe<Scalars['Float']>;
  transFat?: InputMaybe<Scalars['Float']>;
};

export type InputOrganizationAssetById = {
  /** Asset ID */
  assetId: Scalars['ID'];
  /** Organization ID */
  organizationId: Scalars['ID'];
};

export type InputOrganizationSlug = {
  organizationId: Scalars['ID'];
};

export type InputPpSettings = {
  batteryReplacementPlan?: InputMaybe<BatteryReplacementPlan>;
  coolers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  couplers?: InputMaybe<Array<InputMaybe<Coupler>>>;
  daysOnHandGoal?: InputMaybe<Scalars['Int']>;
  defaultServingSize?: InputMaybe<Scalars['Int']>;
  freshnessThreshold?: InputMaybe<Scalars['Int']>;
  inventoryTurnsGoal?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  newProductDefinition?: InputMaybe<Scalars['Int']>;
  pricingMethod?: InputMaybe<PricingMethod>;
  pricingPercentage?: InputMaybe<Scalars['Int']>;
  productLifeTracking?: InputMaybe<ProductLifeTracking>;
  seatingCapacity?: InputMaybe<SeatingCapacity>;
  servingSizes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  standardHours?: InputMaybe<Array<InputMaybe<InputHours>>>;
  tappedGoal?: InputMaybe<Scalars['Int']>;
  timeZone?: InputMaybe<TimeZone>;
  varietyScoreGoal?: InputMaybe<Scalars['Int']>;
  workWeekStartDay?: InputMaybe<AbbrevWeekDay>;
};

export type InputPpSubSlug = {
  ppSubId: Scalars['ID'];
};

export type InputPpSubscriptionAssociation = {
  ppSubId: Scalars['String'];
  role: PerformancePlatformRole;
};

export type InputProductFields = {
  abv?: InputMaybe<Scalars['Float']>;
  assets?: InputMaybe<Array<InputMaybe<InputAssetIds>>>;
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  foodPairingList?: InputMaybe<Array<InputMaybe<InputFoodPairing>>>;
  guid?: InputMaybe<Scalars['String']>;
  hintsOfFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  ingredients?: InputMaybe<Array<InputMaybe<InputIngredient>>>;
  isHiddenExternally?: InputMaybe<Scalars['Boolean']>;
  isNewProduct?: InputMaybe<Scalars['Boolean']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  primaryFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  releaseDate?: InputMaybe<Scalars['String']>;
  servingTemperature?: InputMaybe<ServingTemperature>;
  servingVessels?: InputMaybe<Array<InputMaybe<ServingVessel>>>;
  slug?: InputMaybe<Scalars['String']>;
  tastingExperience?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  traits?: InputMaybe<InputProductTraits>;
};

/** Object to store ddb keys for Product. */
export type InputProductIds = {
  brandId: Scalars['String'];
  productId: Scalars['String'];
};

export type InputProductRelationship = {
  brandId: Scalars['ID'];
  canned?: InputMaybe<Scalars['Boolean']>;
  growler?: InputMaybe<Scalars['Boolean']>;
  onTap?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['ID'];
};

export type InputProductTraits = {
  allergens?: InputMaybe<Array<InputMaybe<Allergen>>>;
  availability?: InputMaybe<Availability>;
  nutrition?: InputMaybe<InputNutrition>;
  otherTraits?: InputMaybe<Array<InputMaybe<OtherProductTraits>>>;
};

/** Input for `promoteAssignedKeg` endpoint. */
export type InputPromoteAssignedKegs = {
  /** User can manually update the final kegStatus for the currently tapped keg (if it exists).  Optional - defaults to `Archived`. */
  kegStatus?: InputMaybe<KegStatus>;
  /** ID of the PP Subscription */
  ppSubId: Scalars['ID'];
  /** ID of the Tap Sensor */
  tapSensorId: Scalars['ID'];
};

export type InputRefreshTapSensor = {
  ppSubId: Scalars['ID'];
  tapSensorId: Scalars['ID'];
};

export type InputRemoveGuestBoard = {
  /** Guest Board ID. */
  guestBoardId: Scalars['ID'];
};

export type InputS3Asset = {
  filename: Scalars['String'];
  sizes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InputSaveGuestBoard = {
  /** Board ID. */
  boardId: Scalars['ID'];
  /** Board owner User ID. */
  boardOwnerUserId: Scalars['ID'];
};

export type InputSearchBpl = {
  /** brand fields to filter by. */
  brandFilters?: InputMaybe<FilterBrands>;
  /** filter across all three. This enables the searchBPL `filter` functionality. This cannot be used with search. */
  filters?: InputMaybe<FilterBpLs>;
  /** number of results to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** location fields to filter by. */
  locationFilters?: InputMaybe<FilterLocations>;
  /** next token for paginated results */
  nextToken?: InputMaybe<Scalars['String']>;
  /** order ASC|DESC */
  order?: InputMaybe<Order>;
  /** field to order by */
  orderBy?: InputMaybe<Scalars['String']>;
  /** product fields to filter by. */
  productFilters?: InputMaybe<FilterProducts>;
  /** search fields - This enables the searchBPL `search` functionality. This cannot be used with filters. */
  search?: InputMaybe<SearchFieldsBpl>;
  /** filter by type */
  typeFilters: Array<InputMaybe<OpenSearchType>>;
};

export type InputSearchBrands = {
  /** filter fields to filter based off allowed parameters */
  filters?: InputMaybe<FilterBrands>;
  /** total number of results to fetch */
  limit?: InputMaybe<Scalars['Int']>;
  /** next token of a paginated result */
  nextToken?: InputMaybe<Scalars['String']>;
};

export type InputSearchBrandsLocationsProducts = {
  /** Number of returned results */
  limit?: InputMaybe<Scalars['Int']>;
  /** nextToken for paginated results */
  nextToken?: InputMaybe<Scalars['String']>;
  /** search fields for searching functionality with parity to search BLP v1 */
  search?: InputMaybe<SearchFieldsBrandsLocationsProducts>;
};

export type InputSearchLocations = {
  /** filter fields to filter based off allowed parameters */
  filters?: InputMaybe<FilterLocations>;
  /** total number of results to fetch */
  limit?: InputMaybe<Scalars['Int']>;
  /** next token of a paginated result */
  nextToken?: InputMaybe<Scalars['String']>;
  /** search fields for searching functionality with parity to searchLocationsV1 */
  search?: InputMaybe<SearchFieldsLocations>;
};

export type InputSearchProducts = {
  /** filter fields to filter based off allowed parameters */
  filters?: InputMaybe<FilterProducts>;
  /** total number of results to fetch */
  limit?: InputMaybe<Scalars['Int']>;
  /** next token of a paginated result */
  nextToken?: InputMaybe<Scalars['String']>;
  /** search fields for searching functionality with parity to searchProductsV1 */
  search?: InputMaybe<SearchFieldsProducts>;
};

export type InputSeltzerFields = {
  placeholder?: InputMaybe<Scalars['String']>;
};

export type InputServingSize = {
  /** Allows disabling a serving size without losing the data to which it is attached. */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Sets default pour size for OnTap and BrewMenu. Front-end must enforce only allowing one default per keg. */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** Brew Menu option that is used to determine if this serving is displayed on a `MenuItem`. */
  isDisplayed?: InputMaybe<Scalars['Boolean']>;
  /** Custom display title for this serving size to be used when displaying non-pourable products.  */
  label?: InputMaybe<Scalars['String']>;
  /** Deprecated for pourSize as of 1.23.0. EOL 1.26.0. */
  oz?: InputMaybe<Scalars['Int']>;
  /** Size of the pour, in fluid ounces. */
  pourSize?: InputMaybe<PourSize>;
  /** Cost of this serving size to consumers. */
  price?: InputMaybe<Scalars['Float']>;
  /** If salePrice is populated it overrides price. */
  salePrice?: InputMaybe<Scalars['Float']>;
  /** Enum descibing the type of serving. Ex. `Taster`, `Pint`, `Growler` */
  servingType?: InputMaybe<ServingType>;
};

/** User and Board ID's needed to lookup a shared Board. */
export type InputSharedBoard = {
  /** Board ID. */
  boardId: Scalars['String'];
  /** User/Owner ID of the Board you want to lookup. */
  userId: Scalars['String'];
};

export type InputSocialMedia = {
  handle: Scalars['String'];
  platform: Scalars['String'];
};

export type InputSuperAdminAddRemoveTenantToOrganization = {
  /** Organization ID */
  organizationId: Scalars['ID'];
  /** Tenant(Application) ID - e.g. For a PPSub or Brand */
  tenantId: Scalars['ID'];
  /** The tenant type (e.g. PerformancePlaform, Brand, etc) */
  tenantType: TenantType;
};

export type InputSuperAdminCreateBrewMenuSubscription = {
  /** ID of the Brand to which this BMSub is attached. */
  brandId?: InputMaybe<Scalars['ID']>;
  /** ID of HubSpot company record related to this PP Subscription. */
  hubSpotId?: InputMaybe<Scalars['String']>;
  /** Disables access to BrewMenu features without altering the tier or subscription information. Intended for SuperAdmin use only. */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** IDs of a Brand locations to which this BMSub is attached. */
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** ID of the Organization this Brew Menu Subscription belongs to */
  organizationId: Scalars['ID'];
  /** ID of the PPSub to which this BMSub is attached. */
  ppSubId?: InputMaybe<Scalars['ID']>;
  /** Input for Brew Menu Subscription settings. */
  settings?: InputMaybe<InputBrewMenuSubscriptionSettings>;
  /** Unique URL-safe identifier; if omitted will be autogenerated based on the title. Serves as the base path for subscription content. e.g. `/[BrewMenuSubscription.slug]/display/1` */
  slug?: InputMaybe<Scalars['String']>;
  /** Subscription tier; controls access to features. */
  subscriptionTier?: InputMaybe<BrewMenuSubscriptionTier>;
  /** Subscription display title; only necessary if not attached to a brand or PPSub. */
  title?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminCreateGateway = {
  channel: Scalars['Int'];
  connectionStatus?: InputMaybe<DigiConnectionStatus>;
  digiDeviceId?: InputMaybe<Scalars['String']>;
  firmware?: InputMaybe<Scalars['String']>;
  firmwareStatus?: InputMaybe<Scalars['String']>;
  gatewayDeviceId: Scalars['String'];
  health?: InputMaybe<DeviceHealth>;
  installedDate?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  lastConnect?: InputMaybe<Scalars['String']>;
  lastContactDate?: InputMaybe<Scalars['String']>;
  lastDisconnect?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  pairingDelay: Scalars['Int'];
  panId: Scalars['String'];
  /** Admin must associate a gateway with a PP Subscription at the time of creation */
  ppSubId: Scalars['ID'];
  serviceWindow?: InputMaybe<Scalars['String']>;
  sleepTime: Scalars['Int'];
  status?: InputMaybe<DeviceStatus>;
  tapSensors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<GatewayType>;
  upgradeTimeout: Scalars['Int'];
};

export type InputSuperAdminCreateOrganization = {
  /** Address of the org. e.g. the main office */
  address?: InputMaybe<InputAddress>;
  /** Billing information. */
  billing?: InputMaybe<InputBillingPolicy>;
  /** General contact info for the org. e.g. customer support or main office */
  contact?: InputMaybe<InputContact>;
  /** Unique ID of the org within a 3rd party auth provider, e.g. Clerk. */
  externalId?: InputMaybe<Scalars['String']>;
  /** Company record in Hubspot for maintaining paper trail in CRM for sales/marketing */
  hubspotId?: InputMaybe<Scalars['String']>;
  /** User ID Only of the Org Owner */
  ownerId: Scalars['ID'];
  /** Superadmin-only field for archiving/activating/deactivating an Org. */
  status?: InputMaybe<OrganizationStatus>;
  /** Name of the Organization */
  title: Scalars['String'];
};

export type InputSuperAdminCreatePpSub = {
  address?: InputMaybe<InputAddress>;
  expirationDate?: InputMaybe<Scalars['String']>;
  /** ID of HubSpot company record related to this PP Subscription. Can be found in URL when viewing a company detail page. e.g. https://app.hubspot.com/contacts/6413354/company/**9864910597** */
  hubSpotId?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationType>;
  /** Number of TapSensor associated with this performance platform */
  numberOfTapSensors: Scalars['Int'];
  /** Note, to FE this is not required on create. */
  onlyPriorityBrands?: InputMaybe<Scalars['Boolean']>;
  /** The Organization ID this Performance Platform Subscription belongs to. */
  organizationId: Scalars['ID'];
  priorityBrands?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Note there are required fields within this object for edit */
  settings?: InputMaybe<InputPpSettings>;
  /** Title of the Performance Platform Subscription */
  title: Scalars['String'];
};

export type InputSuperAdminCreateTapSensor = {
  barTitle?: InputMaybe<Scalars['String']>;
  /** Connection status based on date/time the sensor last reported data. */
  connectionStatus?: InputMaybe<ConnectionStatus>;
  /** Optional description or notes related to sensor. This is currently intended for internal, PintLabs, use */
  description?: InputMaybe<Scalars['String']>;
  /** Version number of the firmware */
  firmware?: InputMaybe<Scalars['String']>;
  /** Make and model of the sensor */
  model?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  ppSubId: Scalars['ID'];
  sensorDeviceId: Scalars['String'];
  /** Status of the sensor; indicates whether it is currently in use, temporarily disabled or archived (replaced) */
  status?: InputMaybe<DeviceStatus>;
  tapNumber: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminCreateUser = {
  /** Create the user in Cognito, the legacy authorization provider. */
  createCognitoUser: Scalars['Boolean'];
  /** ID of the user in external auth provider (Clerk) to be used as PK. Only applicable if `!createCognitoUser`. */
  externalId?: InputMaybe<Scalars['String']>;
  /** Flag to indicate if an email should be sent to the user. Only applicable if creating a Cognito user. */
  sendEmail: Scalars['Boolean'];
  /** Fields used to create a user. */
  userFields: InputSuperAdminCreateUserFields;
  /** Flag to mark the user as verified in Cognito; circumvents the verify_email auth flow. Only applicable if creating a Cognito user. */
  verifyUser: Scalars['Boolean'];
};

/** Input used to create a user. */
export type InputSuperAdminCreateUserFields = {
  /** Address information for user. */
  address: InputAddress;
  /** Flag to determine if user has access to Brewery DB. */
  bdbAccess: Scalars['Boolean'];
  /** Flag to determine if user is a superadmin. */
  brewlogixSuperadmin?: InputMaybe<Scalars['Boolean']>;
  /** Contact information for user. */
  contact: CreateContact;
  /** Email address of user. Must match email address send in the `contact` object. */
  email: Scalars['String'];
  /** Last name of user. */
  familyName: Scalars['String'];
  /** An end users selection of favorite Brand/Product/Location */
  favorites?: InputMaybe<InputFavorites>;
  /** First name of user. */
  givenName: Scalars['String'];
  /** Additional, arbitrary context on a User stored as JSON encoded string. */
  meta?: InputMaybe<Scalars['String']>;
  /** MarketMyBrewery Brand Associations. Authorization field for MMB. */
  mmbBrands?: InputMaybe<Array<InputMaybe<InputBrandsAssociation>>>;
  /** Performance Platform Subscription Associations. Authorization field for PPB. */
  ppSubscriptions?: InputMaybe<Array<InputMaybe<InputPpSubscriptionAssociation>>>;
  /** The source of the user's registration. */
  registrationSource: RegistrationSource;
  /** Allows access to internal tools and features, such as the Docs site when set to `true`. */
  trustedUser?: InputMaybe<Scalars['Boolean']>;
};

export type InputSuperAdminDeleteAccountRequest = {
  id: Scalars['ID'];
};

export type InputSuperAdminDeleteBrand = {
  id: Scalars['ID'];
};

export type InputSuperAdminDeleteBrewMenuSubscription = {
  id: Scalars['ID'];
};

export type InputSuperAdminDeleteOrganization = {
  /** Organization ID */
  organizationId: Scalars['ID'];
};

export type InputSuperAdminDeletePpSubItem = {
  itemId: Scalars['ID'];
  ppSubId: Scalars['ID'];
};

export type InputSuperAdminDeleteTapSensor = {
  ppSubId: Scalars['ID'];
  tapSensorId: Scalars['ID'];
};

export type InputSuperAdminDeleteUnverifiedProduct = {
  ppSubId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type InputSuperAdminDownloadRawReport = {
  /** The name of the file you want to download. */
  filename: Scalars['String'];
  /** Performance Platform Subscription ID. */
  ppSubId: Scalars['ID'];
};

export type InputSuperAdminGetAccountRequests = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminGetGateways = {
  filters?: InputMaybe<FilterSuperAdminGetGateways>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminGetInventoryRecords = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminGetMessages = {
  filters?: InputMaybe<FilterSuperAdminGetMessages>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminGetOrganizations = {
  /** Available fields to filter results by. */
  filters?: InputMaybe<FilterSuperAdminGetOrganizations>;
  /** Max number of results to return per page. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Next token if there are multple pages of results. */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Order of results - ASC/DESC. */
  order?: InputMaybe<Order>;
  /** Filter field to order results by. */
  orderBy?: InputMaybe<Scalars['String']>;
};

/** Input for `superAdminGetTapSensors` endpoint. */
export type InputSuperAdminGetTapSensors = {
  /** Available fields to filter results by. */
  filters?: InputMaybe<FilterSuperAdminGetTapSensors>;
  /** Max number of results to return per page. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Next token if there are multple pages of results. */
  nextToken?: InputMaybe<Scalars['String']>;
  /** Order of results - ASC/DESC. */
  order?: InputMaybe<Order>;
  /** Filter field to order results by. */
  orderBy?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminGetUsers = {
  filters?: InputMaybe<FilterSuperAdminGetUsers>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminPromoteAssignedKegs = {
  /** User can manually update the final kegStatus for the currently tapped keg (if it exists).  Optional - defaults to `Archived`. */
  kegStatus?: InputMaybe<KegStatus>;
  /** ID of the PP Subscription */
  ppSubId: Scalars['ID'];
  /** ID of the Tap Sensor */
  tapSensorId: Scalars['ID'];
};

export type InputSuperAdminRemoveUserOrganization = {
  /** If a user is an owner and you still want to remove; set this flag to True */
  force?: InputMaybe<Scalars['Boolean']>;
  /** The user id of user to add to organization */
  userId: Scalars['ID'];
};

export type InputSuperAdminSetUserOrganization = {
  /** If a user already belongs to an organization or if the owner is already set and you want to override; set this flag to True */
  force?: InputMaybe<Scalars['Boolean']>;
  /** The Organization ID to add user to */
  organizationId: Scalars['ID'];
  /** The Role that should be associated with the user */
  role: UserAssociationRole;
  /** The user id of user to add to organization */
  userId: Scalars['ID'];
};

export type InputSuperAdminUpdateAccountRequest = {
  additionalData?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  approvedDate?: InputMaybe<Scalars['String']>;
  claimedBrand?: InputMaybe<Scalars['ID']>;
  contact?: InputMaybe<CreateContact>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  newBrandAddress?: InputMaybe<InputAddress>;
  newBrandTitle?: InputMaybe<Scalars['String']>;
  /** Notes logged in PintLabs by admins to keep track of reasons why requests were denied or other relevant info. */
  notes?: InputMaybe<Scalars['String']>;
  requestedRole?: InputMaybe<AccountRequestRole>;
  /** Approval status of the request. Defaults to Pending and changed by admins in PintLabs */
  status?: InputMaybe<AccountRequestStatus>;
  /** If approved, the ID of the user that was created from this account request */
  userId?: InputMaybe<Scalars['ID']>;
};

export type InputSuperAdminUpdateBrewMenuSubscription = {
  /** ID of the Brand to which this BMSub is attached. */
  brandId?: InputMaybe<Scalars['ID']>;
  /** ID of HubSpot company record related to this PP Subscription. */
  hubSpotId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Disables access to BrewMenu features without altering the tier or subscription information. Intended for SuperAdmin use only. */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** IDs of a Brand locations to which this BMSub is attached. */
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** ID of the PPSub to which this BMSub is attached. */
  ppSubId?: InputMaybe<Scalars['ID']>;
  settings?: InputMaybe<InputBrewMenuSubscriptionSettings>;
  /** Unique URL-safe identifier. Serves as the base path for subscription content. e.g. `/[BrewMenuSubscription.slug]/display/1` */
  slug?: InputMaybe<Scalars['String']>;
  /** Subscription tier; controls access to features. */
  subscriptionTier?: InputMaybe<BrewMenuSubscriptionTier>;
  /** Subscription display title; only necessary if not attached to a brand or PPSub. */
  title?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminUpdateGateway = {
  channel?: InputMaybe<Scalars['Int']>;
  connectionStatus?: InputMaybe<DigiConnectionStatus>;
  digiDeviceId?: InputMaybe<Scalars['String']>;
  firmware?: InputMaybe<Scalars['String']>;
  firmwareStatus?: InputMaybe<Scalars['String']>;
  gatewayDeviceId?: InputMaybe<Scalars['String']>;
  gatewayId: Scalars['ID'];
  health?: InputMaybe<DeviceHealth>;
  installedDate?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  lastConnect?: InputMaybe<Scalars['String']>;
  lastContactDate?: InputMaybe<Scalars['String']>;
  lastDisconnect?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  pairingDelay?: InputMaybe<Scalars['Int']>;
  panId?: InputMaybe<Scalars['String']>;
  /** PP subscription ID. This ID is a pk and cannot be changed. Once created it the value must remain the same. */
  ppSubId: Scalars['ID'];
  serviceWindow?: InputMaybe<Scalars['String']>;
  sleepTime?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<DeviceStatus>;
  tapSensors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<GatewayType>;
  upgradeTimeout?: InputMaybe<Scalars['Int']>;
};

export type InputSuperAdminUpdateOrganization = {
  /** Address of the org. e.g. the main office */
  address?: InputMaybe<InputAddress>;
  /** Billing information. */
  billing?: InputMaybe<InputBillingPolicy>;
  /** General contact info for the org. e.g. customer support or main office */
  contact?: InputMaybe<InputContact>;
  /** Unique ID of the org within a 3rd party auth provider, e.g. Clerk. */
  externalId?: InputMaybe<Scalars['String']>;
  /** Company record in Hubspot for maintaining paper trail in CRM for sales/marketing */
  hubspotId?: InputMaybe<Scalars['String']>;
  /** Organization ID */
  organizationId: Scalars['ID'];
  /** User ID Only of the Org Owner */
  ownerId?: InputMaybe<Scalars['ID']>;
  /** Superadmin-only field for archiving/activating/deactivating an Org. */
  status?: InputMaybe<OrganizationStatus>;
  /** Name of the Organization */
  title?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminUpdatePpSub = {
  address?: InputMaybe<InputAddress>;
  expirationDate?: InputMaybe<Scalars['String']>;
  /** ID of HubSpot company record related to this PP Subscription. Can be found in URL when viewing a company detail page. e.g. https://app.hubspot.com/contacts/6413354/company/**9864910597** */
  hubSpotId?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationType>;
  numberOfTapSensors?: InputMaybe<Scalars['Int']>;
  onlyPriorityBrands?: InputMaybe<Scalars['Boolean']>;
  ppSubId: Scalars['ID'];
  priorityBrands?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  settings?: InputMaybe<InputPpSettings>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminUpdateTapSensor = {
  barTitle?: InputMaybe<Scalars['String']>;
  /** Connection status based on date/time the sensor last reported data. */
  connectionStatus?: InputMaybe<ConnectionStatus>;
  /** Optional description or notes related to sensor. This is currently intended for internal, PintLabs, use */
  description?: InputMaybe<Scalars['String']>;
  /** Version number of the firmware */
  firmware?: InputMaybe<Scalars['String']>;
  /** Make and model of the sensor */
  model?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  ppSubId: Scalars['ID'];
  sensorDeviceId?: InputMaybe<Scalars['String']>;
  /** Status of the sensor; indicates whether it is currently in use, temporarily disabled or archived (replaced) */
  status?: InputMaybe<DeviceStatus>;
  tapNumber?: InputMaybe<Scalars['Int']>;
  tapSensorId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type InputSuperAdminUpdateUser = {
  id: Scalars['ID'];
  /** Updatable user fields */
  userFields?: InputMaybe<InputSuperAdminUpdateUserFields>;
  /** Field to indicate if a user has been verified. */
  verifyUser?: InputMaybe<Scalars['Boolean']>;
};

/** Input used to update a user ddb reference. */
export type InputSuperAdminUpdateUserFields = {
  /** Address information for user. */
  address?: InputMaybe<InputAddress>;
  /** Flag to determine if user has access to Brewery DB. */
  bdbAccess?: InputMaybe<Scalars['Boolean']>;
  /** Flag to determine if user is a superadmin. */
  brewlogixSuperadmin?: InputMaybe<Scalars['Boolean']>;
  /** Contact information for user. */
  contact?: InputMaybe<CreateContact>;
  /** Email address of user. Must match email address send in the `contact` object. */
  email?: InputMaybe<Scalars['String']>;
  /** Last name of user. */
  familyName?: InputMaybe<Scalars['String']>;
  /** First name of user. */
  givenName?: InputMaybe<Scalars['String']>;
  /** Determines if a user is active or disabled. */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Additional, arbitrary context on a User stored as JSON encoded string. */
  meta?: InputMaybe<Scalars['String']>;
  /** The source of the user's registration. */
  registrationSource?: InputMaybe<RegistrationSource>;
  /** Allows access to internal tools and features, such as the Docs site when set to `true`. */
  trustedUser?: InputMaybe<Scalars['Boolean']>;
};

export type InputSuperAdminUploadCsReport = {
  /** Required for matching the upload file to the DB record. */
  filename?: InputMaybe<Scalars['String']>;
  /** Performance Platform Subscription ID. */
  ppSubId: Scalars['ID'];
  /** Report ID. */
  reportId: Scalars['ID'];
};

export type InputSuperAdminUser = {
  id: Scalars['ID'];
};

export type InputTapSensorEvents = {
  ppSubId: Scalars['ID'];
};

export type InputTapSensorMessageInfo = {
  barTitle?: InputMaybe<Scalars['String']>;
  gatewayDeviceId?: InputMaybe<Scalars['ID']>;
  tapNumber?: InputMaybe<Scalars['Int']>;
  tapSensorId?: InputMaybe<Scalars['ID']>;
};

/** Unique fields for an Unverified Product - currently only supporting new products from existing PP Subs */
export type InputUnverifiedFields = {
  /** Since this product is unverified, user may choose to enter a String for the Brand Title */
  brandTitle?: InputMaybe<Scalars['String']>;
  /** User may choose to leave some notes on why this product is unique (not in our database) */
  comment?: InputMaybe<Scalars['String']>;
  /** User may freeform type any beverage type, this may not match our schema */
  customBrewType?: InputMaybe<Scalars['String']>;
  /** International Bitterness Units (typically 0-100) */
  ibu?: InputMaybe<Scalars['Float']>;
  styleID?: InputMaybe<Scalars['ID']>;
};

export type InputUpdateAsset = {
  /** Alt text. Also serves as asset title */
  alt?: InputMaybe<Scalars['String']>;
  /** ID of asset to update */
  assetId: Scalars['ID'];
  /** Brand to which this asset is attached. **This should not be mutated directly by front-end** Called automatically by `update<Product|Brand|Location>` */
  brand?: InputMaybe<Scalars['ID']>;
  /** ID of brand to which this asset will be attached */
  brandId: Scalars['ID'];
  /** File size, in bytes, of the unmodified asset */
  bytes?: InputMaybe<Scalars['Int']>;
  /** Additional text to describe the assset. Only used in certain placements on front-end. */
  caption?: InputMaybe<Scalars['String']>;
  /** Primary organizational category */
  category?: InputMaybe<AssetCategory>;
  cloudinaryAssetFields?: InputMaybe<InputCloudinaryAsset>;
  /** Height, in pixels, of the full-size asset */
  height?: InputMaybe<Scalars['Int']>;
  /** Locations to which this asset is attached. **This should not be mutated directly by front-end** Called automatically by `update<Product|Brand|Location>` */
  locations?: InputMaybe<Array<InputMaybe<InputLocationIds>>>;
  /** Products to which this asset is attached. **This should not be mutated directly by front-end** Called automatically by `update<Product|Brand|Location>` */
  products?: InputMaybe<Array<InputMaybe<InputProductIds>>>;
  s3AssetFields?: InputMaybe<InputS3Asset>;
  /** indicates whether asset can be viewed by other users */
  shared?: InputMaybe<Scalars['Boolean']>;
  /** organizational tags used to organize assets in media library */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** MIME type */
  type?: InputMaybe<Scalars['String']>;
  /** Width, in pixels, of the full-size asset */
  width?: InputMaybe<Scalars['Int']>;
};

export type InputUpdateBoard = {
  /** ID of the Board to update */
  boardId: Scalars['ID'];
  /** Description of the board. */
  description?: InputMaybe<Scalars['String']>;
  /** Not required. Assumed False by other Board logic. */
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  /** Title of the Board */
  title: Scalars['String'];
};

/** Input for `updateBrewMenu` endpoint. */
export type InputUpdateBrewMenu = {
  /** If used provides a date range for which the menu is active to avoid showing inactive menus and help with scheduling. `[startDate,endDate]` Expects null or 2 dates. Date strings, in UTC of when the menu is active. Start and end dates, respectively. */
  activeDates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** id of BrewMenuSubscription. */
  bmSubId: Scalars['ID'];
  /** id of BrewMenu to update. */
  brewMenuId: Scalars['ID'];
  /** BDB location to which the menu is connected, if any. Crucial for populating information about the location such as hours, address, logos, etc. */
  locationId?: InputMaybe<Scalars['ID']>;
  /** DEPRECATED, EOL v1.28, use `sectionIds`. Ordered list of type/id pairs for `MenuItems` and `MenuSections`. */
  menuItemOrSectionIds?: InputMaybe<Array<InputMaybe<InputMenuItemOrSection>>>;
  /** DEPRECATED, EOL v1.28, use `menuItemOrSectionIds`. List of MenuItems that are associated with the BrewMenu. */
  menuItems?: InputMaybe<Array<InputMaybe<InputMenuItem>>>;
  /** PP Subscription to which the menu is connected, if any. Crucial for populating information about the location such as hours, address, logos, etc. */
  ppSubId?: InputMaybe<Scalars['ID']>;
  /** Ordered list of `MenuSection` IDs. */
  sectionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** See BrewMenuStatus enum for status options. */
  status?: InputMaybe<BrewMenuStatus>;
  /** Name of the menu. */
  title?: InputMaybe<Scalars['String']>;
};

export type InputUpdateBrewMenuDisplay = {
  /** Cloudinary Asset ID used as background watermark. Frontend should manage uploading and fetching this field. */
  backgroundWatermarkAssetId?: InputMaybe<Scalars['String']>;
  /** Associated BrewMenuSubscription Id. */
  bmSubId: Scalars['ID'];
  /** BrewMenuDisplayId. */
  brewMenuDisplayId: Scalars['ID'];
  /** Menu content to display. */
  brewMenuId?: InputMaybe<Scalars['ID']>;
  /** Cloudinary Asset ID. Frontend should manage uploading and fetching this field. */
  cloudinaryAssetId?: InputMaybe<Scalars['String']>;
  /** Overrides the title on consumer-facing apps. */
  displayTitle?: InputMaybe<Scalars['String']>;
  /** The type of the display. Used to control which settings are exposed in the admin and which templates are used to render the menu on the front-end. */
  displayType: DisplayType;
  /** DEPRECATED */
  promotionalImageAssetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** List of Frontend managed Cloudinary Asset IDs and filenames used as promotional images on the display. If null or empty, no associated images. */
  promotionalImageAssets?: InputMaybe<Array<InputMaybe<InputCloudinaryAssetIdAndName>>>;
  /** See type BrewMenuDisplaySettings for more information. */
  settings?: InputMaybe<InputBrewMenuDisplaySettings>;
  /** URL slug for Display. */
  slug?: InputMaybe<Scalars['String']>;
  /** See enum BrewMenuStatus for available options. */
  status: BrewMenuStatus;
  /** Title of the display. Used to generate the slug and for admin purposes. */
  title?: InputMaybe<Scalars['String']>;
};

/**
 * Input for `updateBrewMenuItems` endpoint.
 * DEPRECATED, EOL v1.28, use `inputUpdateBrewMenu`.
 */
export type InputUpdateBrewMenuItems = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /** Brew Menu Id. */
  brewMenuId: Scalars['ID'];
  /** List of Brew Menu Item IDs. Will be validated and stored in the order recieved. */
  menuItemIds: Array<InputMaybe<Scalars['ID']>>;
};

export type InputUpdateBrewMenuItemsVisibility = {
  /** Associated BrewMenuSubscription Id. */
  bmSubId: Scalars['ID'];
  /** Array of menu items to be updated */
  menuItems?: InputMaybe<Array<InputMaybe<InputMenuItemVisibility>>>;
};

export type InputUpdateBrewMenuProductAvailability = {
  /** Main switch: if this is false the rest of the data is ignored. */
  available?: InputMaybe<Scalars['Boolean']>;
  bottled?: InputMaybe<Scalars['Boolean']>;
  canned?: InputMaybe<Scalars['Boolean']>;
  cask?: InputMaybe<Scalars['Boolean']>;
  crowler?: InputMaybe<Scalars['Boolean']>;
  growler?: InputMaybe<Scalars['Boolean']>;
  nitro?: InputMaybe<Scalars['Boolean']>;
  onTap?: InputMaybe<Scalars['Boolean']>;
  /** Redundant to canned and bottled, but provides a more generic option */
  packaged?: InputMaybe<Scalars['Boolean']>;
};

/** Input for Update Brew Menu Subscription. */
export type InputUpdateBrewMenuSubscription = {
  /** Brew Menu Subscription ID required by authorizer. */
  bmSubId: Scalars['ID'];
  /** Set the logo for this subscription */
  logo?: InputMaybe<InputOrganizationAssetById>;
  /** Input for Brew Menu Subscription settings. */
  settings?: InputMaybe<InputBrewMenuSubscriptionSettings>;
  /** Unique URL-safe identifier. Serves as the base path for subscription content. e.g. `/[BrewMenuSubscription.slug]/display/1` */
  slug?: InputMaybe<Scalars['String']>;
  /** Subscription display title; only necessary if not attached to a brand or PPSub. */
  title?: InputMaybe<Scalars['String']>;
};

export type InputUpdateBreweryRoute = {
  coordinates?: InputMaybe<Array<InputMaybe<InputBreweryRouteGeoCoordInput>>>;
  description?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['Float']>;
  duration?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  /** routeId provided by MapBox */
  routeId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Input for updating a Brew Menu Custom Product. */
export type InputUpdateCustomProduct = {
  /** Beer specfic attributes. */
  beerFields?: InputMaybe<InputBeerFields>;
  /** UUID. SK of BrewMenuCustomProduct. */
  bmCustomProductId: Scalars['ID'];
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /** Cider specific attributes. */
  ciderFields?: InputMaybe<InputCiderFields>;
  /** Cocktail specific attributes. */
  cocktailFields?: InputMaybe<InputCocktailFields>;
  /** Coffee specific attributes. */
  coffeeFields?: InputMaybe<InputCoffeeFields>;
  /** Description of the BrewMenuCustomProduct. */
  description?: InputMaybe<Scalars['String']>;
  /** Kombucha specific attributes. */
  kombuchaFields?: InputMaybe<InputKombuchaFields>;
  /** Mead specific attributes. */
  meadFields?: InputMaybe<InputMeadFields>;
  /** Nonalcoholic specific attributes. */
  nonAlcoholicFields?: InputMaybe<InputNonAlcoholicFields>;
  /** Input for generic Product attributes. */
  productFields?: InputMaybe<InputProductFields>;
  /** Seltzer specific attributes. */
  seltzerFields?: InputMaybe<InputSeltzerFields>;
  /** List of available serving sizes and prices for this product. */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  /** Additional information about a BrewMenuCustomProduct. */
  subtitle?: InputMaybe<Scalars['String']>;
  /** Name of the BrewMenuCustomProduct. */
  title?: InputMaybe<Scalars['String']>;
  /** Unverified specific attributes. */
  unverifiedFields?: InputMaybe<InputUnverifiedFields>;
  /** Wine specific attributes. */
  wineFields?: InputMaybe<InputWineFields>;
};

/** Input for updating a DiscountPreset. */
export type InputUpdateDiscountPreset = {
  /** ID of the brew menu subscription */
  bmSubId: Scalars['ID'];
  /** Dates, hours and days of the week the discount is available */
  dateTimeActive?: InputMaybe<InputDateAvailability>;
  /** ID of the DiscountPreset */
  discountPresetId: Scalars['ID'];
  /** Fixed amount of the discount */
  fixedAmount?: InputMaybe<Scalars['Float']>;
  /** Percent discount */
  percent?: InputMaybe<Scalars['Float']>;
  /** Pour sizes that this discount will apply to if added to a Menu Item. */
  pourSizes?: InputMaybe<Array<InputMaybe<PourSize>>>;
  /** See enum CustomProductType for available options. */
  productType?: InputMaybe<CustomProductType>;
  /** Title of the DiscountPreset */
  title?: InputMaybe<Scalars['String']>;
  /** DiscountPreset logic to apply using the `value`. */
  type?: InputMaybe<DiscountPresetType>;
};

export type InputUpdateInventoryRecord = {
  description?: InputMaybe<Scalars['String']>;
  /** Estimated time the incoming devices will arrive. */
  incomingEta?: InputMaybe<Scalars['String']>;
  /** ID of inventory record to update */
  inventoryRecordId: Scalars['ID'];
  inventoryType?: InputMaybe<InventoryType>;
  partNumber?: InputMaybe<Scalars['String']>;
  /** Number of devices on order */
  quantityIncoming?: InputMaybe<Scalars['Int']>;
  /** Number of devices available in the warehouse */
  quantityOnHand?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<InventoryStatus>;
  /** Quantity level used to determine reorder status */
  statusThreshold?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InputUpdateMenuItem = {
  /** Assets for this brew menu. The whole list must be provided in the order you wish to display the assets */
  assets?: InputMaybe<Array<InputMaybe<InputOrganizationAssetById>>>;
  /** Form factors in which the product is offered. */
  availability?: InputMaybe<InputUpdateBrewMenuProductAvailability>;
  /** Associated BrewMenuSub Id. */
  bmSubId: Scalars['ID'];
  /** Discount Preset IDs used by the field resolver. */
  discountPresetIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Go-to serving vessel for the menu item. May be overridden if `BrewMenuProductAvailability` does not include `OnTap`  */
  glassware?: InputMaybe<ServingVessel>;
  /** Determines visibility of a MenuItem. */
  isVisible?: InputMaybe<Scalars['Boolean']>;
  /** MenuItem Id. */
  menuItemId: Scalars['ID'];
  /** One of Product, CustomProduct, or TapSensor. */
  menuItemType?: InputMaybe<MenuItemType>;
  /** List of available serving sizes. */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  /** List of `MenuItemTags` assoicated with this `MenuItem`. */
  tags?: InputMaybe<Array<InputMaybe<InputMenuItemTag>>>;
};

/** Input for `updateMenuSection` endpoint. */
export type InputUpdateMenuSection = {
  /** Brew Menu Subscription ID. */
  bmSubId: Scalars['ID'];
  /**
   * List of `BrewMenuDisplay` IDs that should hide this section.
   * - Omit = No change
   */
  hiddenOnDisplays?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Ordered list of `MenuItem` IDs for this section. */
  menuItemIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Menu Section ID. */
  menuSectionId: Scalars['ID'];
  /** Title of the section. */
  title?: InputMaybe<Scalars['String']>;
};

export type InputUpdateOrganization = {
  /** Address of the org. e.g. the main office */
  address?: InputMaybe<InputAddress>;
  /** Frontend managed Cloudinary Asset ID used as this orgs Logo. If null, no associated logo */
  cloudinaryAssetId?: InputMaybe<Scalars['String']>;
  /** General contact info for the org. e.g. customer support or main office */
  contact?: InputMaybe<InputContact>;
  /** NOTE: Not currently in use until media library support implemented. Use cloudinaryAssetId. Set the logo for this subscription */
  logo?: InputMaybe<InputOrganizationAssetById>;
  /** Organization ID. Full field name for auth purposes. */
  organizationId: Scalars['ID'];
  /** Name of the Organization */
  title?: InputMaybe<Scalars['String']>;
};

export type InputUpdateOrganizationAsset = {
  /** Asset ID */
  assetId: Scalars['ID'];
  /** Cloudinary ID */
  cloudinaryId?: InputMaybe<Scalars['String']>;
  /** Organization ID */
  organizationId: Scalars['ID'];
  /** Do we need this field right now? */
  shared?: InputMaybe<Scalars['Boolean']>;
  /** Tags added/edited by users for this asset */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InputUpdateOrganizationGroup = {
  /** Brew Menu Sub ID. Submit `null` to remove it from the location. */
  bmSubId?: InputMaybe<Scalars['ID']>;
  /** Brand ID. Submit `null` to remove it from the location. */
  brandId?: InputMaybe<Scalars['ID']>;
  /** Frontend managed Cloudinary Asset ID used as this group image. If null, no associated logo. Use this field until the media library is in place. */
  cloudinaryAssetId?: InputMaybe<Scalars['String']>;
  /** Organization Group ID. */
  organizationGroupId: Scalars['ID'];
  /** Organization ID. */
  organizationId: Scalars['ID'];
  /** Performance Platform Sub ID. Submit `null` to remove it from the location. */
  ppSubId?: InputMaybe<Scalars['ID']>;
  /** Group title. */
  title?: InputMaybe<Scalars['String']>;
};

/** Input for `updateOrganizationPricingPreset` endpoint. */
export type InputUpdateOrganizationPricingPreset = {
  /** Indicates if the PricingPreset is the default */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** ID of the Organization */
  organizationId: Scalars['ID'];
  /** ID of the PricingPreset */
  pricingPresetId: Scalars['ID'];
  /** List of inputServingSize objects */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  /** Title of the PricingPreset */
  title?: InputMaybe<Scalars['String']>;
};

/**
 * Input for `updatePricingPreset` endpoint.
 * @DEPRECATED - Use `inputUpdateOrganizationPricingPreset`
 */
export type InputUpdatePricingPreset = {
  /** ID of the Brew Menu Subscription */
  bmSubId?: InputMaybe<Scalars['ID']>;
  /** Indicates if the PricingPreset is the default */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** ID of the PricingPreset */
  itemId: Scalars['ID'];
  /** ID of the PricingPreset */
  ppSubId?: InputMaybe<Scalars['ID']>;
  /** List of inputServingSize objects */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  /** Title of the PricingPreset */
  title?: InputMaybe<Scalars['String']>;
};

/**
 * Input used to toggle if a user's favorite list to return
 * contains only active items or all items.
 */
export type InputUserFavorites = {
  /** True to return only active favorites, False to return all favorites. Defaults to True. */
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type InputUserSlug = {
  userId: Scalars['ID'];
};

export type InputVerifySlug = {
  /** input required when using slugType=BrewMenuSubscription */
  inputBMSubSlug?: InputMaybe<InputBmSubSlug>;
  /** input required when using slugType=(Brand|Product|Location) */
  inputBrandSlug?: InputMaybe<InputBrandSlug>;
  /** input required when using slugType=Organization */
  inputOrganizationSlug?: InputMaybe<InputOrganizationSlug>;
  /** input required when using slugType=PerformancePlatformSubscription */
  inputPPSubSlug?: InputMaybe<InputPpSubSlug>;
  /** input required when using slugType=User */
  inputUserSlug?: InputMaybe<InputUserSlug>;
  /** the slug which you would like to verify */
  slug: Scalars['String'];
  /** the overarching Type for the slug you wish to verify */
  slugType: SlugTypes;
};

export type InputWineFields = {
  acidity?: InputMaybe<Acidity>;
  alcohol?: InputMaybe<Alcohol>;
  body?: InputMaybe<Body>;
  colorIntensity?: InputMaybe<ColorIntensity>;
  finish?: InputMaybe<Finish>;
  flavorIntensity?: InputMaybe<FlavorIntensity>;
  perceivedSweetness?: InputMaybe<PerceivedSweetness>;
  wineColor?: InputMaybe<WineColor>;
};

export type PpSubSlugInput = {
  ppSubId: Scalars['ID'];
};

export type RearrangeTapSensorAssignedKegs = {
  ppSubId: Scalars['ID'];
  rearrangedKegIndices: Array<InputMaybe<Scalars['Int']>>;
  tapSensorId: Scalars['ID'];
};

export type RemoveAndReplaceTappedKeg = {
  /** ID of the tapped Keg */
  kegId: Scalars['ID'];
  /** ID of the PP Subscription */
  ppSubId: Scalars['ID'];
  /** ID of the Tap Sensor */
  tapSensorId: Scalars['ID'];
};

export type RemoveAssignedKeg = {
  kegId: Scalars['ID'];
  ppSubId: Scalars['ID'];
  tapSensorId: Scalars['ID'];
};

export type RemoveTappedKeg = {
  /** User can manually update the final kegStatus for the currently tapped keg (if it exists). The kegStatus input only works for `Unassigned` or `Depleted`. Defaults to `Unassigned`. */
  kegStatus?: InputMaybe<KegStatus>;
  /** ID of the PP Subscription */
  ppSubId: Scalars['ID'];
  /** ID of the Tap Sensor */
  tapSensorId: Scalars['ID'];
};

export type SearchFieldsBpl = {
  /** Fields which can be searched on brands. This functionality allows you to filter results in searchBPL (Brands). */
  searchFieldsBrands?: InputMaybe<SearchFieldsBrands>;
  /** Enables the ability to search across all three types. This is a wildcard search across all three types. */
  searchFieldsBrandsLocationsProducts?: InputMaybe<SearchFieldsBrandsLocationsProducts>;
  /** Fields which can be searched on locations. This functionality allows you to filter results in searchBPL (Locations). */
  searchFieldsLocations?: InputMaybe<SearchFieldsLocations>;
  /** Fields which can be searched on products. This functionality allows you to filter results in searchBPL (Products). */
  searchFieldsProducts?: InputMaybe<SearchFieldsProducts>;
  /** This is the wildcard search text. It will search across title, brand.title, and description in the BPL. */
  wildcardText: Scalars['String'];
};

export type SearchFieldsBrands = {
  /** While using the search brands functionality, results can be filtered by title. */
  title?: InputMaybe<Scalars['String']>;
  /** While using the search brands functionality, results can be filtered by yearEstablished. */
  yearEstablished?: InputMaybe<Scalars['String']>;
};

export type SearchFieldsBrandsLocationsProducts = {
  /** Allows additional filtering based off provided arguments */
  stringIsOneOf?: InputMaybe<Array<InputMaybe<StringIsOneOf>>>;
  typeFilters?: InputMaybe<Array<InputMaybe<OpenSearchType>>>;
  /** Wildcard Text for the search */
  wildcardText?: InputMaybe<Scalars['String']>;
};

export type SearchFieldsLocations = {
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by brew options. */
  brewOptions?: InputMaybe<Array<InputMaybe<BrewOption>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by educational events. */
  educationalActivity?: InputMaybe<Array<InputMaybe<EducationalActivity>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by entertainment events. */
  entertainmentActivity?: InputMaybe<Array<InputMaybe<EntertainmentActivity>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by food options. */
  foodOptions?: InputMaybe<Array<InputMaybe<FoodOption>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by game activites. */
  gameActivity?: InputMaybe<Array<InputMaybe<GameActivity>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by geoBox geoBoxSearchInput. */
  geoBoxSearch?: InputMaybe<GeoBoxSearchInput>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by inputGeoPolygonSearch. */
  geoPolygonSearch?: InputMaybe<InputGeoPolygonSearch>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by geoRadius geoRadiusSearchInput. */
  geoRadiusSearch?: InputMaybe<GeoRadiusSearchInput>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by isPrimary location. */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by location hours. */
  locationHours?: InputMaybe<InputLocationHours>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by miscellaneous activites. */
  miscActivity?: InputMaybe<Array<InputMaybe<MiscActivity>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by patron options. */
  patronOptions?: InputMaybe<Array<InputMaybe<PatronOption>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by physical activities. */
  physicalActivity?: InputMaybe<Array<InputMaybe<PhysicalActivity>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by seating options. */
  seatingOptions?: InputMaybe<Array<InputMaybe<SeatingOption>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by state. */
  state?: InputMaybe<Scalars['String']>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by location status. */
  status?: InputMaybe<LocationStatus>;
  /** This is used with the searchBPL `search` products functionality, the results can be filtered by key words from the title, brand.title, and description fields. */
  stringIsOneOf?: InputMaybe<Array<InputMaybe<StringIsOneOf>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by tour information. */
  tourInfo?: InputMaybe<Array<InputMaybe<TourInput>>>;
  /** This is used with the searchBPL `search` locations functionality, the results can be filtered by type. */
  type?: InputMaybe<FacilityType>;
};

export type SearchFieldsProducts = {
  /** This is used with the searchBPL `search` products functionality, the results can be filtered by abv ranges. */
  abvRange?: InputMaybe<FloatRangeInput>;
  /** This is used with the searchBPL `search` products functionality, the results can be filtered by Availability status. */
  availabilities?: InputMaybe<Array<InputMaybe<Availability>>>;
  /** This is used with the searchBPL `search` products functionality, the results can be filtered by blxGroup. */
  blxGroup?: InputMaybe<Scalars['String']>;
  /** This is used with the searchBPL `search` products functionality, the results can be filtered by BrewTypes. */
  brewTypes?: InputMaybe<Array<InputMaybe<BrewTypes>>>;
  /** This is used with the searchBPL `search` products functionality, the, results can be filtered by ibu ranges. */
  ibuRange?: InputMaybe<FloatRangeInput>;
  /** This is used with the searchBPL `search` products functionality, the weights on title, brand.title, and description can be weighted (0-10). */
  searchFieldsProductsWeights?: InputMaybe<SearchFieldsProductsWeights>;
  /** This is used with the searchBPL `search` products functionality, the results can be filtered by srm ranges. */
  srmRange?: InputMaybe<FloatRangeInput>;
  /** This is used with the searchBPL `search` products functionality, the results can be filtered by state. */
  state?: InputMaybe<Scalars['String']>;
  /** This is used with the searchBPL `search` products functionality, the results can be filtered by key words. */
  stringIsOneOf?: InputMaybe<Array<InputMaybe<StringIsOneOf>>>;
  /** This is used with the searchBPL `search` products functionality, the results can be filtered by style Id. */
  styleIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Input for product weights */
export type SearchFieldsProductsWeights = {
  /** Weight for brandObject title field. Can be an integer from 0-10. */
  brandObjectTitleWeight?: InputMaybe<Scalars['Int']>;
  /** Weight for description field. Can be an integer from 0-10. */
  descriptionWeight?: InputMaybe<Scalars['Int']>;
  /** Weight for title field. Can be an integer from 0-10. */
  titleWeight?: InputMaybe<Scalars['Int']>;
};

export type SearchLocations = {
  brewOptions?: InputMaybe<Array<InputMaybe<BrewOption>>>;
  educationalActivity?: InputMaybe<Array<InputMaybe<EducationalActivity>>>;
  entertainmentActivity?: InputMaybe<Array<InputMaybe<EntertainmentActivity>>>;
  foodOptions?: InputMaybe<Array<InputMaybe<FoodOption>>>;
  gameActivity?: InputMaybe<Array<InputMaybe<GameActivity>>>;
  geoBoxSearch?: InputMaybe<GeoBoxSearchInput>;
  geoRadiusSearch?: InputMaybe<GeoRadiusSearchInput>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  miscActivity?: InputMaybe<Array<InputMaybe<MiscActivity>>>;
  patronOptions?: InputMaybe<Array<InputMaybe<PatronOption>>>;
  physicalActivity?: InputMaybe<Array<InputMaybe<PhysicalActivity>>>;
  seatingOptions?: InputMaybe<Array<InputMaybe<SeatingOption>>>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LocationStatus>;
  tourInfo?: InputMaybe<Array<InputMaybe<TourInput>>>;
  type?: InputMaybe<FacilityType>;
  wildcardText?: InputMaybe<Scalars['String']>;
};

export type SetTappedKeg = {
  kegId: Scalars['ID'];
  lastValidRawReading?: InputMaybe<Scalars['Float']>;
  ppSubId: Scalars['ID'];
  rawReading?: InputMaybe<Scalars['Float']>;
  tapSensorId: Scalars['ID'];
  transformedReading?: InputMaybe<Scalars['Float']>;
};

export type StringIsOneOf = {
  field: Scalars['String'];
  values: Array<InputMaybe<Scalars['String']>>;
};

export type StyleSearchInput = {
  abvMax?: InputMaybe<Scalars['Float']>;
  abvMin?: InputMaybe<Scalars['Float']>;
  category?: InputMaybe<CategoryInput>;
  description?: InputMaybe<Scalars['String']>;
  fgMax?: InputMaybe<Scalars['Float']>;
  fgMin?: InputMaybe<Scalars['Float']>;
  foodPairingList?: InputMaybe<Array<InputMaybe<InputFoodPairing>>>;
  hintsOfFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  hops?: InputMaybe<Hops>;
  ibuMax?: InputMaybe<Scalars['Int']>;
  ibuMin?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  malt?: InputMaybe<Malt>;
  ogMax?: InputMaybe<Scalars['Float']>;
  ogMin?: InputMaybe<Scalars['Float']>;
  primaryFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  servingTemperature?: InputMaybe<ServingTemperature>;
  servingVessels?: InputMaybe<Array<InputMaybe<ServingVessel>>>;
  srmMax?: InputMaybe<Scalars['Int']>;
  srmMin?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  yeast?: InputMaybe<Yeast>;
};

export type TourInput = {
  cost?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Array<InputMaybe<InputHours>>>;
  mustBeLegalDrinkingAge?: InputMaybe<Scalars['Boolean']>;
  toursAvailable: Scalars['Boolean'];
};

/** CRUD input for updating a Brand. */
export type UpdateBrand = {
  /** Address information for a Brand. */
  address?: InputMaybe<InputAddress>;
  /** Assets tied to this specific Brand. Contains a list of brandId's and assetId's. */
  assets?: InputMaybe<Array<InputMaybe<InputAssetIds>>>;
  /** Brewers association ID. */
  baId?: InputMaybe<Scalars['String']>;
  /** Brand ID. PK. */
  brandId: Scalars['ID'];
  /** Contact information for a Brand. */
  contact?: InputMaybe<CreateContact>;
  /** General description of the Brand. Limited on the front-end to 800 characters. */
  description?: InputMaybe<Scalars['String']>;
  /** Overrides the title on consumer-facing apps. */
  displayTitle?: InputMaybe<Scalars['String']>;
  /** Featured product Id for a Brand. */
  featuredBrewId?: InputMaybe<Scalars['ID']>;
  /** Unique Legacy Id from BreweryDB/SteadyServ. Still used to reference original data and to create 301 redirects to old content. */
  guid?: InputMaybe<Scalars['String']>;
  /** Slug of the Brand. AutoGenerated in createBrand using the title. */
  slug?: InputMaybe<Scalars['String']>;
  /** Social media platforms associated with the Brand. */
  socialMedia?: InputMaybe<Array<InputMaybe<InputSocialMedia>>>;
  /** Additional information about a Brand. */
  subtitle?: InputMaybe<Scalars['String']>;
  /** Title of the brand. Used everywhere in public display unless overridden by `displayTitle`. */
  title?: InputMaybe<Scalars['String']>;
  /** Traits of a Brand. inputBrandTraits contains status, brandStatus, brandTypes, brewTypes, isIndepentdent, and isContract. */
  traits?: InputMaybe<InputBrandTraits>;
  /** Website associated with the Brand. */
  website?: InputMaybe<Scalars['String']>;
  /** Year that the Brand was established. */
  yearEstablished?: InputMaybe<Scalars['String']>;
};

export type UpdateCider = {
  acidity?: InputMaybe<Array<InputMaybe<Acidity>>>;
  appleVariety?: InputMaybe<Array<InputMaybe<AppleVariety>>>;
  assets?: InputMaybe<Array<InputMaybe<InputAssetIds>>>;
  body?: InputMaybe<Array<InputMaybe<Body>>>;
  brandId: Scalars['ID'];
  brixCount?: InputMaybe<Scalars['Float']>;
  ciderColor?: InputMaybe<CiderColor>;
  ciderFamily?: InputMaybe<Array<InputMaybe<CiderFamily>>>;
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  foodPairingList?: InputMaybe<Array<InputMaybe<InputFoodPairing>>>;
  fruitOrigin?: InputMaybe<Array<InputMaybe<FruitOrigin>>>;
  guid?: InputMaybe<Scalars['String']>;
  hintsOfFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  pearVariety?: InputMaybe<Array<InputMaybe<PearVariety>>>;
  perceivedSweetness?: InputMaybe<Array<InputMaybe<PerceivedSweetness>>>;
  primaryFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  productId: Scalars['ID'];
  releaseDate?: InputMaybe<Scalars['String']>;
  servingTemperature?: InputMaybe<ServingTemperature>;
  servingVessels?: InputMaybe<Array<InputMaybe<ServingVessel>>>;
  slug?: InputMaybe<Scalars['String']>;
  tannin?: InputMaybe<Array<InputMaybe<Tannin>>>;
  title?: InputMaybe<Scalars['String']>;
  traits?: InputMaybe<InputProductTraits>;
};

export type UpdateKeg = {
  actualKickDate?: InputMaybe<Scalars['String']>;
  assignedPositionIndex?: InputMaybe<Scalars['Int']>;
  bornOnDate?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['ID']>;
  cooler?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  coupler?: InputMaybe<Scalars['String']>;
  couplerType?: InputMaybe<CouplerType>;
  currentFluidOz?: InputMaybe<Scalars['Float']>;
  /** DEPRECATED in favor of `servings[n].isDefault`. Sets the default serving size for this keg. */
  defaultServingSize?: InputMaybe<Scalars['Int']>;
  depletionPace?: InputMaybe<Scalars['Int']>;
  depletionRate?: InputMaybe<Scalars['Float']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  initialFluidOz?: InputMaybe<Scalars['Float']>;
  isEstimate?: InputMaybe<Scalars['Boolean']>;
  isPartial?: InputMaybe<Scalars['Boolean']>;
  isTemporary?: InputMaybe<Scalars['Boolean']>;
  kegId: Scalars['ID'];
  kegStatus?: InputMaybe<KegStatus>;
  ppSubId: Scalars['ID'];
  productId?: InputMaybe<Scalars['ID']>;
  projectedKickDate?: InputMaybe<Scalars['String']>;
  receivedDate?: InputMaybe<Scalars['String']>;
  /** DEPRECATED in favor of `servings`. Serving sizes, in ounces. */
  servingSizes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Servings sizes and price for this keg. Used for BrewMenu. */
  servings?: InputMaybe<Array<InputMaybe<InputServingSize>>>;
  tappedDate?: InputMaybe<Scalars['String']>;
  unexpectedWeightIncreases?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED - Use `productId` with no `brandId` for unverified products. */
  unverifiedProductId?: InputMaybe<Scalars['ID']>;
  vesselId?: InputMaybe<Scalars['ID']>;
};

export type UpdateLocation = {
  address?: InputMaybe<InputAddress>;
  assets?: InputMaybe<Array<InputMaybe<InputAssetIds>>>;
  brandId: Scalars['ID'];
  contact?: InputMaybe<CreateContact>;
  description?: InputMaybe<Scalars['String']>;
  guid?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Array<InputMaybe<InputHours>>>;
  locationHighlights?: InputMaybe<Array<InputMaybe<InputLocationHighlight>>>;
  locationId: Scalars['ID'];
  slug?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<TimeZone>;
  title?: InputMaybe<Scalars['String']>;
  traits?: InputMaybe<InputLocationTraits>;
  yearClosed?: InputMaybe<Scalars['String']>;
  yearOpened?: InputMaybe<Scalars['String']>;
};

export type UpdateMessage = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  messageId: Scalars['ID'];
  ppSubId: Scalars['ID'];
  tapSensorsInfo?: InputMaybe<Array<InputMaybe<InputTapSensorMessageInfo>>>;
  timeRead?: InputMaybe<Scalars['String']>;
  timeResolved?: InputMaybe<Scalars['String']>;
};

export type UpdatePpSub = {
  address?: InputMaybe<InputAddress>;
  expirationDate?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationType>;
  logo?: InputMaybe<InputOrganizationAssetById>;
  numberOfTapSensors?: InputMaybe<Scalars['Int']>;
  onlyPriorityBrands?: InputMaybe<Scalars['Boolean']>;
  ppSubId: Scalars['ID'];
  priorityBrands?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  settings?: InputMaybe<InputPpSettings>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateProduct = {
  beerFields?: InputMaybe<InputBeerFields>;
  /**
   * brandId, for updating a normal product.
   * Either a brandId or ppSubId must be provided, sometimes both if converting unverified -> verified
   */
  brandId?: InputMaybe<Scalars['ID']>;
  ciderFields?: InputMaybe<InputCiderFields>;
  cocktailFields?: InputMaybe<InputCocktailFields>;
  coffeeFields?: InputMaybe<InputCoffeeFields>;
  kombuchaFields?: InputMaybe<InputKombuchaFields>;
  meadFields?: InputMaybe<InputMeadFields>;
  nonAlcoholicFields?: InputMaybe<InputNonAlcoholicFields>;
  /**
   * ppSubId, for updating an unverified product.
   * Either a brandId or ppSubId must be provided, sometimes both if converting unverified -> verified
   */
  ppSubId?: InputMaybe<Scalars['ID']>;
  productFields?: InputMaybe<InputProductFields>;
  productId: Scalars['ID'];
  productType: BrewTypes;
  seltzerFields?: InputMaybe<InputSeltzerFields>;
  unverifiedFields?: InputMaybe<InputUnverifiedFields>;
  wineFields?: InputMaybe<InputWineFields>;
};

export type UpdateStyle = {
  /** Maximum alcohol percentage. */
  abvMax?: InputMaybe<Scalars['Float']>;
  /** Mimimum alcohol percentage. */
  abvMin?: InputMaybe<Scalars['Float']>;
  /** Grouping of styles. */
  blxGroup?: InputMaybe<Scalars['String']>;
  /** Category of the style. */
  category?: InputMaybe<CreateCategory>;
  /** General description of the style. Limited on the front-end to 800 characters. */
  description?: InputMaybe<Scalars['String']>;
  /** Final Gravity (FG) - Maximum value of the style's Final Gravity. */
  fgMax?: InputMaybe<Scalars['Float']>;
  /** Final Gravity (FG) - Minimum value of the style's Final Gravity. */
  fgMin?: InputMaybe<Scalars['Float']>;
  /** List of food pairings for the style. */
  foodPairingList?: InputMaybe<Array<InputMaybe<InputFoodPairing>>>;
  /** List of hints of flavor notes for the style. */
  hintsOfFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  /** List of hops used in the style. */
  hops?: InputMaybe<Hops>;
  /** International Bitterness Units (IBU) - Maximum measurement of the style's bitterness. */
  ibuMax?: InputMaybe<Scalars['Int']>;
  /** International Bitterness Units (IBU) - Minimum measurement of the style's bitterness. */
  ibuMin?: InputMaybe<Scalars['Int']>;
  /** Unique ID of the style. Primary key in DDB. */
  id: Scalars['ID'];
  /** Keg Freshness (KF) - The freshness duration of a keg. */
  kegFreshnessDuration?: InputMaybe<Scalars['Int']>;
  /** Label of the style. */
  label?: InputMaybe<Scalars['String']>;
  /** List of malts used in the style. */
  malt?: InputMaybe<Malt>;
  /** Original Gravity (OG) - Maximum value of the style's Original Gravity. */
  ogMax?: InputMaybe<Scalars['Float']>;
  /** Original Gravity (OG) - Minimum value of the style's Original Gravity. */
  ogMin?: InputMaybe<Scalars['Float']>;
  /** List of primary flavor notes for the style. */
  primaryFlavorNotes?: InputMaybe<Array<InputMaybe<FlavorNote>>>;
  /** Serving temperature of the style. */
  servingTemperature?: InputMaybe<ServingTemperature>;
  /** Serving vessels used for the style. */
  servingVessels?: InputMaybe<Array<InputMaybe<ServingVessel>>>;
  /** Slug of the Style. Auto-Generated */
  slug?: InputMaybe<Scalars['String']>;
  /** Standard Research Method (SRM) - Maximum value of the style's measured color. */
  srmMax?: InputMaybe<Scalars['Int']>;
  /** Standard Research Method (SRM) - Minimum value of the style's measured color. */
  srmMin?: InputMaybe<Scalars['Int']>;
  /** Title of the style. */
  title?: InputMaybe<Scalars['String']>;
  /** List of yeasts used in the style. */
  yeast?: InputMaybe<Yeast>;
};

export type UpdateUser = {
  address?: InputMaybe<InputAddress>;
  contact?: InputMaybe<CreateContact>;
  contactPreference?: InputMaybe<Array<InputMaybe<ContactPreference>>>;
  defaultSubscription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  favorites?: InputMaybe<InputFavorites>;
  givenName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<JobTitle>;
};

export type UpdateVessel = {
  awrSleep?: InputMaybe<Scalars['String']>;
  awrWeight?: InputMaybe<Scalars['String']>;
  couplerWeight?: InputMaybe<Scalars['Float']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  iconSlug?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Material>;
  shortTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  suggestedTotalVolume?: InputMaybe<Scalars['Float']>;
  tareWeight?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  totalVolume?: InputMaybe<Scalars['Float']>;
};

export type UserSlugInput = {
  userId: Scalars['ID'];
};

export type VerifySlugInput = {
  brandSlugInput?: InputMaybe<BrandSlugInput>;
  ppSubSlugInput?: InputMaybe<PpSubSlugInput>;
  slug: Scalars['String'];
  slugType: SlugTypes;
  userSlugInput?: InputMaybe<UserSlugInput>;
};
